import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import { isBlankOrNull } from "../../../utils/Helpres"

class EditLabelModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isShowError: false,
      labelName: ""
    }
  }

  handleChangeLabelName(event) {
    const newLabelName = event.target.value
    const isShowError = isBlankOrNull(newLabelName) || this.props.data?.labels?.some(lb => lb?.name?.toLowerCase() === newLabelName?.toLowerCase())
    this.setState({ labelName: isShowError ? newLabelName : newLabelName, isShowError: isShowError })
  }

  onSaveNameLabel = () => {
    const { labelName } = this.state
    const { label, onLabelUpdate } = this.props.data
    const newLabelName = labelName?.trim()
    if (isBlankOrNull(newLabelName)) {
      this.setState({ isShowError: true })
      return
    }
    onLabelUpdate(label, newLabelName)
    this.toggleModal()
  }

  toggleModal = () => {
    this.props.onHide()
  }

  render() {
    const { label } = this.props.data
    return (
      <Modal
        {...this.props}
        onHide={this.toggleModal}
        centered
        aria-labelledby="contained-modal-title-vcenter"
        className="n-modal__modal"
      >
        <div className="n-modal">
          <div className="n-modal__wrapper">
            <div className="n-modal__close" onClick={this.toggleModal}>
              <svg
                width="20rem"
                height="20rem"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 2.01429L17.9857 0L10 7.98571L2.01429 0L0 2.01429L7.98571 10L0 17.9857L2.01429 20L10 12.0143L17.9857 20L20 17.9857L12.0143 10L20 2.01429Z"
                />
              </svg>
            </div>
            <div className="n-modal__header">
              <div className="n-modal__header__icon">
                <svg width="44rem" height="40rem" viewBox="0 0 44 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_533_3970)">
                    <path d="M29.3991 16.3901L30.1291 15.6601C30.4691 15.3201 30.4691 14.7501 30.1291 14.4301L28.0791 12.3801C27.8991 12.2001 27.6891 12.1301 27.4591 12.1301M27.4591 12.1201C27.2391 12.1201 27.0091 12.2101 26.8491 12.3701L24.3391 14.9101M22.2391 16.9801L14.6191 24.6001V27.8801H17.8991L27.5891 18.1901L24.3091 14.9101" stroke="#203B60" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M22.5 38C32.4411 38 40.5 29.9411 40.5 20C40.5 10.0589 32.4411 2 22.5 2C12.5589 2 4.5 10.0589 4.5 20C4.5 29.9411 12.5589 38 22.5 38Z" stroke="#203B60" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_533_3970">
                      <rect width="38" height="38" fill="white" transform="translate(3.5 1)"/>
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className="n-modal__header__title">
                Edit <b>Label name</b>
              </div>
              <div className="n-modal__line" />
              <div className="n-modal__content n-modal__content--flex">
                <div className="n-modal__content__title">
                  Label&nbsp;<b>{label?.name}</b>
                </div>
                <div className={this.state.isShowError ? "n-input error" : "n-input" }>
                    <input
                        defaultValue={label?.name}
                        type="text"
                        maxLength={20}
                        onChange={this.handleChangeLabelName.bind(this)}
                        placeholder="Type label name"
                    />
                    <div className="n-input__error-message">
                      {isBlankOrNull(this.state.labelName) ? "The label name is empty" : "This label name is already taken"}
                    </div>
                  </div>
                </div>
              <div className="n-modal__btns">
                <div className="button-lg" onClick={this.onSaveNameLabel}>
                  Save
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

export default EditLabelModal