import React, { Component } from 'react'
import { useDropzone } from 'react-dropzone'
import { storageCreate, storageDelete } from '../../../../../../services/storage/storage'
import { uuidv4, generateThumbnail, humanFileSize } from "../../../../../../utils/Helpres"
import {connect} from 'react-redux'
import {updateMedia} from '../../../../../../store/actions/RichMessageActions'
import { Gluejar } from '@charliewilco/gluejar'
import { ProgressBar } from 'react-bootstrap'


import * as K from '../../../../../../utils/Constant'
import ImageTooLarge from '../../../../../modal/permission-message/ImageTooLarge'
import ModalProgressBar from '../../../../../modal/progress-bar/ModalProgressBar'


import { Translation } from 'react-i18next';

const DropzoneContainer = (props) => {

  const onDrop = acceptedFiles => {
    acceptedFiles.forEach(file => {
      if (file?.size >= props.filesize_limit) {        
        props.openImageTooLarge()
        return
      }

      if (!/\.(jpe?g|png)$/i.test(file.name)) return
      const reader = new FileReader()
      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        // Trigger callback
        props.onDrop && props.onDrop({
          name: file.name,
          size: humanFileSize(file.size),
          dataUrl: reader.result,
          original: file
        })
      }
      reader.readAsDataURL(file)
    })
  }

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({ accept: 'image/*', onDrop })

  let className = 'text-center'

  // Set paddings depending on file list is empty or not
  className += !props.files.length ? ' p-0' : ' pt-0 pl-0'

  // Define border color depending on status
  if (isDragActive || isDragAccept) className += ' border-primary'
  else if (isDragReject) className += ' border-danger'
  else className += ' border-light'

  // Dropzone container style
  const style = {
    // border: '3px dashed',
    // outline: '0'
  }
// console.log(props.files, "photo");
  return (
    <React.Fragment>
      <div {...getRootProps({ className, style })}>
        <input {...getInputProps()} />

        {/* Don't show placeholder if the file list isn't empty */}
        {!props.files.length && (
          <div className="text-big text-muted">
            <Translation>{(t, { i18n }) => t("filter_menu_text_46")}</Translation>
          </div>
        )}

        {/* Print files */}
        {!!props.files.length && (
          // Container
          <div className="d-flex align-items-start align-content-start flex-wrap">
            {props.files.map(file => (
              // File card
              <div className="card card-condenced mb-3 mr-3" style={{width: '180px'}} key={file.name} onClick={e => e.stopPropagation()}>
                <div className="card-body">
                  <img className="mb-2" src={file.dataUrl || file.image} alt={file.name} style={{width: '100%'}} />
                  <div className="fontWeight-semibold text-left text-truncate" title={file.name}>{file.name}</div>
                  <div className="text-left small text-muted">{file.size}</div>
                </div>
                {/* Remove button */}
                <button
                  className="btn btn-outline-danger btn-sm card-footer py-2"
                  style={{boxShadow: 'none', borderLeft: 0, borderRight: 0, borderBottom: 0}}
                  onClick={() => props.onRemove && props.onRemove(file)}
                >
                  <Translation>{(t, { i18n }) => t("filter_menu_text_47")}</Translation>
                  </button>
              </div>
            ))}
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

class ImageCard extends Component {
  constructor(props) {
    super(props)

    this.onFileDrop = this.onFileDrop.bind(this)
    this.onFileRemove = this.onFileRemove.bind(this)

    this.state = {
      files: [],
      message: '',
      // fileProgress: '',
      fileProgress: 0,
      showImageTooLarge: false,DropzoneContainer
    }
  }  

  progressCallback = (progress) => {
    const fileProgress = 100 * (progress.loaded / progress.total)
    this.openProgressBar()
    // console.log('UPLOAD FILE PROGRESS: ', fileProgress)
    this.setState({ fileProgress: fileProgress }) 
    if (fileProgress === 100) {
      this.closeProgressBar()
      this.setState({ fileProgress: 0 })
    }
  }

  addFileToStorage = (file) => {
    console.log(file);
    const fileId = uuidv4()
    var bufBase64 = file?.dataUrl.replace(/^data:image\/\w+;base64,/,"")
    var buf = new Buffer.from(bufBase64, 'base64')
    // const fileType = file?.name?.endsWith('.png') ? 'image/png' : 'image/jpeg'
const fileType = 'image/png'

    storageCreate(fileId, buf, fileType, this.progressCallback )
    .then(data => {
      console.log('UPLOAD FILE DONE ', data, file, buf)
      generateThumbnail(file?.original, [300, 300])
      .then(previewBlob => {
        var previewBufBase64 = previewBlob?.replace(/^data:image\/\w+;base64,/,"")
        var previewBuf = new Buffer.from(previewBufBase64, 'base64')
        const previewId = uuidv4()
        storageCreate(previewId, previewBuf, fileType)
        .then(data => {                           
          console.log('UPLOAD PREVIEW FILE DONE ', data)
          const media = { preview: previewId, original: fileId}
          this.props.updateMedia({id: this.props.post?.id, media: media}) 
        })
        .catch(err => {
          console.log('UPLOAD PREVIEW FILE ERROR ', err)
          const media = { preview: fileId, original: fileId}
          this.props.updateMedia({id: this.props.post?.id, media: media}) 
        })
      })
      .catch(err => {
        console.log('GENERATE PREVIEW FILE ERROR ', err)
        const media = { preview: fileId, original: fileId}
        this.props.updateMedia({id: this.props.post?.id, media: media}) 
      })      
      this.setState(state => ({
        files: state.files.concat(file)
      }))
    })
    .catch(err => console.log('UPLOAD FILE ERROR ', err))
  }

  addClipBoardFileToStorage = (file) => {
    // console.log(file);
    if (this.state.files.length) {return}
    if (file.images.length !== 0 && file.images[0] != null) {
      fetch(file.images[0])
      .then(r => r.blob())
      .then(blobFile => {
        console.log('blobFile', blobFile);
        if (blobFile?.type != null && 
            (blobFile?.type === 'image/png' || blobFile?.type === 'image/jpeg' || blobFile?.type === 'image/jpg') &&
            blobFile?.size < 30485761) {
            const fileId = uuidv4()
            // console.log('fileId', fileId);
            storageCreate(fileId, blobFile, blobFile?.type, this.progressCallback )
            .then(data => { 
              console.log('UPLOAD FILE DONE ', data)
              generateThumbnail(blobFile, [300, 300])
                .then(previewBlob => {
                  var bufBase64 = previewBlob?.replace(/^data:image\/\w+;base64,/,"")
                  var previewBuf = new Buffer.from(bufBase64, 'base64')
                  const photo = {
                    dataUrl: previewBlob
                  }
                  this.setState(state => ({
                    files: state.files.concat(photo)
                  }))
                  const previewId = uuidv4()
                  storageCreate(previewId, previewBuf, blobFile?.type )
                  .then(data => {                           
                    console.log('UPLOAD PREVIEW FILE DONE ', data)
                    const media = { preview: previewId, original: fileId}
                    this.props.updateMedia({id: this.props.post?.id, media: media}) 
                    if (media.preview.length > 0) {
                      this.closeProgressBar()
                    }
                  })
                  .catch(err => {
                    console.log('UPLOAD PREVIEW FILE ERROR ', err)
                    const media = { preview: fileId, original: fileId}
                    this.props.updateMedia({id: this.props.post?.id, media: media}) 
                  })
                })
                .catch(err => {
                  console.log('GENERATE PREVIEW FILE ERROR ', err)
                  const media = { preview: fileId, original: fileId}
                  this.props.updateMedia({id: this.props.post?.id, media: media}) 
                })
                // this.setState(state => ({
                //   files: state.files.concat(file)
                // }))
            })
            .catch(err => console.log('UPLOAD FILE ERROR ', err))
        }
      })
    }
    file.images = []  
  }

  onFileDrop(file) {
    this.addFileToStorage(file)
  }

  onFileRemove(file) {
    if (this.props.post?.media?.original != null) {
      storageDelete(this.props.post?.media?.original)
      .then(data => console.log('DELETE FILE DONE ', data))
      .catch(err => console.log('DELETE FILE ERROR ', err))
    }    
    this.setState(state => {
      const files = [...state.files]
      files.splice(files.indexOf(file), 1)
      return { files }
    })
  }

  openImageTooLarge = () => { this.setState({ showImageTooLarge: true }) }
  closeImageTooLarge = () => { this.setState({ showImageTooLarge: false }) }

  openProgressBar = () => { this.setState({ showProgressBar: true }) }
  closeProgressBar = () => { this.setState({ showProgressBar: false }) }

  render() { 
    var filesize_limit = 0
    try {
      const filesize_limit_permission = this.props.permissions.find(per => per?.includes(K.FEATURE_MESSAGE_MAXIMUM_IMAGE_SIZE))
      filesize_limit = filesize_limit_permission != null ? parseInt(filesize_limit_permission.split(":")[1]) : 0
    } catch(err) { console.log('FEATURE_MESSAGE_MAXIMUM_IMAGE_SIZE decode failed', err) }

    return ( 
      <div className="rrich-message__message__card__body__image-card">
        <div className="rrich-message__message__card__body__image-card__icon">
          <svg width="20rem" height="16rem" viewBox="0 0 20 16" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="Rich-message-input-window:-Visual-Composition" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><g id="Group-28" transform="translate(-16.000000, -244.000000)" fill="#A3A4A6"><g id="Group-7" transform="translate(9.000000, 233.000000)"><g id="Mixins/Plugins/Bootstrap-Markdown/Icons/Image" transform="translate(0.000000, 1.000000)"><path d="M24.5340909,10.8181818 L9.46590909,10.8181818 C8.50567472,10.8181818 7.72727273,11.6165428 7.72727273,12.6013986 L7.72727273,23.3006993 C7.72727273,24.2855551 8.50567472,25.0839161 9.46590909,25.0839161 L24.5340909,25.0839161 C25.4943253,25.0839161 26.2727273,24.2855551 26.2727273,23.3006993 L26.2727273,12.6013986 C26.2727273,11.6165428 25.4943253,10.8181818 24.5340909,10.8181818 Z M24.3167614,23.3006993 L9.68323864,23.3006993 C9.56321084,23.3006993 9.46590909,23.2009026 9.46590909,23.0777972 L9.46590909,12.8243007 C9.46590909,12.7011953 9.56321084,12.6013986 9.68323864,12.6013986 L24.3167614,12.6013986 C24.4367892,12.6013986 24.5340909,12.7011953 24.5340909,12.8243007 L24.5340909,23.0777972 C24.5340909,23.2009026 24.4367892,23.3006993 24.3167614,23.3006993 L24.3167614,23.3006993 Z M12.3636364,14.0874126 C11.5634652,14.0874126 10.9147727,14.7527382 10.9147727,15.5734266 C10.9147727,16.3941149 11.5634652,17.0594406 12.3636364,17.0594406 C13.1638075,17.0594406 13.8125,16.3941149 13.8125,15.5734266 C13.8125,14.7527382 13.1638075,14.0874126 12.3636364,14.0874126 Z M11.2045455,21.5174825 L22.7954545,21.5174825 L22.7954545,18.5454545 L19.625522,15.2942417 C19.4557876,15.1201552 19.180576,15.1201552 19.0108054,15.2942417 L14.6818182,19.7342657 L13.250522,18.2662697 C13.0807876,18.0921831 12.805576,18.0921831 12.6358054,18.2662697 L11.2045455,19.7342657 L11.2045455,21.5174825 Z" id="↳-🎨-Fill"></path></g></g></g></g></svg>
        </div>
        <Gluejar onPaste={files => this.addClipBoardFileToStorage(files)} onError={err => console.error(err)}></Gluejar>
        <div className="rrich-message__message__card__body__image-card__text">Image:</div>
        <div className="rrich-message__message__card__body__image-card__table">
        {this.state.fileProgress.length > 0
        ? <div className="progress-bar">
            <ProgressBar now={this.state.fileProgress} label={`${this.state.fileProgress}%`} />
          </div>
          :   
          <DropzoneContainer files={this.state.files} onDrop={this.onFileDrop} onRemove={this.onFileRemove} openImageTooLarge={this.openImageTooLarge.bind(this)} filesize_limit={filesize_limit}/>
        }
        </div>
        <ImageTooLarge
          show={ this.state.showImageTooLarge}
          onHide = { this.closeImageTooLarge.bind(this) } />
        <ModalProgressBar
          fileProgress={ this.state.fileProgress }
          show={ this.state.showProgressBar}
          onHide = { this.closeProgressBar.bind(this) } />
      </div>
    )
  }
}

const mapStateToProps = store => {
  return {
    permissions: store.permissions
  }
}

const mapDispatchToProps = dispatch => {
  return { updateMedia: (id, media) => dispatch(updateMedia(id, media)) }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageCard)