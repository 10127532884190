import React, { Component } from "react"
import { Modal } from "react-bootstrap"

class VendorInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      comment: "",
    }
  }

  render() {
    const { info, src, name } = this.props.data
    return (
      <Modal {...this.props} centered className="n-modal__modal">
        <div className="n-modal">
          <div className="n-modal__wrapper">
            <div className="n-modal__close" onClick={this.props.onHide}>
              <svg
                width="20rem"
                height="20rem"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M20 2.01429L17.9857 0L10 7.98571L2.01429 0L0 2.01429L7.98571 10L0 17.9857L2.01429 20L10 12.0143L17.9857 20L20 17.9857L12.0143 10L20 2.01429Z" />
              </svg>
            </div>
            <div className="n-modal__header">
              <img
                height={120}
                width={120}
                className="n-modal__vendor-img"
                src={src}
                alt=""
              />
              <div className="n-modal__header__title">{name}</div>
              <div className="n-modal__line" />
            </div>
            <div
              style={{ maxHeight: "calc(100vh - 430px)", flexWrap: "nowrap" }}
              className="n-modal__content n-modal__content--flex"
            >
              <div className="n-modal__content__list-block">
                <div className="n-modal__content__list-block__description">
                  Description
                </div>
                <div dangerouslySetInnerHTML={{ __html: info }} />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

export default VendorInfo
