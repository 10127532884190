import { serialize } from '../../utils/Helpres';
import { STRIPE_API_LIMIT } from "../../utils/Constant"

const stripeApiUrl = 'https://api.stripe.com/v1';
const stripeApiHost = 'https://api.stripe.com';

const buildHeaders = (stripekey) => {
  return {
    'Authorization': `Bearer ${stripekey}`,
    'Content-Type': 'application/x-www-form-urlencoded',
  }
}

const loadStripeList = async (action, headers, customParams) => {
  const nextLoad = async (action, headers, customParams, lastId) => {
    const params = new URLSearchParams({"limit": STRIPE_API_LIMIT});
    if (lastId != null) {
      params.append("starting_after", lastId)
    }
    const _ = customParams?.forEach((value, key) => params.append(key, value))
    const response = await fetch(action + "?" + params.toString(), {
      method: 'GET',
      headers,
    })
    const items = await response?.json()
    return items
  }

  var savedItems = []
  var response = await nextLoad(action, headers, customParams, null)
  if (response?.data == null) {
    return null
  }
  var items = response?.data ?? []
  if (items?.length > 0) { savedItems = [...savedItems, ...items] }
  var afterItem = [...items].pop()
  while (response?.has_more && afterItem?.id != null) {
    response = await nextLoad(action, headers, customParams, afterItem?.id)
    items = response?.data ?? []
    afterItem = [...items].pop()
    if (items?.length > 0) { savedItems = [...savedItems, ...items] }
  }
  return savedItems
}

export const getBalance = async (stripeKey) => {
  const headers = buildHeaders(stripeKey)
  return await fetch(`${stripeApiUrl}/balance`, {
    method: 'GET',
    headers,
  }).then(response => {
    return response.json()
  }).catch(error => {
    console.error(error);    
  })
}

export const createCustomer = async (stripeKey, email) => {
  const headers = buildHeaders(stripeKey)
  return await fetch(`${stripeApiUrl}/customers`, {
    method: 'POST',
    headers,
    body: `email=${email}`,
  }).then(response => {
    return response.json()
  }).catch(error => {
    console.error(error);    
  })
}

export const listCustomers = async (stripeKey, email) => {
  const headers = buildHeaders(stripeKey)
  const params = new Map()
  if (email != null) {
    params.set("email", email)
  }
  const items = await loadStripeList(`${stripeApiUrl}/customers`, headers, params)
  return items
}

export const updateCustomer = async (stripeKey, customerId, paymentId) => {
  const headers = buildHeaders(stripeKey)
  return await fetch(`${stripeApiUrl}/customers/${customerId}`, {
    method: 'POST',
    headers,
    body: `invoice_settings[default_payment_method]=${paymentId}`,
  }).then(response => {
    const data = response.json()
    return data
  }).catch(error => {
    console.log('Customer default payment updated', error)
  })
}

export const getCustomer = async (stripeKey, customerId) => {
  const headers = buildHeaders(stripeKey)
  return await fetch(`${stripeApiUrl}/customers/${customerId}`, {
    method: 'GET',
    headers,
  }).then(response => {
    return response.json()
  }).catch(error => {
    console.error(error);    
  })
}

export const destroyCustomer = async (stripeKey, customerId) => {
  const headers = buildHeaders(stripeKey)
  return await fetch(`${stripeApiUrl}/customers/${customerId}`, {
    method: 'DELETE',
    headers,
  }).then(response => {
    return response.json()
  }).catch(error => {
    console.error(error);    
  })
}

export const openCustomerPortalLink = async (stripeKey, customerId) => {
  const headers = buildHeaders(stripeKey)
  const body = serialize({
    customer: customerId,
    return_url: window.location.href,
  })
  return await fetch(`${stripeApiUrl}/billing_portal/sessions`, {
    method: 'POST',
    headers,
    body,
  }).then(response => {
    return response.json()
  }).catch(error => {
    console.error(error);    
  })
}

// generate a unique product per productId
export const createProduct = async (stripeKey, productId, name, authorName) => {
  const headers = buildHeaders(stripeKey)
  const body = serialize({
    id: productId,
    name: name,
    active: true,
    "metadata[authorName]": authorName,
  });
  return await fetch(`${stripeApiUrl}/products`, {
    method: 'POST',
    headers,
    body,
  }).then(response => {
    return response.json()
  }).catch(error => {
    console.error(error);    
  })
}

export const listProduct = async (stripeKey) => {
  const headers = buildHeaders(stripeKey)
  const items = await loadStripeList(`${stripeApiUrl}/products`, headers, null)
  return items
}

export const destroyProduct = async (stripeKey, productId) => {
  const headers = buildHeaders(stripeKey)
  return await fetch(`${stripeApiUrl}/products/${productId}`, {
    method: 'DELETE',
    headers,
  }).then(response => {
    return response.json()
  }).catch(error => {
    console.error(error);    
  })
}

// generate a unique plan per productId
// export const createPlan = async (stripeKey, productId, name, amount, currency, interval) => {
//   const headers = buildHeaders(stripeKey)
//   const body = serialize({
//     id: `${productId}_plan`,
//     product: productId,
//     amount: amount, // 1 dollar
//     currency: currency,
//     interval: interval,
//     nickname: name,
//   });
//   return await fetch(`${stripeApiUrl}/plans`, {
//     method: 'POST',
//     headers,
//     body,
//   }).then(response => {
//     return response.json()
//   }).catch(error => {
//     console.error(error);    
//   })
// }

// export const listPlan = async (stripeKey) => {
//   const headers = buildHeaders(stripeKey)
//   const items = await loadStripeList(`${stripeApiUrl}/plans`, headers, null)
//   return items
// }

// export const destroyPlan = async (stripeKey, productId) => {
//   const headers = buildHeaders(stripeKey)
//   return await fetch(`${stripeApiUrl}/plans/${productId}_plan`, {
//     method: 'DELETE',
//     headers,
//   }).then(response => {
//     return response.json()
//   }).catch(error => {
//     console.error(error);    
//   })
// }

// generate a unique price per productId
export const createPrice = async (stripeKey, productId, name, amount, currency, interval, authorName) => {
  const headers = buildHeaders(stripeKey)
  const body = serialize({
    product: productId,
    unit_amount: amount,
    currency: currency,
    "recurring[interval]": interval,
    nickname: name,
    "metadata[authorName]": authorName,
  });
  return await fetch(`${stripeApiUrl}/prices`, {
    method: 'POST',
    headers,
    body,
  }).then(response => {
    return response.json()
  }).catch(error => {
    console.error(error);    
  })
}

export const listPrice = async (stripeKey) => {
  const headers = buildHeaders(stripeKey)

  const items = await loadStripeList(`${stripeApiUrl}/prices`, headers, null)
  return items
}

export const destroyPrice = async (stripeKey, priceId) => {
  const headers = buildHeaders(stripeKey)
  return await fetch(`${stripeApiUrl}/prices/${priceId}`, {
    method: 'DELETE',
    headers,
  }).then(response => {
    return response.json()
  }).catch(error => {
    console.error(error);    
  })
}

export const createSubscription = async (stripeKey, customerId, priceId, vendorId, vendorShare) => {
  const headers = buildHeaders(stripeKey)
  const body = serialize(
    vendorId?.startsWith("acct_") ? {
      "off_session": "true",
      "enable_incomplete_payments": "false",
      "collection_method": "charge_automatically",
      "items": {
        "0": {
          "price": priceId,
          "quantity": "1"
        }
      },
      "customer": customerId,
      "application_fee_percent": vendorShare,
      "transfer_data[destination]": vendorId
    } : {
      "off_session": "true",
      "enable_incomplete_payments": "false",
      "collection_method": "charge_automatically",
      "items": {
        "0": {
          "price": priceId,
          "quantity": "1"
        }
      },
      "customer": customerId
    }
    );
  return await fetch(`${stripeApiUrl}/subscriptions`, {
    method: 'POST',
    headers,
    body,
  }).then(response => {
    return response.json()
  }).catch(error => {
    console.error(error);    
  })
}

export const updateSubscription = async (stripeKey, subscriptionId, data) => {
  const headers = buildHeaders(stripeKey)
  const body = serialize(data);
  return await fetch(`${stripeApiUrl}/subscriptions/${subscriptionId}`, {
    method: 'POST',
    headers,
    body,
  }).then(response => {
    return response.json()
  }).catch(error => {
    console.error(error);    
  })
}

export const listSubscriptions = async (stripeKey, customerId) => {
  const headers = buildHeaders(stripeKey)
  const params = new Map()
  if (customerId != null) {
    params.set("customer", customerId)
  }
  params.set("status", "all")
  const items = await loadStripeList(`${stripeApiUrl}/subscriptions`, headers, params)
  return items
}

export const getSubscription = async (stripeKey, subscriptionId) => {
  const headers = buildHeaders(stripeKey)
  return await fetch(`${stripeApiUrl}/subscriptions/${subscriptionId}`, {
    method: 'GET',
    headers,
  }).then(response => {
    return response.json()
  }).catch(error => {
    console.error(error);    
  })
}

export const destroySubscription = async (stripeKey, subscriptionId) => {
  const headers = buildHeaders(stripeKey)
  return await fetch(`${stripeApiUrl}/subscriptions/${subscriptionId}`, {
    method: 'DELETE',
    headers,
  }).then(response => {
    return response.json()
  }).catch(error => {
    console.error(error);    
  })
}

export const createCardToken = async (stripeKey, number, exp_month, exp_year, cvc) => {
  const headers = buildHeaders(stripeKey)
  console.log(number, exp_month, exp_year, cvc)
  const body = serialize({
    card: {
      number: number,
      exp_month: exp_month,
      exp_year: exp_year,
      cvc: cvc,
    },
  });
  console.log(body)
  return await fetch(`${stripeApiUrl}/tokens`, {
    method: 'POST',
    headers,
    body,
  }).then(response => {
    return response.json()
  }).catch(error => {
    console.error(error);    
  })
}

export const createCard = async (stripeKey, customerId, cardToken) => {
  const headers = buildHeaders(stripeKey)
  console.log(customerId, cardToken)
  const body = serialize({
    source: cardToken,
  });
  console.log(body)
  return await fetch(`${stripeApiUrl}/customers/${customerId}/sources`, {
    method: 'POST',
    headers,
    body,
  }).then(response => {
    return response.json()
  }).catch(error => {
    console.error(error);    
  })
}

export const destroyCard = async (stripeKey, cardId, custId) => {
  const headers = buildHeaders(stripeKey)
  return await fetch(`${stripeApiUrl}/customers/${custId}/sources/${cardId}`, {
    method: 'DELETE',
    headers,
  }).then(response => {
    return response.json()
  }).catch(error => {
    console.error(error);    
  })
}


// generate a vendor account
export const createAccount = async (stripeKey, email) => {
  const headers = buildHeaders(stripeKey)
  const body = serialize({
    email: email,
    type: 'express',
  });
  return await fetch(`${stripeApiUrl}/accounts`, {
    method: 'POST',
    headers,
    body,
  }).then(response => {
    return response.json()
  }).catch(error => {
    console.error(error);
  })
}

export const listAccounts = async (stripeKey) => {
  const headers = buildHeaders(stripeKey)
  const items = await loadStripeList(`${stripeApiUrl}/accounts`, headers, null)
  return items
}

export const getAccount = async (stripeKey, accountId) => {
  const headers = buildHeaders(stripeKey)
  return await fetch(`${stripeApiUrl}/accounts/${accountId}`, {
    method: 'GET',
    headers,
  }).then(response => {
    return response.json()
  }).catch(error => {
    console.error(error);    
  })
}

export const destroyAccount = async (stripeKey, accountId) => {
  const headers = buildHeaders(stripeKey)
  return await fetch(`${stripeApiUrl}/accounts/${accountId}`, {
    method: 'DELETE',
    headers,
  }).then(response => {
    return response.json()
  }).catch(error => {
    console.error(error);    
  })
}

export const createAccountLink = async (stripeKey, accountId) => {
  const headers = buildHeaders(stripeKey)
  const body = serialize({
    account: accountId,
    refresh_url: window.location.href,
    return_url: window.location.href,
    type: 'account_onboarding',
  });
  return await fetch(`${stripeApiUrl}/account_links`, {
    method: 'POST',
    headers,
    body,
  }).then(response => {
    return response.json()
  }).catch(error => {
    console.error(error);    
  })
}

export const openAccountLink = async (stripeKey, url) => {
  const headers = buildHeaders(stripeKey)
  return await fetch(`${stripeApiHost}`.concat(url ?? ""), {
    method: 'POST',
    headers,
  }).then(response => {
    return response.json()
  }).catch(error => {
    console.error(error);    
  })
}

export const listAccountTransfers = async (stripeKey) => {
  const headers = buildHeaders(stripeKey)
  const items = await loadStripeList(`${stripeApiUrl}/transfers`, headers, null)
  return items
}

// create a setup intent
export const createSetupIntent = async (stripeKey, customerId) => {
  const headers = buildHeaders(stripeKey)
  const body = serialize({
    payment_method_types: ['card'],
    customer: customerId
  })
  return await fetch(`${stripeApiUrl}/setup_intents`, {
    method: 'POST',
    headers,
    body,
  }).then(response => {
    return response.json()
  }).catch(error => {
    console.error('SetupIntents API Error', error);    
  })
}

export const attachCustomerPaymentMethod = async (stripeKey, customerId, paymentId) => {
  const headers = buildHeaders(stripeKey)
  const body = serialize({
    customer: customerId
  })
  const response = await window.fetch(`${stripeApiUrl}/payment_methods/${paymentId}/attach`, {
    method: 'POST',
    headers,
    body,
  })
  let data = null
  if (response.status === 200) {
    data = await response.json()
    updateCustomer(stripeKey, customerId, paymentId)
  }
  if (!data || data.error) {
    throw new Error('AttachPaymentMethod API Error')
  }
  return data
}

export const getCustomerPaymentMethod = async (stripeKey, customerId) => {
  const headers = buildHeaders(stripeKey)
  return await fetch(`${stripeApiUrl}/payment_methods?customer=${customerId}&type=card`, {
    method: 'GET',
    headers,
  }).then(response => {
    return response.json()
  }).catch(error => {
    console.log('GetPaymentMethod API error:', error)
  })    
}