import React, { Component } from 'react'
import { FormControl, Form } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import '../../../../../vendor/libs/react-datepicker/react-datepicker.scss'
import '../../../../../vendor/libs/react-flatpickr/react-flatpickr.scss'
import Flatpickr from 'react-flatpickr'
import '../RichMessage.scss'

class SendTime extends Component {
  constructor(props) {
    super(props)
    this.state = {
      startDate: new Date(),
      date: new Date(),
      time: new Date(),
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleChangeStart = this.handleChangeStart.bind(this)
    this.handleChangeEnd = this.handleChangeEnd.bind(this)
  }

  setDates({ startDate = this.state.startDate, endDate = this.state.endDate }) {
    if (moment(startDate).isAfter(endDate)) {
      endDate = startDate
    }

    this.setState({
      startDate,
      endDate
    })
  }

  handleChange(date) {
    this.setDates({ startDate: date })
  }

  handleChangeStart(startDate) {
    this.setDates({ startDate })
  }

  handleChangeEnd(endDate) {
    this.setDates({ endDate })
  }

  get placement() {
    const isRTL = document.documentElement.getAttribute('dir') === 'rtl'
    return isRTL ? 'auto-end' : 'auto-start'
  }
  render() {
    console.log(this.state);  
    const isIEMode = typeof document['documentMode'] === 'number'
    // const isIE10Mode = document['documentMode'] === 10
    const isRTL = document.documentElement.getAttribute('dir') === 'rtl'
    const timeConfig = {
      enableTime: true,
      noCalendar: true,
      altInput: true,
      animate: !isRTL
    }
    return ( 
      <div className="rrich-message__send-time">
        <div className="rrich-message__send-time__send">
          <Form.Check 
            custom inline 
            type="radio" 
            name="send-now" 
            id="send-now"
            label="Send now"
            value="sendNow"
            checked
          />
        </div>
        <div className="rrich-message__send-time__loop">
          <div className="rrich-message__send-time__loop__wrapper">
            <div className="rrich-message__send-time__loop__interval">
              <div className="rrich-message__send-time__loop__interval__text">
                <Form.Check 
                  custom 
                  inline 
                  type="radio" 
                  name="sendat" 
                  id="sendat"
                  label="Send every" 
                  disabled
                />
              </div>
              <div className="rrich-message__send-time__loop__interval__input">
                <FormControl
                  size="sm"
                  placeholder="60"
                  disabled
                  // onKeyDown={this.handleCreateStream}
                />
              </div>
              <div className="rrich-message__send-time__loop__interval__subtext">seconds</div>
            </div>
            <div className="rrich-message__send-time__loop__loop-repeat">
              <Form.Check 
                custom 
                type="checkbox" 
                id="12312"
                label="Loop repeat"
                disabled
              />
            </div>
          </div>
        </div>
        <div className="rrich-message__send-time__time">
          <div className="rrich-message__send-time__time__title">
            <Form.Check 
              custom 
              inline 
              type="radio" 
              name="sendat" 
              id="sendat"
              label="Send at:"
              disabled 
            />
          </div>
          <div className="rrich-message__send-time__time__time-input">
            <Flatpickr
              placeholder={!isIEMode ? 'Time picker' : null}
              value={this.state.time}
              options={timeConfig}
              onChange={time => { this.setState({ time }) }} 
              disabled
            />
          </div>
        </div>
        <div className="rrich-message__send-time__date">
          <div className="rrich-message__send-time__date__input">
            <DatePicker
              calendarClassName="react-datepicker--inline"
              inline
              selected={this.state.startDate}
              onChange={this.handleChange}
              popperPlacement={this.placement}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default SendTime