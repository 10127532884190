import React from 'react'
import { Modal} from 'react-bootstrap'
import GoToMarketPlaceChannels from '../../home/widget/chat-list/GoToMarketPlaceChannels'

class UploadingFiles extends GoToMarketPlaceChannels {
  constructor(props) {
    super(props)
    this.state = {} 
  }

  onGoToMarketPlace = () => {
    this.toogleModal()
    this.GoToMarketPlaceChannels()
  }

  toogleModal = () => {
    this.props.onHide()
  }

    render() {
      const { companyName } = this.props.data

      return (
        <Modal {...this.props}
          onHide={this.toogleModal}
          centered
          aria-labelledby="contained-modal-title-vcenter"
          className="welcome-screen__modal">
        <div className="welcome-screen text-center">
          <Modal.Body>
            <div className="welcome-screen__container">
            <div className="welcome-screen__container__title">File upload not available</div>
              <div className="welcome-screen__container__subtitle-smal">
                Your current Feature Plan does not allow uploading files. Please click <strong>Go To Marketplace</strong> to open {companyName} MarketPlace and choose a premium Feature Plan.
              </div>
              <div className="d-flex justify-content-end">
                <div className="welcome-screen__container__btn-skip" onClick={this.toogleModal}>Cancel</div>
                <div className="pl-4 welcome-screen__container__btn-next" onClick={this.onGoToMarketPlace}>Go To Marketplace</div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
      );
    }
  }

  export default UploadingFiles