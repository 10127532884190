import React, { Component } from 'react'
import { Card, Button} from 'react-bootstrap'

import { connect } from 'react-redux'
import { createPost } from '../../../../../store/actions/RichMessageActions'

import logoimage from './Sup-Res.png'


import { withTranslation } from 'react-i18next'

class SignalSupRes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: Date.now().toString(),
      category:  this.props.categories?.find(ct => ct?.content?.toLowerCase().trim() === "signal")?.id ?? this.props.category?.id,
      color: '#CCCCFF',
      title: 'S/R Levels',
      table: [
        {position: 0, title: 'Instrument', detail: 'EURUSD'},
        {position: 1, title: 'Timeframe', detail: 'Daily'},
        {position: 2, title: 'Pivot', detail: ''},
        {position: 3, title: 'S1', detail: ''},
        {position: 4, title: 'R1', detail: ''},
        {position: 5, title: 'S2', detail: ''},
        {position: 6, title: 'R2', detail: ''},
      ],
      media: null,
      comment: 'Short-term support at 1.1650 Short-term resistance at 1.1800 Long-term support at 1.1500 Long-term resistance at 1.2000',
    };
  }

  insertTemplate = () => {
    this.props.createPost(this.state)
  }

  render() {
    
    const { t } = this.props

    return (
      <Card className="rrich-message__signals__card">
        <div className="rrich-message__signals__card__wrapper d-flex justify-content-between">
          <div className="rrich-message__signals__card__img">
            <img src={logoimage} alt=""/>
          </div>
          <div className="rrich-message__signals__card__aside">
            <div className="rrich-message__signals__card__aside__title">{t("signal_text_44")}</div>
            <div className="rrich-message__signals__card__aside__price">{t("signal_text_45")}</div>
            <div className="rrich-message__signals__card__aside__btn">
              <Button size="xs" variant="outline-success" onClick={this.insertTemplate}>{t("signal_text_46")}</Button>
            </div>
          </div>
        </div>
      </Card>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createPost: (post) => dispatch(createPost(post)),
  }
}

export default withTranslation()(connect(null, mapDispatchToProps)(SignalSupRes))
