import React, { Component } from "react"
import { Modal } from "react-bootstrap"
import { isBlankOrNull } from "../../../utils/Helpres"
import { verifysms } from "../../../services/function/verifysms"
import validator from "validator"
import { Hub } from "aws-amplify"
import { withTranslation } from 'react-i18next'

class SetPhoneNumberModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      phone: "",
      code: "",
      isShowError: false,
      isShowCodeError: false,
      isValidCode: false,
      isAllowResend: false,
    }
  }

  handleChangePhone(event) {
    const { account } = this.props.data
    const clearPhone = event.target.value.replace(/\s/g, '')
    const isShowError = !validator.isMobilePhone(clearPhone)
      || "+".concat(account?.user?.phone) === clearPhone
      || "+".concat(account?.user?.phone) === "+".concat(clearPhone)
    this.setState({ phone: event.target.value, isShowError: isShowError })
  }
  handleChangeCode(event) {
    const { code } = this.state
    const isShowCodeError = isBlankOrNull(event.target.value) || (event.target.value?.length > 3 && code !== event.target.value)
    this.setState({ isValidCode: code === event.target.value, isShowCodeError: isShowCodeError })
  }

  onVerifyForm = () => {
    if (this.state.isShowError) { return }
    const { companyName } = this.props.data
    const { phone } = this.state
    const code = verifysms(companyName, phone)
    console.log('code - ', code)
    this.setState({code: code, isAllowResend: true});
  }
  
  onSaveForm() {
    const { phone, isShowError } = this.state
    const isActiveGuide = localStorage.getItem("activeGuide") === 'true'
    if (isShowError) { return }
    if (isActiveGuide) {
      Hub.dispatch('IS_SAVE_PHONE_NUMBER')
    }
    const { account, updatePhone } = this.props.data
    updatePhone(account, phone.replace(/\s+/g, ' '))
    this.toggleModal()
  }

  onResendForm() {
    const { phone } = this.state
    const { companyName } = this.props.data
    const code = verifysms(companyName, phone)
    this.setState({code: code, isAllowResend: false});
  }

  toggleModal = () => {
    const isActiveGuide = localStorage.getItem("activeGuide") === 'true'
    if (isActiveGuide) {
      Hub.dispatch('IS_SAVE_PHONE_NUMBER')
    }
    this.props.onHide()
  }

  render() {
    const {t} = this.props
    const { account } = this.props.data
    const { code, isShowError, isShowCodeError, isValidCode, isAllowResend } = this.state
    const isNotVerificationFlow = isBlankOrNull(code)

    return (
      <Modal
        {...this.props}
        onHide={this.toggleModal}
        centered
        aria-labelledby="contained-modal-title-vcenter"
        className="n-modal__modal"
      >
        <div className="n-modal profile-settings-phone-number-save-guide">
          <div className="n-modal__wrapper">
            <div className="n-modal__close" onClick={this.toggleModal}>
              <svg
                width="20rem"
                height="20rem"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 2.01429L17.9857 0L10 7.98571L2.01429 0L0 2.01429L7.98571 10L0 17.9857L2.01429 20L10 12.0143L17.9857 20L20 17.9857L12.0143 10L20 2.01429Z"
                />
              </svg>
            </div>
            <div className="n-modal__header">
                <div className="n-modal__header__icon">
                <svg width="38rem" height="38rem" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M25.9001 15.3901L26.6301 14.6601C26.9701 14.3201 26.9701 13.7501 26.6301 13.4301L24.5801 11.3801C24.4001 11.2001 24.1901 11.1301 23.9601 11.1301M23.9601 11.1201C23.7401 11.1201 23.5101 11.2101 23.3501 11.3701L20.8401 13.9101M18.7401 15.9801L11.1201 23.6001V26.8801H14.4001L24.0901 17.1901L20.8101 13.9101" stroke="#203B60" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M19 37C28.9411 37 37 28.9411 37 19C37 9.05887 28.9411 1 19 1C9.05887 1 1 9.05887 1 19C1 28.9411 9.05887 37 19 37Z" stroke="#203B60" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
                </svg>
                </div>
                <div className="n-modal__header__title">
                  {t('profile_modal_phone_title')}
                </div>
                <div className="n-modal__line" />
                <div className="n-modal__content n-modal__content--flex">
                  <div className="n-modal__content__title">
                    {isNotVerificationFlow ? t('profile_modal_phone_text3') : t('profile_modal_phone_text4')}
                  </div>
                  <div className="n-modal__content__title-sub">
                    {isNotVerificationFlow ? t('profile_modal_phone_text5') : t('profile_modal_phone_text6')}
                  </div>
                  <div
                    className={(isNotVerificationFlow && isShowError) || isShowCodeError  ? "n-input error" : "n-input"}
                  >
                    {isNotVerificationFlow && <input
                      defaultValue={account?.user?.phone}
                      type="text"
                      maxLength={20}
                      placeholder="+13333333333"
                      onChange={this.handleChangePhone.bind(this)}
                    />}
                    {!isNotVerificationFlow && <input defaultValue=""
                      type="text"
                      maxLength={20}
                      placeholder={t('profile_modal_phone_text7')}
                      onChange={this.handleChangeCode.bind(this)}
                    />}
                    <div className="n-input__error-message">
                      {!isNotVerificationFlow ? t('profile_modal_phone_text8') :
                        isBlankOrNull(this.state.phone) ? t('profile_modal_phone_text9') : !validator.isMobilePhone(this.state.phone.replace(/\s/g, '')) ? t('profile_modal_phone_text10') : t('profile_modal_phone_text11')}
                    </div>
                  </div>
                </div>
                <div className="n-modal__btns">
                  {isNotVerificationFlow ?
                  <div className="button-lg" onClick={this.onVerifyForm.bind(this)}>Verify</div> :
                  isValidCode ? <div className="button-lg" onClick={this.onSaveForm.bind(this)}>Save</div> :
                  <div className="button-lg" onClick={isAllowResend ? this.onResendForm.bind(this) : null}>{isAllowResend ? t('profile_modal_phone_text12') : t('profile_modal_phone_text13')} </div>
                  }
                </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

export default withTranslation()(SetPhoneNumberModal)
