import React, { Component } from 'react'
import { connect } from 'react-redux'
import CopytradingConnectModal from "../../components/home/widget/plugins/CopytradingConnectModal"
// import { handleWorkspaceRead } from "../../store/actions/WorkspaceActions"
// import { handleWidgetRead } from "../../store/actions/WidgetActions"
// import { handleStreamRead } from "../../store/actions/StreamActions"
// import { handleExtrasRead } from "../../store/actions/ExtraActions"
// import { handleChannelObserverAPI, handleChannelRead, observeChannel } from "../../store/actions/ChannelActions"
// import { handleProductListRead } from "../../store/actions/ProductActions"
// import { handleLabelRead } from "../../store/actions/LabelActions"
// import { handleTagRead } from "../../store/actions/TagActions"
// import { handleSettingsCreate, handleSettingsRead, handleSettingsUpdate } from "../../store/actions/SettingsActions"
// import { handleTopicsRead } from "../../store/actions/TopicsActions"
import {
  handleEventCreate, handleEventDelete
  // handleEventObserverAPI,
  // handleEventRead,
  // observeEvent
} from "../../store/actions/EventActions"
// import { deleteAllMessageObservers } from "../../store/actions/MessageActions"
// import { handleSessionList } from "../../store/actions/SessionActions"

class CopytradingConnectContainer extends Component {

  onHide() {
    this.props.onHide()
  }

  render() {
    const { account, channels, category } = this.props
    const ownChannels = channels?.filter(channel =>
      channel?.owner === account?.owner &&
      channel?.public === true &&
      !channel?.categories.some(el => el === 'extra')
    )

    const { masterAccount } = this.props.data
    const eventWebhook = this.props.events.find(item => item?.event === "copytrading" && item?.payload.includes(masterAccount?.id))

    return (
      <CopytradingConnectModal
        account={account}
        masterAccount={masterAccount}
        channels={ownChannels}
        category={category}
        event={eventWebhook}
        show={this.props.show}
        onHide={this.onHide.bind(this)}
        handleEventCreate={this.props.handleEventCreate}
        handleEventDelete={this.props.handleEventDelete}
      />
    )
  }
}

const mapStateToProps = store => {
  return {
    account: store.user,
    channels: store.channels.list,
    permissions: store.permissions,
    events: store.events.list,
    category: store.configs.DEFAULT_MESSAGE_CATEGORY,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleEventCreate : (owner, topic, event, payload) => dispatch(handleEventCreate(owner, topic, event, payload)),
    handleEventDelete : (owner, eventId) => dispatch(handleEventDelete(owner, eventId)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CopytradingConnectContainer)