import { loadTradeSyncList, tradeSyncApiUrl, tradeSyncAuthHeader, tradeSyncHeaders } from "./tradesync"

export const tradeSyncAuth = async (email, password) => {
  const headers = tradeSyncAuthHeader()
  const body = JSON.stringify({
    email: email,
    password: password
  })
  const response = await fetch(`${tradeSyncApiUrl}/auth`, {
    method: "POST",
    headers,
    body: body
  })
  if (response.ok) {
    const json = await response.json()
    return json?.data
  } else {
    console.error("tradesync error: ", response.status, response.statusText)
    return null
  }
}

export const tradeSyncAccounts = async (token, params) => {
  const headers = tradeSyncHeaders(token)
  const items = await loadTradeSyncList(`${tradeSyncApiUrl}/accounts`, headers, params)
  return items
}

export const tradeSyncAccount = async (token, account_id, params) => {
  const headers = tradeSyncHeaders(token)
  var request = ""
  try {
    const getParams = new URLSearchParams(params)
    request = `${tradeSyncApiUrl}/accounts/${account_id}?${getParams.toString()}`
  } catch (e) {
    request = `${tradeSyncApiUrl}/accounts/${account_id}`
  }
  const response = await fetch(request, {
    method: "GET",
    headers
  })
  if (response.ok) {
    const json = await response.json()
    return json?.data
  } else {
    console.error("tradesync error: ", response.status, response.statusText)
    return null
  }
}

export const tradeSyncAccountCreate = async (token, params) => {
  const headers = tradeSyncHeaders(token)
  const body = JSON.stringify(params)
  console.log("tradesync create account: ", headers, params)
  const response = await fetch(`${tradeSyncApiUrl}/accounts`, {
    method: "POST",
    headers,
    body: body
  })
  if (response.ok) {
    const json = await response.json()
    return json?.data
  } else {
    const json = await response.json()
    return json
  }
}

export const tradeSyncAccountUpdate = async (token, account_id, params) => {
  const headers = tradeSyncHeaders(token)
  const body = JSON.stringify(params)
  const response = await fetch(`${tradeSyncApiUrl}/accounts/${account_id}`, {
    method: "PATCH",
    headers,
    body: body
  })
  if (response.ok) {
    const json = await response.json()
    return json?.data
  } else {
    console.error("tradesync error: ", response.status, response.statusText)
    return null
  }
}

export const tradeSyncAccountConnectionUpdate = async (token, account_id, params) => {
  const headers = tradeSyncHeaders(token)
  const body = JSON.stringify(params)
  const response = await fetch(`${tradeSyncApiUrl}/accounts/${account_id}/connection`, {
    method: "PATCH",
    headers,
    body: body
  })
  if (response.ok) {
    const json = await response.json()
    return json?.data
  } else {
    console.error("tradesync error: ", response.status, response.statusText)
    return null
  }
}

export const tradeSyncAccountDelete = async (token, account_id) => {
  const headers = tradeSyncHeaders(token)
  const response = await fetch(`${tradeSyncApiUrl}/accounts/${account_id}`, {
    method: "DELETE",
    headers
  })
  if (response.ok) {
    const json = await response.json()
    return json?.data
  } else {
    const json = await response.json()
    console.error("tradesync error: ", response.status, response.statusText)
    return json
  }
}