import {
  userCreate, userRead, userUpdate, userDelete, userObserve,
  searchUserRead, searchUsersRead, HUB_OBSERVER_SEARCH_USER
} from "../../services/api/user"
import { userRoleCreate, myUserRoleRead }  from '../../services/api/userrole'
import { configRead }  from '../../services/api/config'
import { channelCreate, channelRead }  from '../../services/api/channel'
import { streamCreate }  from '../../services/api/stream'
import { widgetCreate }  from '../../services/api/widget'
import { workspaceCreate }  from '../../services/api/workspace'
import { roleRead, fillDefaultRoles }  from '../../services/api/role'
import { fillDefaultProducts } from "../../services/api/product"
import { fillDefaultPermissions } from '../../services/api/permission'
import { fillDefaultRoleProduct }  from '../../services/api/roleproduct'
import { fillConfigs }  from '../../services/api/config'
import { authSignOut, authUpdateUserAttributes } from '../../services/auth'
import { triggerWelcomeMessageInChannel } from '../../utils/TriggerHelpers'

import * as K  from '../../utils/Constant'

import { USER_ROLE_CREATE_SUCCESS, USER_ROLE_READ_SUCCESS } from './UserRoleActions'
import { WORKSPACE_CREATE_SUCCESS } from './WorkspaceActions'
import { CHANNEL_CREATE_SUCCESS } from './ChannelActions'
import { STREAM_CREATE_SUCCESS } from './StreamActions'
import { WIDGET_CREATE_SUCCESS } from './WidgetActions'
import { hideEmail } from "../../utils/Helpres"
import { Hub } from "aws-amplify"

export const USER_CREATE_REQUEST = 'USER_CREATE_REQUEST'
export const USER_CREATE_SUCCESS = 'USER_CREATE_SUCCESS'
export const USER_CREATE_FAIL = 'USER_CREATE_FAIL'

export const USER_READ_REQUEST = 'USER_READ_REQUEST'
export const USER_READ_SUCCESS = 'USER_READ_SUCCESS'
export const USER_READ_FAIL = 'USER_READ_FAIL'

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST'
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL'

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST'
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS'
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL'
export const USER_SIGNOUT_SUCCESS = 'USER_SIGNOUT_SUCCESS'

export function setupPlatform() {
  fillConfigs()
  .then(configs => {
    fillDefaultPermissions()
    .then(perms => {
      fillDefaultRoles(perms)
        .then(roles => {
          fillDefaultProducts(perms)
            .then(products => {
              fillDefaultRoleProduct(roles, products)
                .then(roleProducts => {})
                .catch(err => {})
            })
            .catch(err => {})                                      
        })
        .catch(err => {})
    })
    .catch(err => {})
  }).catch(err => {})
}

export function handleUserCreate(owner, username, attributes) {
  return dispatch => {
    dispatch({
      type: USER_CREATE_REQUEST,
      payload: { owner: owner, username: username},
    })
    userCreate(owner, attributes?.nickname ?? username, attributes?.email, attributes?.name, attributes?.phone_number, attributes["custom:setup_user_id"] == null)
    .then(data => {
      const user = data      
      if (data?.isCreated) {
        roleRead()
        .then(roles => {            
          let role  = roles.find(role => data?.email.startsWith("gsuprunuk@") ? role?.name === K.PLATFORM_ADMIN_ROLE : role?.name === "retailV2") //K.PLATFORM_RETAIL_ROLE
          if (role == null) {
            role  = roles.find(role => role?.name === "retail")
          }
          userRoleCreate(user, role)
          .then(userRole => {
            workspaceCreate(owner, user, K.DEFAULT_WORKSPACE_NAME, 0)
            .then(workspace => {
              authUpdateUserAttributes({"custom:setup_user_id": user?.id})
              .then(userResutl => {
                configRead()
                .then(configs => {
                  const templateConfig = configs?.find(el => el?.detail === K.DEFAULT_SET_WORKSPACE_TEMPLATE_CONFIG_KEY)
                  const enabledTemplateConfig = configs?.find(el => el?.detail === K.DEFAULT_SET_ENABLED_WORKSPACE_TEMPLATE_CONFIG_KEY)
                  if (templateConfig != null && enabledTemplateConfig?.content === 'true') {
                    try {
                      const channelItems = JSON.parse(templateConfig?.content)?.filter(el => el?.channel != null)
                      if (channelItems?.length > 0) {
                        channelRead(owner, user)
                        .then(channels => {
                          const restored = channelItems?.map(item => {
                            const channel = channels?.find(ch => ch?.id === item?.channel)
                            if (channel != null) {
                              const trial = null
                              streamCreate(owner, user, channel, trial)
                              .then(stream => {
                                widgetCreate(owner, workspace, stream, item?.top, item?.left, item?.width, item?.height, 300, 300, stream.channel?.name)
                                .then(widget => {
                                  const category = configs.find(el => el?.detail === K.DEFAULT_MESSAGE_CATEGORY_CONFIG_KEY && el?.title === K.FIRST_MESSAGE_CATEGORY_CONFIG_TITLE)
                                  const message = `${hideEmail(user.email)} has just joined the channel`
                                  const content = {
                                    threadId: null,
                                    category: category?.id,
                                    title: null,
                                    comment: message,
                                    color: null,
                                    media: null,
                                    table: null,
                                }
                                  triggerWelcomeMessageInChannel(channel?.owner, channel?.user, channel, content, null)
                                  dispatch({
                                    type: WIDGET_CREATE_SUCCESS,
                                    payload: widget,
                                  })                                  
                                })
                                .catch(wErr => console.error('Error templated widget:', wErr))
                                dispatch({
                                  type: STREAM_CREATE_SUCCESS,
                                  payload: stream,
                                })
                              })
                              .catch(sErr => console.error('Error templated stream:', sErr))
                            }                                
                          })
                        })
                        .catch(err => {})                                    
                      }
                      const extraItems = JSON.parse(templateConfig?.content)?.filter(el => el?.extra != null)
                      if (extraItems?.length > 0) {
                        const restored = extraItems?.map(item => {
                          channelCreate(owner, user, item?.name, item?.description, item?.info, ["extra", item?.extra], false, K.STATUS_APPROVED)
                          .then(channel => {    
                            if (channel != null) {
                              const trial = null
                              streamCreate(owner, user, channel, trial)
                              .then(stream => {
                                widgetCreate(owner, workspace, stream, item?.top, item?.left, item?.width, item?.height, 300, 300, stream.channel?.name)
                                .then(widget => {
                                  dispatch({
                                    type: WIDGET_CREATE_SUCCESS,
                                    payload: widget,
                                  })                                  
                                })
                                .catch(wErr => console.error('Error templated widget:', wErr))
                                dispatch({
                                  type: STREAM_CREATE_SUCCESS,
                                  payload: stream,
                                })
                              })
                              .catch(sErr => console.error('Error templated stream:', sErr))
                            }
                            dispatch({
                              type: CHANNEL_CREATE_SUCCESS,
                              payload: channel,        
                            })      
                          })
                          .catch(cErr => console.error('Error templated channel:', cErr))
                        })                        
                      }
                    } catch (error) {
                      console.error('template decoding error', error)
                    }  
                  }
                })
                .catch(err => {})                
              })
              .catch(err => {})
              dispatch({
                type: WORKSPACE_CREATE_SUCCESS,
                payload: workspace
              })                
            })
            .catch(err => {})
            dispatch({
              type: USER_ROLE_CREATE_SUCCESS,
              payload: userRole
            })                
          })
          .catch(err => {})
        })
        .catch(err => {})        
      }
      dispatch({
        type: USER_CREATE_SUCCESS,
        payload: {owner: owner, user: data, attributes: attributes},
      })
    })
    .catch(err => {
      dispatch({
        type: USER_CREATE_FAIL,
        error: true,
        payload: new Error(err),
      })
    })    
  }
}

export function handleSearchUserRead(filter) {
  // filters = {
  //   value: "name or username or phone", // equal
  // }
  return dispatch => {
    searchUserRead(filter)
      .then(data => {
        Hub.dispatch(HUB_OBSERVER_SEARCH_USER, { event: HUB_OBSERVER_SEARCH_USER, data: data, message: HUB_OBSERVER_SEARCH_USER })
      })
  }
}

export function handleSearchUsersRead(userIds) {
  return dispatch => {
    searchUsersRead(userIds)
      .then(data => {
        if (data?.length > 0) {
          Hub.dispatch(HUB_OBSERVER_SEARCH_USER, { event: HUB_OBSERVER_SEARCH_USER, data: data, message: HUB_OBSERVER_SEARCH_USER })
        }
      })
  }
}

export function handleUserRead(owner, attributes) {
  return dispatch => {
    dispatch({
      type: USER_READ_REQUEST,
      payload: owner,
    })
    const userId = attributes["custom:setup_user_id"]
    if (userId == null) { return }
    userRead(userId)
    .then(data => {
      if (data?.id != null) {
        myUserRoleRead(data?.id)
        .then(userRoles => {
          dispatch({
            type: USER_ROLE_READ_SUCCESS,
            payload: userRoles
          })
        })
        .catch(err => {})
        dispatch({
          type: USER_READ_SUCCESS,
          payload: {owner: owner, user: data, attributes: attributes},
        })
      }      
    })
    .catch(err => {
      dispatch({
        type: USER_READ_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function handleUserUpdate(owner, userId, username, name, phone, country, info, publicByName, publicByUsername, publicByEmail) {
  return dispatch => {
    dispatch({
      type: USER_UPDATE_REQUEST,
      payload: { owner: owner, userId: userId, username: username, name: name, phone: phone, country: country,
        publicByName: publicByName, publicByUsername: publicByUsername, publicByEmail: publicByEmail},
    })
    userUpdate(owner, userId, username, name, phone, country, info, publicByName, publicByUsername, publicByEmail)
    .then(data => {
      dispatch({
        type: USER_UPDATE_SUCCESS,
        payload: {owner: owner, user: data},        
      })
    })
    .catch(err => {
      dispatch({
        type: USER_UPDATE_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function handleUserDelete(owner, userId) {
  return dispatch => {
    dispatch({
      type: USER_DELETE_REQUEST,
      payload: { owner: owner, userId: userId},
    })
    userDelete(owner, userId)
    .then(() => {
      dispatch({
        type: USER_DELETE_SUCCESS,
        payload: true,
      })
    })
    .catch(err => {
      dispatch({
        type: USER_DELETE_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function handleUserSignOut() {
  return dispatch => {
    authSignOut().then(() => {
      setTimeout(() => {
        dispatch({
          type: USER_SIGNOUT_SUCCESS,
          payload: {}        
        })
      }, 2000);
    })    
  }
}

export function observeUser(owner, userId) {
  return dispatch => {
    userObserve(owner, userId)
  }
}

export function handleUserObserver(data) {
  return dispatch => {
    switch (data.opType) {
      case 'INSERT':
        dispatch({
          type: USER_CREATE_SUCCESS,
          payload: data.element,
        })   
        break;
      case 'UPDATE':
        dispatch({
          type: USER_UPDATE_SUCCESS,
          payload: data.element,
        })   
        break;
      case 'DELETE':
        dispatch({
          type: USER_DELETE_SUCCESS,
          payload: data.element,
        })   
        break;      
      default:
        break;
    }    
  }
}

export function handleUserObserverAPI(event, data) {
  return dispatch => {
    switch (event) {
      case 'INSERT':
        console.log(event, data)
        dispatch({
          type: USER_CREATE_SUCCESS,
          payload: data,
        })   
        break;
      case 'UPDATE':
        console.log(event, data)
        dispatch({
          type: USER_UPDATE_SUCCESS,
          payload: data,
        })   
        break;
      case 'DELETE':
        console.log(event, data)
        dispatch({
          type: USER_DELETE_SUCCESS,
          payload: data,
        })   
        break;      
      default:
        break;
    }    
  }
}