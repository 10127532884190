import { adminUserRead, adminUserUpdate } from "../../../services/api/user"

export const ADMIN_USER_LIST_SUCCESS = "ADMIN_USER_LIST_SUCCESS"
export const ADMIN_USER_LIST_FAIL = "ADMIN_USER_LIST_FAIL"

export const ADMIN_USER_UPDATE_SUCCESS = "ADMIN_USER_UPDATE_SUCCESS"
export const ADMIN_USER_UPDATE_FAIL = "ADMIN_USER_UPDATE_FAIL"

export function handleAdminUserRead(filters) {
  // filters = {
  //   email: "demo@gmail.com", // equal
  //   username: "changename",  // equal
  //   name: "Real Name",       // equal
  //   phone: "+1",             // equal
  //   createdAt: '2020-11-07', // contains
  //   updatedAt: '2020-11-07'  // contains
  // }
  return dispatch => {
    adminUserRead(filters)
      .then(data => {
        dispatch({
          type: ADMIN_USER_LIST_SUCCESS,
          payload: data,
        })
      })
      .catch(err => {
        dispatch({
          type: ADMIN_USER_LIST_FAIL,
          error: true,
          payload: new Error(err),
        })
      })
  }
}

export function handleAdminUserUpdate(
  userId,
  username,
  name,
  phone,
  country,
  info
) {
  console.log("update users", userId, username, name, phone, country, info)
  return dispatch => {
    adminUserUpdate(userId, username, name, phone, country, info)
      .then(data => {
        dispatch({
          type: ADMIN_USER_UPDATE_SUCCESS,
          payload: data,
        })
      })
      .catch(err => {
        dispatch({
          type: ADMIN_USER_UPDATE_FAIL,
          error: true,
          payload: new Error(err),
        })
      })
  }
}
