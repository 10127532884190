import React, { Component } from 'react'

import SignalBuy from './SignalBuy'
import SignalSell from './SignalSell'
import SignalNews from './SignalNews'
import SignalSupRes from './SignalSupRes'

class Signals extends Component {
  constructor(props) {
    super(props)
    this.state={}
  }
  render() {    
    return ( 
      <div className="rrich-message__signals">
        <div className="rrich-message__signals__wrapper d-flex justify-content-between align-items-center">
          <div className="rrich-message__signals__triangle-left"></div>
          <div className="rrich-message__signals__cards d-flex justify-content-between">
            <SignalBuy category={this.props.category} categories={this.props.categories}/>
            <SignalSell category={this.props.category} categories={this.props.categories}/>
            <SignalNews category={this.props.category} categories={this.props.categories}/>
            <SignalSupRes category={this.props.category} categories={this.props.categories}/>
            
          </div>
          <div className="rrich-message__signals__triangle-right"></div>
        </div>
      </div>
    )
  }
}

export default Signals