import { API, graphqlOperation } from 'aws-amplify';
import { listSessions, getSession } from "../../graphql/queries"
import { createSession, updateSession } from "../../graphql/mutations";

import { loadList } from "./shared"
const action = listSessions
const attribute = "listSessions"

export const HUB_OBSERVER_SESSIONS = "user_sessions"

export const sessionCreate = async (owner, user) => {
  if (owner == null || user == null) {
    console.log('Session API: Required fields cannot be empty!')
    return
  }
  const ttlTime = Math.floor((Date.now() + 3 * 60000)  / 1000) // current + 3 mins
  try {
    const response = await API.graphql(graphqlOperation(updateSession, {input: {
        id: owner,
        TTL: ttlTime,
        updatedAt: new Date().toISOString() }}))
    return response?.data?.updateSession
  } catch (err) {
    const response = await API.graphql(graphqlOperation(createSession, {input: {
        id: owner,
        TTL: ttlTime,
        sessionUserId: user.id,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString() }}))
    return response?.data?.createSession
  }
}

export const sessionsList = async () => {
  try {
    const sessions = await loadList(action, attribute, null, 0)
    const now = new Date()
    const filteredSessions = sessions?.filter(session => {
      try {
        const updated = new Date(session?.updatedAt)
        var diff =(now.getTime() - updated.getTime()) / 1000;
        diff /= 60;
        const diffMins =  Math.abs(Math.round(diff));
        return diffMins < 4
      } catch (err) {
        return false
      }
    })
    return filteredSessions
  } catch (err) { 
    console.log('error fetching list sessions', err)
    return null
  }
}