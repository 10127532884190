import { API } from 'aws-amplify';

export const restapiCopytradingSubscribe = async (owner, masterAccountId, workspaceId) => {
  if (owner == null || masterAccountId == null || workspaceId == null) {
    console.error('Rest Ap: Failed request on create copier: empty params')
    return null
  }

  const apiName = 'restapi';
  const path = '/api';
  const params = {
    body: {
      action: "apiCopytradingSubscribe",
      body: {
        owner: owner,
        attributes: {
          masterAccountId: masterAccountId,
          workspaceId: workspaceId
        }
      }
    }
  };
  const response = await API.post(apiName, path, params);
  return response
};