import React, { Component } from "react"
import { Modal } from "react-bootstrap"
import { Hub } from "aws-amplify"
import * as K from "../../../utils/Constant"


import { withTranslation } from 'react-i18next'

class UnsubscribeFreeChannelModal extends Component {

  onChannelUnsubscribe = () => {
    const { channel, onSubscriptionDelete } = this.props.data
    onSubscriptionDelete(channel)
    this.toggleModal()
  }

  onShowPackageMenu = () => {
    Hub.dispatch(K.HUB_OPENPACKAGEMENU)
    this.toggleModal()
  }

  toggleModal = () => {
    this.props.onHide()
  }

  render() {
    
    const { t } = this.props

    const { channel, preventSubscriptionDelete } = this.props.data
    const pkg = preventSubscriptionDelete(channel)

    return (
      <Modal
        {...this.props}
        onHide={this.toggleModal}
        centered
        aria-labelledby="contained-modal-title-vcenter"
        className="n-modal__modal"
      >
        <div className="n-modal">
          <div className="n-modal__wrapper">
            <div className="n-modal__close" onClick={this.toggleModal}>
              <svg
                width="20rem"
                height="20rem"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 2.01429L17.9857 0L10 7.98571L2.01429 0L0 2.01429L7.98571 10L0 17.9857L2.01429 20L10 12.0143L17.9857 20L20 17.9857L12.0143 10L20 2.01429Z"
                />
              </svg>
            </div>
            <div className="n-modal__header">
              <div className="n-modal__header__icon-red">
                <svg
                  width="44rem"
                  height="40rem"
                  viewBox="0 0 44 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M40.0361 39H3.63619C2.46802 39 1.41764 38.3914 0.838464 37.3803C0.259285 36.3692 0.249468 35.1617 0.838464 34.1408L19.0384 2.61974C19.6176 1.60863 20.668 1 21.8362 1C23.0043 1 24.0547 1.60863 24.6339 2.61974L42.8339 34.1408C43.4229 35.1519 43.4229 36.3594 42.8339 37.3705C42.2449 38.3816 41.2043 39 40.0361 39ZM21.8362 2.96332C21.6202 2.96332 21.0705 3.02222 20.7367 3.6014L2.53673 35.1225C2.20297 35.6918 2.42875 36.2023 2.53673 36.3986C2.64472 36.5852 2.97848 37.0367 3.63619 37.0367H40.0361C40.6938 37.0367 41.0276 36.595 41.1356 36.3986C41.2436 36.2121 41.4693 35.7017 41.1356 35.1225L22.9356 3.6014C22.6117 3.02222 22.0619 2.96332 21.8362 2.96332Z"
                    fill="#E30C0C"
                  />
                  <path
                    d="M22 27C21.45 27 21 26.55 21 26V15C21 14.45 21.45 14 22 14C22.55 14 23 14.45 23 15V26C23 26.55 22.55 27 22 27Z"
                    fill="#E30C0C"
                  />
                  <path
                    d="M22.3333 30.9633H21.6667C21.3 30.9633 21 30.5216 21 29.9817C21 29.4417 21.3 29 21.6667 29H22.3333C22.7 29 23 29.4417 23 29.9817C23 30.5216 22.7 30.9633 22.3333 30.9633Z"
                    fill="#E30C0C"
                  />
                </svg>
              </div>
              {pkg ?
                <div className="n-modal__header__title-red">
                  {t("modal_channel_text_88")} <b>{pkg?.name}</b>
                </div> :
                <div className="n-modal__header__title-red">
                  {t("modal_channel_text_89")} <b>{channel?.name}</b>
                </div>
              }
              <div className="n-modal__line" />
              <div className="n-modal__content">
                {pkg ?
                  <div className="n-modal__content__title">
                    {t("modal_channel_text_90")}<br/>
                    <b>{t("modal_channel_text_91")}</b> {t("modal_channel_text_92")}
                  </div> :
                  <div className="n-modal__content__title">
                  {t("modal_channel_text_93")}{" "}
                  <b>{t("modal_channel_text_94")}</b>, {t("modal_channel_text_95")}
                </div>
                }
              </div>
              <div className="n-modal__btns">
                {pkg ?
                  <div className="n-modal__btns__confirm-red" onClick={this.onShowPackageMenu.bind(this)}>
                    {t("modal_channel_text_96")}
                  </div> :
                  <div className="n-modal__btns__confirm-red" onClick={this.onChannelUnsubscribe.bind(this)}>
                    {t("modal_channel_text_97")}
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

export default withTranslation()(UnsubscribeFreeChannelModal)
