import React, { Component } from 'react'
import { FormControl } from 'react-bootstrap'


import { withTranslation } from 'react-i18next'

class TableInputTitle extends Component {
  handleChange(event) {
    this.props.updateTitleInput(event.target.value)
  }

  render() {  
    
    const { t } = this.props

    return ( 
      <FormControl
        placeholder={t("filter_menu_text_44")}
        maxLength="32"
        defaultValue={this.props.title}
        onChange={this.handleChange.bind(this)}
      />
    )
  }
}

export default withTranslation()(TableInputTitle)