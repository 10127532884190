import React, { Component } from "react"
import { Modal } from "react-bootstrap"
import { isBlankOrNull } from "../../../utils/Helpres"


import { withTranslation } from 'react-i18next'

class EditNewChannelModal extends Component {
  constructor(props) {
    super(props)

    const { channel } = props.data

    this.state = {
      channelName: channel?.name ?? "",
      channelDescription: channel?.description ?? "",
      isShowError: false,
      isShowVisible: true,
    }
  }

  handleChangeChannelName(event) {
    const { channels } = this.props.data
    const editChannelName = event.target.value?.trim()
    const isShowError =
      isBlankOrNull(editChannelName) ||
      channels?.find(
        ch => ch?.name?.toLowerCase() === editChannelName?.toLowerCase()
      )
    this.setState({
      channelName: event.target.value,
      isShowError: isShowError,
      isShowVisible: !isShowError,
    })
  }

  handleChangeChannelDescription(event) {
    this.setState({
      channelDescription: event.target.value,
      isShowVisible: true,
    })
  }

  onSaveChannel() {
    
    const { t } = this.props
    
    const { channelName, channelDescription } = this.state
    const { channel, channels, onChannelUpdate } = this.props.data
    const newChannelName = channelName?.trim()
    const isShowError =
      isBlankOrNull(newChannelName) ||
      channels?.find(ch => ch?.name === newChannelName)
    var newChannelDesc = channelDescription?.trim()
    if (isBlankOrNull(newChannelDesc)) {
      newChannelDesc = t("modal_channel_text_53")
    }
    this.setState({ isShowError: isShowError })
    if (!isShowError) {
      onChannelUpdate(
        channel,
        newChannelName,
        newChannelDesc,
        newChannelDesc,
        channel?.public,
        channel?.status,
        channel?.categories
      )
      this.toggleModal()
    }
  }

  toggleModal = () => {
    this.props.onHide()
  }

  render() {
    
    const { t } = this.props
    
    const { channel } = this.props.data
    const { channelName, channelDescription, isShowError } = this.state
    return (
      <Modal
        {...this.props}
        onHide={this.toggleModal}
        centered
        aria-labelledby="contained-modal-title-vcenter"
        className="n-modal__modal"
      >
        <div className="n-modal">
          <div className="n-modal__wrapper">
            <div className="n-modal__close" onClick={this.toggleModal}>
              <svg
                width="20rem"
                height="20rem"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M20 2.01429L17.9857 0L10 7.98571L2.01429 0L0 2.01429L7.98571 10L0 17.9857L2.01429 20L10 12.0143L17.9857 20L20 17.9857L12.0143 10L20 2.01429Z" />
              </svg>
            </div>
            <div className="n-modal__header">
              <div className="n-modal__header__icon">
                <svg
                  width="39rem"
                  height="38rem"
                  viewBox="0 0 39 38"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_533_3952)">
                    <path
                      d="M19.5 13.5V24.5"
                      stroke="#203B60"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                    />
                    <path
                      d="M25 19H14"
                      stroke="#203B60"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                    />
                    <path
                      d="M19.5 37C29.4411 37 37.5 28.9411 37.5 19C37.5 9.05887 29.4411 1 19.5 1C9.55887 1 1.5 9.05887 1.5 19C1.5 28.9411 9.55887 37 19.5 37Z"
                      stroke="#203B60"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_533_3952">
                      <rect
                        width="38"
                        height="38"
                        fill="white"
                        transform="translate(0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className="n-modal__header__title">
              {t("modal_channel_text_531")} <b>{channel?.name}</b>
              </div>
              <div className="n-modal__line" />
              <div className="n-modal__content n-modal__content--flex">
                <div className="n-modal__content__title">
                {t("modal_channel_text_54")}&nbsp;<b>{t("modal_channel_text_55")}</b>
                </div>
                <div className="n-modal__content__required">{t("modal_channel_text_56")}</div>
                <div className={isShowError ? "n-input error" : "n-input"}>
                  <input
                    value={channelName}
                    type="text"
                    // maxLength={20}
                    onChange={this.handleChangeChannelName.bind(this)}
                    placeholder={t("modal_channel_text_62")}
                  />
                  <div className="n-input__error-message">
                    {isBlankOrNull(channelName)
                      ? t("modal_channel_text_63")
                      : t("modal_channel_text_64")}
                  </div>
                </div>
              </div>
              <div
                className={
                  this.state.isShowVisible
                    ? "n-modal__content n-modal__content--flex"
                    : "n-modal__content n-modal__content--flex is-opacity-30"
                }
              >
                <div className="n-modal__content__title">
                {t("modal_channel_text_57")}&nbsp;<b>{t("modal_channel_text_58")}</b>
                </div>
                <div className="n-modal__content__optional">{t("modal_channel_text_61")}</div>
                <div className="n-input">
                  <input
                    value={channelDescription}
                    onChange={this.handleChangeChannelDescription.bind(this)}
                    type="text"
                    placeholder={t("modal_channel_text_60")}
                  />
                </div>
              </div>
              <div className="n-modal__btns">
                <div
                  className="button-lg"
                  onClick={this.onSaveChannel.bind(this)}
                >
                  {t("modal_channel_text_59")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

export default withTranslation()(EditNewChannelModal)
