import React from 'react'
import {connect} from 'react-redux'

import MessageCard from './MessageCard'
import MessageCardAddButton from './MessageCardAddButton'


import { withTranslation } from 'react-i18next'

const Message = ({syncPosts, t}) => {
  
  // const { t } = this.props

  if (!syncPosts.length) {
    return (
      <div className="rrich-message__message">
        <div className="rrich-message__message__card">
          <div className="rrich-message__message__card__title rrich-message__message__card__title--additional">{t("filter_menu_text_51")}<br></br>{t("filter_menu_text_52")}<br></br>{t("filter_menu_text_53")}</div>
          <MessageCardAddButton />
        </div>
      </div>
    )
  }
  return (
    <div className="rrich-message__message">
      <div className="rrich-message__message__card">
        {syncPosts.map((post,index) => (
          <div key={post.id}>
            <div className="rrich-message__message__card__title">{t("filter_menu_text_54")} {index + 1}</div>
            <MessageCard post={post}/>
          </div>
        ))}
        <MessageCardAddButton />
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    syncPosts: state.posts.posts
  }
}

export default withTranslation()(connect(mapStateToProps, null)(Message))