import { Hub, API, graphqlOperation } from 'aws-amplify';
import { listUserRoles, getUserRole } from "../../graphql/queries";
import { createUserRole, deleteUserRole, updateUserRole } from "../../graphql/mutations";
import { onCreateUserRole, onDeleteUserRole, onUpdateUserRole } from "../../graphql/subscriptions";

import { loadList } from "./shared"
const action = listUserRoles
const attribute = "listUserRoles"

export const HUB_OBSERVER_USER_ROLE = "userrole"

let subscriptions = []

export const userRoleCreate = async (user, role) => {
  try {
    const filter = {
      userId: { eq: user.id }, roleId: { eq: role.id }
    }
    const userRoles = await loadList(action, attribute, filter, 1)
    if (userRoles?.length > 0) {
      console.log('UserRole already exist!')
      return
    }
  } catch (err) { console.log('error fetching userroles') }
  if (user == null || role == null) { 
    console.log('UserRole API: Required fields cannot be empty!')
    return
  }
  try {
    const response = await API.graphql(graphqlOperation(createUserRole, {input: {
      userId: user.id,
      roleId: role.id,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString() }}))
      return response?.data?.createUserRole
  } catch (err) {
    console.log('error creating userrole:', err)
    return
  }  
}

export const myUserRoleRead = async (userId) => {
  try {
    if (userId == null) { 
      console.log('UserRole API: Required fields cannot be empty!')
      return null
    }
    const filter = {
      userId: { eq: userId }
    }
    const userRoles = await loadList(action, attribute, filter, 0)
    return userRoles
  } catch (err) { 
    console.log('error fetching userroles') 
    return null
  }
}

export const userRoleRead = async () => {
  try {
    const userRoles = await loadList(action, attribute, null, 0)
    return userRoles
  } catch (err) { 
    console.log('error fetching userroles') 
    return null
  }
}

export const userRoleUpdate = async (userRoleId, role) => {
  var original = null
  try {
    const exist = await API.graphql(graphqlOperation(getUserRole, 
      { id: userRoleId }
    ))
    original = exist?.data?.getUserRole
  } catch (err) { console.log('error fetching userRole:', err) }
  if (original?.id === userRoleId) {
    if ( role == null) { 
      console.log('User Role API: Required fields cannot be empty!')
      return
    }
    try {
      const response = await API.graphql(graphqlOperation(updateUserRole, {input: {
        id: userRoleId, 
        roleId: role.id,
        updatedAt: new Date().toISOString() }}))
        return response?.data?.updateUserRole
    } catch (err) {
      console.log('error updating userRole:', err)
      return
    }
  } else {
    return null
  }
}

export const userRoleDelete = async (userRoleId) => {
  try {
    await API.graphql(graphqlOperation(deleteUserRole, { input: { id: userRoleId }}));
    return userRoleId
  } catch (err) { 
    console.log('error delete userrole:', err) 
    return userRoleId
  }       
}

export const userRoleObserve = async (userId) => {
  if ( userId == null || subscriptions.some(sub => sub.userId === userId) ) { return }
  const createEvent = 'INSERT'
  const createSubscription = API.graphql(graphqlOperation(onCreateUserRole, { userId: userId } )).subscribe({
    next: response => {
      const userrole = response.value.data.onCreateUserRole
      Hub.dispatch(HUB_OBSERVER_USER_ROLE, {event: createEvent, data: userrole, message:'create userrole observe' });  
    },
  })   
  subscriptions.push({userId, createSubscription, createEvent})
  const updateEvent = 'UPDATE'
  const updateSubscription = API.graphql(graphqlOperation(onUpdateUserRole, { userId: userId } )).subscribe({
    next: response => {
      const userrole = response.value.data.onUpdateUserRole
      Hub.dispatch(HUB_OBSERVER_USER_ROLE, {event: updateEvent, data: userrole, message:'update userrole observe' });  
    },
  })   
  subscriptions.push({userId, updateSubscription, updateEvent})
  const deleteEvent = 'DELETE'
  const deleteSubscription = API.graphql(graphqlOperation(onDeleteUserRole, { userId: userId } )).subscribe({
    next: response => {
      const userrole = response.value.data.onDeleteUserRole
      Hub.dispatch(HUB_OBSERVER_USER_ROLE, {event: deleteEvent, data: userrole, message:'delete userrole observe' });
    },
  })
  subscriptions.push({userId, deleteSubscription, deleteEvent})
}

export async function deleteUserRoleObservers(userId) {
  if (userId != null) {
    for (let sub of subscriptions) {
      if (sub.userId === userId && sub.subscription != null) {
          sub.subscription.unsubscribe()
      }
    }
  }          
}