import React, { Component } from "react"
import { connect } from "react-redux"
import { Modal } from "react-bootstrap"
import cn from "classname"
import { Hub } from "aws-amplify"
import * as K from "../../../utils/Constant"

import "./CopytradingCreateAccountErrorModal.scss"

class CopytradingCreateAccountErrorModal extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  toggleModal = () => {
    this.props.onHide()
    this.props.onHide()
    this.props.onHide()
    Hub.dispatch(K.HUB_OPEN_COPYTRADING_MENU_ACCOUNT)
  }
  onTryAgain = () => {
    this.props.onHide()
    this.props.onHide()
    this.props.onHide()
    Hub.dispatch(K.HUB_OPEN_COPYTRADING_MENU_ACCOUNT)
  }

  render() {    
    const { typeCreatingAccount } = this.props
    return (
      <Modal 
        {...this.props}
        onHide={this.toggleModal}
        centered
        aria-labelledby="contained-modal-title-vcenter"
        className="modal__modal"
      >
        <div className={cn("n-modal", "disclamer-modal")}>
          <div className="n-modal__close" onClick={this.toggleModal}>
            <svg
              width="20rem"
              height="20rem"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 2.01429L17.9857 0L10 7.98571L2.01429 0L0 2.01429L7.98571 10L0 17.9857L2.01429 20L10 12.0143L17.9857 20L20 17.9857L12.0143 10L20 2.01429Z"
              />
            </svg>
          </div>
          <div className="n-modal__header">
            <div className="n-modal__header__title-red">
              {typeCreatingAccount === 'master' && <div>Master account not registered</div>}   
              {typeCreatingAccount === 'slave' && <div>Account registration failed. <br></br>Check the broker, account number and password.</div>}      
            </div>
          </div>
          <div className="n-modal__wrapper">
             
          </div>
          <div className="n-modal__wrapper">
            <button 
              className="n-modal__btns__confirm-red"
              onClick={this.onTryAgain}
            >
              Try again
            </button>
          </div>
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = store => {
  return {
    typeCreatingAccount: store.tradesync.typeCreatingAccount
  }
}

export default connect(
  mapStateToProps,
  null
)(CopytradingCreateAccountErrorModal)