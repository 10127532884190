import { 
  CREATE_POST,
  DELETE_POST,
  DELETE_ALL_POSTS,
  UPDATE_CATEGORY,
  UPDATE_COLOR,
  UPDATE_TITLE,
  ADD_ROW_TABLE,
  UPDATE_ROW_TABLE,
  DELETE_ROW_TABLE,
  UPDATE_MEDIA,
  UPDATE_COMMENT
} from "../actions/RichMessageActions"

const initialState = {
  posts: []
}

export const richMessage = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_POST:
      return { ...state, posts: [...state.posts, action.payload] }

    case DELETE_POST:
      return { ...state, posts: state.posts.filter(item => item.id !== action.payload) }
    
    case DELETE_ALL_POSTS:
      return { ...state, posts: [] }
    
    case UPDATE_CATEGORY:
      return { ...state,
        posts: state.posts.map(item => item.id === action.payload?.id ? { ...item, 
          category: action.payload?.category, 
        } : item)
      }
      
    case UPDATE_COLOR:
      return { ...state,
        posts: state.posts.map(item => item.id === action.payload?.id ? { ...item, 
          color: action.payload?.color, 
        } : item)
      }

    case UPDATE_TITLE:
      return { ...state,
        posts: state.posts.map(item => item.id === action.payload?.id ? { ...item, 
          title: action.payload?.title, 
        } : item)
      }

    case ADD_ROW_TABLE:
      return { ...state,
          posts: state.posts.map(item => item.id === action.payload?.id ? { ...item, 
            table: item.table?.concat([action.payload?.row]) ?? [action.payload?.row], 
          } : item)
        }

    case UPDATE_ROW_TABLE:
      return { ...state,
        posts: state.posts.map(item => item.id === action.payload?.id ? { ...item, 
          table: item.table.map(row => {
            if (row?.position === action.payload?.row?.position) {
              console.log(row)
              row.title = action.payload?.row?.title
              row.detail = action.payload?.row?.detail
            }
            return row
          }), 
        } : item)
      }

    case DELETE_ROW_TABLE:
        return { ...state,
          posts: state.posts.map(item => item.id === action.payload?.id ? { ...item, 
            table: item.table.filter(row => row?.position !== action.payload?.row?.position), 
          } : item)
        }

    case UPDATE_MEDIA:
      return { ...state,
        posts: state.posts.map(item => item.id === action.payload?.id ? { ...item, 
          media: action.payload?.media, 
        } : item)
      }

    case UPDATE_COMMENT:
      return { ...state,
        posts: state.posts.map(item => item.id === action.payload?.id ? { ...item, 
          comment: action.payload?.comment, 
        } : item)
      }

    default: return state
  }
}