import React, { Component } from 'react'
import { connect } from 'react-redux'
import RssConnectModal from "../../components/home/widget/plugins/RssConnectModal"

class RssConnectContainer extends Component {

  onHide() {
    this.props.onHide()
  }

  render() {
    const { account, channel, category } = this.props.data
    const eventRule = this.props.events.find(item => item?.event === "rss" && item?.payload.includes(channel?.id) &&
      !this.props.events.some(el => el?.payload.includes(item?.id)) )

    return (
      <RssConnectModal
        account={account}
        channel={channel}
        category={category}
        rule={eventRule}
        show={this.props.show}
        onHide={this.onHide.bind(this)}
      />
    )
  }
}

const mapStateToProps = store => {
  return {
    permissions: store.permissions,
    events: store.events.list,
  }
}

export default connect(
  mapStateToProps
)(RssConnectContainer)