import React, { Component } from 'react'

import { AmplifyConfirmSignIn } from '@aws-amplify/ui-react';

class ConfirmSignIn extends Component {
  render() {
    return (
      <div slot="confirm-sign-in" style={{ textAlign: 'center' }}> 
          <AmplifyConfirmSignIn 
          usernameAlias="email"
          headerText="Verification code was sent to your email"/>
      </div>
    )
  }
}

export default ConfirmSignIn
