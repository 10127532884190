import React, { Component } from 'react'

import { AmplifyAuthenticator } from '@aws-amplify/ui-react';

import SignIn from '../../components/auth/SignIn';
import SignUp from '../../components/auth/SignUp';
import Reset from '../../components/auth/Reset';
import ConfirmSignUp from '../../components/auth/ConfirmSignUp';
import ConfirmSignIn from '../../components/auth/ConfirmSignIn';
import AuthTheme from '../../components/auth/AuthTheme';

class SignInContainer extends Component {

  render() {
    return (
      <AuthTheme>
        <AmplifyAuthenticator usernameAlias="email">          
          <SignIn />
          <SignUp />
          <Reset />
          <ConfirmSignUp />
          <ConfirmSignIn />
          {/* <VerifyContact />*/}          
        </AmplifyAuthenticator>
      </AuthTheme>
    )
  }
}

export default SignInContainer