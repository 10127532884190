import React, { Component } from 'react'
import { Modal } from "react-bootstrap"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { Hub } from 'aws-amplify'

import * as K from "../../../utils/Constant"
import { authUpdateUserAttributes } from "../../../services/auth"
class PrivacyModal extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    document.querySelector('html').classList.add('scale')
  }

  onSubmit = () => {
    authUpdateUserAttributes({"custom:setup_initial": "1"})
      .then(() => {
        Hub.dispatch(K.HUB_MODAL, {event: K.MODAL_ALERT_SHARE_LINK, data: {onAction: () => { Hub.dispatch(K.HUB_OPENGUIDECHANNELSMENU) } }, message:'show modal' });
        setTimeout(() => {
          Hub.dispatch(K.HUB_MODAL, {event: K.MODAL_ALERT_SHARE_LINK, data: {onAction: () => { Hub.dispatch(K.HUB_OPENGUIDECHANNELSMENU) } }, message:'show modal' });
        }, 5000)
      })
      .catch(err => console.error('Error confirm disclaimer:', err))
  }

  render() {
    const configDisclaimer = this.props.configs?.find(el => el?.detail === K.DEFAULT_PRIVACY_DISCLAIMER_CONFIG_KEY)
    const configPP = this.props.configs?.find(el => el?.detail === K.DEFAULT_PRIVACY_PP_CONFIG_KEY)
    const configToS = this.props.configs?.find(el => el?.detail === K.DEFAULT_PRIVACY_TOS_CONFIG_KEY)

    var disclaimerText = 'Loading...'
    var ppText = 'Loading...'
    var tosText = 'Loading...'
    var showD = false
    var showPP = false
    var showToS = false
    if (configDisclaimer != null) {
      try {
        const content = JSON.parse(configDisclaimer?.content)
        showD = content?.checked === true
        disclaimerText = JSON.parse(configDisclaimer?.content)?.text ?? disclaimerText
      } catch (e) {}
      try {
        const content = JSON.parse(configPP?.content)
        showPP = content?.checked === true
        ppText = content?.text ?? ''
      } catch (e) {}
      try {
        const content = JSON.parse(configToS?.content)
        showToS = content?.checked === true
        tosText = content?.text ?? ''
      } catch (e) {}
    }

    return (
      <Modal 
        {...this.props}
        centered
        aria-labelledby="contained-modal-title-vcenter" 
        className="n-modal__modal-information"
      >
        <div className='n-modal'>
          <div className='n-modal__wrapper'>
            <div className='n-modal__header'>
              <div className='n-modal__header__icon'>
                <svg width="44rem" height="40rem" viewBox="0 0 44 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_533_3970)">
                    <path d="M29.3991 16.3901L30.1291 15.6601C30.4691 15.3201 30.4691 14.7501 30.1291 14.4301L28.0791 12.3801C27.8991 12.2001 27.6891 12.1301 27.4591 12.1301M27.4591 12.1201C27.2391 12.1201 27.0091 12.2101 26.8491 12.3701L24.3391 14.9101M22.2391 16.9801L14.6191 24.6001V27.8801H17.8991L27.5891 18.1901L24.3091 14.9101" stroke="#203B60" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M22.5 38C32.4411 38 40.5 29.9411 40.5 20C40.5 10.0589 32.4411 2 22.5 2C12.5589 2 4.5 10.0589 4.5 20C4.5 29.9411 12.5589 38 22.5 38Z" stroke="#203B60" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_533_3970">
                      <rect width="38rem" height="38rem" fill="white" transform="translate(3.5 1)"/>
                    </clipPath>
                  </defs>
                </svg>
              </div><br/><br/>

              <Tabs>
                <TabList>
                  {showD && disclaimerText?.length > 0 && <Tab>Disclaimer</Tab> }
                  {showPP && ppText?.length > 0 && <Tab>Privacy</Tab> }
                  {showToS && tosText?.length > 0 && <Tab>Terms of Services</Tab> }
                </TabList>
                {showD && disclaimerText?.length > 0 &&  <TabPanel>
                  {disclaimerText}
                </TabPanel>
                }
                {showPP && ppText?.length > 0 && <TabPanel>
                  {ppText}
                </TabPanel>}
                {showToS && tosText?.length > 0 && <TabPanel>
                  {tosText}
                </TabPanel>}
              </Tabs>
              <div className="n-modal__line" />
              <div className='n-modal__btns'>
                <div className='button-lg' onClick={this.onSubmit.bind(this)}>Agree</div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default PrivacyModal
