import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'


import { withTranslation } from 'react-i18next'

class AlertPrivateChannelModal extends Component {

  toggleModal = () => {
    this.props.onHide()
  }

  closeModalAndOpenInfo = (isNeedSubscribe) => {
    this.props.data.onAction(isNeedSubscribe)
    this.props.onHide()
  }

  render() {
    
    const { t } = this.props

    const { channel, author } = this.props.data

    return (
      <Modal {...this.props}
             onHide={this.toggleModal}
             centered size="md"
             aria-labelledby="contained-modal-title-vcenter"
             className="n-modal__modal">
        <div className="n-modal">
          <div className="n-modal__wrapper">
            <div className="n-modal__header">
              <div className="n-modal__header__icon">
                <svg width="40rem" height="40rem" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20 38C29.9 38 38 29.9 38 20C38 10.1 29.9 2 20 2C10.1 2 2 10.1 2 20C2 29.9 10.1 38 20 38Z" stroke="#203B60" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
                  <path d="M17 13.9L27.4 20.1L17 26.3V13.9Z" stroke="#203B60" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
              <div className="n-modal__header__title">
                {t("modal_channel_text_26")} <b>{channel?.name}</b>
              </div>
              <div className="n-modal__line" />
              <div className="n-modal__content n-modal__content--flex">
                <div className="n-modal__content__title">
                {t("modal_channel_text_27")} <b>{author}</b>. {t("modal_channel_text_28")}
                </div>
              </div>
              <div className="n-modal__btns">
                <div className='n-modal__btns__wrapper'>
                  <div className="button-lg" onClick={() => this.closeModalAndOpenInfo(true)}>{t("modal_channel_text_29")}</div>
                  <div className="button-lg-outline" onClick={() => this.closeModalAndOpenInfo(false)}>{t("modal_channel_text_30")}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default withTranslation()(AlertPrivateChannelModal)