import { tradeSyncApiUrl, tradeSyncHeaders } from "./tradesync"
import { storageRead } from "../storage/storage"
import { COPYTRADING_FILE_ANALYSES_NAME } from "../../utils/Constant"

export const tradeSyncAnalysisAccounts = async (token, params) => {
  // const headers = tradeSyncHeaders(token)
  // const items = await loadTradeSyncList(`${tradeSyncApiUrl}/analyses`, headers, params)
  // return items
  const items = await storageRead(COPYTRADING_FILE_ANALYSES_NAME, true, true)
  try {
    return JSON.parse(items)
  } catch (e) {
    return null
  }
}

export const tradeSyncAnalysisAccount = async (token, account_id, params) => {
  const headers = tradeSyncHeaders(token)
  var request = ''
  try {
    const getParams = new URLSearchParams(params)
    request = `${tradeSyncApiUrl}/analyses/${account_id}?${getParams.toString()}`
  } catch (e) {
    request = `${tradeSyncApiUrl}/analyses/${account_id}`
  }
  const response = await fetch(request, {
    method: 'GET',
    headers,
  })
  if (response.ok) {
    const json = await response.json()
    return json?.data
  } else {
    console.error('tradesync error: ', response.status, response.statusText)
    return null
  }
}

export const tradeSyncAnalysisByHours = async (token, account_id, params) => {
  const headers = tradeSyncHeaders(token)
  var request = ''
  try {
    const getParams = new URLSearchParams(params)
    request = `${tradeSyncApiUrl}/analyses/${account_id}/hours?${getParams.toString()}`
  } catch (e) {
    request = `${tradeSyncApiUrl}/analyses/${account_id}/hours`
  }
  const response = await fetch(request, {
    method: 'GET',
    headers,
  })
  if (response.ok) {
    const json = await response.json()
    return json?.data
  } else {
    console.error('tradesync error: ', response.status, response.statusText)
    return null
  }
}

export const tradeSyncAnalysisByDays = async (token, account_id, params) => {
  const headers = tradeSyncHeaders(token)
  var request = ''
  try {
    const getParams = new URLSearchParams(params)
    request = `${tradeSyncApiUrl}/analyses/${account_id}/days?${getParams.toString()}`
  } catch (e) {
    request = `${tradeSyncApiUrl}/analyses/${account_id}/days`
  }
  const response = await fetch(request, {
    method: 'GET',
    headers,
  })
  if (response.ok) {
    const json = await response.json()
    return json?.data
  } else {
    console.error('tradesync error: ', response.status, response.statusText)
    return null
  }
}

export const tradeSyncAnalysisDaily = async (token, account_id, params) => {
  const headers = tradeSyncHeaders(token)
  var request = ''
  try {
    const getParams = new URLSearchParams(params)
    request = `${tradeSyncApiUrl}/analyses/${account_id}/dailies?${getParams.toString()}`
  } catch (e) {
    request = `${tradeSyncApiUrl}/analyses/${account_id}/dailies`
  }
  const response = await fetch(request, {
    method: 'GET',
    headers,
  })
  if (response.ok) {
    const json = await response.json()
    return json?.data
  } else {
    console.error('tradesync error: ', response.status, response.statusText)
    return null
  }
}

export const tradeSyncAnalysisMonthly = async (token, account_id, params) => {
  const headers = tradeSyncHeaders(token)
  var request = ''
  try {
    const getParams = new URLSearchParams(params)
    request = `${tradeSyncApiUrl}/analyses/${account_id}/monthlies?${getParams.toString()}`
  } catch (e) {
    request = `${tradeSyncApiUrl}/analyses/${account_id}/monthlies`
  }
  const response = await fetch(request, {
    method: 'GET',
    headers,
  })
  if (response.ok) {
    const json = await response.json()
    return json?.data
  } else {
    console.error('tradesync error: ', response.status, response.statusText)
    return null
  }
}

export const tradeSyncAnalysisSymbolMonthly = async (token, account_id, params) => {
  const headers = tradeSyncHeaders(token)
  var request = ''
  try {
    const getParams = new URLSearchParams(params)
    request = `${tradeSyncApiUrl}/analyses/${account_id}/monthly-symbols?${getParams.toString()}`
  } catch (e) {
    request = `${tradeSyncApiUrl}/analyses/${account_id}/monthly-symbols`
  }
  const response = await fetch(request, {
    method: 'GET',
    headers,
  })
  if (response.ok) {
    const json = await response.json()
    return json?.data
  } else {
    console.error('tradesync error: ', response.status, response.statusText)
    return null
  }
}