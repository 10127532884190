import React, { Component } from "react"
import { Modal, Spinner } from "react-bootstrap"
import parse from "html-react-parser"
import * as K from "../../../utils/Constant"
import CurrencyFormatter from "currencyformatter.js"
import { Hub } from "aws-amplify"

class PackageInformationModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      paymentProcessing: null,
    }
  }

  componentDidMount() {
    Hub.listen(K.HUB_PAYMENT_PACKAGE, this.handleStripePayment)
  }

  componentWillUnmount() {
    if (this.handleStripePayment) Hub.remove(K.HUB_PAYMENT_PACKAGE, this.handleStripePayment)
  }

  handleStripePayment = async ({ payload: { event, data, message } }) => {
    const isPurchased = event === "success" && data?.status === "active"
    this.setState({paymentProcessing: event})
    if (isPurchased) {
      setTimeout(this.toggleModal, 1000)
    }
  }

  addPayment = () => {
    const modal = K.MODAL_QUOTA_NO_PAYMENT_METHOD
    const data = {channel: this.props.data?.channel}
    Hub.dispatch(K.HUB_MODAL, {event: modal, data: data, message:'show modal' });
    this.toggleModal()
  }

  joinPackage = (isTrial) => {
    const { pkg, onFreePackageSubscription } = this.props.data
    onFreePackageSubscription(pkg, isTrial)
    this.toggleModal()
  }

  buyPackage = () => {
    const { pkg, onPaidPackageSubscription } = this.props.data
    onPaidPackageSubscription(pkg)
    this.setState({paymentProcessing: "processing"})
  }

  toggleModal = () => {
    this.props.onHide()
  }

  render() {
    const { pkg,  media, products, isFree, isRequiredPayment } = this.props.data
    const { paymentProcessing } = this.state

    const channelProducts = products?.filter(sub => sub?.bundle?.startsWith(K.PRODUCT_PREFIX_SINGLE_CHANNEL))
    const extraProducts = products?.filter(sub => sub?.bundle?.startsWith(K.PRODUCT_PREFIX_SINGLE_EXTRA))
    const planProduct = products?.find(sub => sub?.bundle?.startsWith(K.PRODUCT_PREFIX_PLAN))

    const fulPrice = pkg?.price > 0 ? pkg?.products?.reduce(function (result, item) {
      return result + item?.price;
    }, 0) : 0
    const savingPercent = Math.round(pkg?.price / fulPrice * 100)
    const savingPrice = CurrencyFormatter.format( fulPrice - pkg?.price , { currency: pkg?.currency, valueOnError: 'Invalid price'})

    const isReadySubscribe = this.props.data?.isReadySubscribe(pkg)
    const preventPackageSubscription = this.props.data.preventPackageSubscription(pkg)

    const isTrialed = this.props.data?.isTrialed(pkg)
    const isTrialAvailable = this.props.data?.isTrialAvailable(pkg)
    // const isTrialExpired = this.props.data?.isTrialExpired(pkg)

    return (
      <Modal
        {...this.props}
        onHide={this.toggleModal}
        centered
        aria-labelledby="contained-modal-title-vcenter"
        className="n-modal__modal-information"
      >
        <div className="n-modal">
          <div className="n-modal__wrapper">
            <div className="n-modal__close" onClick={this.toggleModal}>
              <svg
                width="20rem"
                height="20rem"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 2.01429L17.9857 0L10 7.98571L2.01429 0L0 2.01429L7.98571 10L0 17.9857L2.01429 20L10 12.0143L17.9857 20L20 17.9857L12.0143 10L20 2.01429Z"
                />
              </svg>
            </div>
            <div className="n-modal__body">
              <div className="n-modal__body__col-1">
                <div className="n-modal__body__channel-card">

                  <div className="n-modal__body__channel-card__img">
                    {/* If there is no picture */}
                    {/* <div className="n-modal__body__channel-card__img--default paid">PD</div> */}
                    {/* If there is an image */}
                    {media ? <img src={media}
                      alt=""
                         width="192px"
                         height="144px" /> :
                      <svg width="134rem" height="100rem" viewBox="0 0 134 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1638_30409)">
                          <rect width="133.962" height="100" rx="4" fill="url(#paint0_linear_1638_30409)"/>
                          <g clip-path="url(#clip1_1638_30409)">
                            <path d="M67.6792 76.2897V49.7024L91.5413 36.2103V51.2897C94.3252 51.2897 97.1091 51.6865 99.4953 52.877V29.4643C99.4953 27.877 98.6999 26.6865 97.5068 25.8929L66.0884 8.43257C65.293 8.03574 64.4976 7.63892 63.7022 7.63892C62.9068 7.63892 62.1114 8.03574 61.316 8.43257L29.8977 25.8929C28.7046 26.6865 27.9092 27.877 27.9092 29.4643V65.1786C27.9092 66.7659 28.7046 67.9564 29.8977 68.75L61.316 86.2103C62.1114 86.6072 62.9068 87.004 63.7022 87.004C64.4976 87.004 65.293 86.6072 66.0884 86.2103L69.6677 84.2262C68.4746 81.8453 68.0769 79.0675 67.6792 76.2897ZM63.7022 16.3691L87.5642 29.4643L79.6102 33.8294L56.1459 20.3373L63.7022 16.3691ZM59.7252 76.2897L35.8632 62.7976V36.2103L59.7252 49.7024V76.2897ZM63.7022 42.5596L39.8402 29.4643L47.7942 24.7024L71.6562 38.5913L63.7022 42.5596ZM95.5183 59.2262V71.131H107.449V79.0675H95.5183V90.9723H87.5642V79.0675H75.6332V71.131H87.5642V59.2262H95.5183Z" fill="#0F1B2D" fill-opacity="0.3"/>
                          </g>
                        </g>
                        <rect x="0.5" y="0.5" width="132.962" height="99" rx="5.5" stroke="#4E8DE8" stroke-opacity="0.5"/>
                        <defs>
                          <linearGradient id="paint0_linear_1638_30409" x1="64.998" y1="99.6842" x2="65.1259" y2="1.44925" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00A79D"/>
                            <stop offset="1" stop-color="#27AAE1"/>
                          </linearGradient>
                          <clipPath id="clip0_1638_30409">
                            <rect width="133.962" height="100" rx="6" fill="white"/>
                          </clipPath>
                          <clipPath id="clip1_1638_30409">
                            <rect width="79.5401" height="83.3333" fill="white" transform="translate(27.9092 7.63892)"/>
                          </clipPath>
                        </defs>
                      </svg>}
                  </div>
                  <div className="n-modal__body__channel-card__name">
                    <div className="n-modal__body__channel-card__name--subtitle">Subscribe to</div>
                    <div className="n-modal__body__channel-card__name--title">{pkg?.name}</div>
                  </div>
                  {preventPackageSubscription?.length > 0 ? <div className="n-modal__body__channel-card__free">
                      <b>Subscription isn't available<br/>because you have an individual<br/>subscription with these products:</b><br/><br/>
                      {preventPackageSubscription?.map(ch => <div>{ch?.name}<br/></div>)}
                  </div> :
                    isFree ?
                    <div className="n-modal__body__channel-card__paid">
                      <div className="button-lg" onClick={() => this.joinPackage(false)}>Subscribe for free</div>
                    </div> :
                    <div className="n-modal__body__channel-card__paid">
                      <div className="n-modal__body__channel-card__paid--price">{CurrencyFormatter.format(pkg?.price, { currency: pkg?.currency, valueOnError: 'Invalid price'})}/month</div>
                      {paymentProcessing === "processing" ? <div className="button-lg"><Spinner animation="border" /></div> :
                        paymentProcessing === "success" ? <div>Payment is completed <Spinner animation="grow" variant="success" /></div> :
                          paymentProcessing === "error" ? <div>Payment is NOT completed <Spinner animation="grow" variant="danger" /></div> :
                            isReadySubscribe ? <div className="button-lg" onClick={isRequiredPayment ? this.addPayment.bind(this) : this.buyPackage.bind(this)}><b>Buy now</b></div> :
                                <div className="n-modal__body__channel-card__paid--trial">Will be available soon.</div>
                      }
                      {paymentProcessing == null && !isTrialed && isTrialAvailable &&
                        <div className="button-lg-outline" style={{ textTransform: 'uppercase', marginTop: '12px'}} onClick={() => this.joinPackage(true)}>
                          <b>Free Trial {pkg?.trial?.value} {pkg?.trial?.unit}</b>
                        </div>}
                    </div>}
                </div>
              </div>
              <div className="n-modal__body__br"></div>
              <div className="n-modal__body__col-2">
                <div className="n-modal__body__channel-card__info">
                  <div className="n-modal__body__channel-card__info__wrapper">
                    <div className="n-modal__body__channel-card__info__one-description">
                      <div>With this package you will get</div>
                      <ul>
                        {channelProducts?.length > 0 && <li><b style={{color: '#000000'}}>{channelProducts?.length}</b>&nbsp;included premium channels</li>}
                        {extraProducts?.length > 0 && <li><b style={{color: '#000000'}}>{extraProducts?.length}</b>&nbsp;included premium extras</li>}
                        {planProduct && <li><b style={{color: '#000000'}}>{planProduct?.name}</b>&nbsp;feature plan</li>}
                      </ul>
                      <svg width="18rem" height="18rem" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 12.7273L1.54545 9L0 5.27273L3.72726 3.72727L5.27273 0L8.99999 1.54545L12.7273 0L14.2727 3.72727L18 5.27273L16.4545 9L18 12.7273L14.2727 14.2727L12.7273 18L8.99999 16.4545L5.27273 18L3.72726 14.2727L0 12.7273ZM2.36363 6.27273L3.45455 9L2.36363 11.7273L5.0909 12.8182L6.18182 15.5455L8.90909 14.4545L11.6364 15.5455L12.7273 12.8182L15.4545 11.7273L14.3636 9L15.4545 6.27273L12.7273 5.18182L11.6364 2.45455L8.90909 3.54545L6.18182 2.45455L5.0909 5.18182L2.36363 6.27273Z" fill="#39D6C7"/>
                      </svg>&nbsp;
                      <b style={{ margin: '0 0 0 6px', color: '#39D6C7' }}>Save</b>&nbsp;
                      <b style={{ color: '#000000' }}>{savingPrice}</b>&nbsp;
                      <b style={{ color: '#000000', alignItems: 'center' }}>{`or ${savingPercent}%`}</b>
                    </div>
                    <div className="n-modal__body__channel-card__info__description">{parse (pkg?.description ?? "<p />")}</div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

export default PackageInformationModal
