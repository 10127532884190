import React, { Component } from 'react'
import { Form } from 'react-bootstrap'

import { connect } from 'react-redux'
import { updateComment } from '../../../../../../store/actions/RichMessageActions'
import * as K from '../../../../../../utils/Constant'
import MessageTooLong from '../../../../../modal/permission-message/MessageTooLong'


import { withTranslation } from 'react-i18next'

class TextCard extends Component {  
  constructor(props) {
    super(props)
    this.state={
      showMessageTooLong: false
    }        
  }

  handleChange(event) {
    var msg_limit = 0
    try {
      const msg_limit_permission = this.props.permissions?.find(per => per?.includes(K.FEATURE_MESSAGE_MAXIMUM_LENGTH_OF_A_PLAIN_MESSAGE))
      msg_limit = msg_limit_permission != null ? parseInt(msg_limit_permission.split(":")[1]) : 0
    } catch(err) { console.log('FEATURE_MESSAGE_MAXIMUM_LENGTH_OF_A_PLAIN_MESSAGE decode failed', err) }
    if (event.target.value.length > msg_limit) {
      this.openMessageTooLong()
    } else {
      this.props.updateComment({id: this.props.id, comment: event.target.value})
    }
  }

  openMessageTooLong = () => this.setState({ showMessageTooLong: true })
  closeMessageTooLong = () => this.setState({ showMessageTooLong: false })

  render() { 
    
    const { t } = this.props

    return ( 
      <div className="rrich-message__message__card__body__text-card">
        <div className="rrich-message__message__card__body__text-card__icon">
          <svg width="22rem" height="22rem" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg"><defs><filter id="filter-1"><feColorMatrix in="SourceGraphic" type="matrix" values="0 0 0 0 0.533333 0 0 0 0 0.533333 0 0 0 0 0.533333 0 0 0 1.000000 0"></feColorMatrix></filter></defs><g id="Rich-message-input-window:-Visual-Composition" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><g id="Group-28" transform="translate(-19.000000, -277.000000)" fill="#888888"><g filter="url(#filter-1)" id="Icons/ion-md-list"><g transform="translate(15.000000, 272.000000)"><path d="M4.75903614,20.5 L4.75903614,17.5 L19.9879518,17.5 L19.9879518,20.5 L4.75903614,20.5 Z M4.75903614,14.5 L4.75903614,11.5 L23.7951807,11.5 L23.7951807,14.5 L4.75903614,14.5 Z M4.75903614,8.5 L4.75903614,5.5 L25.6987952,5.5 L25.6987952,8.5 L4.75903614,8.5 Z M4.75903614,26.5 L4.75903614,23.5 L21.8915663,23.5 L21.8915663,26.5 L4.75903614,26.5 Z" fill="#FFFFFF"></path></g></g></g></g></svg>
        </div>
        <div className="rrich-message__message__card__body__text-card__text-comment">
          <Form.Control as="textarea" rows="3"
            // maxLength="1200"
            style={{
              minHeight: '86rem'
            }}
            placeholder={t("filter_menu_text_48")}
            value={this.props.comment} 
            onChange={this.handleChange.bind(this)}
          />
        </div>
        <MessageTooLong
            show={ this.state.showMessageTooLong }
            onHide = { this.closeMessageTooLong.bind(this) } />
      </div>
    )
  }
}

const mapStateToProps = store => {
  return {
    permissions: store.permissions
  }
}

const mapDispatchToProps = dispatch => {
  return { updateComment: (id, comment) => dispatch(updateComment(id, comment)) }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(TextCard))