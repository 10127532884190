import { Component } from 'react'
import { Hub } from 'aws-amplify'
import * as K from "../../../../utils/Constant"

class GoToMarketPlaceChannels extends Component {
  GoToMarketPlaceChannels() {
    Hub.dispatch(K.HUB_GOTOMARKETPLACECHANNELS, { event: 'Subheader', data: null, message:'' })
  }
}

export default GoToMarketPlaceChannels