import React, { Component } from 'react'

import {connect} from 'react-redux'
import {deletePost} from '../../../../../store/actions/RichMessageActions'

import ChooseMessageCategory from './message-card/ChooseMessageCategory'
import ColorCard from './message-card/ColorCard'
import TitleCard from './message-card/TitleCard'
import TableCard from './message-card/TableCard'
import ImageCard from './message-card/ImageCard'
import TextCard from './message-card/TextCard'

class MessageCard extends Component {
  constructor(props) {
    super(props)
    this.state={}
  }

  deleteMessageCard = () => { this.props.deletePost(this.props.post?.id) }

  render() {
    return ( 
      <div { ...this.props }>
        <div className="rrich-message__message__card__body rrich-message__message__card__body__background">
          <div className="icon icon-24 icon-close" onClick={ this.deleteMessageCard }>
            <span className="icon-block">
              <span className="icon-wrapper">
                <svg height="24rem" viewBox="0 0 24 24" width="24rem" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd"><path d="m0 0h24v24h-24z"/><path d="m18 7.20805369-4.7919463 4.79194631 4.7919463 4.7919463-1.2080537 1.2080537-4.7919463-4.7919463-4.79194631 4.7919463-1.20805369-1.2080537 4.7919463-4.7919463-4.7919463-4.79194631 1.20805369-1.20805369 4.79194631 4.7919463 4.7919463-4.7919463z" fill="currentColor"/></g></svg>
              </span>
            </span>
          </div>
          <div className="rrich-message__message__card__body__wrapper">
            <ChooseMessageCategory id={this.props.post?.id} category={this.props.post?.category}/>
            <ColorCard id={this.props.post?.id} color={this.props.post?.color ?? "#FFFFFF"}/>
            <TitleCard id={this.props.post?.id} title={this.props.post?.title}/>
            <TableCard post={this.props.post}/>
            <ImageCard post={this.props.post}/>
            <TextCard id={this.props.post?.id} comment={this.props.post?.comment}/>
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    deletePost: (id) => dispatch(deletePost(id)),
  }
}

export default connect(null, mapDispatchToProps)(MessageCard)
