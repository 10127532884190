import React, { Component } from 'react'
import { connect } from 'react-redux'

import { handleRoleGet, observeRole, handleRoleObserverAPI, applyRoles } from '../store/actions/RoleActions'
import { handleUserRoleRead, observeUserRole, handleUserRoleObserverAPI } from '../store/actions/UserRoleActions'
import { handleRoleProductRead, observeRoleProduct, handleRoleProductObserverAPI } from '../store/actions/RoleProductActions'
import { handleUserProductRead, observeUserProduct, handleUserProductObserverAPI } from '../store/actions/UserProductActions'
import { handleProductRead, applyProducts, replacePermission, clearFeature } from "../store/actions/ProductActions"

import { Hub } from 'aws-amplify'
import { HUB_OBSERVER_ROLE } from '../services/api/role'
import { HUB_OBSERVER_USER_ROLE } from '../services/api/userrole'
import Loader from "../shared/Loader"
import { HUB_OBSERVER_USER_PRODUCT } from "../services/api/userproduct"
import { HUB_OBSERVER_ROLE_PRODUCT } from "../services/api/roleproduct"
import { PRODUCT_PREFIX_PLAN } from "../utils/Constant"


class PermissionContainer extends Component {
  constructor(props) {
    super(props)
    this.state={
      roleRead: null,
      appliedRole: null,
      productsRead: false,
      fetchedProducts: [],
      appliedProducts: [],
    }        
  }
  
  componentDidMount() {
    // console.log('PERMISSION - DID MOUNT')
    this.props.handleUserRoleRead(this.props.account?.user?.id)
  }

  componentDidUpdate() {
    const currentRole = this.props.account?.roles?.find(el => el != null)
    if (currentRole?.roleId == null) { return }
    if (currentRole?.roleId !== this.state.roleRead) {
      this.removeObservers()
      this.props.handleRoleGet(currentRole?.roleId)

      Hub.listen(HUB_OBSERVER_USER_ROLE, this.userRoleObserver)
      setTimeout(this.onUserRoleObserve, 50)

      Hub.listen(HUB_OBSERVER_ROLE, this.roleObserver)
      setTimeout(this.onRoleObserve, 100)

      Hub.listen(HUB_OBSERVER_ROLE_PRODUCT, this.roleProductObserver)
      setTimeout(this.onRoleProductObserve, 150)

      Hub.listen(HUB_OBSERVER_USER_PRODUCT, this.userProductObserver)
      setTimeout(this.onUserProductObserve, 200)


      this.setState({roleRead: currentRole?.roleId, productsRead: false})
    } else if (currentRole?.roleId === this.state.roleRead && !this.state.productsRead) {
      this.props.handleRoleProductRead(this.props.account?.roles[0]?.roleId)
      this.props.handleUserProductRead(this.props.account?.user?.id)
      this.setState({productsRead: true})
    }

    const roleProductIds = this.props.account?.roleProducts?.map(el => el?.productId)
    const userProductIds = this.props.account?.userProducts?.filter(uPr => uPr?.product?.bundle?.startsWith(PRODUCT_PREFIX_PLAN))?.map(uPr => uPr?.productId)
    const productIds = roleProductIds.concat(userProductIds)
    if (productIds?.some(id => !this.state.fetchedProducts?.some(appliedId => appliedId === id ))) {
      this.props.handleProductRead(productIds)
      this.setState({fetchedProducts: productIds})
    } else if (this.props.account?.products?.some(product => !this.state.appliedProducts?.some(appliedId => appliedId === product?.id ))) {
      this.setState({appliedProducts: this.props.account?.products?.map(item => item?.id)})
      this.reloadProductPermissions()
    }
  }

  componentWillUnmount() {
    // console.log('PERMISSION - UNMOUNT')    
    this.removeObservers()
  }

  removeObservers() {
    // console.log('PERMISSION - REMOVE OBSERVERS')    
    // if (this.userRoleObserver) Hub.remove(HUB_OBSERVER_USER_ROLE, this.userRoleObserver);
    // if (this.roleObserver) Hub.remove(HUB_OBSERVER_ROLE, this.roleObserver);
    // if (this.onRoleProductObserve) Hub.remove(HUB_OBSERVER_ROLE_PRODUCT, this.onRoleProductObserve);
    // if (this.onUserProductObserve) Hub.remove(HUB_OBSERVER_USER_PRODUCT, this.onUserProductObserve);
  }

  onUserRoleObserve = () => {
    // console.log('PERMISSION - USER ROLE OBSERVER')
    if (this.props.account?.user?.id != null) {
      this.props.observeUserRole(this.props.account?.user?.id)
    }    
  }

  userRoleObserver = async ({ payload: { event, data, message } }) => {
    // console.log('PERMISSION - USER ROLE OBSERVER EVENT', event, message, data)
    if (data != null) {
      this.props.handleUserRoleObserverAPI(event, data)
    }    
  }

  onRoleObserve = () => {
    // console.log('PERMISSION - ROLE PERMISSION OBSERVER')
    if (this.props.account?.roles?.length > 0) {
      this.props.observeRole(this.props.account?.roles[0]?.roleId)
    }    
  }

  roleObserver = async ({ payload: { event, data, message } }) => {
    // console.log('PERMISSION - ROLE PERMISSION OBSERVER EVENT', event, message, data)
    if (data != null && this.props.account?.roles[0]?.roleId === data?.id) {
      this.props.handleRoleObserverAPI(event, data)
      this.reloadProductPermissions()
    }    
  }

  onRoleProductObserve = () => {
    // console.log('PERMISSION - ROLE PRODUCT OBSERVER')
    if (this.props.account?.roles?.length > 0) {
      this.props.observeRoleProduct(this.props.account?.roles[0]?.roleId)
    }
  }

  roleProductObserver = async ({ payload: { event, data, message } }) => {
    // console.log('PERMISSION - ROLE PRODUCT OBSERVER EVENT', event, message, data)
    if (data != null && this.props.account?.roles[0]?.roleId === data?.roleId) {
      this.props.handleRoleProductObserverAPI(event, data)
      this.reloadProductPermissions()
    }
  }

  onUserProductObserve = () => {
    // console.log('PERMISSION - USER PRODUCT OBSERVER')
    if (this.props.account?.user?.id != null) {
      this.props.observeUserProduct(this.props.account?.user?.id)
    }
  }

  userProductObserver = async ({ payload: { event, data, message } }) => {
    // console.log('PERMISSION - ROLE PRODUCT OBSERVER EVENT', event, message, data)
    if (data != null && this.props.account?.user?.id === data?.userId) {
      this.props.handleUserProductObserverAPI(event, data)
      this.reloadProductPermissions()
    }
  }
  
  reloadProductPermissions() {
    // console.log('Reload product permissions', this.props.account?.products)
    const plans = this.props.account?.products?.forEach(product => {
      product?.features && this.reloadPlanPermissions(product?.features)
    })
    return plans
  }

  reloadPlanPermissions(features) {
    const planPermissions = features?.filter(feature => feature?.policy !== "false" )?.forEach(feature => {
      this.checkPlanFeature(feature)
      return
    })
    return planPermissions
  }

  checkPlanFeature(feature) {
    if (feature?.permission == null || feature?.policy == null) { return }
    try {
      if (feature?.policy === "true") {
        const permission = this.props.permissions.find(per => per === feature?.permission)
        if (permission == null) {
          const oldPermission = null
          const newPermission = feature?.permission
          this.props.replacePermission(oldPermission, newPermission)
        }
      } else {
        const permission = this.props.permissions.find(per => per?.includes(feature?.permission))
        const limit = permission != null ? parseInt(permission.split(":")[1]) : 0
        if (limit < parseInt(feature?.policy ?? 0)) {
          const oldPermission = permission
          const newPermission = `${feature?.permission}:${feature?.policy}`
          this.props.replacePermission(oldPermission, newPermission)
        }
      }
    } catch(err) { console.log(`${feature?.permission} decode failed: `, err) }
  }

  render() {
    return (
      <div>{ this.props.account?.roles?.length > 0 && this.state.fetchedProducts?.length === this.state.appliedProducts?.length && this.state.appliedProducts?.length > 0 ? this.props.children : <Loader/> }</div>
    )
  }
}

const mapStateToProps = store => {
  return {
    account: store.user,
    permissions: store.permissions,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleUserRoleRead: (userId) => dispatch(handleUserRoleRead(userId)),
    observeUserRole: (userId) => dispatch(observeUserRole(userId)),
    handleUserRoleObserverAPI: (event, userRole) => dispatch(handleUserRoleObserverAPI(event, userRole)),
    handleRoleGet: (roleId) => dispatch(handleRoleGet(roleId)),
    observeRole: (roleId) => dispatch(observeRole(roleId)),
    handleRoleObserverAPI: (event, role) => dispatch(handleRoleObserverAPI(event, role)),
    applyRoles: (roles) => dispatch(applyRoles(roles)),

    handleUserProductRead: (userId) => dispatch(handleUserProductRead(userId)),
    observeUserProduct: (userId) => dispatch(observeUserProduct(userId)),
    handleUserProductObserverAPI: (event, userProduct) => dispatch(handleUserProductObserverAPI(event, userProduct)),

    handleRoleProductRead: (roleId) => dispatch(handleRoleProductRead(roleId)),
    observeRoleProduct: (roleId) => dispatch(observeRoleProduct(roleId)),
    handleRoleProductObserverAPI: (event, roleProduct) => dispatch(handleRoleProductObserverAPI(event, roleProduct)),

    handleProductRead: (ids) => dispatch(handleProductRead(ids)),
    applyProducts: (productPermissions) => dispatch(applyProducts(productPermissions)),
    replacePermission: (oldPermission, newPermission) => dispatch(replacePermission(oldPermission, newPermission)),
    clearFeature: (clearPermission) => dispatch(clearFeature(clearPermission)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PermissionContainer)