import { API, graphqlOperation } from 'aws-amplify';
import { listConfigs, getConfig } from "../../graphql/queries";
import { createConfig, deleteConfig, updateConfig } from "../../graphql/mutations";
import * as K from "../../utils/Constant";

import { loadList } from "./shared"
// import { DEFAULT_SET_USER_DATA_CONFIG_NAME_TITLE } from "../../utils/Constant"
const action = listConfigs
const attribute = "listConfigs"

export const HUB_OBSERVER_CONFIG = "config"

export const configCreate = async (title, detail, content) => {
  if (title == null || detail == null || content == null) {
    console.log('Config API: Required fields cannot be empty!')
    return
  } else {
    const configs = await loadList(action, attribute, {title: {eq: title}, detail: {eq: detail}}, 1)
    if (configs?.length > 0) {
      console.log('Config already exist')
      return
    }
  }
  try {
    const response = await API.graphql(graphqlOperation(createConfig, {input: {
      title: title,
      detail: detail,
      content: content,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString() }}))
      return response?.data?.createConfig
  } catch (err) {
    console.log('error creating config:', err)
    return
  }
}

export const configRead = async () => {
  try {
    const configs = await loadList(action, attribute, null, 0)
    return configs
  } catch (err) { 
    console.log('error fetching config', err) 
    return null
  }  
}

export const configUpdate = async (configId, title, detail, content) => {
  var original = null
  try {
    const exist = await API.graphql(graphqlOperation(getConfig, 
      { id: configId }
    ))
    original = exist?.data?.getConfig
  } catch (err) { console.log('error fetching config:', err) }
  if (original?.id === configId) {
    if (configId == null || title == null || detail == null || content == null) { 
      console.log('Config API: Required fields cannot be empty!')
      return
    }
    try {
      const response = await API.graphql(graphqlOperation(updateConfig, {input: {
        id: configId, 
        title: title,
        detail: detail,      
        content: content,
        updatedAt: new Date().toISOString() }}))
        return response?.data?.updateConfig
    } catch (err) {
      console.log('error updating config:', err)
      return
    }
  } else {
    return null
  }
}

export const configDelete = async (configId) => {
  await API.graphql(graphqlOperation(deleteConfig, { input: { id: configId }}));
  return configId
}

export async function fillConfigs() {
  await configCreate("Company name", K.DEFAULT_SET_COMPANY_NAME_CONFIG_KEY, "Econq")
  await configCreate("Company logo", K.DEFAULT_SET_COMPANY_LOGO_CONFIG_KEY, "null")
  await configCreate("Backgraund color workspace", K.DEFAULT_SET_BACKGROUND_COLOR_PICKER_KEY, "#aaaaaa")
  await configCreate(K.FIRST_MESSAGE_CATEGORY_CONFIG_TITLE, K.DEFAULT_MESSAGE_CATEGORY_CONFIG_KEY, "message")
  await configCreate("Category 0", K.DEFAULT_MESSAGE_CATEGORY_CONFIG_KEY, "post")
  await configCreate("Category 1", K.DEFAULT_MESSAGE_CATEGORY_CONFIG_KEY, "alarm")
  await configCreate("Category 2", K.DEFAULT_MESSAGE_CATEGORY_CONFIG_KEY, "order")
  await configCreate("Category 3", K.DEFAULT_MESSAGE_CATEGORY_CONFIG_KEY, "notification")
  await configCreate(K.DEFAULT_SET_USER_DATA_CONFIG_NAME_TITLE, K.DEFAULT_SET_USER_DATA_CONFIG_KEY, "true")
  await configCreate(K.DEFAULT_SET_USER_DATA_CONFIG_USERNAME_TITLE, K.DEFAULT_SET_USER_DATA_CONFIG_KEY, "true")
  await configCreate(K.DEFAULT_SET_USER_DATA_CONFIG_PHONE_TITLE, K.DEFAULT_SET_USER_DATA_CONFIG_KEY, "true")
  await configCreate("Stripe public key", K.PLATFORM_STRIPE_PUBLIC_KEY, btoa("public key"))
  await configCreate("Stripe secret key", K.PLATFORM_STRIPE_KEY, btoa("secret key"))
  await configCreate(K.DEFAULT_WORKSPACE_NAME, K.DEFAULT_SET_WORKSPACE_TEMPLATE_CONFIG_KEY, JSON.stringify([]))
  await configCreate("Enabled workspace template", K.DEFAULT_SET_ENABLED_WORKSPACE_TEMPLATE_CONFIG_KEY, "true")
  await configCreate("Stripe default currency", K.PLATFORM_STRIPE_CURRENCY_KEY, K.CURRENCY_USD)
}
