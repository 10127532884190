import * as K from "./Constant"
import { isBlankOrNull, loadBrokerAddress } from "./Helpres"

export const getCompanyNameFromConfig = (configs) => {
  return configs?.find(el => el?.detail === K.DEFAULT_SET_COMPANY_NAME_CONFIG_KEY)?.content ?? "Company";
}

export const getCurrencyFromConfig = (configs) => {
  return configs?.find(el => el?.detail === K.PLATFORM_STRIPE_CURRENCY_KEY)?.content ?? K.CURRENCY_USD;
}

export const getStripeKeyFromConfig = (configs) => {
  const stripeKeyContent = configs?.find(cf => cf?.detail === K.PLATFORM_STRIPE_KEY)?.content ?? ""
  const stripeKey = atob(stripeKeyContent)
  if (stripeKey?.startsWith("sk_")) {
    return stripeKey
  } else {
    return null
  }
}

export const getStripePublicKeyFromConfig = (configs) => {
  const stripePublicKeyContent = configs?.find(cf => cf?.detail === K.PLATFORM_STRIPE_PUBLIC_KEY)?.content ?? ""
  const stripePublicKey = atob(stripePublicKeyContent)
  if (stripePublicKey?.startsWith("pk_")) {
    return stripePublicKey
  } else {
    return null
  }
}

export const getTradeSyncKeyFromConfig = (configs) => {
  const tradesyncKeyContent = configs?.find(cf => cf?.detail === K.PLATFORM_COPYTRADING_KEY)?.content ?? ""
  const tradesyncKey = atob(tradesyncKeyContent)
  const tradesyncPublicKeyContent = configs?.find(cf => cf?.detail === K.PLATFORM_COPYTRADING_PUBLIC_KEY)?.content ?? ""
  const tradesyncPublicKey = atob(tradesyncPublicKeyContent)
  if (!isBlankOrNull(tradesyncKey) && !isBlankOrNull(tradesyncPublicKey)) {
    return btoa(`${tradesyncPublicKey}:${tradesyncKey}`)
  } else {
    return null
  }
}

export const getTradeSyncBrokerIdFromConfig = (configs) => {
  const brokerAddressContent = configs?.find(cf => cf?.detail === K.PLATFORM_COPYTRADING_BROKER_ADDRESS_KEY)?.content ?? ""
  if (!isBlankOrNull(brokerAddressContent)) {
    var json = null
    try {
      json = loadBrokerAddress(brokerAddressContent)
    } catch {}
    return json
  } else {
    return null
  }
}