// import { Hub } from "aws-amplify"
// import * as K from "../../utils/Constant"
import {
  tradeSyncAccount,
  tradeSyncAccountCreate,
  tradeSyncAccountDelete,
  tradeSyncAccounts, tradeSyncAccountUpdate
} from "../../services/tradesync/accounts"
import { tradeSyncBrokerALlServers, tradeSyncBrokers } from "../../services/tradesync/brokers"
import {
  tradeSyncCopierCreate,
  tradeSyncCopierDelete,
  tradeSyncCopiers,
  tradeSyncCopierUpdate
} from "../../services/tradesync/copiers"
import { tradeSyncAnalysisAccounts } from "../../services/tradesync/analysis"
import { tradeSyncWebhookCreate, tradeSyncWebhookDelete, tradeSyncWebhooks } from "../../services/tradesync/webhooks"
import { tradesyncCreate, tradesyncRead } from "../../services/api/tradesync"
import { restapiCopytradingSubscribe } from "../../services/rest/restApi"
import { channelGet } from "../../services/api/channel"
import { CHANNEL_GET_SUCCESS } from "./ChannelActions"
import { streamGet } from "../../services/api/stream"
import { STREAM_GET_SUCCESS } from "./StreamActions"
import { widgetGet } from "../../services/api/widget"
import { WIDGET_GET_SUCCESS } from "./WidgetActions"
import { handleMessageRead } from "./MessageActions"

export const COPYTRADING_ACCOUNT_LIST_SUCCESS = "COPYTRADING_ACCOUNT_LIST_SUCCESS"
export const COPYTRADING_ACCOUNT_LIST_FAIL = "COPYTRADING_ACCOUNT_LIST_FAIL"
export const COPYTRADING_ACCOUNT_GET_SUCCESS = "COPYTRADING_ACCOUNT_GET_SUCCESS"
export const COPYTRADING_ACCOUNT_GET_FAIL = "COPYTRADING_ACCOUNT_GET_FAIL"
export const COPYTRADING_ACCOUNT_CREATE_START = "COPYTRADING_ACCOUNT_CREATE_START"
export const COPYTRADING_ACCOUNT_CREATE_SUCCESS = "COPYTRADING_ACCOUNT_CREATE_SUCCESS"
export const COPYTRADING_TYPE_CREATE_ACCOUNT = "COPYTRADING_TYPE_CREATE_ACCOUNT"
export const COPYTRADING_ACCOUNT_CREATE_FAIL = "COPYTRADING_ACCOUNT_CREATE_FAIL"
export const COPYTRADING_ACCOUNT_UPDATE_SUCCESS = "COPYTRADING_ACCOUNT_UPDATE_SUCCESS"
export const COPYTRADING_ACCOUNT_UPDATE_FAIL = "COPYTRADING_ACCOUNT_UPDATE_FAIL"
export const COPYTRADING_ACCOUNT_DELETE_SUCCESS = "COPYTRADING_ACCOUNT_DELETE_SUCCESS"
export const COPYTRADING_ACCOUNT_DELETE_FAIL = "COPYTRADING_ACCOUNT_DELETE_FAIL"

export const COPYTRADING_BROKER_LIST_SUCCESS = "COPYTRADING_BROKER_LIST_SUCCESS"
export const COPYTRADING_BROKER_LIST_FAIL = "COPYTRADING_BROKER_LIST_FAIL"
export const COPYTRADING_BROKER_SERVER_LIST_SUCCESS = "COPYTRADING_BROKER_SERVER_LIST_SUCCESS"
export const COPYTRADING_BROKER_SERVER_LIST_FAIL = "COPYTRADING_BROKER_SERVER_LIST_FAIL"

export const COPYTRADING_COPIER_LIST_SUCCESS = "COPYTRADING_COPIER_LIST_SUCCESS"
export const COPYTRADING_COPIER_LIST_FAIL = "COPYTRADING_COPIER_LIST_FAIL"
export const COPYTRADING_COPIER_CREATE_SUCCESS = "COPYTRADING_COPIER_CREATE_SUCCESS"
export const COPYTRADING_COPIER_CREATE_FAIL = "COPYTRADING_COPIER_CREATE_FAIL"
export const COPYTRADING_COPIER_DELETE_SUCCESS = "COPYTRADING_COPIER_DELETE_SUCCESS"
export const COPYTRADING_COPIER_DELETE_FAIL = "COPYTRADING_COPIER_DELETE_FAIL"

export const COPYTRADING_ANALYSIS_ACCOUNT_LIST_SUCCESS = "COPYTRADING_ANALYSIS_ACCOUNT_LIST_SUCCESS"
export const COPYTRADING_ANALYSIS_ACCOUNT_LIST_FAIL = "COPYTRADING_ANALYSIS_ACCOUNT_LIST_FAIL"

export const COPYTRADING_WEBHOOK_LIST_SUCCESS = "COPYTRADING_WEBHOOK_LIST_SUCCESS"
export const COPYTRADING_WEBHOOK_LIST_FAIL = "COPYTRADING_WEBHOOK_LIST_FAIL"

export const COPYTRADING_WEBHOOK_CREATE_SUCCESS = "COPYTRADING_WEBHOOK_CREATE_SUCCESS"
export const COPYTRADING_WEBHOOK_CREATE_FAIL = "COPYTRADING_WEBHOOK_CREATE_FAIL"
export const COPYTRADING_WEBHOOK_DELETE_SUCCESS = "COPYTRADING_WEBHOOK_DELETE_SUCCESS"
export const COPYTRADING_WEBHOOK_DELETE_FAIL = "COPYTRADING_WEBHOOK_DELETE_FAIL"

export const COPYTRADING_TRADESYNC_LIST_SUCCESS = "COPYTRADING_TRADESYNC_LIST_SUCCESS"
export const COPYTRADING_TRADESYNC_LIST_FAIL = "COPYTRADING_TRADESYNC_LIST_FAIL"
export const COPYTRADING_TRADESYNC_GET_SUCCESS = "COPYTRADING_TRADESYNC_GET_SUCCESS"
export const COPYTRADING_TRADESYNC_GET_FAIL = "COPYTRADING_TRADESYNC_GET_FAIL"
export const COPYTRADING_TRADESYNC_CREATE_SUCCESS = "COPYTRADING_TRADESYNC_CREATE_SUCCESS"
export const COPYTRADING_TRADESYNC_CREATE_FAIL = "COPYTRADING_TRADESYNC_CREATE_FAIL"
export const COPYTRADING_TRADESYNC_UPDATE_SUCCESS = "COPYTRADING_TRADESYNC_UPDATE_SUCCESS"
export const COPYTRADING_TRADESYNC_UPDATE_FAIL = "COPYTRADING_TRADESYNC_UPDATE_FAIL"
export const COPYTRADING_TRADESYNC_DELETE_SUCCESS = "COPYTRADING_TRADESYNC_DELETE_SUCCESS"
export const COPYTRADING_TRADESYNC_DELETE_FAIL = "COPYTRADING_TRADESYNC_DELETE_FAIL"
export const COPYTRADING_TRADESYNC_RESET_DATA = "COPYTRADING_TRADESYNC_RESET_DATA"

export function handleTradeSyncListAccounts(token) {
  if (token == null) {
    return
  }
  return dispatch => {
    tradeSyncAccounts(token)
      .then(accounts => {
        dispatch({
          type: COPYTRADING_ACCOUNT_LIST_SUCCESS,
          payload: accounts
        })
      }).catch(error => {
      console.error(error)
      dispatch({
        type: COPYTRADING_ACCOUNT_LIST_FAIL,
        error: true,
        payload: new Error(error)
      })
    })
  }
}

export function handleTradeSyncGetAccount(token, accountId) {
  if (token == null) {
    return
  }
  return dispatch => {
    tradeSyncAccount(token, accountId)
      .then(account => {
        if (account?.id == null) {
          return
        }
        dispatch({
          type: COPYTRADING_ACCOUNT_GET_SUCCESS,
          payload: account
        })
      }).catch(error => {
      console.error(error)
      dispatch({
        type: COPYTRADING_ACCOUNT_GET_FAIL,
        error: true,
        payload: new Error(error)
      })
    })
  }
}

export function handleTradeSyncCreateAccount(token, params) {
  if (token == null) {
    return
  }
  return dispatch => {
    dispatch({
      type: COPYTRADING_ACCOUNT_CREATE_START
    })
    tradeSyncAccountCreate(token, params)
      .then(account => {
        if (!account.ok && !account.account_name) {
          dispatch({
            type: COPYTRADING_ACCOUNT_CREATE_FAIL,
            error: true,
            payload: account
          })
          return
        }
        dispatch({
          type: COPYTRADING_ACCOUNT_CREATE_SUCCESS,
          payload: account
        })
      }).catch(error => {
      console.error(error)
      dispatch({
        type: COPYTRADING_ACCOUNT_CREATE_FAIL,
        error: true,
        payload: new Error(error)
      })
    })
  }
}

export function handleTradeTypeCreateAccount(token, type) {
  if (token == null) {
    return
  }
  return dispatch => {
    dispatch({
      type: COPYTRADING_TYPE_CREATE_ACCOUNT,
      payload: type
    })
  }
}

export function handleTradeSyncUpdateAccount(token, accountId, params) {
  console.log('handleTradeSyncUpdateAccount');
  if (token == null) {
    return
  }
  return dispatch => {
    tradeSyncAccountUpdate(token, accountId, params)
      .then(account => {
        dispatch({
          type: COPYTRADING_ACCOUNT_UPDATE_SUCCESS,
          payload: account
        })
      }).catch(error => {
      console.error(error)
      dispatch({
        type: COPYTRADING_ACCOUNT_UPDATE_FAIL,
        payload: null
      })
    })
  }
}

export function handleTradeSyncDeleteAccount(token, accountId) {
  console.log('handleTradeSyncDeleteAccount');
  if (token == null) {
    return
  }
  return dispatch => {
    dispatch({
      type: COPYTRADING_ACCOUNT_CREATE_START
    })
    tradeSyncAccountDelete(token, accountId)
      .then(response => {
        if (response == null) {
          return
        }
        if (!response.ok && !Array.isArray(response)) {
          dispatch({
            type: COPYTRADING_ACCOUNT_DELETE_FAIL,
            error: true,
            payload: response
          })
          return
        }
        dispatch({
          type: COPYTRADING_ACCOUNT_DELETE_SUCCESS,
          payload: accountId
        })
      }).catch(error => {
      console.error(error)
      dispatch({
        type: COPYTRADING_ACCOUNT_DELETE_FAIL,
        error: true,
        payload: new Error(error)
      })
    })
  }
}

export function handleTradeSyncListBrokers(token) {
  console.log('handleTradeSyncListBrokers');
  if (token == null) {
    return
  }
  return dispatch => {
    tradeSyncBrokers(token)
      .then(brokers => {
        dispatch({
          type: COPYTRADING_BROKER_LIST_SUCCESS,
          payload: brokers
        })
      }).catch(error => {
      console.error(error)
      dispatch({
        type: COPYTRADING_BROKER_LIST_FAIL,
        error: true,
        payload: new Error(error)
      })
    })
  }
}

export function handleTradeSyncListBrokerServers(token) {
  console.log('handleTradeSyncListBrokerServers');
  if (token == null) {
    return
  }
  return dispatch => {
    tradeSyncBrokerALlServers(token)
      .then(servers => {
        dispatch({
          type: COPYTRADING_BROKER_SERVER_LIST_SUCCESS,
          payload: servers
        })
      }).catch(error => {
      console.error(error)
      dispatch({
        type: COPYTRADING_BROKER_SERVER_LIST_FAIL,
        error: true,
        payload: new Error(error)
      })
    })
  }
}

export function handleTradeSyncListCopiers(token, followerId) {
  if (token == null || followerId == null) {
    return
  }
  return dispatch => {
    tradeSyncCopiers(token, { "follower_id": followerId })
      .then(copiers => {
        dispatch({
          type: COPYTRADING_COPIER_LIST_SUCCESS,
          payload: copiers
        })
      }).catch(error => {
      console.error(error)
      dispatch({
        type: COPYTRADING_COPIER_LIST_FAIL,
        error: true,
        payload: new Error(error)
      })
    })
  }
}

export function handleTradeSyncCreateCopier(token, params) {
  if (token == null) {
    return
  }
  return dispatch => {
    tradeSyncCopierCreate(token, params)
      .then(copier => {
        if (copier?.id == null) {
          return
        }
        tradeSyncCopierUpdate(token, copier?.id, {
          "mode": "on",
          "copy_existing": "no",
          "copy_pending": "no",
          "copy_sl": "no",
          "copy_tp": "no"
        })
        dispatch({
          type: COPYTRADING_COPIER_CREATE_SUCCESS,
          payload: copier
        })
      }).catch(error => {
      console.error(error)
      dispatch({
        type: COPYTRADING_COPIER_CREATE_FAIL,
        error: true,
        payload: new Error(error)
      })
    })
  }
}

export function handleRestapiCopytradingSubscribe(owner, masterAccountId, workspaceId) {
  return dispatch => {
    restapiCopytradingSubscribe(owner, masterAccountId, workspaceId)
      .then(response => {
        const copier = response?.programSubscription
        if (copier?.id != null) {
          dispatch({
            type: COPYTRADING_COPIER_CREATE_SUCCESS,
            payload: copier
          })
        }
        const channelSubscription = response?.channelSubscription
        channelGet(channelSubscription?.channelId)
          .then(channel => {
            dispatch({
              type: CHANNEL_GET_SUCCESS,
              payload: channel
            })
            streamGet(channelSubscription?.streamId)
              .then(stream => {
                dispatch({
                  type: STREAM_GET_SUCCESS,
                  payload: stream
                })
                widgetGet(channelSubscription?.widgetId)
                  .then(widget => {
                    dispatch({
                      type: WIDGET_GET_SUCCESS,
                      payload: widget
                    })
                    for (let rule of channel?.filterRules || []) {
                      if (!rule?.disable) {
                        dispatch(handleMessageRead(rule, 99, null))
                      }
                    }
                  }).catch(error => {
                  console.error(error)
                })
              }).catch(error => {
              console.error(error)
            })
          }).catch(error => {
          console.error(error)
        })
      }).catch(error => {
      console.error(error)
      dispatch({
        type: COPYTRADING_COPIER_CREATE_FAIL,
        error: true,
        payload: new Error(error)
      })
    })
  }
}

export function handleTradeSyncUpdateCopier(token, copier, params) {
  if (token == null) {
    return
  }
  return dispatch => {
    tradeSyncCopierUpdate(token, copier?.id, params)
      .then(response => {
        console.log("updated copier mm", response)
      }).catch(error => {
      console.error(error)
    })
  }
}

export function handleTradeSyncDeleteCopier(token, copierId) {
  if (token == null) {
    return
  }
  return dispatch => {
    tradeSyncCopierDelete(token, copierId)
      .then(response => {
        if (response == null) {
          return
        }
        dispatch({
          type: COPYTRADING_COPIER_DELETE_SUCCESS,
          payload: copierId
        })
      }).catch(error => {
      console.error(error)
      dispatch({
        type: COPYTRADING_COPIER_DELETE_FAIL,
        error: true,
        payload: new Error(error)
      })
    })
  }
}

export function handleTradeSyncAnalysisAccounts(token) {
  if (token == null) {
    return
  }
  return dispatch => {
    tradeSyncAnalysisAccounts(token)
      .then(analyses => {
        if (analyses == null) {
          return
        }
        dispatch({
          type: COPYTRADING_ANALYSIS_ACCOUNT_LIST_SUCCESS,
          payload: analyses
        })
      }).catch(error => {
      console.error(error)
      dispatch({
        type: COPYTRADING_ANALYSIS_ACCOUNT_LIST_FAIL,
        error: true,
        payload: new Error(error)
      })
    })
  }
}

export function handleTradeSyncListWebhooks(token) {
  if (token == null) {
    return
  }
  return dispatch => {
    tradeSyncWebhooks(token)
      .then(webhooks => {
        dispatch({
          type: COPYTRADING_WEBHOOK_LIST_SUCCESS,
          payload: webhooks
        })
      }).catch(error => {
      console.error(error)
      dispatch({
        type: COPYTRADING_WEBHOOK_LIST_FAIL,
        error: true,
        payload: new Error(error)
      })
    })
  }
}

export function handleTradeSyncCreateWebhook(token, params) {
  if (token == null) {
    return
  }
  return dispatch => {
    tradeSyncWebhookCreate(token, params)
      .then(webhook => {
        dispatch({
          type: COPYTRADING_WEBHOOK_CREATE_SUCCESS,
          payload: webhook
        })
      }).catch(error => {
      console.error(error)
      dispatch({
        type: COPYTRADING_WEBHOOK_CREATE_FAIL,
        error: true,
        payload: new Error(error)
      })
    })
  }
}

export function handleTradeSyncDeleteWebhook(token, webhookId) {
  if (token == null) {
    return
  }
  return dispatch => {
    tradeSyncWebhookDelete(token, webhookId)
      .then(response => {
        if (response == null) {
          return
        }
        dispatch({
          type: COPYTRADING_WEBHOOK_DELETE_SUCCESS,
          payload: webhookId
        })
      }).catch(error => {
      console.error(error)
      dispatch({
        type: COPYTRADING_WEBHOOK_DELETE_FAIL,
        error: true,
        payload: new Error(error)
      })
    })
  }
}

// DYNAMO DB ACTIONS

export function handleTradesyncsCreate(id, model, filterStr, filterInt, item) {
  return dispatch => {
    tradesyncCreate(id, model, filterStr, filterInt, item)
      .then(data => {
        dispatch({
          type: COPYTRADING_TRADESYNC_CREATE_SUCCESS,
          payload: data
        })
      })
      .catch(err => {
        dispatch({
          type: COPYTRADING_TRADESYNC_CREATE_FAIL,
          error: true,
          payload: new Error(err)
        })
      })
  }
}

export function handleTradesyncsRead(model) {
  return dispatch => {
    tradesyncRead(model)
      .then(data => {
        dispatch({
          type: COPYTRADING_TRADESYNC_LIST_SUCCESS,
          payload: data
        })
      })
      .catch(err => {
        dispatch({
          type: COPYTRADING_TRADESYNC_LIST_FAIL,
          error: true,
          payload: new Error(err)
        })
      })
  }
}

export function handleTradesyncsResetData() {
  return dispatch => {
    dispatch({
      type: COPYTRADING_TRADESYNC_RESET_DATA,
      payload: null
    })
  }
}