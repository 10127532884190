import { Component } from 'react'
import { Hub } from 'aws-amplify'
import * as K from "../../../utils/Constant"

class GoToMarketPlace extends Component {
  goToMarketplace() {
    Hub.dispatch(K.HUB_GOTOMYACCOUNT, { event: "QuotaExceeded", data: null, message:"" })
    this.props.onHide()    
  }
}

export default GoToMarketPlace