import { Auth } from "aws-amplify"
import Lambda from "aws-sdk/clients/lambda"
import { ENV, REGION } from "../../utils/Constant"
import { isBlankOrNull, uuidv4 } from "../../utils/Helpres"

// schedulerCreateRule("name", "cron(0/1 * * * ? *)", "system", "ping", {"text": "called from platform 2"})
export function schedulerCreateRule(name, cron, topic, event, payload) {
  const params = [name, cron, topic, event]
  if (params.find(item => isBlankOrNull(item)) || payload == null ) {
    return false
  }

  Auth.currentCredentials()
    .then(credentials => {
      const lambda = new Lambda({
        env: ENV,
        region: REGION,
        credentials: Auth.essentialCredentials(credentials)
      });

      var params = {
        FunctionName: 'scheduler-'.concat(ENV),
        Payload: JSON.stringify({
          "rule": `${topic}_${name}_${uuidv4()}_${ENV}`,
          "cron": cron,
          "action": "eventhub",
          "target": `Target_${topic}_${name}_${ENV}`,
          "payload": JSON.stringify({
            "Id": "eventhubsid",
            "topic": topic,
            "plugin": event,
            "payload": payload
          })
        })
      }

      lambda.invoke(params, function(err, data) {
        if (err) console.log(err, err.stack);
        else     console.log(data);
      });
    })
  return true
}

export function schedulerDeleteRule(ruleName, target, topic, event, payload) {
  const params = [ruleName, target]
  if (params.find(item => isBlankOrNull(item))) {
    return false
  }

  Auth.currentCredentials()
    .then(credentials => {
      const lambda = new Lambda({
        env: ENV,
        region: REGION,
        credentials: Auth.essentialCredentials(credentials)
      });

      var params = {
        FunctionName: 'scheduler-'.concat(ENV),
        Payload: JSON.stringify({
          "rule": ruleName,
          "cron": null,
          "action": "delete",
          "target": target,
          "payload": JSON.stringify({
            "Id": "eventhubsid",
            "topic": topic,
            "plugin": event,
            "payload": payload
          })
        })
      }

      lambda.invoke(params, function(err, data) {
        if (err) console.log(err, err.stack);
        else     console.log(data);
      });
    })
  return true
}