import { loadTradeSyncList, tradeSyncApiUrl, tradeSyncHeaders } from "./tradesync"

export const tradeSyncBrokers = async (token, params) => {
  const headers = tradeSyncHeaders(token)
  const items = await loadTradeSyncList(`${tradeSyncApiUrl}/brokers`, headers, params)
  return items
}

export const tradeSyncBroker = async (token, broker_id, params) => {
  const headers = tradeSyncHeaders(token)
  var request = ''
  try {
    const getParams = new URLSearchParams(params)
    request = `${tradeSyncApiUrl}/brokers/${broker_id}?${getParams.toString()}`
  } catch (e) {
    request = `${tradeSyncApiUrl}/brokers/${broker_id}`
  }
  const response = await fetch(request, {
    method: 'GET',
    headers,
  })
  if (response.ok) {
    const json = await response.json()
    return json?.data
  } else {
    console.error('tradesync error: ', response.status, response.statusText)
    return null
  }
}

export const tradeSyncBrokerALlServers = async (token, params) => {
  const headers = tradeSyncHeaders(token)
  const items = await loadTradeSyncList(`${tradeSyncApiUrl}/broker-servers`, headers, params)
  return items
}

export const tradeSyncBrokerServers = async (token, broker_server_id, params) => {
  const headers = tradeSyncHeaders(token)
  const items = await loadTradeSyncList(`${tradeSyncApiUrl}/broker-servers/${broker_server_id}`, headers, params)
  return items
}