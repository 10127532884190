import React, { Component } from 'react'

import { AmplifySignUp } from '@aws-amplify/ui-react';

class SignUp extends Component {
  render() {
    return (
      <div slot="sign-up" style={{ textAlign: 'center' }}> 
          <AmplifySignUp usernameAlias="email"
              formFields={[
                {
                  type: "email",
                  required: true,
                  handleInputChange: (event, cb) => {
                    event.target.value = event.target.value?.toLowerCase()
                    localStorage.setItem("VoxStorageResu", event.target.value)
                    cb(event);
                  }
                },
                {
                  type: "password",
                  required: true,
                  handleInputChange: (event, cb) => {
                    localStorage.setItem("VoxStorageSsap", event.target.value)
                    cb(event);
                  }
                },
              ]}
            />
      </div>
    )
  }
}

export default SignUp
