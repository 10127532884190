import React, { Component } from "react"
import { Modal, Spinner } from "react-bootstrap"
import { isBlankOrNull } from "../../../utils/Helpres"
import { Hub } from "aws-amplify"
import validator from "validator"
import * as K from "../../../utils/Constant"
import { withTranslation } from 'react-i18next';

class ChangePasswordModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      oldPassword: "",
      newPassword: "",
      repeatPassword: "",
      isShowOldError: false,
      isShowNewError: false,
      isShowRepeatError: false,
      isShowVisible: false,
      passwordProcessing: false,
    }
  }

  componentDidMount() {
    Hub.listen(K.HUB_CHANGEPASSWORD, this.handleChangePassword)
  }

  componentWillUnmount() {
    if (this.handleStripePayment) Hub.remove(K.HUB_CHANGEPASSWORD, this.handleChangePassword)
  }

  handleChangePassword = async ({ payload: { event, data, message } }) => {
    const isChanged = event === "changePassword" && data === "Success"
    if (isChanged) {
      setTimeout(this.toggleModal, 1000)
    } else {
      this.setState({ oldPassword: "", isShowOldError: true, passwordProcessing: false })
    }
  }

  handleChangeOldPassword(event) {
    const isShowOldError = !validator.isLength(event.target.value, { min: 8 })
    this.setState({ oldPassword: isShowOldError ? "" : event.target.value, isShowOldError: isShowOldError })
  }

  handleChangeNewPassword(event) {
    const { oldPassword } = this.state
    const isShowNewError = !validator.isLength(event.target.value, { min: 8 }) || oldPassword === event.target.value
    this.setState({ newPassword: isShowNewError ? "" : event.target.value, isShowNewError: isShowNewError, isShowVisible: true })
  }

  handleChangeRepeatPassword(event) {
    const { newPassword } = this.state
    const isShowRepeatError = !validator.isLength(event.target.value, { min: 8 }) || newPassword !== event.target.value
    this.setState({ repeatPassword: isShowRepeatError ? "" : event.target.value, isShowRepeatError: isShowRepeatError })
  }

  onSaveForm() {
    const { oldPassword, newPassword, repeatPassword} = this.state
    const isActiveGuide = localStorage.getItem("activeGuide") === 'true'
    const isShowOldError = !validator.isLength(oldPassword, { min: 8 })
    const isShowNewError = !validator.isLength(newPassword, { min: 8 }) || oldPassword === newPassword
    const isShowRepeatError = !validator.isLength(repeatPassword, { min: 8 }) || newPassword !== repeatPassword
    if (isShowOldError || isShowNewError || isShowRepeatError) {
      this.setState({isShowOldError: isShowOldError, isShowNewError: isShowNewError, isShowRepeatError: isShowRepeatError})
      return
    }
    if (isActiveGuide) {
      Hub.dispatch('IS_SAVE_PASSWORD');
    }
    const { changePassword } = this.props.data
    changePassword(oldPassword, newPassword)
    this.setState({passwordProcessing: true})
  }

  toggleModal = () => {
    const isActiveGuide = localStorage.getItem("activeGuide") === 'true'
    if (isActiveGuide) {
      Hub.dispatch('IS_SAVE_PASSWORD');
    }
    this.props.onHide()
  }

  render() {
    const {t} = this.props
    return (
      <Modal
        {...this.props}
        onHide={this.toggleModal}
        centered
        aria-labelledby="contained-modal-title-vcenter"
        className="n-modal__modal"
      >
        <div className="n-modal profile-settings-change-password-save-guide">
          <div className="n-modal__wrapper">
            <div className="n-modal__close" onClick={this.toggleModal}>
              <svg
                width="20rem"
                height="20rem"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 2.01429L17.9857 0L10 7.98571L2.01429 0L0 2.01429L7.98571 10L0 17.9857L2.01429 20L10 12.0143L17.9857 20L20 17.9857L12.0143 10L20 2.01429Z"
                />
              </svg>
            </div>
            <div className="n-modal__header">
                <div className="n-modal__header__icon">
                <svg width="38rem" height="38rem" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M25.9001 15.3901L26.6301 14.6601C26.9701 14.3201 26.9701 13.7501 26.6301 13.4301L24.5801 11.3801C24.4001 11.2001 24.1901 11.1301 23.9601 11.1301M23.9601 11.1201C23.7401 11.1201 23.5101 11.2101 23.3501 11.3701L20.8401 13.9101M18.7401 15.9801L11.1201 23.6001V26.8801H14.4001L24.0901 17.1901L20.8101 13.9101" stroke="#203B60" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M19 37C28.9411 37 37 28.9411 37 19C37 9.05887 28.9411 1 19 1C9.05887 1 1 9.05887 1 19C1 28.9411 9.05887 37 19 37Z" stroke="#203B60" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
                </svg>
                </div>
                <div className="n-modal__header__title">
                  {t('profile_modal_password_title')}
                </div>
                <div className="n-modal__line" />
                <div className="n-modal__content n-modal__content--flex">
                  <div className="n-modal__content__title">
                    {t("profile_modal_password_text1")} <b>{t('profile_modal_password_text2')}</b>
                  </div>
                  <div className="n-modal__content__title-sub">
                    {t('profile_modal_password_text3')}
                  </div>
                  <div
                    className={
                      this.state.isShowOldError
                        ? "n-input error"
                        : "n-input"
                    }
                  >
                    <input
                      type="password"
                      maxLength={20}
                      placeholder={t('profile_modal_password_text4')}
                      onChange={this.handleChangeOldPassword.bind(this)}
                    />
                    <div className="n-input__error-message">
                      {isBlankOrNull(this.state.oldPassword) ? t('profile_modal_password_text10') :
                     t('profile_modal_password_text11')
                      }
                    </div>
                  </div>
                </div>
                <div className="n-modal__content n-modal__content--flex">
                  <div className="n-modal__content__title">
                    {t('profile_modal_password_text1')} <b>{t('profile_modal_password_text5')}</b>
                  </div>
                  <div className="n-modal__content__title-sub">
                    {t('profile_modal_password_text6')}
                  </div>
                  <div
                    className={
                      this.state.isShowNewError
                        ? "n-input error"
                        : "n-input"
                    }
                  >
                    <input
                      type="password"
                      maxLength={20}
                      placeholder={t('profile_modal_password_text5')}
                      onChange={this.handleChangeNewPassword.bind(this)}
                    />
                    <div className="n-input__error-message">
                      {t('profile_modal_password_text12')}
                    </div>
                  </div>
                </div>
                <div className={
                                this.state.isShowVisible
                                  ? "n-modal__content n-modal__content--flex"
                                  : "n-modal__content n-modal__content--flex is-opacity-30"
                              }
                >
                  <div className="n-modal__content__title-sub">
                    {t('profile_modal_password_text8')}
                  </div>
                  <div
                    className={
                      this.state.isShowRepeatError
                        ? "n-input error"
                        : "n-input"
                    }
                  >
                    <input
                      type="password"
                      maxLength={20}
                      placeholder={t('profile_modal_password_text9')}
                      onChange={this.handleChangeRepeatPassword.bind(this)}
                    />
                    <div className="n-input__error-message">
                      {t("profile_modal_password_text13")}
                    </div>
                  </div>
                </div>
                <div className="n-modal__btns">
                  {this.state.passwordProcessing ? <div className="button-lg"><Spinner animation="border" /></div> :
                  <div className="button-lg" onClick={this.onSaveForm.bind(this)}>{t('profile_modal_password_text14')}</div>}
                </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}
export default withTranslation()(ChangePasswordModal)
