import { Auth } from "aws-amplify"
import Lambda from "aws-sdk/clients/lambda"
import { ENV, REGION } from "../../utils/Constant"
import { isBlankOrNull } from "../../utils/Helpres"

export function sendMail(receivers, sender, subject, body) {
  if (isBlankOrNull(subject) || isBlankOrNull(body) || receivers.some(email => !email.includes("@")) || !sender.includes("@")) {
    console.log('sendmail - invalid params')
    return false
  }

  Auth.currentCredentials()
    .then(credentials => {
      const lambda = new Lambda({
        env: ENV,
        region: REGION,
        credentials: Auth.essentialCredentials(credentials)
      });

      const params = {
        FunctionName: 'sendmail-'.concat(ENV),
        Payload: JSON.stringify({
          "receivers": receivers,
          "sender": sender,
          "subject": subject,
          "body": body
        })
      }

      lambda.invoke(params, function(err, data) {
        if (err) console.log(err, err.stack);
        else     console.log(data);
      });
    })
  return true
}