import { API, graphqlOperation } from 'aws-amplify';
import { listTradesyncs, getTradesync } from "../../graphql/queries";
import { createTradesync, deleteTradesync, updateTradesync } from "../../graphql/mutations";

import { loadList } from "./shared"
const action = listTradesyncs
const attribute = "listTradesyncs"

export const HUB_OBSERVER_TRADESYNCS = "tradesync"

export const tradesyncCreate = async (id, model, filterStr, filterInt, item) => {

  if (id == null || model == null || item == null) {
    console.log('Tradesync API: Required fields cannot be empty!')
    return null
  }
  try {
    const response = await API.graphql(graphqlOperation(createTradesync, {input: {
      id: id,
      model: model,
      filterStr: filterStr,
      filterInt: filterInt,
      attributes: JSON.stringify(item),
      createdAt: new Date(item?.created_at).toISOString(),
      updatedAt: new Date(item?.updated_at).toISOString()
    }}))
      return response?.data?.createTradesync
  } catch (err) {
    console.log('error creating tradesync:', err)
    return null
  }
}

export const tradesyncRead = async (model, filterStr, filterInt) => {
  try {
    const filter = {
      model: {
        eq: model
      }
    }
    const items = await loadList(action, attribute, filter, 0)
    return items
  } catch (err) { 
    console.log('error fetching tradesync', err) 
    return null
  }  
}