import { tradeSyncApiUrl, tradeSyncHeaders } from "./tradesync"

export const tradeSyncWebhooks = async (token, params) => {
  const headers = tradeSyncHeaders(token)
  var request = ''
  try {
    const getParams = new URLSearchParams(params)
    request = `${tradeSyncApiUrl}/webhooks?${getParams.toString()}`
  } catch (e) {
    request = `${tradeSyncApiUrl}/webhooks`
  }
  const response = await fetch(request, {
    method: 'GET',
    headers,
  })
  if (response.ok) {
    const json = await response.json()
    return json?.data
  } else {
    console.error('tradesync error: ', response.status, response.statusText)
    return null
  }
}

export const tradeSyncWebhookCreate = async (token, params) => {
  const headers = tradeSyncHeaders(token)
  const body = JSON.stringify(params)
  const response = await fetch(`${tradeSyncApiUrl}/webhooks`, {
    method: 'POST',
    headers,
    body: body,
  })
  if (response.ok) {
    const json = await response.json()
    return json?.data
  } else {
    console.error('tradesync error: ', response.status, response.statusText)
    return null
  }
}

export const tradeSyncWebhookUpdate = async (token, webhook_id, params) => {
  const headers = tradeSyncHeaders(token)
  const body = JSON.stringify(params)
  const response = await fetch(`${tradeSyncApiUrl}/webhooks/${webhook_id}`, {
    method: 'PATCH',
    headers,
    body: body,
  })
  if (response.ok) {
    const json = await response.json()
    return json?.data
  } else {
    console.error('tradesync error: ', response.status, response.statusText)
    return null
  }
}


export const tradeSyncWebhookDelete = async (token, webhook_id) => {
  const headers = tradeSyncHeaders(token)
  const response = await fetch(`${tradeSyncApiUrl}/webhooks/${webhook_id}`, {
    method: 'DELETE',
    headers,
  })
  if (response.ok) {
    const json = await response.json()
    return json?.data
  } else {
    console.error('tradesync error: ', response.status, response.statusText)
    return null
  }
}