import * as K from "./Constant"
import { sendMail } from "../services/function/sendmail"
import { configRead } from "../services/api/config"
import { messageCreate } from "../services/api/message"
import { userRoleRead } from "../services/api/userrole"
import { Auth } from "aws-amplify"
import { getCompanyNameFromConfig } from "./ConfigsHelpers"
import { hideEmail } from "./Helpres"

export const triggerEmail = (subject, body, additionalReceivers) => {
  Auth.currentAuthenticatedUser({ bypassCache: false })
  .then(authData => {
    if (authData?.username != null) {
      userRoleRead().then(responseUserRoles => {
        const userRoles = responseUserRoles
        configRead().then(configs => {
          const companyName = getCompanyNameFromConfig(configs)
          const uRoles = userRoles?.filter(el => el?.role.name === K.PLATFORM_ADMIN_ROLE)?.concat(userRoles?.filter(el => additionalReceivers?.some(owner => el?.user?.owner === owner)))
          const notifiedUserRoles = uRoles?.length > 0 ? [...new Set(uRoles)] : []
          const receivers = notifiedUserRoles?.map(userRole => userRole?.user?.email?.toString() )
          let newBody = body
          newBody = newBody.replace(/%companyName%/g, companyName)
          newBody = newBody.replace(/%name%/g, authData?.attributes?.name)
          newBody = newBody.replace(/%username%/g, authData?.attributes?.nickname)
          newBody = newBody.replace(/%phone%/g, authData?.attributes?.phone_number)
          newBody = newBody.replace(/%email%/g, authData?.attributes?.email)
          if (receivers?.length > 0) {
            sendMail(receivers, K.EMAIL_PLATFORM_SENDER, subject, newBody)
          }
        }).catch(e => { console.log('Trigger - company name error', e) })
      }).catch(e => { console.log('Trigger - receivers error', e) })
    }
  })
  .catch(e => { console.log('Trigger - error', e) })
}

export const triggerEmailSubscription = (name, isTrial, owners) => {
  if (name == null) { return }
  const subject = isTrial ? K.EMAIL_SUBSCRIPTION_TRIAL_SUBJECT : K.EMAIL_SUBSCRIPTION_SUBJECT
  let body = isTrial ? K.EMAIL_SUBSCRIPTION_TRIAL_BODY : K.EMAIL_SUBSCRIPTION_BODY
  body = body.replace('%productName%', name)
  triggerEmail(subject, body, owners)
}

export const triggerEmailSubscriptionCancel = (name, owners) => {
  if (name == null) { return }
  const subject = K.EMAIL_SUBSCRIPTION_CANCEL_SUBJECT
  let body = K.EMAIL_SUBSCRIPTION_CANCEL_BODY
  body = body.replace('%productName%', name)
  triggerEmail(subject, body, owners)
}

export const triggerWelcomeMessage = (channel, account, category) => {
  const message = `${hideEmail(account?.user?.username)} has just joined the channel`
  const content = {
    threadId: null,
    category: category?.id,
    title: null,
    comment: message,
    color: null,
    media: null,
    table: null,
  }
  const owner = channel?.owner
  const user = channel?.user
  triggerWelcomeMessageInChannel(owner, user, channel, content, null)
}

export const triggerWelcomeMessageInChannel = (owner, user, channel, content, guid) => {
  messageCreate(owner, user, channel, content, guid)
}