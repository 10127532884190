import React from 'react'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'
import GoToMarketPlace from './GoToMarketPlace'
import { getCompanyNameFromConfig } from "../../../utils/ConfigsHelpers"

class RichMessagingUnavailable extends GoToMarketPlace {
  constructor(props) {
    super(props)
    this.state = {} 
  }

  render() {
    const companyName = getCompanyNameFromConfig(this.props.configs?.list)
    return (
      <Modal {...this.props} centered  aria-labelledby="contained-modal-title-vcenter" className="welcome-screen__modal">
        <div className="welcome-screen text-center">
          <Modal.Body>
            <div className="welcome-screen__container">
            <div className="welcome-screen__container__title">Rich messaging unavailable</div>
              <div className="welcome-screen__container__subtitle-smal">You cannot send Rich Messages because your feature plan does not allow it. Please click <strong>Go To Marketplace</strong> to open {companyName} MarketPlace and upgrade to a premium plan.</div>
              <div className="d-flex justify-content-end">
                <div className="welcome-screen__container__btn-skip" onClick={this.props.onHide}>Cancel</div>
                <div className="pl-4 welcome-screen__container__btn-next" onClick={this.goToMarketplace.bind(this)}>Go To Marketplace</div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = store => {
  return {
    channels: store.channels,
    configs: store.configs
  }
}

export default connect(
  mapStateToProps,
  null
)(RichMessagingUnavailable)
