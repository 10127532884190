import { API, graphqlOperation } from 'aws-amplify';
import { listTags } from "../../graphql/queries";
import { createTag, deleteTag } from "../../graphql/mutations";

import { loadList } from "./shared"
const action = listTags
const attribute = "listTags"

export const HUB_OBSERVER_TAG = "tag"

export const tagCreate = async (owner, user, label, message) => {
  try {
    const filter = { owner: { eq: owner }, tagLabelId: { eq: label.id }, tagMessageId: { eq: message.id } }
    const tags = await loadList(action, attribute, filter, 1)
    if (tags?.length > 0) {
      console.log('Tag already exist!')
      return
    }
  } catch (err) { console.log('error fetching tags') }
  if (owner == null || user == null || label == null || message == null) { 
    console.log('Tag API: Required fields cannot be empty!')
    return
  }
  try {
    const response = await API.graphql(graphqlOperation(createTag, {input: {
      owner: owner, 
      tagUserId: user.id,
      tagLabelId: label.id,
      tagMessageId: message.id,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString() }}))
      return response?.data?.createTag
  } catch (err) {
    console.log('error creating tag:', err)
    return
  }
}

export const tagRead = async (owner) => {
  try {
    const filter = {
      owner: {
        eq: owner
      }
    }
    const tags = await loadList(action, attribute, filter, 0)
    return tags
  } catch (err) { 
    console.log('error fetching tags', err) 
    return null
  }
}

export const tagDelete = async (owner, tagId) => {
  await API.graphql(graphqlOperation(deleteTag, { input: { id: tagId }}));
  return tagId
}