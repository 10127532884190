import { Auth } from "aws-amplify"
import Lambda from "aws-sdk/clients/lambda"
import { ENV, REGION } from "../../utils/Constant"
import { isBlankOrNull, randomCode } from "../../utils/Helpres"

export function verifysms(company, phone) {
  var code = randomCode()
  if ( isBlankOrNull(phone) || isBlankOrNull(code) ) { return null }
  Auth.currentCredentials()
    .then(credentials => {
      const lambda = new Lambda({
        region: REGION,
        credentials: Auth.essentialCredentials(credentials)
      });

      var params = {
        FunctionName: 'verifysms-'.concat(ENV),
        Payload: JSON.stringify(
          {
            "phone": phone,
            "text": `${company ?? "Verify"}: your one-time verification code is ${code}`
          })
      }

      lambda.invoke(params, function(err, data) {
        if (err) console.log(err, err.stack);
        else     console.log(data);
      });
    })
  return code
}