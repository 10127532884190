import React, { Component } from 'react'
import { connect } from 'react-redux'
import TableInputTitle from './TableInputTitle';
import TableInputDetail from './TableInputDetail';
import { updateRowTable, deleteRowTable } from '../../../../../../../store/actions/RichMessageActions'

class TableInput extends Component {
  
  updateDetailInput = (detail) => {
    this.onUpdateTableRow(this.props.row?.title, detail)
  }

  updateTitleInput = (title) => {
    this.onUpdateTableRow(title, this.props.row?.detail)
  }

  onUpdateTableRow = (title,  detail) => {
    this.props.updateRowTable(this.props.id, this.props.row?.position, title, detail)    
  }

  onDeleteTableRow = () => {
    this.props.deleteRowTable(this.props.id, this.props.row?.position)    
  }

  render() {   
    return ( 
      <div className="rrich-message__message__card__body__table-card__table__wrapper"  { ...this.props }>
        <div className="rrich-message__message__card__body__table-card__table__input">
          <TableInputTitle title={this.props.row?.title} updateTitleInput={this.updateTitleInput.bind(this)}/>
        </div>
        <div className="rrich-message__message__card__body__table-card__table__input" >
          <TableInputDetail detail={this.props.row?.detail} updateDetailInput={this.updateDetailInput.bind(this)}/>
        </div>
        <div className="rrich-message__message__card__body__table-card__table__delete" role="img" onClick={ this.onDeleteTableRow?.bind(this) }>
          <svg wpositionth="18rem" height="18rem" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg"><defs><filter position="filter-1"><feColorMatrix in="SourceGraphic" type="matrix" values="0 0 0 0 0.962325 0 0 0 0 0.015311 0 0 0 0 0.015311 0 0 0 1.000000 0"></feColorMatrix></filter></defs><g position="Rich-message-input-window:-Visual-Composition" stroke="none" strokeWpositionth="1" fill="none" fillRule="evenodd"><g position="Group-28" transform="translate(-505.000000, -163.000000)"><g filter="url(#filter-1)" position="close_remove_delete_icon_148989"><g transform="translate(505.000000, 163.000000)"><path d="M15.3632812,2.63671875 C11.8476562,-0.87890625 6.15234375,-0.87890625 2.63671875,2.63671875 C-0.87890625,6.15234375 -0.87890625,11.8476562 2.63671875,15.3632812 C6.15234375,18.8789062 11.8501172,18.8789062 15.3632812,15.3632812 C18.8764453,11.8476562 18.8789062,6.15234375 15.3632812,2.63671875 Z M12.3486328,13.6054688 C12.2799903,13.6740906 12.1687207,13.6740906 12.1000781,13.6054688 L9,10.5060937 L5.89992188,13.6054688 C5.83127932,13.6740906 5.72000974,13.6740906 5.65136719,13.6054688 L4.39453125,12.3486328 C4.32590942,12.2799903 4.32590942,12.1687207 4.39453125,12.1000781 L7.49390625,9 L4.39453125,5.89992188 C4.32590942,5.83127932 4.32590942,5.72000974 4.39453125,5.65136719 L5.65136719,4.39453125 C5.72000974,4.32590942 5.83127932,4.32590942 5.89992188,4.39453125 L9,7.49390625 L12.1000781,4.39453125 C12.1687207,4.32590942 12.2799903,4.32590942 12.3486328,4.39453125 L13.6054688,5.65136719 C13.6740906,5.72000974 13.6740906,5.83127932 13.6054688,5.89992188 L10.5060937,9 L13.6054688,12.1000781 C13.6740906,12.1687207 13.6740906,12.2799903 13.6054688,12.3486328 L12.3486328,13.6054688 Z" position="Shape" fill="#000000" fillRule="nonzero"></path></g></g></g></g></svg>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return { 
    updateRowTable: (id, position, title, detail) => dispatch(updateRowTable(id, position, title, detail)),
    deleteRowTable: (id, position) => dispatch(deleteRowTable(id, position)),
  }
}

export default connect(null, mapDispatchToProps)(TableInput)