import React from 'react'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'

import * as K from '../../../utils/Constant'
import GoToMarketPlace from './GoToMarketPlace'
import { getCompanyNameFromConfig } from "../../../utils/ConfigsHelpers"

class MessageTooLong extends GoToMarketPlace {
  constructor(props) {
    super(props)
    this.state = {} 
  }

  render() {
    const companyName = getCompanyNameFromConfig(this.props.configs?.list)
    var limit = 0
    try {
      const permission = this.props.permissions.find(per => per?.includes(K.FEATURE_MESSAGE_MAXIMUM_LENGTH_OF_A_PLAIN_MESSAGE))
      limit = permission != null ? parseInt(permission.split(":")[1]) : 0
    } catch(err) { console.log('FEATURE_MESSAGE_MAXIMUM_LENGTH_OF_A_PLAIN_MESSAGE decode failed', err) }

    return (
      <Modal {...this.props} centered  aria-labelledby="contained-modal-title-vcenter" className="welcome-screen__modal">
        <div className="welcome-screen text-center">
          <Modal.Body>
            <div className="welcome-screen__container">
            <div className="welcome-screen__container__title">Message too long</div>
              <div className="welcome-screen__container__subtitle-smal">Your message exceeds a maximum of {limit} charaters. Please click <strong>Go To Marketplace</strong> to open {companyName} MarketPlace and upgrade to a premium plan.</div>
              <div className="d-flex justify-content-end">
                <div className="welcome-screen__container__btn-skip" onClick={this.props.onHide}>Cancel</div>
                <div className="pl-4 welcome-screen__container__btn-next" onClick={this.goToMarketplace.bind(this)}>Go To Marketplace</div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = store => {
  return {
    permissions: store.permissions,
    configs: store.configs
  }
}

export default connect(
  mapStateToProps,
  null
)(MessageTooLong)
