import React, { Component } from "react"
import { Modal } from "react-bootstrap"
import parse from "html-react-parser"


import { withTranslation } from 'react-i18next'

class ExtraInformationModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      paymentProcessing: null,
    }
  }

  joinExtra() {
    const { extra, onFreeExtraSubscription } = this.props.data
    onFreeExtraSubscription(extra)
    this.toggleModal()
  }

  toggleModal = () => {
    this.props.onHide()
  }

  render() {
    
    const { t } = this.props

    const { extra,  media } = this.props.data

    return (
      <Modal
        {...this.props}
        onHide={this.toggleModal}
        centered
        aria-labelledby="contained-modal-title-vcenter"
        className="n-modal__modal-information"
      >
        <div className="n-modal">
          <div className="n-modal__wrapper">
            <div className="n-modal__close" onClick={this.toggleModal}>
              <svg
                width="20rem"
                height="20rem"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 2.01429L17.9857 0L10 7.98571L2.01429 0L0 2.01429L7.98571 10L0 17.9857L2.01429 20L10 12.0143L17.9857 20L20 17.9857L12.0143 10L20 2.01429Z"
                />
              </svg>
            </div>
            <div className="n-modal__share">
              <svg width="18rem" height="20rem" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 14.08C14.24 14.08 13.56 14.38 13.04 14.85L5.91 10.7C5.96 10.47 6 10.24 6 10C6 9.76 5.96 9.53 5.91 9.3L12.96 5.19C13.5 5.69 14.21 6 15 6C15.7956 6 16.5587 5.68393 17.1213 5.12132C17.6839 4.55871 18 3.79565 18 3C18 2.20435 17.6839 1.44129 17.1213 0.87868C16.5587 0.316071 15.7956 0 15 0C14.2044 0 13.4413 0.316071 12.8787 0.87868C12.3161 1.44129 12 2.20435 12 3C12 3.24 12.04 3.47 12.09 3.7L5.04 7.81C4.5 7.31 3.79 7 3 7C2.20435 7 1.44129 7.31607 0.87868 7.87868C0.316071 8.44129 0 9.20435 0 10C0 10.7956 0.316071 11.5587 0.87868 12.1213C1.44129 12.6839 2.20435 13 3 13C3.79 13 4.5 12.69 5.04 12.19L12.16 16.34C12.11 16.55 12.08 16.77 12.08 17C12.08 18.61 13.39 19.91 15 19.91C16.61 19.91 17.92 18.61 17.92 17C17.92 16.2256 17.6124 15.4829 17.0648 14.9352C16.5171 14.3876 15.7744 14.08 15 14.08Z" />
              </svg>
            </div>
            <div className="n-modal__like">
              <svg width="20rem" height="19rem" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 18.35L8.55 17.03C3.4 12.36 0 9.27 0 5.5C0 2.41 2.42 0 5.5 0C7.24 0 8.91 0.81 10 2.08C11.09 0.81 12.76 0 14.5 0C17.58 0 20 2.41 20 5.5C20 9.27 16.6 12.36 11.45 17.03L10 18.35Z"  />
              </svg>
            </div>
            <div className="n-modal__body">
              <div className="n-modal__body__col-1">
                <div className="n-modal__body__channel-card">

                  <div className="n-modal__body__channel-card__img">
                    {/* If there is no picture */}
                    {/* <div className="n-modal__body__channel-card__img--default paid">PD</div> */}
                    {/* If there is an image */}
                    <img 
                      alt=""
                      src={media}
                      width="192px"
                      height="144px" 
                    />
                  </div>
                  <div className="n-modal__body__channel-card__name">
                    <div className="n-modal__body__channel-card__name--subtitle">{t("modal_channel_text_83")}</div>
                    <div className="n-modal__body__channel-card__name--title">{extra?.name}</div>
                    <div className="n-modal__body__channel-card__name--subtitle">{t("modal_channel_text_84")} {extra?.authorName}</div>
                  </div>
                  <div className="n-modal__body__channel-card__free">
                    <div className="button-lg" onClick={this.joinExtra.bind(this)}>{t("modal_channel_text_85")}</div>
                  </div>
                </div>
              </div>
              <div className="n-modal__body__br"></div>
              <div className="n-modal__body__col-2">
                <div className="n-modal__body__channel-card__info">
                  <div className="n-modal__body__channel-card__info__wrapper">
                    <div className="n-modal__body__channel-card__info__brief-description">{extra?.brief}</div>
                    <div className="n-modal__body__channel-card__info__description">{parse (extra?.description ?? "<p />")}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

export default withTranslation()(ExtraInformationModal)
