import React, { Component } from 'react'
import {connect} from 'react-redux'
import {updateTitle} from '../../../../../../store/actions/RichMessageActions'


import { withTranslation } from 'react-i18next'

class TitleCard extends Component {
  handleChange(event) {
    this.props.updateTitle({id: this.props.id, title: event.target.value})
  }
  render() {
    
    const { t } = this.props

    return ( 
      <div className="rrich-message__message__card__body__title-card">
        <div className="rrich-message__message__card__body__title-card__icon">
          <svg height="24rem" viewBox="0 0 24 24" width="24rem" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd"><path d="m0 0h24v24h-24z"/><path d="m14.2011719 21.5390625.1230469-.5058594c-.8658855-.0182291-1.4401042-.1298828-1.7226563-.3349609s-.4238281-.4807943-.4238281-.8271484c0-.373698.1139323-.9479167.3417968-1.7226563l4.1289063-13.95898438c1.3216146.07291667 2.2604167.37141928 2.8164063.89550782.5559895.52408854.8339843 1.26009114.8339843 2.20800781 0 .20052083-.0091146.43294271-.0273437.69726563h.4921875l1.4082031-4.99023438h-14.91601562l-1.46289063 4.99023438h.53320313c.79296874-1.33984376 1.66796874-2.296875 2.625-2.87109376.95703124-.57421874 2.14648432-.88411458 3.56835932-.9296875l-4.1289062 13.95898438c-.35546875 1.2213542-.74739583 2.007487-1.17578125 2.3583984-.42838542.3509115-1.11197917.5263672-2.05078125.5263672l-.1640625.5058594z"/></g></svg>
        </div>
        <div className="rrich-message__message__card__body__title-card__text">{t("filter_menu_text_50")}</div>
        <div className="rrich-message__message__card__body__title-card__edit">
          <input 
            placeholder={t("filter_menu_text_49")}
            type="text"
            value={this.props.title}
            onChange={this.handleChange.bind(this)}
          />
        </div>
      </div>
    )
  }
}



const mapDispatchToProps = dispatch => {
  return { updateTitle: (id, title) => dispatch(updateTitle(id, title)) }
}

export default withTranslation()(connect(null, mapDispatchToProps)(TitleCard))