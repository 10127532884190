/* eslint-disable import/first */
import React, { Component } from 'react'
import { Card, Collapse } from 'react-bootstrap'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import {connect} from 'react-redux'
import {deletePost} from '../../../../../store/actions/RichMessageActions'
import { getContrastOrNull } from '../../../../../utils/UserColors'
import { storageRead } from '../../../../../services/storage/storage'

import './Preview.scss'
import 'react-lazy-load-image-component/src/effects/blur.css';


import { withTranslation } from 'react-i18next'

class Preview extends Component {
  constructor(props) {
    super(props)
    this.state={ accordion1: 1, imageSrc: '' }  
  }

  componentDidUpdate() {
    if (this.props.post.media?.original != null && this.state.src == null) {
      storageRead(this.props.post.media?.original)
        .then(data => {
          this.setState({imageSrc: data})          
        })
        .catch(err => console.log('READ FILE ERROR ', err))
    }   
  }

  deleteMessageCard = () => { this.props.deletePost(this.props.post.id) }

  componentStyle(color) {
    return getContrastOrNull(color)
  }

  render() {
    
    const { t } = this.props

    return (
      <div className="rich-message__preview" {...this.props}>
        <div className="css-fn5tqh" onClick={ this.deleteMessageCard }>
          <span className="css-j8fq0c">
            <span className="Icon__IconWrapper-dyhwwi-0 jdkWJB"><svg width="24rem" height="24rem" viewBox="0 0 24 24" focusable="false" role="presentation"><path d="M12 10.586L6.707 5.293a1 1 0 0 0-1.414 1.414L10.586 12l-5.293 5.293a1 1 0 0 0 1.414 1.414L12 13.414l5.293 5.293a1 1 0 0 0 1.414-1.414L13.414 12l5.293-5.293a1 1 0 1 0-1.414-1.414L12 10.586z" fill="currentColor"></path></svg></span>
          </span>
        </div>
        <div className="rich-message__block">
          <Card className="mb-2 chat-list" style={ this.componentStyle(this.props.post.color) ? { backgroundColor: this.props.post.color, color: this.componentStyle(this.props.post.color)} : { backgroundColor: this.props.post.color}}>
            <Card.Header className="chat-list__header">
              <div className="d-flex justify-content-between">
                <div className="chat-list__header__name">{this.props.post.title}</div>
                <div className="d-flex align-items-center">
                  <div className="icon icon-24 icon-label">
                    <span className="icon-block">
                      <span className="icon-wrapper">
                        <svg fill="none" height="24rem" viewBox="0 0 24 24" width="24rem" xmlns="http://www.w3.org/2000/svg"><path clipRule="evenodd" d="m10.9114 6.488c.1549-.15483.3388-.27763.5411-.36136.2024-.08374.4193-.12677.6383-.12664h4.9093c.2652 0 .5196.10536.7071.29289.1875.18754.2929.44189.2929.70711v4.9093c-.0002.4422-.176.8661-.4887 1.1787l-5.1266 5.1267c-.3126.3124-.7364.4879-1.1784.4879-.4419 0-.8658-.1755-1.1783-.4879l-4.24267-4.2427c-.15481-.1548-.27762-.3385-.3614-.5408-.08379-.2022-.12691-.419-.12691-.6379s.04312-.4356.12691-.6379c.08378-.2022.20659-.386.3614-.5407l5.12667-5.1267zm1.1794.84533c-.0883.00002-.1729.03502-.2354.09734l-5.12664 5.12663c-.03112.031-.05582.0677-.07267.1082-.01686.0405-.02553.084-.02553.1278 0 .0439.00867.0873.02553.1278.01685.0405.04155.0773.07267.1082l4.24264 4.2427c.0625.0625.1473.0976.2357.0976s.1732-.0351.2357-.0976l5.1266-5.1267c.0311-.0309.0558-.0677.0726-.1082.0169-.0405.0255-.0839.0254-.1278v-4.57597zm1.902 1.33334c-.7407 0-1.3267.55466-1.3267 1.32533 0 .7713.586 1.3413 1.326 1.3413.7547 0 1.3413-.57 1.3413-1.3413 0-.77067-.5866-1.32533-1.3413-1.32533z" fill="#fff" fillRule="evenodd"/></svg>
                      </span>
                    </span>
                  </div>
                  <div className="icon icon-24 icon-label">
                    <span className="icon-block">
                      <span className="icon-wrapper">
                        <svg fill="none" height="24rem" viewBox="0 0 24 24" width="24rem" xmlns="http://www.w3.org/2000/svg"><path d="m5.12797 11.5036 4.37493-4.37585c.12512-.12512.31149-.16187.47687-.0945.16363.06737.27033.2275.27033.40424v2.18747h1.3125c4.1011 0 7.4374 3.33634 7.4374 7.43734 0 .1952-.1295.3667-.3167.42-.0403.0123-.0805.0175-.1208.0175-.1479 0-.2896-.0752-.371-.2056l-.1312-.21c-1.1874-1.9022-3.318-3.0843-5.5614-3.0843h-2.2488v2.1875c0 .1767-.1067.3368-.27033.4042-.1645.0665-.35175.0298-.47687-.0945l-4.37493-4.3749c-.17063-.1707-.17063-.448 0-.6186zm4.24718 3.6276v-1.5688c0-.2415.196-.4375.43749-.4375h2.68626c2.064 0 4.045.8829 5.4362 2.3686-.7043-2.863-3.2943-4.9936-6.3725-4.9936h-1.74996c-.24149 0-.43749-.196-.43749-.4374v-1.5689l-3.31882 3.3188z" fill="#fff" stroke="#fff" strokeWidth=".4"/></svg>
                      </span>
                    </span>
                  </div>
                  <a 
                    href="#accordion"
                    onClick={e => this.expandAccordion(e, 'accordion1', 1)}
                    aria-expanded={this.state.accordion1 === 1}>
                      <div className="icon icon-24 icon-collapse">
                        <span className="icon-block">
                          <span className="icon-wrapper">
                            <svg fill="none" height="24rem" viewBox="0 0 24 24" width="24rem" xmlns="http://www.w3.org/2000/svg"><path d="m15.5943 12.5941-5.1598 5.1596c-.3282.3284-.86036.3284-1.18843 0-.32809-.3281-.32809-.8602 0-1.1883l4.56563-4.5655-4.5655-4.56533c-.32809-.32822-.32809-.86031 0-1.1884.3281-.32823.8602-.32823 1.1885 0l5.1598 5.15973c.164.1641.2459.379.2459.594 0 .2151-.082.4301-.2461.5942z" fill="#fff"/></svg>
                          </span>
                        </span>
                      </div>
                  </a>
                </div>
              </div>
            </Card.Header>

            <Collapse in={this.state.accordion1 === 1}>
              <div className="chat-list__body-header">
                <div className="chat-list__body-header__name">{t("filter_menu_text_57")}</div>
                <div>08/18/20, 08:10PM</div>
              </div>
            </Collapse>

            <Card.Body className="chat-list__body">
              {this.props.post.table && <div className="chat-list-form__parameter">
                {this.props.post.table?.map(row => (
                  <div className="chat-list-form__parameter-list" key={ row?.position }>
                    <div className="chat-list-form__parameter-item">
                      <div className="chat-list-form__parameter-flex">
                        <div className="chat-list-form__parameter-part chat-list-form__parameter-part_1">
                          <div className="chat-list-form__description chat-list-form__description_other chat-list-form__description_base-alter">{ row?.title }</div>
                        </div> 
                        <div className="chat-list-form__parameter-part chat-list-form__parameter-part_2">
                          <div className="chat-list-form__description chat-list-form__description_primary chat-list-form__description_base-alter jest-year">{ row?.detail }</div>
                        </div>
                      </div>
                    </div> 
                  </div>
                ))}
              </div>}
              {this.state.imageSrc && <div className={ 'chat-list-form__image'}>
                <div className="chat-list-form__image-preview">
                  <LazyLoadImage 
                    // alt={image.alt}
                    // height={image.height}
                    effect="blur"
                    // src = { image.src } // use for 
                    src={this.state.imageSrc } // use normal <img> attributes as props
                    // width={image.width} />
                    crossOrigin={'anonymous'}
                    />

                </div>
              </div>}
              {this.props.post.comment && <div className={'widget__wrapper-title__text__'}>{this.props.post.comment}</div>}
            </Card.Body>
          </Card>
        </div>
        {/* <div className="rich-message__block">
          <div className="btn-custom__time">
            +15 sec
          </div>
        </div> */}
      </div>
    );
  }
}

const mapDispatchToProps = { deletePost }

export default withTranslation()(connect(null, mapDispatchToProps)(Preview))
