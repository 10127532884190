import React, { Component } from "react"
import { Modal } from "react-bootstrap"
import { isBlankOrNull } from "../../../utils/Helpres"


import { withTranslation } from 'react-i18next'

class AddingNewWorkspaceModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      workspaceName: "",
      isShowError: false,
    }
  }

  handleChangeWorkspaceName(event) {
    const { workspaces } = this.props.data
    const isShowError =
      isBlankOrNull(event.target.value) ||
      workspaces?.some(w => w?.name === event.target.value)
    this.setState({
      workspaceName: event.target.value,
      isShowError: isShowError,
    })
  }

  onSaveNameWorkspace = () => {
    const { workspaces, onWorkspaceCreate } = this.props.data
    const { workspaceName } = this.state
    const isShowError =
      isBlankOrNull(workspaceName) ||
      workspaces?.some(w => w?.name === workspaceName)
    this.setState({ isShowError: isShowError })
    if (!isShowError) {
      onWorkspaceCreate(workspaceName)
      this.toggleModal()
    }
  }

  toggleModal = () => {
    this.props.onHide()
  }

  render() {
    
    const { t } = this.props

    const { workspaceName } = this.state
    return (
      <Modal
        {...this.props}
        onHide={this.toggleModal}
        centered
        aria-labelledby="contained-modal-title-vcenter"
        className="n-modal__modal"
      >
        <div className="n-modal">
          <div className="n-modal__wrapper">
            <div className="n-modal__close" onClick={this.toggleModal}>
              <svg
                width="20rem"
                height="20rem"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M20 2.01429L17.9857 0L10 7.98571L2.01429 0L0 2.01429L7.98571 10L0 17.9857L2.01429 20L10 12.0143L17.9857 20L20 17.9857L12.0143 10L20 2.01429Z" />
              </svg>
            </div>
            <div className="n-modal__header">
              <div className="n-modal__header__icon">
                <svg
                  width="39rem"
                  height="38rem"
                  viewBox="0 0 39 38"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_533_3952)">
                    <path
                      d="M19.5 13.5V24.5"
                      stroke="#203B60"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                    />
                    <path
                      d="M25 19H14"
                      stroke="#203B60"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                    />
                    <path
                      d="M19.5 37C29.4411 37 37.5 28.9411 37.5 19C37.5 9.05887 29.4411 1 19.5 1C9.55887 1 1.5 9.05887 1.5 19C1.5 28.9411 9.55887 37 19.5 37Z"
                      stroke="#203B60"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_533_3952">
                      <rect
                        width="38"
                        height="38"
                        fill="white"
                        transform="translate(0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className="n-modal__header__title">{t("workspace_create_new_workspace_title")}</div>
              <div className="n-modal__line" />
              <div className="n-modal__content">
                <div className="n-modal__content__title">
                  {t("workspace_content_text_1")} <b>'{t("workspace_content_text_2")}'</b>
                </div>
                <div
                  className={
                    this.state.isShowError ? "n-input error" : "n-input"
                  }
                >
                  <input
                    value={workspaceName}
                    type="text"
                    maxLength={20}
                    onChange={this.handleChangeWorkspaceName.bind(this)}
                    placeholder={t("workspace_name_placeholder")}
                  />
                  <div className="n-input__error-message">
									{t("workspace_create_name_error_1")}
                  </div>
                </div>
              </div>
              <div className="n-modal__btns">
                <div className="button-lg" onClick={this.onSaveNameWorkspace}>
								{t("workspace_create_button")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

export default withTranslation()(AddingNewWorkspaceModal)
