import React, { Component } from "react"
import { connect } from "react-redux"
import * as K from "../utils/Constant"
import { Hub } from "aws-amplify"

import { handleShowModal } from "../store/actions/ModalActions"

import ChannelInformationModal from "../components/modal/channels/ChannelInformationModal"
import BlockChannelModal from "../components/modal/channels/BlockChannelModal"
import UnsubscribeFreeChannelModal from "../components/modal/channels/UnsubscribeFreeChannelModal"
import AddNewChannelModal from "../components/modal/channels/AddNewChannelModal"
import DeleteChannelModal from "../components/modal/channels/DeleteChannelModal"
import EditChannelModal from "../components/modal/channels/EditChannelModal"
import UnsubscribePaidChannelModal from "../components/modal/channels/UnsubscribePaidChannelModal"
import NoCardOnFile from "../components/modal/permission-message/NoCardOnFile"
import AlertNewChannel from "../components/modal/channels/AlertNewChannel"
import ExtraInformationModal from "../components/modal/channels/ExtraInformationModal"
import QuotaExceeded from "../components/modal/permission-message/QuotaExceeded"
import SetUserNameModal from "../components/modal/profile-settings-menu/SetUserNameModal"
import SetRealNameModal from "../components/modal/profile-settings-menu/SetRealNameModal"
import SetPhoneNumberModal from "../components/modal/profile-settings-menu/SetPhoneNumberModal"
import ChangePasswordModal from "../components/modal/profile-settings-menu/ChangePasswordModal"
import SignOutModal from "../components/modal/profile-settings-menu/SignOutModal"
import AddingNewLabelModal from "../components/modal/label/AddingNewLabelModal"
import EditLabelModal from "../components/modal/label/EditLabelModal"
import DeleteLabelModal from "../components/modal/label/DeleteLabelModal"
import AddingNewWorkspaceModal from "../components/modal/workspace/AddingNewWorkspaceModal"
import EditWorkspaceModal from "../components/modal/workspace/EditWorkspaceModal"
import DeleteWorkspaceModal from "../components/modal/workspace/DeleteWorkspaceModal"
import FilesTooLarge from "../components/modal/permission-message/FilesTooLarge"
import ImageTooLarge from "../components/modal/permission-message/ImageTooLarge"
import UploadingFiles from "../components/modal/permission-message/UploadingFiles"
import ModalProgressBar from "../components/modal/progress-bar/ModalProgressBar"
import RssConnectContainer from "./plugins/RssConnectContainer"
import RichMessageContainer from "./message/RichMessageContainer"
import RichMessagingUnavailable from "../components/modal/permission-message/RichMessagingUnavailable"
import RssConnectUnavailable from "../components/modal/permission-message/RssConnectUnavailable"
import MessageTooLong from "../components/modal/permission-message/MessageTooLong"
import ShareLinkChannelModal from "../components/modal/channels/ShareLinkChannelModal"
import AlertShareLinkModal from "../components/modal/channels/AlertShareLinkModal"
import AddPrivateChannelModal from "../components/modal/channels/AddPrivateChannelModal"
import AlertPrivateChannelModal from "../components/modal/channels/AlertPrivateChannel"
import Queue from "../shared/queue/Queue"
import PackageInformationModal from "../components/modal/packages/PackageInformationModal"
import UnsubscribeFreePackageModal from "../components/modal/packages/UnsubscribeFreePackageModal"
import UnsubscribePaidPackageModal from "../components/modal/packages/UnsubscribePaidPackageModal"
import EditPrivateChannelModal from "../components/modal/channels/EditPrivateChannelModal"
import MetaTraderModal from "../components/modal/meta-trader/MetaTraderModal"
import DisclamerModal from "../components/modal/disclaimer/DisclamerModal"
import VendorInfo from "../components/modal/vendor-info/VendorInfo"
import VendorChannels from "../components/modal/vendor-channels/VendorChannels"
import XLSX from "../components/modal/preview/xlsx"
import DOC from "../components/modal/preview/doc"
import CopytradingConnectContainer from "./plugins/CopytradingConnectContainer"
import CopytradingCreateAccountErrorModal from "../components/modal/copytrading/CopytradingCreateAccountErrorModal"

class ModalContainer extends Component {
  constructor(props) {
    super(props)
    this.queue = new Queue()
  }
  
  componentDidMount() {
    Hub.listen(K.HUB_MODAL, this.renderModal)
  }
  
  componentDidUpdate() {
    this.showNextModal(false)
  }
  
  componentWillUnmount() {
    if (this.renderModal) Hub.remove(K.HUB_MODAL, this.renderModal)
  }
  
  renderModal = async ({ payload: { event, data, message } }) => {
    if (event === K.MODAL_FILE_PROGRESS) {
      if (data?.show) {
        this.showModal(event, data)
      } else {
        this.showModal(null, null)
      }
      return
    }
    this.queue.push([event, data])
    this.showNextModal(false)
  }
  
  showNextModal = (closePrevious) => {
    if (this.queue.length > 0 && (closePrevious || this.props.modal.presented == null)) {
      try {
        const nextModal = this.queue.shift()
        const data = nextModal[1]
        const modal = nextModal[0]
        if (modal && data) {
          this.showModal(modal, data)
        }
      } catch (e) {
        console.log("failed render modal", e)
      }
    } else if (closePrevious) {
      this.showModal(null, null)
    }
  }
  
  showModal = (modal, data) => {
    const modalCard = modal && K.MODALS.includes(modal) ? modal : null
    const dataCard = modalCard ? data : null
    this.props.handleShowModal(modalCard, dataCard)
  }
  
  closeModal = () => {
    this.showNextModal(true)
  }
  
  render() {
    const { presented, data } = this.props.modal
    return (
      <div>
        {this.props.children}
        
        {presented === K.MODAL_WORKSPACE_ADD &&
          <AddingNewWorkspaceModal show={true} data={data} onHide={this.closeModal.bind(this)} />}
        {presented === K.MODAL_WORKSPACE_EDIT &&
          <EditWorkspaceModal show={true} data={data} onHide={this.closeModal.bind(this)} />}
        {presented === K.MODAL_WORKSPACE_DELETE &&
          <DeleteWorkspaceModal show={true} data={data} onHide={this.closeModal.bind(this)} />}
        
        {presented === K.MODAL_CHANNEL_ADD &&
          <AddNewChannelModal show={true} data={data} onHide={this.closeModal.bind(this)} />}
        {presented === K.MODAL_CHANNEL_ADD_PRIVATE &&
          <AddPrivateChannelModal show={true} data={data} onHide={this.closeModal.bind(this)} />}
        {presented === K.MODAL_CHANNEL_EDIT &&
          <EditChannelModal show={true} data={data} onHide={this.closeModal.bind(this)} />}
        {presented === K.MODAL_CHANNEL_EDIT_PRIVATE &&
          <EditPrivateChannelModal show={true} data={data} onHide={this.closeModal.bind(this)} />}
        {presented === K.MODAL_CHANNEL_INFO &&
          <ChannelInformationModal show={true} data={data} onHide={this.closeModal.bind(this)} />}
        {presented === K.MODAL_CHANNEL_DELETE &&
          <DeleteChannelModal show={true} data={data} onHide={this.closeModal.bind(this)} />}
        {presented === K.MODAL_CHANNEL_BLOCK &&
          <BlockChannelModal show={true} data={data} onHide={this.closeModal.bind(this)} />}
        {presented === K.MODAL_CHANNEL_UNSUBSCRIBE_FREE &&
          <UnsubscribeFreeChannelModal show={true} data={data} onHide={this.closeModal.bind(this)} />}
        {presented === K.MODAL_CHANNEL_UNSUBSCRIBE_PAID &&
          <UnsubscribePaidChannelModal show={true} data={data} onHide={this.closeModal.bind(this)} />}
        {presented === K.MODAL_CHANNEL_PUBLIC_ADDED &&
          <AlertNewChannel show={true} data={data} onHide={this.closeModal.bind(this)} />}
        {presented === K.MODAL_CHANNEL_SHARE_LINK &&
          <ShareLinkChannelModal show={true} data={data} onHide={this.closeModal.bind(this)} />}
        {presented === K.MODAL_ALERT_SHARE_LINK &&
          <AlertShareLinkModal show={true} data={data} onHide={this.closeModal.bind(this)} />}
        {presented === K.MODAL_ALERT_SHARE_CHANNEL &&
          <AlertPrivateChannelModal show={true} data={data} onHide={this.closeModal.bind(this)} />}
        
        {presented === K.MODAL_EXTRA_INFO &&
          <ExtraInformationModal show={true} data={data} onHide={this.closeModal.bind(this)} />}
        
        {presented === K.MODAL_PACKAGE_INFO &&
          <PackageInformationModal show={true} data={data} onHide={this.closeModal.bind(this)} />}
        {presented === K.MODAL_PACKAGE_UNSUBSCRIBE_FREE &&
          <UnsubscribeFreePackageModal show={true} data={data} onHide={this.closeModal.bind(this)} />}
        {presented === K.MODAL_PACKAGE_UNSUBSCRIBE_PAID &&
          <UnsubscribePaidPackageModal show={true} data={data} onHide={this.closeModal.bind(this)} />}
        
        {presented === K.MODAL_RICH_MESSAGE &&
          <RichMessageContainer show={true} data={data} onHide={this.closeModal.bind(this)} />}
        {presented === K.MODAL_RICH_MESSAGE_BLOCK &&
          <RichMessagingUnavailable show={true} data={data} onHide={this.closeModal.bind(this)} />}
        
        {presented === K.MODAL_RSS_CONNECT &&
          <RssConnectContainer show={true} data={data} onHide={this.closeModal.bind(this)} />}
        {presented === K.MODAL_RSS_CONNECT_BLOCK &&
          <RssConnectUnavailable show={true} data={data} onHide={this.closeModal.bind(this)} />}

        {presented === K.MODAL_COPYTRADING_CONNECT &&
          <CopytradingConnectContainer show={true} data={data} onHide={this.closeModal.bind(this)} />}
        
        {presented === K.MODAL_FILE_PROGRESS &&
          <ModalProgressBar show={data?.show === true} data={data} onHide={this.closeModal.bind(this)} />}
        
        {presented === K.MODAL_QUOTA_FILE_UPLOAD &&
          <UploadingFiles show={true} data={data} onHide={this.closeModal.bind(this)} />}
        
        {presented === K.MODAL_QUOTA_NO_PAYMENT_METHOD &&
          <NoCardOnFile show={true} data={data} onHide={this.closeModal.bind(this)} />}
        {presented === K.MODAL_QUOTA_EXCEEDED &&
          <QuotaExceeded show={true} data={data} onHide={this.closeModal.bind(this)} />}
        {presented === K.MODAL_QUOTA_IMAGE_TOO_LARGE &&
          <ImageTooLarge show={true} data={data} onHide={this.closeModal.bind(this)} />}
        {presented === K.MODAL_QUOTA_FILE_TOO_LARGE &&
          <FilesTooLarge show={true} data={data} onHide={this.closeModal.bind(this)} />}
        {presented === K.MODAL_QUOTA_MESSAGE_TOO_LONG &&
          <MessageTooLong show={true} data={data} onHide={this.closeModal.bind(this)} />}
        
        {presented === K.MODAL_LABEL_ADD &&
          <AddingNewLabelModal show={true} data={data} onHide={this.closeModal.bind(this)} />}
        {presented === K.MODAL_LABEL_EDIT &&
          <EditLabelModal show={true} data={data} onHide={this.closeModal.bind(this)} />}
        {presented === K.MODAL_LABEL_DELETE &&
          <DeleteLabelModal show={true} data={data} onHide={this.closeModal.bind(this)} />}
        
        {presented === K.MODAL_USER_USERNAME &&
          <SetUserNameModal show={true} data={data} onHide={this.closeModal.bind(this)} />}
        {presented === K.MODAL_USER_NAME &&
          <SetRealNameModal show={true} data={data} onHide={this.closeModal.bind(this)} />}
        {presented === K.MODAL_USER_PHONE &&
          <SetPhoneNumberModal show={true} data={data} onHide={this.closeModal.bind(this)} />}
        
        {presented === K.MODAL_AUTH_CHANGE_PASSWORD &&
          <ChangePasswordModal show={true} data={data} onHide={this.closeModal.bind(this)} />}
        
        {presented === K.MODAL_AUTH_SIGN_OUT &&
          <SignOutModal show={true} data={data} onHide={this.closeModal.bind(this)} />}
        
        
        {presented === K.MODAL_META_TRADER &&
          <MetaTraderModal show={true} data={data} onHide={this.closeModal.bind(this)} />}
        
        {presented === K.MODAL_DISCLAMER &&
          <DisclamerModal show={true} data={data} onHide={this.closeModal.bind(this)} />}
        
        {presented === K.MODAL_VENDOR_INFO &&
          <VendorInfo show={true} data={data} onHide={this.closeModal.bind(this)} />}
        
        {presented === K.MODAL_VENDOR_CHANNELS &&
          <VendorChannels show={true} data={data} onHide={this.closeModal.bind(this)} />}
        
        {presented === K.MODAL_PREVIEW_XLSX_IN_MESSAGE &&
          <XLSX show={true} data={data} onHide={this.closeModal.bind(this)} />}

        {presented === K.MODAL_PREVIEW_DOC_IN_MESSAGE &&
          <DOC show={true} data={data} onHide={this.closeModal.bind(this)} />}

        {presented === K.MODAL_COPYTRADING_CREATE_ACCOUNT_ERROR &&
          <CopytradingCreateAccountErrorModal show={true} data={data} onHide={this.closeModal.bind(this)} />}
      </div>
    )
  }
  
}

const mapStateToProps = store => {
  return {
    modal: store.modal
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleShowModal: (modal, data) => dispatch(handleShowModal(modal, data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer)

