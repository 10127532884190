import React, { Component } from 'react'
import { connect } from 'react-redux'
import { handleMessageCreate } from '../../store/actions/MessageActions'
import { deleteAllPosts } from '../../store/actions/RichMessageActions'
import RichMessage from '../../components/home/widget/rich-message/RichMessage'
import * as K from '../../utils/Constant'
import Permissions from "react-redux-permissions"      

class RichMessageContainer extends Component {
  allowPersmissions() {
    return [K.CHANNEL_CAN_POST.concat(K.PRIVATE_POLICY).concat(K.OWNER_POLICY),
            K.CHANNEL_CAN_POST.concat(K.PRIVATE_POLICY).concat(K.GUEST_POLICY), 
            K.CHANNEL_CAN_POST.concat(K.PUBLIC_POLICY).concat(K.OWNER_POLICY),
            K.CHANNEL_CAN_POST.concat(K.PUBLIC_POLICY).concat(K.GUEST_POLICY)]
  }

  hasPersmissions(isPublic, isOwner) {
    var result = this.props.permissions.some(per => per === K.CHANNEL_CAN_POST.concat(isPublic ? K.PUBLIC_POLICY : K.PRIVATE_POLICY).concat(isOwner ? K.OWNER_POLICY : K.GUEST_POLICY))
    console.log('RICH Message - hasPersmissions', isPublic, isOwner, result)
    return result
  }

  onMessageCreate = () => {
    const { account, channel } = this.props.data
    if (!this.hasPersmissions(channel?.public, channel?.owner === account?.owner)) { return }
    for (let msg of this.props.syncPosts) {
      const content = { 
        threadId: null,
        category: msg.category,
        title: msg.title,
        comment: msg.comment,
        color: msg.color,
        media: JSON.stringify(msg.media),
        table: JSON.stringify(msg.table),
      }
      this.props.handleMessageCreate(account?.owner, account?.user, channel, content, null)
    }
    this.props.deleteAllPosts()
  }

  onHide() {
    this.props.deleteAllPosts()
    const { onClearRichMessage } = this.props.data
    onClearRichMessage()
    this.props.onHide()
  }


  render() {
    return (
      <Permissions 
      allowed={this.allowPersmissions()}
      fallbackElement={
        <RichMessage  
        syncPosts={this.props.syncPosts}
        onMessageCreate={() => {}}
        show={this.props.show} 
        onHide={this.onHide.bind(this)}/>}>
        <RichMessage  
        syncPosts={this.props.syncPosts}
        onMessageCreate={this.onMessageCreate.bind(this)}
        show={this.props.show} 
        onHide={this.onHide.bind(this)}/>
      </Permissions>      
    )
  }
}

const mapStateToProps = store => {
  return {
    permissions: store.permissions,
    syncPosts: store.posts.posts
  }
}


const mapDispatchToProps = dispatch => {
  return {
    handleMessageCreate: (owner, user, channel, content, guid) => dispatch(handleMessageCreate(owner, user, channel, content, guid)),
    deleteAllPosts: () => dispatch(deleteAllPosts())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RichMessageContainer)