import * as StripeApi  from '../../services/billing/stripe'
import { Hub } from "aws-amplify"
import * as K from "../../utils/Constant"
export const STRIPE_BALANCE_GET_SUCCESS = 'STRIPE_BALANCE_GET_SUCCESS'
export const STRIPE_BALANCE_GET_FAIL = 'STRIPE_BALANCE_GET_FAIL'

export const STRIPE_CUSTOMER_CREATE_SUCCESS = 'STRIPE_CUSTOMER_CREATE_SUCCESS'
export const STRIPE_CUSTOMER_CREATE_FAIL = 'STRIPE_CUSTOMER_CREATE_FAIL'
export const STRIPE_CUSTOMER_READ_SUCCESS = 'STRIPE_CUSTOMER_READ_SUCCESS'
export const STRIPE_CUSTOMER_READ_FAIL = 'STRIPE_CUSTOMER_READ_FAIL'
export const STRIPE_CUSTOMER_LIST_SUCCESS = 'STRIPE_CUSTOMER_LIST_SUCCESS'
export const STRIPE_CUSTOMER_LIST_FAIL = 'STRIPE_CUSTOMER_LIST_FAIL'
export const STRIPE_CUSTOMER_GET_SUCCESS = 'STRIPE_CUSTOMER_GET_SUCCESS'
export const STRIPE_CUSTOMER_GET_FAIL = 'STRIPE_CUSTOMER_GET_FAIL'
export const STRIPE_CUSTOMER_DELETE_SUCCESS = 'STRIPE_CUSTOMER_DELETE_SUCCESS'
export const STRIPE_CUSTOMER_DELETE_FAIL = 'STRIPE_CUSTOMER_DELETE_FAIL'

export const STRIPE_PRODUCT_CREATE_SUCCESS = 'STRIPE_PRODUCT_CREATE_SUCCESS'
export const STRIPE_PRODUCT_CREATE_FAIL = 'STRIPE_PRODUCT_CREATE_FAIL'
export const STRIPE_PRODUCT_LIST_SUCCESS = 'STRIPE_PRODUCT_LIST_SUCCESS'
export const STRIPE_PRODUCT_LIST_FAIL = 'STRIPE_PRODUCT_LIST_FAIL'
export const STRIPE_PRODUCT_DELETE_SUCCESS = 'STRIPE_PRODUCT_DELETE_SUCCESS'
export const STRIPE_PRODUCT_DELETE_FAIL = 'STRIPE_PRODUCT_DELETE_FAIL'

export const STRIPE_PRICE_CREATE_SUCCESS = 'STRIPE_PRICE_CREATE_SUCCESS'
export const STRIPE_PRICE_CREATE_FAIL = 'STRIPE_PRICE_CREATE_FAIL'
export const STRIPE_PRICE_LIST_SUCCESS = 'STRIPE_PRICE_LIST_SUCCESS'
export const STRIPE_PRICE_LIST_FAIL = 'STRIPE_PRICE_LIST_FAIL'
export const STRIPE_PRICE_DELETE_SUCCESS = 'STRIPE_PRICE_DELETE_SUCCESS'
export const STRIPE_PRICE_DELETE_FAIL = 'STRIPE_PRICE_DELETE_FAIL'

export const STRIPE_SUBSCRIPTION_CREATE_SUCCESS = 'STRIPE_SUBSCRIPTION_CREATE_SUCCESS'
export const STRIPE_SUBSCRIPTION_CREATE_FAIL = 'STRIPE_SUBSCRIPTION_CREATE_FAIL'
export const STRIPE_SUBSCRIPTION_UPDATE_SUCCESS = 'STRIPE_SUBSCRIPTION_UPDATE_SUCCESS'
export const STRIPE_SUBSCRIPTION_UPDATE_FAIL = 'STRIPE_SUBSCRIPTION_UPDATE_FAIL'
export const STRIPE_SUBSCRIPTION_LIST_SUCCESS = 'STRIPE_SUBSCRIPTION_LIST_SUCCESS'
export const STRIPE_SUBSCRIPTION_LIST_FAIL = 'STRIPE_SUBSCRIPTION_LIST_FAIL'
export const STRIPE_SUBSCRIPTION_GET_SUCCESS = 'STRIPE_SUBSCRIPTION_GET_SUCCESS'
export const STRIPE_SUBSCRIPTION_GET_FAIL = 'STRIPE_SUBSCRIPTION_GET_FAIL'
export const STRIPE_SUBSCRIPTION_DELETE_SUCCESS = 'STRIPE_SUBSCRIPTION_DELETE_SUCCESS'
export const STRIPE_SUBSCRIPTION_DELETE_FAIL = 'STRIPE_SUBSCRIPTION_DELETE_FAIL'

export const STRIPE_CARD_CREATE_SUCCESS = 'STRIPE_CARD_CREATE_SUCCESS'
export const STRIPE_CARD_CREATE_FAIL = 'STRIPE_CARD_CREATE_FAIL'
export const STRIPE_CARD_DELETE_SUCCESS = 'STRIPE_CARD_DELETE_SUCCESS'
export const STRIPE_CARD_DELETE_FAIL = 'STRIPE_CARD_DELETE_FAIL'

export const STRIPE_ACCOUNT_CREATE_SUCCESS = 'STRIPE_ACCOUNT_CREATE_SUCCESS'
export const STRIPE_ACCOUNT_CREATE_FAIL = 'STRIPE_ACCOUNT_CREATE_FAIL'
export const STRIPE_ACCOUNT_LIST_SUCCESS = 'STRIPE_ACCOUNT_LIST_SUCCESS'
export const STRIPE_ACCOUNT_LIST_FAIL = 'STRIPE_ACCOUNT_LIST_FAIL'
export const STRIPE_ACCOUNT_READ_SUCCESS = 'STRIPE_ACCOUNT_READ_SUCCESS'
export const STRIPE_ACCOUNT_READ_FAIL = 'STRIPE_ACCOUNT_READ_FAIL'
export const STRIPE_ACCOUNT_GET_SUCCESS = 'STRIPE_ACCOUNT_GET_SUCCESS'
export const STRIPE_ACCOUNT_GET_FAIL = 'STRIPE_ACCOUNT_GET_FAIL'
export const STRIPE_ACCOUNT_DELETE_SUCCESS = 'STRIPE_ACCOUNT_DELETE_SUCCESS'
export const STRIPE_ACCOUNT_DELETE_FAIL = 'STRIPE_ACCOUNT_DELETE_FAIL'

export const STRIPE_PAYMENT_CREATE_SUCCESS = 'STRIPE_PAYMENT_CREATE_SUCCESS'
export const STRIPE_PAYMENT_CREATE_FAIL = 'STRIPE_PAYMENT_CREATE_FAIL'

export const STRIPE_ACCOUNT_TRANSFER_LIST_SUCCESS = 'STRIPE_ACCOUNT_TRANSFER_LIST_SUCCESS'
export const STRIPE_ACCOUNT_TRANSFER_LIST_FAIL = 'STRIPE_ACCOUNT_TRANSFER_LIST_FAIL'

export function handleStripeGetBalance(stripeKey) {
  if (stripeKey == null) { return }
  return dispatch => {
    StripeApi.getBalance(stripeKey)
    .then(balance => {
      dispatch({
        type: STRIPE_BALANCE_GET_SUCCESS,
        payload: balance,
      })
    }).catch(error => {
      dispatch({
        type: STRIPE_BALANCE_GET_FAIL,
        error: true,
        payload: new Error(error),
      })
    })
  }
}

export function handleStripeCreateCustomer(stripeKey, email) {
  if (stripeKey == null) { return }
  return dispatch => {
    StripeApi.listCustomers(stripeKey, email)
    .then(customers => {
      const myCustomer = customers?.find(item => item?.email === email)
      if (customers == null) {
        dispatch({
          type: STRIPE_CUSTOMER_READ_FAIL,
          error: true,
          payload: customers,
        })
      } else {
        if (myCustomer != null) {
          StripeApi.getCustomerPaymentMethod(stripeKey, myCustomer?.id)
            .then(methods => {
              dispatch({
                type: STRIPE_PAYMENT_CREATE_SUCCESS,
                payload: methods,
              })
          }).catch(error => {
            console.error(error);
            dispatch({
              type: STRIPE_PAYMENT_CREATE_FAIL,
              error: true,
              payload: new Error(error),
            })
          })
          dispatch({
            type: STRIPE_CUSTOMER_READ_SUCCESS,
            payload: myCustomer,
          })
        } else {
          StripeApi.createCustomer(stripeKey, email)
            .then(customer => {
              dispatch({
                type: STRIPE_CUSTOMER_CREATE_SUCCESS,
                payload: customer,
              })
            }).catch(error => {
            console.error(error);
            dispatch({
              type: STRIPE_CUSTOMER_CREATE_FAIL,
              error: true,
              payload: new Error(error),
            })
          })
        }
      }
    }).catch(error => {
      dispatch({
        type: STRIPE_CUSTOMER_READ_FAIL,
        error: true,
        payload: new Error(error),
      })
      console.error(error);
    })    
  }
}

export function handleStripeReadMyCustomer(stripeKey, email) {
  if (stripeKey == null) { return }
  return dispatch => {
    StripeApi.listCustomers(stripeKey, email)
      .then(customers => {
        const myCustomer = customers?.find(item => item?.email === email)
        if (customers == null) {
          dispatch({
            type: STRIPE_CUSTOMER_READ_FAIL,
            payload: customers,
          })
        } else {
          if (myCustomer != null) {
            StripeApi.getCustomerPaymentMethod(stripeKey, myCustomer?.id)
              .then(methods => {
                dispatch({
                  type: STRIPE_PAYMENT_CREATE_SUCCESS,
                  payload: methods,
                })
            }).catch(error => {
              dispatch({
                type: STRIPE_PAYMENT_CREATE_FAIL,
                payload: new Error(error),
              })
              console.error(error);
            })
            dispatch({
              type: STRIPE_CUSTOMER_READ_SUCCESS,
              payload: myCustomer,
            })
          } else {
            StripeApi.createCustomer(stripeKey, email)
              .then(customer => {
                dispatch({
                  type: STRIPE_CUSTOMER_CREATE_SUCCESS,
                  payload: customer,
                })
              }).catch(error => {
              console.error(error);
              dispatch({
                type: STRIPE_CUSTOMER_CREATE_FAIL,
                error: true,
                payload: new Error(error),
              })
            })
          }
        }
    }).catch(error => {
      dispatch({
        type: STRIPE_CUSTOMER_READ_FAIL,
        error: true,
        payload: new Error(error),
      })
      console.error(error);
    })
  }
}

export function handleStripeListCustomers(stripeKey, email) {
  if (stripeKey == null) { return }
  return dispatch => {
    StripeApi.listCustomers(stripeKey, email)
    .then(customers => {
      dispatch({
        type: STRIPE_CUSTOMER_LIST_SUCCESS,
        payload: customers,
      })
    }).catch(error => {
      console.error(error);
      dispatch({
        type: STRIPE_CUSTOMER_LIST_FAIL,
        error: true,
        payload: new Error(error),
      })
    })
  }
}

export function handleStripeGetCustomer(stripeKey, customerId) {
  if (stripeKey == null) { return }
  return dispatch => {
    StripeApi.getCustomer(stripeKey, customerId)
    .then(customer => {
      dispatch({
        type: STRIPE_CUSTOMER_GET_SUCCESS,
        payload: customer?.data,
      })
    }).catch(error => {
      console.error(error);
      dispatch({
        type: STRIPE_CUSTOMER_GET_FAIL,
        error: true,
        payload: new Error(error),
      })
    })
  }
}

export function handleStripeDeleteCustomer(stripeKey, customerId) {
  if (stripeKey == null) { return }
  return dispatch => {
    StripeApi.destroyCustomer(stripeKey, customerId)
    .then(customer => {
      dispatch({
        type: STRIPE_CUSTOMER_DELETE_SUCCESS,
        payload: customer?.data,
      })
    }).catch(error => {
      console.error(error);
      dispatch({
        type: STRIPE_CUSTOMER_DELETE_FAIL,
        error: true,
        payload: new Error(error),
      })
    })
  }
}

export function handleUpdatePayment(stripeKey, customerId, paymentId) {
  if (stripeKey == null) { return }
  return dispatch => {
    StripeApi.attachCustomerPaymentMethod(stripeKey, customerId, paymentId)
      .then(updateDefault => {
        StripeApi.getCustomerPaymentMethod(stripeKey, customerId)
          .then(methods => {
            dispatch({
              type: STRIPE_PAYMENT_CREATE_SUCCESS,
              payload: methods,
            })
          }).catch(error => {
          console.error(error);
        })
      }).catch(error => {
      console.error(error);
    })
  }  
}

export function handleStripeCreateProduct(stripeKey, productId, name) {
  if (stripeKey == null) { return }
  return dispatch => {
    StripeApi.createProduct(stripeKey, productId, name)
    .then(product => {
      dispatch({
        type: STRIPE_PRODUCT_CREATE_SUCCESS,
        payload: product?.data,
      })
    }).catch(error => {
      console.error(error);
      dispatch({
        type: STRIPE_PRODUCT_CREATE_FAIL,
        error: true,
        payload: new Error(error),
      })
    })
  }
}

export function handleStripeListProduct(stripeKey) {
  if (stripeKey == null) { return }
  return dispatch => {
    StripeApi.listProduct(stripeKey)
    .then(products => {
      const allowedProds = products?.filter(pr => pr?.id?.startsWith(K.PRODUCT_PREFIX_SINGLE) || pr?.id?.startsWith(K.PRODUCT_PREFIX_PLAN) || pr?.id?.startsWith(K.PRODUCT_PREFIX_PACKAGE))
      dispatch({
        type: STRIPE_PRODUCT_LIST_SUCCESS,
        payload: allowedProds,
      })
    }).catch(error => {
      console.error(error);
      dispatch({
        type: STRIPE_PRODUCT_LIST_FAIL,
        error: true,
        payload: new Error(error),
      })
    })
  }
}

export function handleStripeDeleteProduct(stripeKey, productId) {
  if (stripeKey == null) { return }
  return dispatch => {
    StripeApi.destroyProduct(stripeKey, productId)
    .then(product => {
      dispatch({
        type: STRIPE_PRODUCT_DELETE_SUCCESS,
        payload: product?.data,
      })
    }).catch(error => {
      console.error(error);
      dispatch({
        type: STRIPE_PRODUCT_DELETE_FAIL,
        error: true,
        payload: new Error(error),
      })
    })
  }
}

export function handleStripeCreatePrice(stripeKey, productId, name, amount, currency, interval) {
  if (stripeKey == null) { return }
  return dispatch => {
    StripeApi.createPrice(stripeKey, productId, name, amount, currency, interval)
    .then(price => {
      dispatch({
        type: STRIPE_PRICE_CREATE_SUCCESS,
        payload: price?.data,
      })
    }).catch(error => {
      console.error(error);
      dispatch({
        type: STRIPE_PRICE_CREATE_FAIL,
        error: true,
        payload: new Error(error),
      })
    })
  }
}

export function handleStripeListPrice(stripeKey) {
  if (stripeKey == null) { return }
  return dispatch => {
    StripeApi.listPrice(stripeKey)
    .then(prices => {
      const allowedPrices = prices?.filter(pr => pr?.product?.startsWith(K.PRODUCT_PREFIX_SINGLE) || pr?.product?.startsWith(K.PRODUCT_PREFIX_PLAN) || pr?.product?.startsWith(K.PRODUCT_PREFIX_PACKAGE) )
      dispatch({
        type: STRIPE_PRICE_LIST_SUCCESS,
        payload: allowedPrices,
      })
    }).catch(error => {
      console.error(error);
      dispatch({
        type: STRIPE_PRICE_LIST_FAIL,
        error: true,
        payload: new Error(error),
      })
    })
  }
}

export function handleStripeDeletePrice(stripeKey, priceId) {
  if (stripeKey == null) { return }
  return dispatch => {
    StripeApi.destroyPrice(stripeKey, priceId)
    .then(price => {
      dispatch({
        type: STRIPE_PRICE_DELETE_SUCCESS,
        payload: price?.data,
      })
    }).catch(error => {
      console.error(error);
      dispatch({
        type: STRIPE_PRICE_DELETE_FAIL,
        error: true,
        payload: new Error(error),
      })
    })
  }
}

export function handleStripeCreateSubscription(stripeKey, customerId, priceId, isPackage, vendorId, vendorShare) {
  if (stripeKey == null) { return }
  return dispatch => {
    StripeApi.createSubscription(stripeKey, customerId, priceId, vendorId, vendorShare)
    .then(subscription => {
      dispatch({
        type: STRIPE_SUBSCRIPTION_CREATE_SUCCESS,
        payload: subscription,
      })
      Hub.dispatch(isPackage ? K.HUB_PAYMENT_PACKAGE : K.HUB_PAYMENT,
        {event: "success", data: subscription, message:'payment done' });
    }).catch(error => {
      console.error(error);
      dispatch({
        type: STRIPE_SUBSCRIPTION_CREATE_FAIL,
        error: true,
        payload: new Error(error),
      })
      Hub.dispatch(isPackage ? K.HUB_PAYMENT_PACKAGE : K.HUB_PAYMENT,
        {event: "error", data: error, message:'payment error' });
    })
  }
}

export function handleStripeUpdateSubscription(stripeKey, subscriptionId, data) {
  if (stripeKey == null) { return }
  return dispatch => {
    StripeApi.updateSubscription(stripeKey, subscriptionId, data)
    .then(subscription => {
      dispatch({
        type: STRIPE_SUBSCRIPTION_UPDATE_SUCCESS,
        payload: subscription?.data,
      })
    }).catch(error => {
      console.error(error);
      dispatch({
        type: STRIPE_SUBSCRIPTION_UPDATE_FAIL,
        error: true,
        payload: new Error(error),
      })
    })
  }
}

export function handleStripeListSubscription(stripeKey, customerId) {
  if (stripeKey == null) { return }
  return dispatch => {
    StripeApi.listSubscriptions(stripeKey, customerId)
    .then(subscriptions => {
      const allowedSubs = subscriptions?.filter(sub => sub?.plan?.product?.startsWith(K.PRODUCT_PREFIX_SINGLE) || sub?.plan?.product?.startsWith(K.PRODUCT_PREFIX_PLAN) || sub?.plan?.product?.startsWith(K.PRODUCT_PREFIX_PACKAGE) )
      dispatch({
        type: STRIPE_SUBSCRIPTION_LIST_SUCCESS,
        payload: allowedSubs,
      })
    }).catch(error => {
      console.error(error);
      dispatch({
        type: STRIPE_SUBSCRIPTION_LIST_FAIL,
        error: true,
        payload: new Error(error),
      })
    })
  }
}

export function handleStripeGetSubscription(stripeKey, subscriptionId) {
  if (stripeKey == null) { return }
  return dispatch => {
    StripeApi.getSubscription(stripeKey, subscriptionId)
    .then(subscription => {
      dispatch({
        type: STRIPE_SUBSCRIPTION_GET_SUCCESS,
        payload: subscription?.data,
      })
    }).catch(error => {
      console.error(error);
      dispatch({
        type: STRIPE_SUBSCRIPTION_GET_FAIL,
        error: true,
        payload: new Error(error),
      })
    })
  }
}

export function handleStripeDeleteSubscription(stripeKey, subscriptionId) {
  if (stripeKey == null) { return }
  return dispatch => {
    StripeApi.destroySubscription(stripeKey, subscriptionId)
    .then(subscription => {
      dispatch({
        type: STRIPE_SUBSCRIPTION_UPDATE_SUCCESS,
        payload: subscription,
      })
    }).catch(error => {
      console.error(error);
      dispatch({
        type: STRIPE_SUBSCRIPTION_DELETE_FAIL,
        error: true,
        payload: new Error(error),
      })
    })
  }
}

export function handleStripeCreateCard(stripeKey, customerId, number, exp_month, exp_year, cvc) {
  if (stripeKey == null) { return }
  return dispatch => {
    StripeApi.createCardToken(stripeKey, number, exp_month, exp_year, cvc)
    .then(token => {
      if (token == null) { return }       
      StripeApi.createCard(stripeKey, customerId, token?.id)
        .then(card => {
          dispatch({
            type: STRIPE_CARD_CREATE_SUCCESS,
            payload: card?.data,
          })
        }).catch(error => {
          console.error(error);
          dispatch({
            type: STRIPE_CARD_CREATE_FAIL,
            error: true,
            payload: new Error(error),
          })            
        })
    }).catch(error => {
      console.error(error);            
    })
  }
}

export function handleStripeDeleteCard(stripeKey, cardId, custId) {
  if (stripeKey == null) { return }
  return dispatch => {
    StripeApi.destroyCard(stripeKey, cardId, custId)
    .then((card) => {
      dispatch({
        type: STRIPE_CARD_DELETE_SUCCESS,
        payload: card?.data,
      })
    }).catch(error => {
      console.error(error);
      dispatch({
        type: STRIPE_CARD_DELETE_FAIL,
        error: true,
        payload: new Error(error),
      })
    })
  }
}

export function handleStripeCreateAccount(stripeKey, email) {
  if (stripeKey == null) { return }
  return dispatch => {
    StripeApi.createAccount(stripeKey, email)
    .then(account => {
      dispatch({
        type: STRIPE_ACCOUNT_CREATE_SUCCESS,
        payload: account,
      })
    }).catch(error => {
      console.error(error);
      dispatch({
        type: STRIPE_ACCOUNT_CREATE_FAIL,
        error: true,
        payload: new Error(error),
      })
    })
  }
}

export function handleStripeReadMyAccount(stripeKey, email) {
  if (stripeKey == null) { return }
  return dispatch => {
    StripeApi.listAccounts(stripeKey)
      .then(accounts => {
        const listAccounts = accounts?.sort(function(a,b){
          return new Date(a.created) - new Date(b.created);
        })
        const myAccounts = listAccounts?.filter(v => v?.email === email)
        const myAccount = myAccounts?.find(v => v?.charges_enabled && v?.payouts_enabled) ?? myAccounts?.find(v => v != null)
        if (listAccounts == null) {
          dispatch({
            type: STRIPE_ACCOUNT_READ_FAIL,
            payload: myAccount,
          })
        } else {
          dispatch({
            type: STRIPE_ACCOUNT_READ_SUCCESS,
            payload: myAccount,
          })
        }
      }).catch(error => {
        console.error(error);
        dispatch({
          type: STRIPE_ACCOUNT_READ_FAIL,
          payload: null,
        })
    })
  }
}

export function handleStripeListAccounts(stripeKey) {
  if (stripeKey == null) { return }
  return dispatch => {
    StripeApi.listAccounts(stripeKey)
    .then(accounts => {
      dispatch({
        type: STRIPE_ACCOUNT_LIST_SUCCESS,  
        payload: accounts,
      })
    }).catch(error => {
      console.error(error);
      dispatch({
        type: STRIPE_ACCOUNT_LIST_FAIL,
        error: true,
        payload: new Error(error),
      })
    })
  }
}

export function handleStripeGetAccount(stripeKey, accountId) {
  if (stripeKey == null) { return }
  return dispatch => {
    StripeApi.getAccount(stripeKey, accountId)
    .then(account => {
      dispatch({
        type: STRIPE_ACCOUNT_GET_SUCCESS,
        payload: account,
      })
    }).catch(error => {
      console.error(error);
      dispatch({
        type: STRIPE_ACCOUNT_GET_FAIL,
        error: true,
        payload: new Error(error),
      })
    })
  }
}

export function handleStripeDeleteAccount(stripeKey, accountId) {
  if (stripeKey == null) { return }
  return dispatch => {
    StripeApi.destroyAccount(stripeKey, accountId)
    .then(account => {
      dispatch({
        type: STRIPE_ACCOUNT_DELETE_SUCCESS,
        payload: account,
      })
    }).catch(error => {
      console.error(error);
      dispatch({
        type: STRIPE_ACCOUNT_DELETE_FAIL,
        error: true,
        payload: new Error(error),
      })
    })
  }
}

export function handleStripeListAccountTransfers(stripeKey) {
  if (stripeKey == null) { return }
  return dispatch => {
    StripeApi.listAccountTransfers(stripeKey)
    .then(transfers => {
      dispatch({
        type: STRIPE_ACCOUNT_TRANSFER_LIST_SUCCESS,  
        payload: transfers,
      })
    }).catch(error => {
      dispatch({
        type: STRIPE_ACCOUNT_TRANSFER_LIST_FAIL,
        error: true,
        payload: new Error(error),
      })
    })
  }
}