import { productCreate } from '../../../services/api/product'
import { publishedChannelRead, publishedChannelUpdate } from '../../../services/api/channel'
// import { createProduct, createPlan }  from '../../../services/billing/stripe'
import * as K from '../../../utils/Constant'
import { slugify } from '../../../utils/Helpres'

export const ADMIN_CHANNEL_MANAGEMENT_LIST_SUCCESS = "ADMIN_CHANNEL_MANAGEMENT_LIST_SUCCESS"
export const ADMIN_CHANNEL_MANAGEMENT_LIST_FAIL = "ADMIN_CHANNEL_MANAGEMENT_LIST_FAIL"

export const ADMIN_CHANNEL_MANAGEMENT_UPDATE_SUCCESS = "ADMIN_CHANNEL_MANAGEMENT_UPDATE_SUCCESS"
export const ADMIN_CHANNEL_MANAGEMENT_UPDATE_FAIL = "ADMIN_CHANNEL_MANAGEMENT_UPDATE_FAIL"

export function handleAdminChannelRead() {
  return dispatch => {
    publishedChannelRead()
    .then(data => {
      dispatch({  
        type: ADMIN_CHANNEL_MANAGEMENT_LIST_SUCCESS,
        payload: data,        
      })
    })
    .catch(err => {
      dispatch({
        type: ADMIN_CHANNEL_MANAGEMENT_LIST_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function handleAdminChannelUpdate(channel, name, brief, description, media, isPublic, status, currency, categories) {
  return dispatch => {
    if (status === K.STATUS_APPROVED) {
      const productItem = { itemId: channel?.id, authorId: channel?.user?.name, authorName: channel?.user?.name, authorShare: "100" }
      productCreate(`${K.PRODUCT_PREFIX_SINGLE_CHANNEL}-${slugify(channel?.id)}`, name, description, false, productItem, null, null, 0, currency).
      then(createdProduct => {
        const products = createdProduct == null ? 
          channel?.products : 
          channel?.products.filter(el => el.itemId !== createdProduct?.id ).concat({ 
            itemId: createdProduct?.id, 
            isPublic: createdProduct?.public, 
            isPaid: createdProduct?.public && createdProduct?.price > 0
          })
        publishedChannelUpdate(channel?.id, name, brief, description, media, isPublic, status, products, categories)
        .then(data => {      
          dispatch({  
            type: ADMIN_CHANNEL_MANAGEMENT_UPDATE_SUCCESS,
            payload: data,        
          })
        })
        .catch(err => {
          dispatch({
            type: ADMIN_CHANNEL_MANAGEMENT_UPDATE_FAIL,
            error: true,
            payload: new Error(err),
          })
        })
      }).
      catch(err => {
        console.log('error create single product', err)
      })
    } else {
      publishedChannelUpdate(channel?.id, name, brief, description, media, isPublic, status, channel?.products, categories)
        .then(data => {      
          dispatch({  
            type: ADMIN_CHANNEL_MANAGEMENT_UPDATE_SUCCESS,
            payload: data,        
          })
        })
        .catch(err => {
          dispatch({
            type: ADMIN_CHANNEL_MANAGEMENT_UPDATE_FAIL,
            error: true,
            payload: new Error(err),
          })
        })
    }    
  }
}

export function handleAdminChannelProductUpdate(channel, updatedProduct, productIsPublic, productPrice, productTrial) {
  return dispatch => {
    const products = channel?.products.filter(product => product?.itemId !== updatedProduct?.id).concat({
      itemId: updatedProduct?.id,
      isPublic: productIsPublic,
      isPaid: productIsPublic && productPrice > 0
    })
    publishedChannelUpdate(channel?.id, channel?.name, channel?.brief, channel?.description, channel?.media, channel?.public, channel?.status, products, channel?.categories)
    .then(data => {      
      dispatch({  
        type: ADMIN_CHANNEL_MANAGEMENT_UPDATE_SUCCESS,
        payload: data,        
      })
    })
    .catch(err => {
      dispatch({
        type: ADMIN_CHANNEL_MANAGEMENT_UPDATE_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}