import { Auth } from "aws-amplify"
import Lambda from "aws-sdk/clients/lambda"
import { ENV, REGION } from "../../utils/Constant"
import { isBlankOrNull } from "../../utils/Helpres"

export async function handleRssSource(topic, event, payload) {
  const props = [topic, event]
  if (props.find(item => isBlankOrNull(item)) || payload == null ) {
    return null
  }

  const credentials = await Auth.currentCredentials()
  const lambda = new Lambda({
    env: ENV,
    region: REGION,
    credentials: Auth.essentialCredentials(credentials)
  });

  const params = {
    FunctionName: 'eventhub-'.concat(ENV),
    Payload: JSON.stringify({
      "Id": "eventhubsid",
      "topic": topic,
      "plugin": event,
      "payload": payload
    })
  }

  const data = await lambda.invoke(params).promise();
  var response = null;
  try {
    response = JSON.parse(data?.Payload)
    response = JSON.parse(response?.body)
  } catch (e) { }
  return response
}