import { DEFAULT_PLATFORM_USE_NEW_DESIGN_TITLE, PLATFORM_USE_NEW_DESIGN_KEY } from "../../../utils/Constant";

export const AVAILABLE_SETTINGS_KEYS = [
  PLATFORM_USE_NEW_DESIGN_KEY
];

export const DEFAULT_SETTINGS_OBJ = {
  [PLATFORM_USE_NEW_DESIGN_KEY]: {
    title: DEFAULT_PLATFORM_USE_NEW_DESIGN_TITLE,
    detail: PLATFORM_USE_NEW_DESIGN_KEY,
    value: true
  }
};