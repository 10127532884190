import React from 'react';
import { connect } from 'react-redux'
import { createPost } from '../../../../../store/actions/RichMessageActions'

class MessageCardAddButton extends React.Component {
  addNewMessageCard = () => {
    const category = this.props.configs.DEFAULT_MESSAGE_CATEGORY
    const newPost = { 
      id: Date.now().toString(),
      threadId: null,
      category: category?.id,
      title: '',
      comment: '',
      color: null,
      media: null,
      table: null  
    }
    this.props.createPost(newPost)
  };

  render() {
    return (
      <div className="rrich-message__message__card__add" onClick={ this.addNewMessageCard }>
        <svg className="rrich-message__message__card__add__btn" width="24rem" height="24rem" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"><defs><filter id="filter-1"><feColorMatrix in="SourceGraphic" type="matrix" values="0 0 0 0 0.666667 0 0 0 0 0.666667 0 0 0 0 0.666667 0 0 0 1.000000 0"></feColorMatrix></filter></defs><g id="Rich-message-input-window:-Visual-Composition" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><g id="Group-31"><g filter="url(#filter-1)" id="close_remove_delete_icon_148989"><g><path d="M20.4854252,3.515625 C15.7976849,-1.171875 8.20354556,-1.171875 3.51580524,3.515625 C-1.17193508,8.203125 -1.17193508,15.796875 3.51580524,20.484375 C8.20354556,25.171875 15.8009663,25.171875 20.4854252,20.484375 C25.1698841,15.796875 25.1731655,8.203125 20.4854252,3.515625 Z" id="Shape" fill="#000000" fillRule="nonzero"></path></g></g><polygon id="+" fill="#FFFFFF" fillRule="nonzero" points="13.9358717 19 13.9358717 13.6113208 19 13.6113208 19 10.1641509 13.9358717 10.1641509 13.9358717 5 10.0781563 5 10.0781563 10.1641509 5 10.1641509 5 13.6113208 10.0781563 13.6113208 10.0781563 19"></polygon></g></g></svg>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    configs: store.configs
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createPost: (post) => dispatch(createPost(post)),
  }
}

export default connect(
  mapStateToProps, 
  mapDispatchToProps
)(MessageCardAddButton)
