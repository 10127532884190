import React, { Component } from 'react'
import { Card, Button} from 'react-bootstrap'

import { connect } from 'react-redux'
import { createPost } from '../../../../../store/actions/RichMessageActions'

import logoimage from './signal_SELL.png'


import { withTranslation } from 'react-i18next'

class SignalSell extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: Date.now().toString(),
      category: this.props.categories?.find(ct => ct?.content?.toLowerCase().trim() === "signal")?.id ?? this.props.category?.id,
      color: '#FF3333',
      title: 'SIGNAL',
      table: [
        {position: 0, title: 'Side', detail: 'SELL'},
        {position: 1, title: 'Type', detail: 'Market'},
        {position: 2, title: 'Pair', detail: ''},
        {position: 3, title: 'Price', detail: ''},
        {position: 4, title: 'Stop-loss', detail: ''},
        {position: 5, title: 'Profit target', detail: ''},
      ],
      media: null,
      comment: '',
    };
  }

  insertTemplate = () => {
    this.props.createPost(this.state)
  }
  render() {
    
    const { t } = this.props

    return (
      <Card className="rrich-message__signals__card">
        <div className="rrich-message__signals__card__wrapper d-flex justify-content-between">
          <div className="rrich-message__signals__card__img">
            <img src={logoimage} alt=""/>
          </div>
          <div className="rrich-message__signals__card__aside">
            <div className="rrich-message__signals__card__aside__title">{t("signal_text_31")}</div>
            <div className="rrich-message__signals__card__aside__price"></div>
            <div className="rrich-message__signals__card__aside__btn">
            <Button size="xs" variant="outline-dark" onClick={this.insertTemplate}>{t("signal_text_32")}</Button>
            </div>
          </div>
        </div>
      </Card>
    );
  }
}

  const mapDispatchToProps = dispatch => {
    return {
      createPost: (post) => dispatch(createPost(post)),
    }
  }

export default withTranslation()(connect(null, mapDispatchToProps)(SignalSell))
