import { URL_PREFIX } from "../consts"
import { stringToBoolean } from "./settings"
import * as K from "../../../utils/Constant"

export const errorLogger = message => {
  console.error('⚠️⚠️⚠️', message)
}

export const getChannelViaExtra = (channels, extra) => {
  return channels?.find(el => el?.categories.some(ct => ct === extra?.id))
}

export const getWidgetViaExtra = (widgets, extra) => {
  return widgets?.find(widget => widget?.content === extra?.name)
}

export const streamViaChannel = (streams, channel, method) => {
  return streams[method](stream => stream?.streamChannelId === channel?.id)
}

export const getWidgetViaStream = (widgets, stream) => {
  return widgets?.find(widget => widget?.widgetStreamId === stream?.id)
}

export const getWorkspaceWithSelectedExtra = ({
  targetWorkspace,
  widgets,
  extra
}) => {
  if (!targetWorkspace) return

  let workspaceWithSelectedExtra = null
  
  widgets
    .forEach(widget => {
      if (workspaceWithSelectedExtra) return
      if (widget?.content !== extra?.name) return
      if (!widget?.visible) return

      if (widget?.widgetWorkspaceId === targetWorkspace.id) {
        workspaceWithSelectedExtra = targetWorkspace
      } else {
        workspaceWithSelectedExtra = widget?.workspace
      }
    })
  return workspaceWithSelectedExtra
}

export const widgetViaChannel = (widgets, channel, method) => {
  if (!Array.isArray(widgets)) return
  return widgets[method](widget => widget?.content === channel?.name)
}

export const getPathToWidget = widget => {
  if (!widget) return errorLogger('!widget');

  return `${URL_PREFIX}/${widget?.workspace?.id}/${widget?.id}`
}

export const onNavigateToWidget = (widget, navigate) => {
  if (!widget) return errorLogger('!widget');

  const pathToRedirect = getPathToWidget(widget)
  navigate(pathToRedirect)
}

export const findObject = (objArray = [], field, equalTo) => {
  if (!objArray) return;
  return objArray.find(item => field in item && item[field] === equalTo);
};

export const findIndex = (objArray = [], field, equalTo) => {
  if (!objArray) return;
  return objArray.findIndex(item => field in item && item[field] === equalTo);
};

export const navigateToNewDesign = () => {
  const pathToRedirect = `${window.location.origin}${URL_PREFIX}`
  if (!window.location.href.includes(URL_PREFIX)) {
    window.location.href = pathToRedirect

  }
}

export const navigateToOldDesign = () => {
  const pathToRedirect = `${window.location.origin}/`
  if (window.location.href.includes(URL_PREFIX)) {
    window.location.href = pathToRedirect
  }
}

export const isShouldCheckForRedirect = () => {
  return !['/admin', '/privacy', '/eula'].some(path => window.location.pathname.startsWith(path))
}

export const handleRedirect = settingsList => {
  if (isShouldCheckForRedirect()) {
    const foundUserNewDesignParam = findObject(settingsList, 'detail', K.PLATFORM_USE_NEW_DESIGN_KEY)
    if (foundUserNewDesignParam) {
      const isUserUseNewDesign = stringToBoolean(foundUserNewDesignParam.content)
      if (isUserUseNewDesign) {
        navigateToNewDesign()
      } else {
        navigateToOldDesign()
      }
    } else {
      navigateToNewDesign()
    }
  }
}