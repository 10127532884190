import { Hub } from "aws-amplify"
import React, { Component } from "react"
import { Modal } from "react-bootstrap"
import { isBlankOrNull } from "../../../utils/Helpres"
import { HUB_OBSERVER_SEARCH_USER } from "../../../services/api/user"


import { withTranslation } from 'react-i18next'

class AddPrivateChannelModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      channelName: "",
      channelDescription: "",
      searchUser: "",
      connectedUsers: this.props.data.addedUser?.id
        ? [this.props.data.account.user, this.props.data.addedUser]
        : [this.props.data.account.user],
      limitConnectedUsers: 0,
      isShowModal: true,
      isShowError: false,
      isShowVisible: false,
      isSearchError: false,
    }
  }

  componentDidMount() {
    Hub.listen(HUB_OBSERVER_SEARCH_USER, this.searchObserver)
    const limit = this.props.data?.usersLimitPrivateChannel ?? 1
    this.setState({ limitConnectedUsers: limit })
  }

  componentWillUnmount() {
    if (this.searchObserver)
      Hub.remove(HUB_OBSERVER_SEARCH_USER, this.searchObserver)
  }

  searchObserver = async ({ payload: { event, data, message } }) => {
    console.log(
      "ADD NEW PRIVATE CHANNEL - SEARCH USER OBSERVER EVENT",
      event,
      message,
      data
    )
    var newFoundUsers = this.state.connectedUsers
    const foundUsers = data?.filter(u => u?.id != null) ?? []
    newFoundUsers = newFoundUsers.concat(
      foundUsers?.filter(
        u => !this.state.connectedUsers?.some(el => el?.id === u?.id)
      )
    )
    const isSuccess = foundUsers.length > 0
    this.setState({
      searchUser: isSuccess ? "" : this.state.searchUser,
      connectedUsers: newFoundUsers,
      isSearchError: !isSuccess,
    })
  }

  removeConnectedUser = user => {
    var newFoundUsers = this.state.connectedUsers.filter(
      u => u?.id !== user?.id
    )
    this.setState({ connectedUsers: newFoundUsers })
  }

  handleChangeChannelName(event) {
    const { channels } = this.props.data
    const newChannelName = event.target.value?.trim()
    const isShowError =
      isBlankOrNull(newChannelName) ||
      channels?.find(
        ch => ch?.name?.toLowerCase() === newChannelName?.toLowerCase()
      )
    this.setState({
      channelName: event.target.value,
      isShowError: isShowError,
      isShowVisible: !isShowError,
    })
  }

  handleChangeChannelDescription(event) {
    this.setState({ channelDescription: event.target.value })
  }

  handleSearchUser = event => {
    console.log(event.key)
    const { onSearchUserRead } = this.props.data
    if (
      event.key === "Enter" &&
      (!isBlankOrNull(event.target.value) ||
        !isBlankOrNull(this.state.searchUser))
    ) {
      this.setState({ isSearchError: false })
      onSearchUserRead(this.state.searchUser?.trim())
    } else {
      const searchUser = event.target.value
      this.setState({ searchUser: searchUser, isSearchError: false })
    }
  }

  onSaveChannel() {
    
    const { t } = this.props

    const { channelName, channelDescription, connectedUsers } = this.state
    const { isPublic, channels, onChannelCreate } = this.props.data
    const newChannelName = channelName?.trim()
    const isShowError =
      isBlankOrNull(newChannelName) ||
      channels?.find(
        ch => ch?.name?.toLowerCase() === newChannelName?.toLowerCase()
      )
    const isSearchError = connectedUsers?.filter(u => u?.id != null)?.length < 1
    console.log("isSearchError", isSearchError, connectedUsers)
    var newChannelDesc = channelDescription?.trim()
    if (isBlankOrNull(newChannelDesc)) {
      newChannelDesc = t("modal_channel_text_1")
    }
    this.setState({
      isShowError: isShowError,
      isSearchError: isSearchError,
    })
    if (!isShowError && !isSearchError) {
      onChannelCreate(
        newChannelName,
        newChannelDesc,
        newChannelDesc,
        isPublic === true,
        connectedUsers
      )
      this.toggleModal()
    }
  }

  toggleModal = () => {
    this.props.onHide()
  }

  render() {
    
    const { t } = this.props

    const { channelName, channelDescription, searchUser } = this.state

    const allowConnectUser =
      this.state.connectedUsers?.length < this.state.limitConnectedUsers

    return (
      <Modal
        {...this.props}
        onHide={this.toggleModal}
        centered
        aria-labelledby="contained-modal-title-vcenter"
        className="n-modal__modal"
      >
        <div className="n-modal">
          <div className="n-modal__wrapper">
            <div className="n-modal__close" onClick={this.toggleModal}>
              <svg
                width="20rem"
                height="20rem"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M20 2.01429L17.9857 0L10 7.98571L2.01429 0L0 2.01429L7.98571 10L0 17.9857L2.01429 20L10 12.0143L17.9857 20L20 17.9857L12.0143 10L20 2.01429Z" />
              </svg>
            </div>
            <div className="n-modal__header">
              <div className="n-modal__header__icon">
                <svg
                  width="39rem"
                  height="38rem"
                  viewBox="0 0 39 38"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_533_3952)">
                    <path
                      d="M19.5 13.5V24.5"
                      stroke="#203B60"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                    />
                    <path
                      d="M25 19H14"
                      stroke="#203B60"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                    />
                    <path
                      d="M19.5 37C29.4411 37 37.5 28.9411 37.5 19C37.5 9.05887 29.4411 1 19.5 1C9.55887 1 1.5 9.05887 1.5 19C1.5 28.9411 9.55887 37 19.5 37Z"
                      stroke="#203B60"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_533_3952">
                      <rect
                        width="38"
                        height="38"
                        fill="white"
                        transform="translate(0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className="n-modal__header__title">
                {t("modal_channel_text_2")}&nbsp;<b>{t("modal_channel_text_3")}</b> {t("modal_channel_text_4")}
              </div>
              <div className="n-modal__line" />
              <div
                style={{
                  overflow: "auto",
                  maxHeight: "calc(100vh - 380px)",
                  width: "calc(100% + 72px)",
                  marginLeft: "-36px",
                  padding: "40px",
                }}
              >
                <div className="n-modal__content n-modal__content--flex channel-name-guide">
                  <div className="n-modal__content__title">
                  {t("modal_channel_text_5")}&nbsp;<b>{t("modal_channel_text_6")}</b>
                  </div>
                  <div className="n-modal__content__required">{t("modal_channel_text_7")}</div>
                  <div
                    className={
                      this.state.isShowError ? "n-input error" : "n-input"
                    }
                  >
                    <input
                      value={channelName}
                      type="text"
                      // maxLength={20}
                      onChange={this.handleChangeChannelName.bind(this)}
                      placeholder={t("modal_channel_text_8")}
                    />
                    <div className="n-input__error-message">
                      {isBlankOrNull(channelName)
                        ? t("modal_channel_text_9")
                        : t("modal_channel_text_10")}
                    </div>
                  </div>
                </div>
                <div
                  className={
                    this.state.isShowVisible
                      ? "n-modal__content n-modal__content--flex channel-description-guide"
                      : "n-modal__content n-modal__content--flex is-opacity-30 channel-description-guide"
                  }
                >
                  <div className="n-modal__content__title">
                    {t("modal_channel_text_11")}&nbsp;<b>{t("modal_channel_text_12")}</b>
                  </div>
                  <div className="n-modal__content__optional">{t("modal_channel_text_13")}</div>
                  <div className="n-input">
                    <input
                      value={channelDescription}
                      onChange={this.handleChangeChannelDescription.bind(this)}
                      type="text"
                      placeholder={t("modal_channel_text_14")}
                    />
                  </div>
                </div>
                <div className="n-modal__line" />
                {allowConnectUser && (
                  <div className="n-modal__content n-modal__content--flex channel-name-guide">
                    <div className="n-modal__content__title">
                    {t("modal_channel_text_15")}&nbsp;<b>{t("modal_channel_text_16")}</b>
                    </div>
                    <div
                      className={
                        this.state.isSearchError ? "n-input error" : "n-input"
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <input
                          value={searchUser}
                          type="text"
                          //   maxLength={40}
                          onChange={this.handleSearchUser.bind(this)}
                          onKeyDown={this.handleSearchUser.bind(this)}
                          placeholder={t("modal_channel_text_17")}
                        />
                        <div
                          style={{
                            marginLeft: "10px",
                          }}
                          className={"button-lg"}
                          onClick={e => {
                            e.key = "Enter"
                            const handleSearchUser =
                              this.handleSearchUser.bind(this)
                            handleSearchUser(e)
                          }}
                        >
                          {t("modal_channel_text_18")}
                        </div>
                      </div>
                      <div className="n-input__error-message">
                      {t("modal_channel_text_19")}
                      </div>
                    </div>
                  </div>
                )}
                {this.state.connectedUsers?.length > 0 && (
                  <div className="n-modal__content n-modal__content--flex channel-name-guide">
                    <b
                      style={{
                        marginBottom: "10px",
                      }}
                    >
                      {t("modal_channel_text_20")}
                    </b>
                    {!allowConnectUser && (
                      <div className="n-modal__content__required">
                        {t("modal_channel_text_21")}
                      </div>
                    )}
                    {this.state.connectedUsers?.map(u => (
                      <div
                        className={"n-modal__content__user-added"}
                        key={u?.id}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20rem"
                          height="20rem"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM16.36 14.83C14.93 13.09 11.46 12.5 10 12.5C8.54 12.5 5.07 13.09 3.64 14.83C2.62 13.49 2 11.82 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 11.82 17.38 13.49 16.36 14.83ZM6.5 7.5C6.5 5.56 8.06 4 10 4C11.94 4 13.5 5.56 13.5 7.5C13.5 9.44 11.94 11 10 11C8.06 11 6.5 9.44 6.5 7.5Z"
                            fill="#A6C6F3"
                          />
                        </svg>
                        <span>{u?.email}</span>
                        {this.props.data?.account?.user?.id !== u?.id && (
                          <span onClick={() => this.removeConnectedUser(u)}>
                            x
                          </span>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="n-modal__line" />
              <div className="n-modal__btns channel-modal-btn-guide">
                <div
                  className="button-lg"
                  onClick={this.onSaveChannel.bind(this)}
                >
                  {t("modal_channel_text_22")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

export default withTranslation()(AddPrivateChannelModal)
