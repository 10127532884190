import React, { Component } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import Signals from './signals/Signals';
// import UserTemplate from './user-template/UserTemplate';
import Message from './message/Message';
import ComposingMode from './composing-mode/ComposingMode';
import SendTime from './send-time/SendTime';
import PreviewContainer from './preview/PreviewContainer';

import CreatableSelect from 'react-select/creatable';
import '../../../../vendor/libs/react-select/react-select.scss'
import { connect } from 'react-redux'
import { handleSettingsCreate, handleSettingsUpdate } from '../../../../store/actions/SettingsActions'
import { createPost } from '../../../../store/actions/RichMessageActions'
import './RichMessage.scss'
import ManyUserTemplates from '../../../modal/permission-message/ManyUserTemplates';
import * as K from '../../../../utils/Constant'


import { withTranslation } from 'react-i18next'

class RichMessage extends Component {
  constructor(props) {
    super(props);  
    this.state={
      isPreviewMode: false,
      titleUserTemplate: '',
      checkedSave: false,
      selectedTemplate: this.props.posts.posts,
      showManyUserTemplates: false,
      selectTitleUserTemplate: ''
    }
    this.handleCreateUserTemplate = this.handleCreateUserTemplate.bind(this);
    this.handleChangeTitleUserTemplate = this.handleChangeTitleUserTemplate.bind(this);
    this.handleChangeСheckedSave = this.handleChangeСheckedSave.bind(this);
  }

  validateLimitUserTemplates() {
    var user_templates_limit = 0
    try {
      const user_templates_permission = this.props.permissions.find(per => per?.includes(K.FEATURE_MESSAGE_MAXIMUM_NUMBER_OF_AVAILABLE_USER_TEMPLATES))
      user_templates_limit = user_templates_permission != null ? parseInt(user_templates_permission.split(":")[1]) : 0
    } catch(err) { console.log('FEATURE_MESSAGE_MAXIMUM_NUMBER_OF_AVAILABLE_USER_TEMPLATES decode failed', err) }
    console.log(this.props.settings?.list != null, this.props.settings?.list?.filter(el => el?.detail === K.MESSAGE_TEMPLATE_SETTING_TYPE)?.length, user_templates_limit)
    if (this.props.settings?.list != null && this.props.settings?.list?.filter(el => el?.detail === K.MESSAGE_TEMPLATE_SETTING_TYPE)?.length < user_templates_limit) {
      return true
    }
    return false
  }

  handleChangeTitleUserTemplate(event) {
    this.setState({titleUserTemplate: event.target.value});
  }

  handleChangeСheckedSave(event) {
    this.setState({ checkedSave: event.target.checked })
  }

  handleCreateUserTemplate = (event) => {
    
    const { t } = this.props

    this.setState({ selectTitleUserTemplate: event.label })
    const settingsList = this.props.settings?.list;
    const settings = settingsList.map(elem =>elem.label);
    // const id = settingsList.map(elem =>elem.id);
    // console.log(event.label);
    if (settings.includes(event.label) === false) {
      if (!this.validateLimitUserTemplates()) {
        this.openManyUserTemplates()
        return
      }
      if (this.props.posts.length > 1) {
        alert( t("rich_message_text_1") );
      } else if (this.props.posts.length === 0) {
        alert( t("rich_message_text_2") )
      } else {
        const { account } = this.props
        let title = event.label
        let detail = K.MESSAGE_TEMPLATE_SETTING_TYPE 
        let content = JSON.stringify(this.props.posts)
        this.props.handleSettingsCreate(account.owner, account.user, title, detail, content)
        this.setState({ checkedSave: true})
      }
    } else {
      event.content.map(post => {
        this.props.createPost(post)
        return (post)
      })
    }
  }
  openManyUserTemplates = () => { this.setState({ showManyUserTemplates: true }) }
  closeManyUserTemplates = () => this.setState({ showManyUserTemplates: false });


  onCreateMessage = (e) => {
    
    const { t } = this.props

    const settingsList = this.props.settings?.list;
    // const settings = settingsList.map(elem =>elem.label);
    const id = settingsList.map(elem =>elem.id);
    if (this.state.checkedSave === true) {
      if (this.props.posts.length === 0) {
        alert( 'Choose a template' )
      }
       else if (this.state.selectTitleUserTemplate !== '') {
        const { account } = this.props
        let title = this.state.selectTitleUserTemplate
        let detail = K.MESSAGE_TEMPLATE_SETTING_TYPE 
        let content = JSON.stringify(this.props.posts)
        let settingsId = id.toString()
        this.props.handleSettingsUpdate(account.owner, settingsId, title, detail, content)
        this.props.onMessageCreate()
        this.setState({ checkedSave: false})
        this.props.onHide()
      } else if (this.state.selectTitleUserTemplate === '') {
        alert( t("rich_message_text_3") )
      }
      else if (this.props.posts.length > 1) {
        alert( t("rich_message_text_4") );
      } else {
        const { account } = this.props
        let title = this.state.selectTitleUserTemplate
        let detail = K.MESSAGE_TEMPLATE_SETTING_TYPE 
        let content = JSON.stringify(this.props.posts)
        let settingsId = id.toString()
        this.props.handleSettingsUpdate(account.owner, settingsId, title, detail, content)
        this.props.onMessageCreate()
        this.setState({ checkedSave: false})
        this.props.onHide()
      }
    } else {
      if (this.props.posts.length === 0) {
        alert( t("rich_message_text_5") )
      } else {
        this.props.onMessageCreate()
        this.setState({ checkedSave: false})
        this.setState({names: [], isPreviewMode: false})
        this.props.onHide()
      }
    }
  }

  onShowPreview = () => { this.setState({ isPreviewMode: !this.state.isPreviewMode }) }
    render() {
      
    const { t } = this.props

      const messageTemplates = this.props.settings?.list.filter(el => el.detail === K.MESSAGE_TEMPLATE_SETTING_TYPE).map((el, index) => { 
        try {
          el.value = String(index)
          el.label = el.title
          el.content = JSON.parse(el.content)
        } catch (err) {}
        return el
       })
      const  { isPreviewMode } = this.state
      return (
        <Modal {...this.props} className="rrich-message">
          <div className="rrich-message__wrapper">
            <div className="rrich-message__header">
              <div className="rrich-message__close">
                <div className="css-fn5tqh">
                  <span className="css-j8fq0c">
                    <span className="Icon__IconWrapper-dyhwwi-0 jdkWJB" onClick={this.props.onHide}><svg width="24rem" height="24rem" viewBox="0 0 24 24" focusable="false" role="presentation"><path d="M12 10.586L6.707 5.293a1 1 0 0 0-1.414 1.414L10.586 12l-5.293 5.293a1 1 0 0 0 1.414 1.414L12 13.414l5.293 5.293a1 1 0 0 0 1.414-1.414L13.414 12l5.293-5.293a1 1 0 1 0-1.414-1.414L12 10.586z" fill="currentColor"></path></svg></span>
                  </span>
                </div>
              </div>
              <div className="rrich-message__header__preview">
                <span className={ `${isPreviewMode ? 'rrich-message__body__close' : 'rrich-message__header__preview__bold'}`}>Edit </span>
                <span className={ `${isPreviewMode ? 'rrich-message__header__preview__bold' : 'rrich-message__body__close'}`}>Preview </span>
                <span className="">{t("rich_message_text_6")}</span>
              </div>
            </div>
              <Signals
                category={this.props.category}
                categories={this.props.categories}
              />
            <div className="rrich-message__body d-flex justify-content-between">
              <div className="rrich-message__body">
              <div className="rrich-message__user-template" { ...this.props }>
              <div className="rrich-message__user-template__wrapper">
                <div className="rrich-message__user-template__wrapper__inputs">
                  <div className="rrich-message__user-template__select">
                  <CreatableSelect className="react-select" classNamePrefix="react-select"
                      options={messageTemplates}
                      onChange={(e) => this.handleCreateUserTemplate(e)}
                      />
                    {/* <Select className="react-select" classNamePrefix="react-select"
                      options={messageTemplates}
                      onChange={(e) => this.handleCreateUserTemplate(e)} /> */}
                    </div>
                  {/* <div className="rrich-message__user-template__wrapper__bottom">
                    <input 
                      placeholder="Input name user template"
                      type="text"
                      value={this.state.titleUserTemplate} 
                      onChange={this.handleChangeTitleUserTemplate}
                      maxLength="32"
                      className="rrich-message__user-template__wrapper__input"
                      />
                  </div> */}
                </div>
                  <Form.Check 
                    custom 
                    type="checkbox" 
                    id="save_on_send"
                    label={t("rich_message_text_7")}
                    onClick={this.handleChangeСheckedSave}
                    checked={this.state.checkedSave} 
                  />
                  </div>
                </div>
    
                  <div>
                    <div className={ `${isPreviewMode ? 'rrich-message__body__close' : 'rrich-message__body__show'}`}>
                      <Message />
                    </div>
                    <div className={ `rrich-message__message ${isPreviewMode ? 'rrich-message__body__show p-3' : 'rrich-message__body__close'}`}>
                      <PreviewContainer />
                    </div>
                  </div>
                <ComposingMode onShowPreview={this.onShowPreview}/>
              </div>
              <div className="rrich-message__body">
                <SendTime />
                <div className="rrich-message__body d-flex justify-content-end">
                  <Button onClick={this.onCreateMessage.bind(this)}>{t("rich_message_text_8")}</Button>
                </div>
              </div>
            </div>
            <ManyUserTemplates
              show={ this.state.showManyUserTemplates }
              onHide = { this.closeManyUserTemplates.bind(this) } />
          </div>
        </Modal>
      );
    }
  }
  const mapStateToProps = store => {
    return {
      posts: store.posts.posts,
      account: store.user,
      settings: store.settings,
      category: store.configs.DEFAULT_MESSAGE_CATEGORY,
      categories: store.configs.list?.filter(el => el?.detail === K.DEFAULT_MESSAGE_CATEGORY_CONFIG_KEY),
      permissions: store.permissions
    }
  }

  const mapDispatchToProps = dispatch => {
    return {
      createPost: (post) => dispatch(createPost(post)),
      handleSettingsCreate: (owner, user, title, detail, content) => dispatch(handleSettingsCreate(owner, user, title, detail, content)),
      handleSettingsUpdate: (owner, settingsId, title, detail, content) => dispatch(handleSettingsUpdate(owner, settingsId, title, detail, content))
    }
  }
  
  export default withTranslation()(connect(
    mapStateToProps, 
    mapDispatchToProps
  )(RichMessage))
