import { roleProductCreate, getRoleProductRead, roleProductDelete, roleProductObserve }  from '../../services/api/roleproduct'

export const ROLE_PRODUCT_CREATE_REQUEST = 'ROLE_PRODUCT_CREATE_REQUEST'
export const ROLE_PRODUCT_CREATE_SUCCESS = 'ROLE_PRODUCT_CREATE_SUCCESS'
export const ROLE_PRODUCT_CREATE_FAIL = 'ROLE_PRODUCT_CREATE_FAIL'

export const ROLE_PRODUCT_READ_REQUEST = 'ROLE_PRODUCT_READ_REQUEST'
export const ROLE_PRODUCT_READ_SUCCESS = 'ROLE_PRODUCT_READ_SUCCESS'
export const ROLE_PRODUCT_READ_FAIL = 'ROLE_PRODUCT_READ_FAIL'

export const ROLE_PRODUCT_UPDATE_REQUEST = 'ROLE_PRODUCT_UPDATE_REQUEST'
export const ROLE_PRODUCT_UPDATE_SUCCESS = 'ROLE_PRODUCT_UPDATE_SUCCESS'
export const ROLE_PRODUCT_UPDATE_FAIL = 'ROLE_PRODUCT_UPDATE_FAIL'

export const ROLE_PRODUCT_DELETE_REQUEST = 'ROLE_PRODUCT_DELETE_REQUEST'
export const ROLE_PRODUCT_DELETE_SUCCESS = 'ROLE_PRODUCT_DELETE_SUCCESS'
export const ROLE_PRODUCT_DELETE_FAIL = 'ROLE_PRODUCT_DELETE_FAIL'

export function handleRoleProductCreate(role, product) {
  return dispatch => {
    dispatch({
      type: ROLE_PRODUCT_CREATE_REQUEST,
      payload: { role: role, product: product },
    })
    roleProductCreate(role, product)
    .then(data => {
      dispatch({
        type: ROLE_PRODUCT_CREATE_SUCCESS,
        payload: data,        
      })
    })
    .catch(err => {
      dispatch({
        type: ROLE_PRODUCT_CREATE_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function handleRoleProductRead(roleId) {
  return dispatch => {
    dispatch({
      type: ROLE_PRODUCT_READ_REQUEST,
      payload: {roleId : roleId},
    })
    getRoleProductRead(roleId)
    .then(data => {
      dispatch({
        type: ROLE_PRODUCT_READ_SUCCESS,
        payload: data,        
      })
    })
    .catch(err => {      
      dispatch({
        type: ROLE_PRODUCT_READ_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function handleRoleProductDelete(roleProductId) {
  return dispatch => {
    dispatch({
      type: ROLE_PRODUCT_DELETE_REQUEST,
      payload: { roleProductId: roleProductId },
    })
    roleProductDelete(roleProductId)
    .then(data => {
      dispatch({
        type: ROLE_PRODUCT_DELETE_SUCCESS,
        payload: data,
      })
    })
    .catch(err => {
      dispatch({
        type: ROLE_PRODUCT_DELETE_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function observeRoleProduct(roleId) {
  return dispatch => {
    roleProductObserve(roleId)
  }
}

export function handleRoleProductObserver(data) {
  return dispatch => {
    switch (data.opType) {
      case 'INSERT':
        dispatch({
          type: ROLE_PRODUCT_CREATE_SUCCESS,
          payload: data.element,
        })   
        break;
      case 'UPDATE':
        dispatch({
          type: ROLE_PRODUCT_UPDATE_SUCCESS,
          payload: data.element,
        })   
        break;
      case 'DELETE':
        dispatch({
          type: ROLE_PRODUCT_DELETE_SUCCESS,
          payload: data.element,
        })   
        break;      
      default:
        break;
    }    
  }
}

export function handleRoleProductObserverAPI(event, data) {
  return dispatch => {
    switch (event) {
      case 'INSERT':
        console.log(event, data)
        dispatch({
          type: ROLE_PRODUCT_CREATE_SUCCESS,
          payload: data,
        })   
        break;
      case 'UPDATE':
        console.log(event, data)
        dispatch({
          type: ROLE_PRODUCT_UPDATE_SUCCESS,
          payload: data,
        })   
        break;
      case 'DELETE':
        console.log(event, data)
        dispatch({
          type: ROLE_PRODUCT_DELETE_SUCCESS,
          payload: data,
        })   
        break;      
      default:
        break;
    }    
  }
}