import React, { Component } from "react"
import { Modal } from "react-bootstrap"
// import { isBlankOrNull } from "../../../utils/Helpres"
import validator from "validator"
import { Hub } from "aws-amplify"
import { withTranslation } from 'react-i18next'

class SetRealNameModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      isShowError: false
    }
  }

  handleChangeUserName(event) {
    const { account } = this.props.data
    const isShowError = !validator.isLength(event.target.value, { min: 5 }) || account?.user?.name === event.target.value
    this.setState({ name: event.target.value, isShowError: isShowError })

  }

  onSaveForm() {
    const { name, isShowError } = this.state
    const isActiveGuide = localStorage.getItem("activeGuide") === 'true'
    if (isShowError) { return }
    if (isActiveGuide) {
      Hub.dispatch('IS_SAVE_REAL_NAME');
    }
    const { account, updateName } = this.props.data
    updateName(account, name)
    this.toggleModal()
  }

  toggleModal = () => {
    const isActiveGuide = localStorage.getItem("activeGuide") === 'true'
    if (isActiveGuide) {
      Hub.dispatch('IS_SAVE_REAL_NAME');
    }
    this.props.onHide()
  }

  render() {
    const { account } = this.props.data
    const {t} = this.props

    return (
      <Modal
        {...this.props}
        onHide={this.toggleModal}
        centered
        aria-labelledby="contained-modal-title-vcenter"
        className="n-modal__modal"
      >
        <div className="n-modal profile-settings-realname-save-guide">
          <div className="n-modal__wrapper">
            <div className="n-modal__close" onClick={this.toggleModal}>
              <svg
                width="20rem"
                height="20rem"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 2.01429L17.9857 0L10 7.98571L2.01429 0L0 2.01429L7.98571 10L0 17.9857L2.01429 20L10 12.0143L17.9857 20L20 17.9857L12.0143 10L20 2.01429Z"
                />
              </svg>
            </div>
            <div className="n-modal__header">
                <div className="n-modal__header__icon">
                <svg width="38rem" height="38rem" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M25.9001 15.3901L26.6301 14.6601C26.9701 14.3201 26.9701 13.7501 26.6301 13.4301L24.5801 11.3801C24.4001 11.2001 24.1901 11.1301 23.9601 11.1301M23.9601 11.1201C23.7401 11.1201 23.5101 11.2101 23.3501 11.3701L20.8401 13.9101M18.7401 15.9801L11.1201 23.6001V26.8801H14.4001L24.0901 17.1901L20.8101 13.9101" stroke="#203B60" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M19 37C28.9411 37 37 28.9411 37 19C37 9.05887 28.9411 1 19 1C9.05887 1 1 9.05887 1 19C1 28.9411 9.05887 37 19 37Z" stroke="#203B60" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
                </svg>
                </div>
                <div className="n-modal__header__title">
                  {t('profile_modal_realname_title')}
                </div>
                <div className="n-modal__line" />
                <div className="n-modal__content n-modal__content--flex">
                  <div className="n-modal__content__title">
                    {t("profile_modal_realname_text1")}
                  </div>
                  <div className="n-modal__content__title-sub">
                    {t('profile_modal_realname_text2')}
                  </div>
                  <div
                    className={
                      this.state.isShowError
                        ? "n-input error"
                        : "n-input"
                    }
                  >
                    <input
                      defaultValue={account?.user?.name}
                      type="text"
                      maxLength={20}
                      placeholder="Type in new user name"
                      onChange={this.handleChangeUserName.bind(this)}
                    />
                    <div className="n-input__error-message">
                      {!validator.isLength(this.state.name, { min: 5 }) ? t('profile_modal_realname_text3') : t('profile_modal_realname_text4')}
                    </div>
                  </div>
                </div>
                <div className="n-modal__btns">
                  <div className="button-lg" onClick={this.onSaveForm.bind(this)}>{t('profile_modal_realname_text5')}</div>
                </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

export default withTranslation()(SetRealNameModal)
