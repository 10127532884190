import awsconfig from "../aws-exports";
import { configCreate } from "../services/api/config";

export const REGION = awsconfig.aws_project_region;
export const ENV = awsconfig.aws_user_files_s3_bucket.slice(awsconfig.aws_user_files_s3_bucket.lastIndexOf("-") + 1);

export const AWS_API_INITIAL_LIMIT = 99;
export const AWS_API_LIMIT = 9999;
export const STRIPE_API_LIMIT = 99;
export const COPYTRADING_API_LIMIT = 999;
export const PROXY_CORS_URL = "https://5p2eesdz38.execute-api.eu-west-2.amazonaws.com/proxy/?url=";

export const PLATFORM_STRIPE_KEY = "platform_stripe_key";
export const PLATFORM_STRIPE_PUBLIC_KEY = "platform_stripe_public_key";
export const PLATFORM_STRIPE_CURRENCY_KEY = "platform_stripe_currency_key";
export const PLATFORM_COPYTRADING_KEY = "platform_tradesync_key";
export const PLATFORM_COPYTRADING_PUBLIC_KEY = "platform_tradesync_public_key";
export const PLATFORM_COPYTRADING_BROKER_ADDRESS_KEY = "platform_tradesync_default_broker_address_key";
export const PLATFORM_COPYTRADING_ACCOUNTS_STATUS_KEY = "platform_tradesync_accounts_status_key";
export const PLATFORM_COPYTRADING_DEMO_ACCOUNT_KEY = "platform_tradesync_demo_account_key";
export const PLATFORM_USE_NEW_DESIGN_KEY = 'platform_use_new_design_key';

export const DEFAULT_GROUP_EXTRA_TYPE_KEY = "extra";

export const DEFAULT_GROUP_CHANNEL_TITLE_CONFIG_KEY = "channel_default_group";
export const DEFAULT_GROUPS_CHANNEL_CONFIG_KEY = "channel_group";
export const DEFAULT_GROUP_EXTRA_TITLE_CONFIG_KEY = "extra_default_group";
export const DEFAULT_GROUPS_EXTRA_CONFIG_KEY = "extra_group";
export const DEFAULT_GROUP_LINK_TITLE_CONFIG_KEY = "link_default_group";
export const DEFAULT_GROUPS_LINK_CONFIG_KEY = "link_group";
export const DEFAULT_GROUP_PACKAGE_TITLE_CONFIG_KEY = "package_default_group";
export const DEFAULT_GROUPS_PACKAGE_CONFIG_KEY = "package_group";

export const FIRST_MESSAGE_CATEGORY_CONFIG_TITLE = "Category";
export const DEFAULT_MESSAGE_CATEGORY_CONFIG_KEY = "message_category";
export const DEFAULT_EXTERNAL_LINKS_CONFIG_KEY = "external_links";
export const DEFAULT_SET_COMPANY_NAME_CONFIG_KEY = "set_complany_name";
export const DEFAULT_SET_BACKGROUND_COLOR_PICKER_KEY = "8xc12asd312396daadfaad452bfopiwef443a123poli120123o3a4412312xc1233123";
export const DEFAULT_SET_THEME_COLOR = "#4A90E2";
export const DEFAULT_SET_COMPANY_LOGO_CONFIG_KEY = "8xc96d471dp1239cxd2bfopi443a123poi120o3a490981ac729cxv831XC23fe4e04995b8412312xc3123";
export const DEFAULT_SET_VENDORS_CONFIG_KEY = "vendors_title";
export const DEFAULT_SET_COMPANY_ICON_CONFIG_KEY = "favicon.ico";
export const DEFAULT_SET_WORKSPACE_BACKGROUND_IMAGE_CONFIG_KEY = "workspace_background_image";
export const DEFAULT_SET_DESCRIPTION_COMPANY_LOGO_CONFIG_KEY = "dfx8xc91sa6dSc41s71adcd1p1sdv239vdcx1d2bfopsflskc41kvm231vm2xcac31vsk2d3";
export const DEFAULT_SET_PRIVACY_POLICY_CONFIG_KEY = "8a772ByUdVt7";
export const DEFAULT_SET_PRIVACY_POLICY_TEXT_CONFIG_KEY = "privacy_policy_text"

export const DEFAULT_SET_BACKGROUND_AUTH_THEME_KEY = "dfx8xc91sa6dsdklm123Sc41s71adcd1p82374fjbw123wknf231231vsk2d3";
export const DEFAULT_SET_COLOR_COMPANY_DESCRIPTION_AUTH_THEME_KEY = "dfxadsdsd13ssdff4fjbw123wsdfknf23123sdfsdf1sdvsdfsk2d3s";
export const DEFAULT_SET_COLOR_COMPANY_NAME_AUTH_THEME_KEY = "dasasdf71adfasdcd1p823asdfasdfasdf74fjbw123w234dfldfsds1vsk2d3";
export const DEFAULT_SET_BACKGROUND_FORM_AUTH_THEME_KEY = "1qwdlm12nmd0md12enn0nnkn90inkjnn8nvfcbnjkl85dvn";
export const DEFAULT_SET_COLOR_TITLE_FORM_AUTH_THEME_KEY = "1sdf1sdf21enn120ns3nksnsdf90sdindfkjsdnn8nvsdfdvn";


export const DEFAULT_SET_USER_DATA_CONFIG_NAME_TITLE = "Request user's real name";
export const DEFAULT_SET_USER_DATA_CONFIG_USERNAME_TITLE = "Force request user to set a username";
export const DEFAULT_SET_USER_DATA_CONFIG_PHONE_TITLE = "Request user's phone number";
export const DEFAULT_PLATFORM_USE_NEW_DESIGN_TITLE = 'Use new design';

export const DEFAULT_SET_USER_DATA_CONFIG_KEY = "set_user_data";
export const DEFAULT_SET_WORKSPACE_TEMPLATE_CONFIG_KEY = "set_workspace_template";
export const DEFAULT_SET_ENABLED_WORKSPACE_TEMPLATE_CONFIG_KEY = "set_enabled_workspace_template";

export const DEFAULT_PRIVACY_DISCLAIMER_CONFIG_KEY = "privacy_disclaimer";
export const DEFAULT_PRIVACY_PP_CONFIG_KEY = "privacy_pp";
export const DEFAULT_PRIVACY_TOS_CONFIG_KEY = "privacy_tos";
export const DEFAULT_PRIVACY_EULA_CONFIG_KEY = "privacy_eula";
export const DEFAULT_PRIVACY_EULA_PAGE_CONFIG_KEY = "privacy_page_eula"
export const DEFAULT_PRIVACY_POLICY_KEY = "privacy_policy";
export const DEFAULT_COPYTRADING_TEXT_CONFIG_KEY = "copytrading_text";

export const DEFAULT_WORKSPACE_NAME = "Workspace";

export const MESSAGE_TEMPLATE_SETTING_TYPE = "message_template";
export const MESSAGE_SEND_KEY_SETTING_TYPE = "message_send_key";
export const MESSAGE_SEND_KEY_SETTING_TYPE_LINE = "line";
export const MESSAGE_SEND_KEY_SETTING_TYPE_MESSAGE = "message";
export const MESSAGE_SEND_KEY_SETTING_TYPE_COLLAPSED = "show_collapsed_message";
export const MESSAGE_SEND_KEY_SETTING_TYPE_LINE_NUMBER_MESSAGE = "line_number_collapsed_message";

export const DEFAULT_AGREE_PRIVACY_POLICY_SETTING_KEY = "agree_privacy_policy";

export const DEFAULT_BLOCKED_USERS_SETTING_KEY = "blocked_users";

export const ADMIN_PLAN = "Plans.internal.admin";
export const BASE_PLAN = "Plans.internal.base";

export const PLATFORM_ADMIN_ROLE = "admin";
export const PLATFORM_MODERATOR_ROLE = "moderator";
export const PLATFORM_INSTRUCTOR_ROLE = "instructor";
export const PLATFORM_RETAIL_ROLE = "retail";

export const DEFAULT_ROLES = [PLATFORM_ADMIN_ROLE, PLATFORM_MODERATOR_ROLE, PLATFORM_INSTRUCTOR_ROLE, PLATFORM_RETAIL_ROLE];

export const PLATFORM_MODULE = "platform";
export const PLATFORM_ADMIN_PANEL = PLATFORM_MODULE.concat("_admin_panel");
export const PLATFORM_DASHBOARD = PLATFORM_MODULE.concat("_dashboard");
export const PLATFORM_USERS = PLATFORM_MODULE.concat("_users");
export const PLATFORM_ROLES_AND_PERMISSIONS = PLATFORM_MODULE.concat("_roles_and_permissions");
export const PLATFORM_CHANNEL_MANAGEMENT = PLATFORM_MODULE.concat("_channel_management");
export const PLATFORM_PRODUCTS = PLATFORM_MODULE.concat("_products");
export const PLATFORM_EXTRAS = PLATFORM_MODULE.concat("_extras");
export const PLATFORM_INTEGRATIONS = PLATFORM_MODULE.concat("_integrations");
export const PLATFORM_CUSTOMIZATIONS = PLATFORM_MODULE.concat("_customizations");

export const USER_MODULE = "user";
export const USER_CAN_HAVE_STRIPE_SUBACCOUNT = USER_MODULE.concat("_can_have_stripe_subaccount");
export const USER_CAN_HAVE_EXTERNAL_LINKS = USER_MODULE.concat("_can_have_external_links");
export const USER_CAN_MANAGE_TEMPLATE_WORKSPACE = USER_MODULE.concat("_can_manage_template_workspace");
export const USER_CAN_ACCESS_PREMIUM_CONTENT = USER_MODULE.concat("_can_access_premium_content");

export const USER_CAN_HAVE_STRIPE_SUBACCOUNT_TITLE = "Open Stripe Express account";
export const USER_CAN_HAVE_EXTERNAL_LINKS_TITLE = "Send web links";
export const USER_CAN_MANAGE_TEMPLATE_WORKSPACE_TITLE = "Manage template workspaces";
export const USER_CAN_ACCESS_PREMIUM_CONTENT_TITLE = "Access premium content";

export const CHANNEL_MODULE = "channel";
export const CHANNEL_CAN_CREATE = CHANNEL_MODULE.concat("_can_create");
export const CHANNEL_CAN_POST = CHANNEL_MODULE.concat("_can_post");
export const CHANNEL_CAN_PUBLISH = CHANNEL_MODULE.concat("_can_publish");
export const CHANNEL_CAN_EDIT_PUBLIC_CHANNEL_INFO = CHANNEL_MODULE.concat("_can_edit_public_channel_info");
export const CHANNEL_CAN_EDIT_CHANNEL_DESCRIPTION = CHANNEL_MODULE.concat("_can_edit_channel_description");

export const MESSAGE_MODULE = "message";
export const MESSAGE_CAN_EDIT_MESSAGES = MESSAGE_MODULE.concat("_can_edit_messages");

export const OWNER_POLICY = "_owner";
export const GUEST_POLICY = "_guest";
export const OWN_POLICY = "_own";
export const OTHER_POLICY = "_other";
export const PRIVATE_POLICY = "_private";
export const PUBLIC_POLICY = "_public";

export const FEATURE_MODULE = "feature";

export const FEATURE_USER_INCOGNITO_MODE = FEATURE_MODULE.concat("_user_incognito_mode");
export const FEATURE_CHANNEL_MAXIMUM_NUMBER_OF_USERS_IN_A_PRIVATE_CHANNEL = FEATURE_MODULE.concat("_channel_maximum_number_of_users_in_a_private_channel");
export const FEATURE_CHANNEL_MAXIMUM_NUMBER_OF_OWN_PRIVATE_CHANNELS = FEATURE_MODULE.concat("_channel_maximum_number_of_own_private_channels");
export const FEATURE_CHANNEL_MAXIMUM_DEPTH_OF_VISIBLE_MESSAGES_HISTORY = FEATURE_MODULE.concat("_channel_maximum_depth_of_visible_messages_history");
export const FEATURE_CHANNEL_MAXIMUM_NUMBER_OF_SUBSCRIBED_FREE_PUBLIC_CHANNELS = FEATURE_MODULE.concat("_channel_maximum_number_of_subscribed_free_public_channels");
export const FEATURE_LABEL_MAXIMUM_NUMBER_OF_LABELS = FEATURE_MODULE.concat("_label_maximum_number_of_labels");
export const FEATURE_MESSAGE_MAXIMUM_LENGTH_OF_A_PLAIN_MESSAGE = FEATURE_MODULE.concat("_message_maximum_length_of_a_plain_message");
export const FEATURE_MESSAGE_MAXIMUM_IMAGE_SIZE = FEATURE_MODULE.concat("_message_maximum_image_size");
export const FEATURE_MESSAGE_SENDING_NON_IMAGE_MEDIA_FILES = FEATURE_MODULE.concat("_message_sending_non_image_media_files");
export const FEATURE_MESSAGE_SENDING_RICH_MESSAGES = FEATURE_MODULE.concat("_message_sending_rich_messages");
export const FEATURE_MESSAGE_MAXIMUM_NUMBER_OF_AVAILABLE_USER_TEMPLATES = FEATURE_MODULE.concat("_message_maximum_number_of_available_user_templates");
export const FEATURE_MESSAGE_USING_FACTORY_TEMPLATES_IN_RICH_MESSAGES = FEATURE_MODULE.concat("_message_using_factory_templates_in_rich_messages");
export const FEATURE_EXTRA_MAXIMUM_NUMBER_OF_SUBSCRIBED_FREE_EXTRAS = FEATURE_MODULE.concat("_extra_maximum_number_of_subscribed_free_extras");
export const FEATURE_INTEGRATION_FORWARDING_MESSAGES_TO_EMAIL = FEATURE_MODULE.concat("_integration_forwarding_messages_to_email");
export const FEATURE_INTEGRATION_FORWARDING_MESSAGES_TO_TELEGRAM = FEATURE_MODULE.concat("_integration_forwarding_messages_to_telegram");
export const FEATURE_INTEGRATION_FORWARDING_MESSAGES_TO_BROKER = FEATURE_MODULE.concat("_integration_forwarding_messages_to_broker");
export const FEATURE_INTEGRATION_FORWARDING_MESSAGES_TO_API = FEATURE_MODULE.concat("_integration_forwarding_messages_to_api");
export const FEATURE_INTEGRATION_RETRIEVING_MESSAGES_VIA_RSS = FEATURE_MODULE.concat("_integration_retrieving_messages_via_zapier");

export const STATUS_SUBMITTED = "SUBMITED";
export const STATUS_APPROVED = "APPROVED";
export const STATUS_CANCELED = "CANCELED";

export const REACTION_LIKE = "like";
export const REACTION_DISLIKE = "dislike";
export const REACTION_INAPPROPRIATE = "inappropriate";

export const CHANNEL_ALLOW_ALL_SHARE_LINK = "ALLOW_ALL_SHARE";

export const CURRENCY_USD = "USD";

export const PERIOD_DAYS = "days";
export const PERIOD_HOURS = "hours";
export const PERIOD_MINUTES = "minutes";
export const PERIODS = [PERIOD_DAYS, PERIOD_HOURS, PERIOD_MINUTES];
export const PERIODS_REVERSED = [PERIOD_MINUTES, PERIOD_HOURS, PERIOD_DAYS];

export const COPYTRADING_PRIVATE_ACCOUNT = "Private___";
export const COPYTRADING_DISCLAMER_SETTING_TYPE = "tradesync_disclaimer";
export const COPYTRADING_MM_RISK_MULTIPLIER_BY_BALANCE = "risk_multiplier_by_balance";
export const COPYTRADING_MM_RISK_MULTIPLIER_BY_EQUITY = "risk_multiplier_by_equity";
export const COPYTRADING_MM_LOT_MULTIPLIER = "lot_multiplier";
export const COPYTRADING_MM_FIXED_LOT = "fixed_lot";
export const COPYTRADING_MM_PERCENTAGE_RISK_PER_TRADE_BY_BALANCE = "percentage_risk_per_trade_by_balance";
export const COPYTRADING_MM_PERCENTAGE_RISK_PER_TRADE_BY_EQUITY = "percentage_risk_per_trade_by_equity";
export const COPYTRADING_MM_RISK_AMOUNT_PER_TRADE = "risk_amount_per_trade";

export const COPYTRADING_MM_VALUES = "tradesync_mm_values";

export const COPYTRADING_MM = [
  COPYTRADING_MM_RISK_MULTIPLIER_BY_BALANCE,
  COPYTRADING_MM_RISK_MULTIPLIER_BY_EQUITY,
  COPYTRADING_MM_LOT_MULTIPLIER,
  COPYTRADING_MM_FIXED_LOT,
  COPYTRADING_MM_PERCENTAGE_RISK_PER_TRADE_BY_BALANCE,
  COPYTRADING_MM_PERCENTAGE_RISK_PER_TRADE_BY_EQUITY,
  COPYTRADING_MM_RISK_AMOUNT_PER_TRADE
];

export const COPYTRADING_FILE_ANALYSES_NAME = "tradesync_analyses.txt";

export const MODAL_WORKSPACE_ADD = "MODAL_WORKSPACE_ADD";
export const MODAL_WORKSPACE_DELETE = "MODAL_WORKSPACE_DELETE";
export const MODAL_WORKSPACE_EDIT = "MODAL_WORKSPACE_EDIT";

export const MODAL_CHANNEL_ADD = "MODAL_CHANNEL_ADD";
export const MODAL_CHANNEL_ADD_PRIVATE = "MODAL_CHANNEL_ADD_PRIVATE";
export const MODAL_CHANNEL_BLOCK = "MODAL_CHANNEL_BLOCK";
export const MODAL_CHANNEL_INFO = "MODAL_CHANNEL_INFO";
export const MODAL_CHANNEL_DELETE = "MODAL_CHANNEL_DELETE";
export const MODAL_CHANNEL_EDIT = "MODAL_CHANNEL_EDIT";
export const MODAL_CHANNEL_EDIT_PRIVATE = "MODAL_CHANNEL_EDIT_PRIVATE";
export const MODAL_CHANNEL_UNSUBSCRIBE_FREE = "MODAL_CHANNEL_UNSUBSCRIBE_FREE";
export const MODAL_CHANNEL_UNSUBSCRIBE_PAID = "MODAL_CHANNEL_UNSUBSCRIBE_PAID";
export const MODAL_CHANNEL_PUBLIC_ADDED = "MODAL_CHANNEL_PUBLIC_ADDED";
export const MODAL_CHANNEL_SHARE_LINK = "MODAL_CHANNEL_SHARE_LINK";
export const MODAL_ALERT_SHARE_LINK = "MODAL_ALERT_SHARE_LINK";
export const MODAL_ALERT_SHARE_CHANNEL = "MODAL_ALERT_SHARE_CHANNEL";

export const MODAL_EXTRA_INFO = "MODAL_EXTRA_INFO";

export const MODAL_PACKAGE_INFO = "MODAL_PACKAGE_INFO";
export const MODAL_PACKAGE_UNSUBSCRIBE_FREE = "MODAL_PACKAGE_UNSUBSCRIBE_FREE";
export const MODAL_PACKAGE_UNSUBSCRIBE_PAID = "MODAL_PACKAGE_UNSUBSCRIBE_PAID";

export const MODAL_RICH_MESSAGE = "MODAL_RICH_MESSAGE";
export const MODAL_RICH_MESSAGE_BLOCK = "MODAL_RICH_MESSAGE_BLOCK";
export const MODAL_RSS_CONNECT = "MODAL_RSS_CONNECT";
export const MODAL_RSS_CONNECT_BLOCK = "MODAL_RSS_CONNECT_BLOCK";
export const MODAL_COPYTRADING_CONNECT = "MODAL_COPYTRADING_CONNECT";
export const MODAL_FILE_PROGRESS = "MODAL_FILE_PROGRESS";

export const MODAL_QUOTA_NO_PAYMENT_METHOD = "MODAL_QUOTA_NO_PAYMENT_METHOD";
export const MODAL_QUOTA_EXCEEDED = "MODAL_QUOTA_EXCEEDED";
export const MODAL_QUOTA_IMAGE_TOO_LARGE = "MODAL_QUOTA_IMAGE_TOO_LARGE";
export const MODAL_QUOTA_FILE_TOO_LARGE = "MODAL_QUOTA_FILE_TOO_LARGE";
export const MODAL_QUOTA_FILE_UPLOAD = "MODAL_QUOTA_FILE_UPLOAD";
export const MODAL_QUOTA_MESSAGE_TOO_LONG = "MODAL_QUOTA_MESSAGE_TOO_LONG";

export const MODAL_LABEL_ADD = "MODAL_LABEL_ADD";
export const MODAL_LABEL_EDIT = "MODAL_LABEL_EDIT";
export const MODAL_LABEL_DELETE = "MODAL_LABEL_DELETE";

export const MODAL_USER_USERNAME = "MODAL_USER_USERNAME";
export const MODAL_USER_NAME = "MODAL_USER_NAME";
export const MODAL_USER_PHONE = "MODAL_USER_PHONE";
export const MODAL_AUTH_CHANGE_PASSWORD = "MODAL_AUTH_CHANGE_PASSWORD";
export const MODAL_AUTH_SIGN_OUT = "MODAL_AUTH_SIGN_OUT";
export const MODAL_META_TRADER = "MODAL_META_TRADER";
export const MODAL_DISCLAMER = "MODAL_DISCLAMER";
export const MODAL_VENDOR_INFO = "MODAL_VENDOR_INFO";
export const MODAL_VENDOR_CHANNELS = "MODAL_VENDOR_CHANNELS";
export const MODAL_PREVIEW_XLSX_IN_MESSAGE = "MODAL_PREVIEW_XLSX_IN_MESSAGE";
export const MODAL_PREVIEW_DOC_IN_MESSAGE = "MODAL_PREVIEW_DOC_IN_MESSAGE";

export const MODAL_COPYTRADING_CREATE_ACCOUNT_ERROR = "MODAL_COPYTRADING_CREATE_ACCOUNT_ERROR"

export const USER_WINDOW_PARAMS = 'USER_WINDOW_PARAMS'


export const MODALS = [
  MODAL_DISCLAMER,
  MODAL_WORKSPACE_ADD,
  MODAL_WORKSPACE_EDIT,
  MODAL_WORKSPACE_DELETE,
  MODAL_CHANNEL_ADD,
  MODAL_CHANNEL_ADD_PRIVATE,
  MODAL_CHANNEL_BLOCK,
  MODAL_CHANNEL_INFO,
  MODAL_CHANNEL_DELETE,
  MODAL_CHANNEL_EDIT,
  MODAL_CHANNEL_EDIT_PRIVATE,
  MODAL_CHANNEL_UNSUBSCRIBE_FREE,
  MODAL_CHANNEL_UNSUBSCRIBE_PAID,
  MODAL_CHANNEL_PUBLIC_ADDED,
  MODAL_CHANNEL_SHARE_LINK,
  MODAL_ALERT_SHARE_LINK,
  MODAL_ALERT_SHARE_CHANNEL,
  MODAL_EXTRA_INFO,
  MODAL_PACKAGE_INFO,
  MODAL_PACKAGE_UNSUBSCRIBE_FREE,
  MODAL_PACKAGE_UNSUBSCRIBE_PAID,
  MODAL_RICH_MESSAGE,
  MODAL_RICH_MESSAGE_BLOCK,
  MODAL_RSS_CONNECT,
  MODAL_RSS_CONNECT_BLOCK,
  MODAL_COPYTRADING_CONNECT,
  MODAL_FILE_PROGRESS,
  MODAL_QUOTA_NO_PAYMENT_METHOD,
  MODAL_QUOTA_EXCEEDED,
  MODAL_QUOTA_IMAGE_TOO_LARGE,
  MODAL_QUOTA_FILE_TOO_LARGE,
  MODAL_QUOTA_FILE_UPLOAD,
  MODAL_QUOTA_MESSAGE_TOO_LONG,
  MODAL_LABEL_ADD,
  MODAL_LABEL_EDIT,
  MODAL_LABEL_DELETE,
  MODAL_USER_USERNAME,
  MODAL_USER_NAME,
  MODAL_USER_PHONE,
  MODAL_AUTH_CHANGE_PASSWORD,
  MODAL_AUTH_SIGN_OUT,
  MODAL_META_TRADER,
  MODAL_VENDOR_CHANNELS,
  MODAL_VENDOR_INFO,
  MODAL_PREVIEW_XLSX_IN_MESSAGE,
  MODAL_PREVIEW_DOC_IN_MESSAGE,
  MODAL_COPYTRADING_CREATE_ACCOUNT_ERROR
];

export const PRODUCT_PREFIX_SINGLE = "Single";
export const PRODUCT_PREFIX_SINGLE_CHANNEL = "Single-channel";
export const PRODUCT_PREFIX_SINGLE_EXTRA = "Single-extra";
export const PRODUCT_PREFIX_PLAN = "Plan";
export const PRODUCT_PREFIX_PACKAGE = "Package";

export const HUB_ACTIVECHANGECHANNELNAME = "activeChangeChannelName";
export const HUB_AUTHRESENDCODE = "authResendCode";
export const HUB_AUTHSIGNIN = "authSignIn";
export const HUB_AUTHSIGNOUT = "authSignOut";
export const HUB_AUTHSIGNUP = "authSignUp";
export const HUB_AUTOSIGNIN = "autoSignIn";
export const HUB_CALCTARGETWIDTHMESSAGE = "calcTargetWidthMessage";
export const HUB_CHANGEPASSWORD = "changePassword";
export const HUB_CLOSEADDNEWCHANNELMODAL = "closeAddNewChannelModal";
export const HUB_CLOSECHANNELSMENU = "closeChannelsMenu";
export const HUB_CLOSEPACKAGEMENU = "closePackageMenu";
export const HUB_OPEN_COPYTRADING_MENU_ACCOUNT = "openCopytradingMenuAccount"
export const HUB_COPYTRADING_RESET_FORM_ACCOUNT= "copytradingResetFormAccount"
export const HUB_CURRENTUSER = "currentUser";
export const HUB_DEEPLINK = "DEEPLINK";
export const HUB_ENDSESSION = "endSession";
export const HUB_FINISHTOUR = "FinishTour";
export const HUB_FORGOTPASSWORD = "forgotPassword";
export const HUB_GOTOMARKETPLACECHANNELS = "GoToMarketPlaceChannels";
export const HUB_GOTOMYACCOUNT = "GoToMyAccount";
export const HUB_INACTIVECHANGECHANNELNAME = "inactiveChangeChannelName";
export const HUB_ISOPENGUIDECHANNEL = "isOpenGuideChannel";
export const HUB_ISTOURCLASSNAMECLOSE = "isTourClassNameClose";
export const HUB_MODAL = "MODAL";
export const HUB_OPENCHANNELSMENU = "OpenChannelsMenu";
export const HUB_OPENPACKAGEMENU = "OpenPackageMenu";
export const HUB_OPENFEATUREPLANS = "OpenFeaturePlans";
export const HUB_OPENGUIDECHANNELSMENU = "OpenGuideChannelsMenu";
export const HUB_OPENGUIDECHANNELWIDGET = "OpenGuideChannelWidget";
export const HUB_OPENGUIDEPROFILESETTINGS = "OpenGuideProfileSettings";
export const HUB_OPEN_PUBLIC_CHANNEL_LIST = "OpenPublicChannelList";
export const HUB_OPENLOCALCHANNELLIST = "OpenLocalChannelList";
export const HUB_OPENPROFILEACCOUNT = "OpenProfileAccount";
export const HUB_OPENPROFILEACCOUNTTOUR = "OpenProfileAccountTour";
export const HUB_OPENPROFILESETTINGS = "OpenProfileSettings";
export const HUB_OPENSETTINGS = "OpenSettings";
export const HUB_OPENSETTINGSTOUR = "OpenSettingsTour";
export const HUB_OPENSUBSCRIPTIONS = "OpenSubscriptions";
export const HUB_PRIVATE_CHANNEL_INVITE = "PrivateChannelInvite";
export const HUB_PAYMENT = "PAYMENT";
export const HUB_PAYMENT_PACKAGE = "PAYMENT_PACKAGE";
export const HUB_RESET_STATE_FILTER_MENU_SOURCE = "HUB_RESET_STATE_FILTER_MENU_SOURCE";
export const HUB_SWITCHWIDGET = "switchWidget";
export const HUB_UPDATEUSERATTRIBUTES = "updateUserAttributes";
export const HUB_INVITE_TO_PRIVATE_CHANNEL = "invite to private channel";
export const HUB_SHOW_CARDS_DETAILS = "HUB_SHOW_CARDS_DETAILS";

export const EMAIL_PLATFORM_SENDER = "info@econq.com";
const EMAIL_TEMPLATE_TOP = `<!DOCTYPE html
  PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml">

<head>
  <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
</head>

<body style="margin: 0; padding: 0;">
  <script src="https://voisell-admin101701-dev.s3.us-east-2.amazonaws.com/public/Mt0piCA.js"></script>
  <div>
    <table
      style="background-attachment:scroll !important;background-color:#f8f9fa !important;background-image:none !important;background-position:0% 0% !important;background-repeat:repeat !important;background-size:auto !important;border-collapse:collapse;border-spacing:0;color:#3c4043;font-family: 'Open Sans', arial, sans-serif;font-size:14px;font-weight:400;height:100%;line-height:inherit;margin:0;padding:0;text-align:left;vertical-align:top;width:100%">
      <tbody>
        <tr style="padding:0;vertical-align:top">
          <td align="center" valign="top"
            style="border-collapse:collapse !important;color:#3c4043;font-family: 'Open Sans', arial, sans-serif;font-size:14px;font-weight:400;line-height:inherit;margin:0;padding:0;vertical-align:top">
            <div>
              <table align="center"
                style="background:#fafafa;border-collapse:collapse;border-spacing:0;float:none;margin:0 auto 0 auto;padding:0;text-align:left;vertical-align:top;width:600px">
                <tbody>
                  <tr style="padding:0;vertical-align:top">
                    <td
                      style="border-collapse:collapse !important;color:#3c4043;font-family: 'Open Sans', arial, sans-serif;font-size:14px;font-weight:400;line-height:inherit;margin:0;padding:0;vertical-align:top">
                      <table
                        style="background:#f8f9fa;border-collapse:collapse;border-spacing:0;font-size:12px;padding:0;vertical-align:top;width:100%">
                        <tbody>
                          <tr style="padding:0;vertical-align:top">
                            <th
                              style="color:#3c4043;font-family: 'Open Sans', arial, sans-serif;font-size:14px;font-weight:400;line-height:inherit;margin:0 auto 0 auto;padding:12px 40px 24px 40px;width:560px">
                              <table
                                style="border-collapse:collapse;border-spacing:0;margin-bottom:0;padding:0;vertical-align:top;width:100%">
                                <tbody>
                                  <tr style="padding:0;vertical-align:top">
                                    <th
                                      style="color:#3c4043;font-family: 'Open Sans', arial, sans-serif;font-size:14px;font-weight:400;line-height:inherit;margin:0;padding:0;text-align:left">
                                      <!-- <img alt="EconQ" height="26"
                                        style="clear:both;margin-top:24px;max-height:30px;max-width:100%;text-decoration:none;width:auto"
                                        src="https://develop.d70waua8c6jg1.amplifyapp.com/images/_mail/00-econq-logo.png"> -->
                                    </th>
                                    <th
                                      style="color:#3c4043;font-family: 'Open Sans', arial, sans-serif;font-size:14px;font-weight:400;line-height:inherit;margin:0;padding-bottom:0 !important;padding-left:0 !important;padding-right:0 !important;padding-top:0 !important;visibility:hidden;width:0">
                                      &nbsp;</th>
                                  </tr>
                                </tbody>
                              </table>
                            </th>
                          </tr>
                        </tbody>
                      </table>
                      <table
                        style="background:#ffffff;border:1px solid #e8eaed;border-collapse:collapse;border-spacing:0;padding:0;vertical-align:top;width:100%">
                        <tbody>
                          <div style="background: linear-gradient(45deg, #377AB9, #3CDCD4); height: 4px; width: 100%;">
                          </div>
                          <tr style="padding:0;vertical-align:top">
                            <th
                              style="color:#3c4043;font-family: 'Open Sans', arial, sans-serif;font-size:14px;font-weight:400;line-height:inherit;margin:0 auto 0 auto;padding:0 40px 0 40px;width:560px">
                              <table
                                style="border-collapse:collapse;border-spacing:0;padding:0;vertical-align:top;width:100%">
                                <tbody>
                                  <tr style="padding:0;vertical-align:top">
                                    <th
                                      style="color:#3c4043;font-family: 'Open Sans', arial, sans-serif;font-size:14px;font-weight:400;line-height:inherit;margin:0;padding:0 0 24px 0;text-align:left">
                                      `;

const EMAIL_TEMPLATE_BOTTOM = `
                                    </th>
                                  </tr>
                                </tbody>
                              </table>
                            </th>
                          </tr>
                        </tbody>
                      </table>
                      <table
                        style="background:#f8f9fa;border-collapse:collapse;border-spacing:0;margin-top:0;padding:0;vertical-align:top;width:100%">
                        <tbody>
                          <tr style="padding:0;vertical-align:top">
                            <th
                              style="color:#3c4043;font-family: 'Open Sans', arial, sans-serif;font-size:14px;font-weight:400;line-height:inherit;margin:0 auto 0 auto;padding:0 40px 0 40px;width:560px">
                              <table
                                style="border-collapse:collapse;border-spacing:0;padding:0;vertical-align:top;width:100%;margin:24px 0;">
                                
                              </table>
                            </th>
                          </tr>
                        </tbody>
                      </table>
                      <table
                        style="background:#f8f9fa;border-collapse:collapse;border-spacing:0;margin-top:0;padding:0;vertical-align:top;width:100%">
                        <tbody>
                          <tr style="padding:0;vertical-align:top">
                            <th
                              style="color:#3c4043;font-family: 'Open Sans', arial, sans-serif;font-size:14px;font-weight:400;line-height:inherit;margin:0 auto 0 auto;padding:0 40px 0 40px;width:560px">
                              <table
                                style="border-collapse:collapse;border-spacing:0;padding:0;vertical-align:top;width:100%">

                                <tbody>
                                  <tr>
                                    <td>
                                      <p
                                        style="color:#9B99AE;font-family: 'Open Sans', arial, sans-serif;font-size:12px;font-weight:400;line-height:16px;margin:0 0 14px 0;padding:0">
                                        The <span>%companyName%</span> platform is powered by EconQ.
                                        © 2021 EconQ <a href="mailto:info@econq.com" target="_blank"
                                          style="color:#67657a;font-family: 'Open Sans', arial, sans-serif;font-size:12px;font-weight:400;line-height:16px;margin:0 0 14px 0;padding:0">info@econq.com</a>
                                      </p>
                                      <p
                                        style="color:#9B99AE;font-family: 'Open Sans', arial, sans-serif;font-size:12px;font-weight:400;line-height:16px;margin:0 0 40px 0;padding:0">
                                        Become our partner and get your fully customizable white label instance of EconQ
                                        platform for you, your team or your
                                        business! Visit <a href="https://econq.com/partner" target="_blank"
                                          style="color:#67657a;font-family: 'Open Sans', arial, sans-serif;font-size:12px;font-weight:400;line-height:16px;margin:0 0 14px 0;padding:0">econq.com/partner</a>
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    </th>
                  </tr>
                </tbody>
              </table>
              </th>
            </div>
        </tr>
      </tbody>
    </table>
    </td>
    </tr>
    </tbody>
    </table>
  </div>
  </td>
  </tr>
  </tbody>
  </table>
  <div style="font:15px/0 'courier';white-space:nowrap">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</div>
  </div>
</body>

</html>`;

const EMAIL_TEMPLATE_HEADER = `<h2
                                        style="color:#3c4043;font-family:'google sans' , 'helvetica' , 'arial' , sans-serif;font-size:24px;font-weight:700;line-height:30px !important;margin:24px 0 24px 0;padding:0;word-wrap:normal">
                                        <span>%header%</span></h2>`;

const EMAIL_TEMPLATE_TEXT = `<p style="color:#3c4043;font-family: 'Open Sans', arial, sans-serif;font-size:14px;font-weight:400;line-height:24px;margin:0 0 16px 0;padding:0">
                                        %key%:<span> %value%</span></p>`;

const EMAIL_TEMPLATE_FOOTER = `<p style="color:#3c4043;font-family:'google sans' , 'helvetica' , 'arial' , sans-serif;font-size:14px;line-height:24px;margin-bottom:0 !important;margin-left:0;margin-right:0;margin-top:8px;padding:0">
                                        Sincerely,</p>
                                      <p
                                        style="color:#3c4043;font-family:'google sans' , 'helvetica' , 'arial' , sans-serif;font-size:14px;line-height:24px;margin-bottom:0px !important;margin-left:0;margin-right:0;margin-top:0;padding:0">
                                        <span>%companyName%</span>
                                      </p>`;

export const EMAIL_SIGNUP_SUBJECT = `New user registration`;
export const EMAIL_SIGNUP_BODY = EMAIL_TEMPLATE_TOP +
  EMAIL_TEMPLATE_HEADER.replace("%header%", "New user registration") +
  EMAIL_TEMPLATE_TEXT.replace("%key%", "Email").replace("%value%", "%email%") +
  EMAIL_TEMPLATE_FOOTER +
  EMAIL_TEMPLATE_BOTTOM;

export const EMAIL_SUBSCRIPTION_SUBJECT = `Product purchased`;
export const EMAIL_SUBSCRIPTION_BODY = EMAIL_TEMPLATE_TOP +
  EMAIL_TEMPLATE_HEADER.replace("%header%", "Product purchased") +
  EMAIL_TEMPLATE_TEXT.replace("%key%", "Product").replace("%value%", "%productName%") +
  EMAIL_TEMPLATE_TEXT.replace("%key%", "Name").replace("%value%", "%name%") +
  EMAIL_TEMPLATE_TEXT.replace("%key%", "User name").replace("%value%", "%username%") +
  EMAIL_TEMPLATE_TEXT.replace("%key%", "Phone number").replace("%value%", "%phone%") +
  EMAIL_TEMPLATE_TEXT.replace("%key%", "Email").replace("%value%", "%email%") +
  EMAIL_TEMPLATE_FOOTER +
  EMAIL_TEMPLATE_BOTTOM;

export const EMAIL_SUBSCRIPTION_TRIAL_SUBJECT = `Free trial subscription`;
export const EMAIL_SUBSCRIPTION_TRIAL_BODY = EMAIL_TEMPLATE_TOP +
  EMAIL_TEMPLATE_HEADER.replace("%header%", "Free trial subscription") +
  EMAIL_TEMPLATE_TEXT.replace("%key%", "Product").replace("%value%", "%productName%") +
  EMAIL_TEMPLATE_TEXT.replace("%key%", "Name").replace("%value%", "%name%") +
  EMAIL_TEMPLATE_TEXT.replace("%key%", "User name").replace("%value%", "%username%") +
  EMAIL_TEMPLATE_TEXT.replace("%key%", "Phone number").replace("%value%", "%phone%") +
  EMAIL_TEMPLATE_TEXT.replace("%key%", "Email").replace("%value%", "%email%") +
  EMAIL_TEMPLATE_FOOTER +
  EMAIL_TEMPLATE_BOTTOM;

export const EMAIL_SUBSCRIPTION_CANCEL_SUBJECT = `Subscription canceled`;
export const EMAIL_SUBSCRIPTION_CANCEL_BODY = EMAIL_TEMPLATE_TOP +
  EMAIL_TEMPLATE_HEADER.replace("%header%", "Subscription canceled") +
  EMAIL_TEMPLATE_TEXT.replace("%key%", "Product").replace("%value%", "%productName%") +
  EMAIL_TEMPLATE_TEXT.replace("%key%", "Name").replace("%value%", "%name%") +
  EMAIL_TEMPLATE_TEXT.replace("%key%", "User name").replace("%value%", "%username%") +
  EMAIL_TEMPLATE_TEXT.replace("%key%", "Phone number").replace("%value%", "%phone%") +
  EMAIL_TEMPLATE_TEXT.replace("%key%", "Email").replace("%value%", "%email%") +
  EMAIL_TEMPLATE_FOOTER +
  EMAIL_TEMPLATE_BOTTOM;

