import React, { Component } from 'react'
import { Form } from 'react-bootstrap'


import { withTranslation } from 'react-i18next'

class ComposingMode extends Component {
  constructor(props) {
    super(props)
    this.state={}
  }
  render() {  
    
    const { t } = this.props

    return ( 
      <div className="rrich-message__composing-mode">
        <div className="rrich-message__composing-mode__wrapper">
          <div className="rrich-message__composing-mode__aside-left">
            <div className="rrich-message__composing-mode__aside-left__title">{t("filter_menu_text_39")}</div>
            <div className="rrich-message__composing-mode__aside-left_chek">
              <Form.Check custom inline type="radio" name="visual" id="visual" label={t("filter_menu_text_40")} checked/>

              <Form.Check custom inline type="radio" name="markdown" id="markdown" label={t("filter_menu_text_41")} disabled/>
            </div>
          </div>
          <div className="rrich-message__composing-mode__aside-right">
            <Form.Check 
              custom 
              type="checkbox" 
              id="preview"
              label={t("filter_menu_text_42")}
              onClick={this.props.onShowPreview}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(ComposingMode)