import {
  REACTION_CREATE_REQUEST,
  REACTION_CREATE_SUCCESS,
  REACTION_CREATE_FAIL,
  REACTION_READ_SUCCESS,
  REACTION_UPDATE_SUCCESS,
  REACTION_DELETE_SUCCESS
} from '../actions/ReactionActions'
import { CONFIGS_UPDATE_SUCCESS } from "../actions/ConfigsActions"

const initialState = {
  list: [],
  error: '',
  isFetching: false,
}

export function reactionReducer(state = initialState, action) {
  switch (action.type) {
    case REACTION_CREATE_REQUEST:
      return { 
        ...state,
        list: [...state.list, action.payload] 
      }

    case REACTION_CREATE_SUCCESS:
      return { ...state, isFetching: false, list: [...state.list.concat(action.payload)] }

    case REACTION_CREATE_FAIL:
      return { ...state, isFetching: false, error: action.payload?.message }

    case REACTION_READ_SUCCESS:
      return { ...state, isFetching: false, list: action.payload }
    case REACTION_UPDATE_SUCCESS:
      return { ...state, isFetching: false,
        list: state.list.map(item => item.id === action.payload?.id ? { ...item,
          reaction: action.payload?.reaction,
          status: action.payload?.status,
        } : item)
      }
    case REACTION_DELETE_SUCCESS:
        return {
          ...state,
          list: state.list.filter(item => item.id !== action.payload)
        }

    default:
      return state
  }
}