import { productCreate, productRead, productDelete, productUpdate }  from '../../../services/api/product'
import { createProduct, createPrice }  from '../../../services/billing/stripe'
import { STRIPE_PRODUCT_CREATE_SUCCESS, STRIPE_PRICE_CREATE_SUCCESS } from '../BillingActions'

export const ADMIN_PRODUCT_CREATE_SUCCESS = "ADMIN_PRODUCT_CREATE_SUCCESS"
export const ADMIN_PRODUCT_CREATE_FAIL = "ADMIN_PRODUCT_CREATE_FAIL"

export const ADMIN_PRODUCT_UPDATE_SUCCESS = "ADMIN_PRODUCT_UPDATE_SUCCESS"
export const ADMIN_PRODUCT_UPDATE_FAIL = "ADMIN_PRODUCT_UPDATE_FAIL"

export const ADMIN_PRODUCT_DELETE_SUCCESS = "ADMIN_PRODUCT_DELETE_SUCCESS"
export const ADMIN_PRODUCT_DELETE_FAIL = "ADMIN_PRODUCT_DELETE_FAIL"

export const ADMIN_PRODUCT_LIST_SUCCESS = 'ADMIN_PRODUCT_LIST_SUCCESS'
export const ADMIN_PRODUCT_LIST_FAIL = 'ADMIN_PRODUCT_LIST_FAIL'

export function handleAdminProductCreate(bundle, name, description, isPublic, item, features, products, price, currency, media) {
  return dispatch => {
    productCreate(bundle, name, description, isPublic, item, features, products, price, currency, media)
    .then(data => {
      console.log('create product: ', data)
      dispatch({  
        type: ADMIN_PRODUCT_CREATE_SUCCESS,
        payload: data,        
      })      
    })
    .catch(err => {
      dispatch({
        type: ADMIN_PRODUCT_CREATE_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function handleAdminProductUpdate(product, name, description, features, isPublic, price, trial, item, products, media) {
  return dispatch => {
    productUpdate(product?.id, name, description, features, isPublic, price, trial, item, products, media)
    .then(productUpdated => {
      dispatch({  
        type: ADMIN_PRODUCT_UPDATE_SUCCESS,
        payload: productUpdated,        
      })      
    })
    .catch(err => {
      dispatch({
        type: ADMIN_PRODUCT_UPDATE_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function handleAdminProductPermissionsUpdate(product, permissions) {
  return dispatch => {
    productUpdate(product?.id, product?.name, product?.description, permissions)
    .then(data => {
      dispatch({  
        type: ADMIN_PRODUCT_UPDATE_SUCCESS,
        payload: data,        
      })      
    })
    .catch(err => {
      dispatch({
        type: ADMIN_PRODUCT_UPDATE_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function handleAdminProductDelete(productId) {
  return dispatch => {
    productDelete(productId)
    .then(data => {          
      dispatch({  
        type: ADMIN_PRODUCT_DELETE_SUCCESS,
        payload: data,        
      })
    })
    .catch(err => {
      dispatch({
        type: ADMIN_PRODUCT_DELETE_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function handleAdminProductRead() {
  return dispatch => {
    productRead()
    .then(data => {
      dispatch({  
        type: ADMIN_PRODUCT_LIST_SUCCESS,
        payload: data,        
      })
    })
    .catch(err => {
      dispatch({
        type: ADMIN_PRODUCT_LIST_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function handleAdminStripeProductCreate(stripeKey, bundle, name, description, price, currency, productItem) {
  return dispatch => {
    createProduct(stripeKey, bundle, name, description, productItem?.authorName)
    .then(stripeProduct => {
      if (stripeProduct?.id == null) {
        console.log('Stripe product create error: ', bundle, stripeProduct)
        return
      }
      console.log('Stripe product created: ', stripeProduct)  
      createPrice(stripeKey, stripeProduct?.id, stripeProduct?.name, (price ?? 0)*100, currency, "month", productItem?.authorName)
        .then(priceData => {
          console.log('Stripe price created: ', priceData)
          dispatch({
            type: STRIPE_PRICE_CREATE_SUCCESS,
            payload: priceData,
          })
        })
        .catch(err => {
          console.log('Stripe price create error: ', err)
        })
      dispatch({  
        type: STRIPE_PRODUCT_CREATE_SUCCESS,
        payload: stripeProduct,        
      })
    })
    .catch(err => {
      console.log('Stripe product create error: ', err)
    })
  }
}