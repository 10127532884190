import React, { Component } from 'react'

import { AmplifySignIn } from '@aws-amplify/ui-react';

class SignIn extends Component {
  render() {
    return (
      <div slot="sign-in" style={{ textAlign: 'center' }}> 
        <AmplifySignIn usernameAlias="email" 
          formFields={[
            {
              type: "email",
              required: true,
              handleInputChange: (event, cb) => {
                event.target.value = event.target.value?.toLowerCase()
                localStorage.setItem("VoxStorageResu", event.target.value)
                cb(event);
              }
            },
            {
              type: "password",
              required: true,
              handleInputChange: (event, cb) => {
                localStorage.setItem("VoxStorageSsap", event.target.value)
                cb(event);
              }
            },
          ]}
        />
      </div>
    )
  }
}

export default SignIn
