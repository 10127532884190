import React, { Component } from 'react'

import { AmplifyForgotPassword } from '@aws-amplify/ui-react';

class Reset extends Component {
  render() {
    return (
      <div slot="forgot-password" style={{ textAlign: 'center' }}> 
        <AmplifyForgotPassword  
        usernameAlias="email"
        formFields={[
          {
            type: "email",
            required: true,
            handleInputChange: (event, cb) => {
              event.target.value = event.target.value?.toLowerCase()
              cb(event);
            }
          }
        ]}/>
      </div>            
    )
  }
}

export default Reset
