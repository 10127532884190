import { loadTradeSyncList, tradeSyncApiUrl, tradeSyncHeaders } from "./tradesync"

export const tradeSyncCopiers = async (token, params) => {
  const headers = tradeSyncHeaders(token)
  const items = await loadTradeSyncList(`${tradeSyncApiUrl}/copiers`, headers, params)
  return items
}

export const tradeSyncCopier = async (token, copier_id, params) => {
  const headers = tradeSyncHeaders(token)
  var request = ''
  try {
    const getParams = new URLSearchParams(params)
    request = `${tradeSyncApiUrl}/copiers/${copier_id}?${getParams.toString()}`
  } catch (e) {
    request = `${tradeSyncApiUrl}/copiers/${copier_id}`
  }
  const response = await fetch(request, {
    method: 'GET',
    headers,
  })
  if (response.ok) {
    const json = await response.json()
    return json?.data
  } else {
    console.error('tradesync error: ', response.status, response.statusText)
    return null
  }
}

export const tradeSyncCopierCreate = async (token, params) => {
  const headers = tradeSyncHeaders(token)
  const body = JSON.stringify(params)
  const response = await fetch(`${tradeSyncApiUrl}/copiers`, {
    method: 'POST',
    headers,
    body: body,
  })
  if (response.ok) {
    const json = await response.json()
    return json?.data
  } else {
    console.error('tradesync error: ', response.status, response.statusText)
    return null
  }
}

export const tradeSyncCopierUpdate = async (token, copier_id, params) => {
  const headers = tradeSyncHeaders(token)
  const body = JSON.stringify(params)
  const response = await fetch(`${tradeSyncApiUrl}/copiers/${copier_id}`, {
    method: 'PATCH',
    headers,
    body: body,
  })
  if (response.ok) {
    const json = await response.json()
    return json?.data
  } else {
    console.error('tradesync error: ', response.status, response.statusText)
    return null
  }
}

export const tradeSyncCopierDelete = async (token, copier_id) => {
  const headers = tradeSyncHeaders(token)
  const response = await fetch(`${tradeSyncApiUrl}/copiers/${copier_id}`, {
    method: 'DELETE',
    headers,
  })
  if (response.ok) {
    const json = await response.json()
    return json?.data
  } else {
    console.error('tradesync error: ', response.status, response.statusText)
    return null
  }
}

export const tradeSyncCopierMap = async (token, copier_id, params) => {
  const headers = tradeSyncHeaders(token)
  var request = ''
  try {
    const getParams = new URLSearchParams(params)
    request = `${tradeSyncApiUrl}/copiers/${copier_id}/maps?${getParams.toString()}`
  } catch (e) {
    request = `${tradeSyncApiUrl}/copiers/${copier_id}/maps`
  }
  const response = await fetch(request, {
    method: 'GET',
    headers,
  })
  if (response.ok) {
    const json = await response.json()
    return json?.data
  } else {
    console.error('tradesync error: ', response.status, response.statusText)
    return null
  }
}

export const tradeSyncCopierMapCreate = async (token, copier_id, params) => {
  const headers = tradeSyncHeaders(token)
  const body = JSON.stringify(params)
  const response = await fetch(`${tradeSyncApiUrl}/copiers/${copier_id}/maps`, {
    method: 'POST',
    headers,
    body: body,
  })
  if (response.ok) {
    const json = await response.json()
    return json?.data
  } else {
    console.error('tradesync error: ', response.status, response.statusText)
    return null
  }
}

export const tradeSyncCopierMapDelete = async (token, copier_id, copier_map_id) => {
  const headers = tradeSyncHeaders(token)
  const response = await fetch(`${tradeSyncApiUrl}/copiers/${copier_id}/maps/${copier_map_id}`, {
    method: 'DELETE',
    headers,
  })
  if (response.ok) {
    const json = await response.json()
    return json?.data
  } else {
    console.error('tradesync error: ', response.status, response.statusText)
    return null
  }
}