import React, { Component } from "react"
import { Modal } from "react-bootstrap"

class MetaTraderModal extends Component {

  toggleModal = () => {
    this.props.onHide()
  }

  render() {
    return (
      <Modal
        {...this.props}
        onHide={this.toggleModal}
        centered
        aria-labelledby="contained-modal-title-vcenter"
        className="n-modal__modal-information"
      >
        <div className="n-modal">
          <div className="n-modal__wrapper">
            <div className="n-modal__close" onClick={this.toggleModal}>
              <svg
                width="20rem"
                height="20rem"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 2.01429L17.9857 0L10 7.98571L2.01429 0L0 2.01429L7.98571 10L0 17.9857L2.01429 20L10 12.0143L17.9857 20L20 17.9857L12.0143 10L20 2.01429Z"
                />
              </svg>
            </div>

            <div>
            <div className="n-modal__body__col-1">
                <div className="n-modal__body__channel-card">

                  <div className="n-modal__body__channel-card__img">
                    <img alt="" src='1234'
                         width="192px"
                         height="144px" />
                  </div>
                  <div className="n-modal__body__channel-card__name">
                    <div className="n-modal__body__channel-card__name--subtitle"><b>Vendor name</b></div>
                  </div>
                </div>
              </div>
              <div className="n-modal__body__col-2">
                description is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus
              </div>
            </div>

            <div className="n-modal__body">
              <div className="n-modal__body__col-1">
              <div className="n-modal__body__channel-card__name--subtitle"><b>Channels</b></div>

                <div className='n-packages-menu__card-widget-block'>
                  <div className='n-packages-menu__card-widget-block__wrapper' style={{ padding: '10px 0' }}>
                    <div className='n-packages-menu__card-widget-block__image'>
                      <img alt="" width="72px" height="54px" />
                    </div>
                    <div className='n-packages-menu__card-widget-block__title' style={{ color: 'var(--colorHEX7)' }}>Channel name</div>
                    <div className='n-packages-menu__card-widget-block__status' style={{ color: 'var(--colorHEX7)' }}>
                      <svg width="8rem" height="8rem" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="4" cy="4" r="3.5" fill="#FF0000"  stroke="white"/>
                      </svg>
                      Premium
                    </div>
                  </div>
                  <div className="n-packages-menu__card-widget-block__border" style={{ margin: '0 10px 0 10px' }}></div>
                </div>
                <div className='n-packages-menu__card-widget-block'>
                  <div className='n-packages-menu__card-widget-block__wrapper' style={{ padding: '10px 0' }}>
                    <div className='n-packages-menu__card-widget-block__image'>
                      <img alt="" width="72px" height="54px" />
                    </div>
                    <div className='n-packages-menu__card-widget-block__title' style={{ color: 'var(--colorHEX7)' }}>Channel name</div>
                    <div className='n-packages-menu__card-widget-block__status' style={{ color: 'var(--colorHEX7)' }}>
                      <svg width="8rem" height="8rem" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="4" cy="4" r="3.5" fill="#FF0000"  stroke="white"/>
                      </svg>
                      Premium
                    </div>
                  </div>
                  <div className="n-packages-menu__card-widget-block__border" style={{ margin: '0 10px 0 10px' }}></div>
                </div>
                <div className='n-packages-menu__card-widget-block'>
                  <div className='n-packages-menu__card-widget-block__wrapper' style={{ padding: '10px 0' }}>
                    <div className='n-packages-menu__card-widget-block__image'>
                      <img alt="" width="72px" height="54px" />
                    </div>
                    <div className='n-packages-menu__card-widget-block__title' style={{ color: 'var(--colorHEX7)' }}>Channel name</div>
                    <div className='n-packages-menu__card-widget-block__status' style={{ color: 'var(--colorHEX7)' }}>
                      <svg width="8rem" height="8rem" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="4" cy="4" r="3.5" fill="#FF0000"  stroke="white"/>
                      </svg>
                      Premium
                    </div>
                  </div>
                  <div className="n-packages-menu__card-widget-block__border" style={{ margin: '0 10px 0 10px' }}></div>
                </div>

              </div>
              <div className="n-modal__body__br"></div>
              <div className="n-modal__body__col-2">
              <div className="n-modal__body__channel-card__name--subtitle"><b>Account</b></div>
              <div className='n-packages-menu__card-widget-block'>
                <div className='n-packages-menu__card-widget-block__wrapper' style={{ padding: '10px 0' }}>
                  <div className='n-packages-menu__card-widget-block__title' style={{ color: 'var(--colorHEX7)' }}>2349123-04123-1234123-1234</div>
                </div>
                <div className="n-packages-menu__card-widget-block__border" style={{ margin: '0 10px 0 10px' }}></div>
              </div>
              <div className='n-packages-menu__card-widget-block'>
                <div className='n-packages-menu__card-widget-block__wrapper' style={{ padding: '10px 0' }}>
                  <div className='n-packages-menu__card-widget-block__title' style={{ color: 'var(--colorHEX7)' }}>2349123-04123-1234123-1234</div>
                </div>
                <div className="n-packages-menu__card-widget-block__border" style={{ margin: '0 10px 0 10px' }}></div>
              </div>
              <div className='n-packages-menu__card-widget-block'>
                <div className='n-packages-menu__card-widget-block__wrapper' style={{ padding: '10px 0' }}>
                  <div className='n-packages-menu__card-widget-block__title' style={{ color: 'var(--colorHEX7)' }}>2349123-04123-1234123-1234</div>
                </div>
                <div className="n-packages-menu__card-widget-block__border" style={{ margin: '0 10px 0 10px' }}></div>
              </div>
              <div className='n-packages-menu__card-widget-block'>
                <div className='n-packages-menu__card-widget-block__wrapper' style={{ padding: '10px 0' }}>
                  <div className='n-packages-menu__card-widget-block__title' style={{ color: 'var(--colorHEX7)' }}>2349123-04123-1234123-1234</div>
                </div>
                <div className="n-packages-menu__card-widget-block__border" style={{ margin: '0 10px 0 10px' }}></div>
              </div>
              <div className='n-packages-menu__card-widget-block'>
                <div className='n-packages-menu__card-widget-block__wrapper' style={{ padding: '10px 0' }}>
                  <div className='n-packages-menu__card-widget-block__title' style={{ color: 'var(--colorHEX7)' }}>2349123-04123-1234123-1234</div>
                </div>
                <div className="n-packages-menu__card-widget-block__border" style={{ margin: '0 10px 0 10px' }}></div>
              </div>
              <div className='n-packages-menu__card-widget-block'>
                <div className='n-packages-menu__card-widget-block__wrapper' style={{ padding: '10px 0' }}>
                  <div className='n-packages-menu__card-widget-block__title' style={{ color: 'var(--colorHEX7)' }}>2349123-04123-1234123-1234</div>
                </div>
                <div className="n-packages-menu__card-widget-block__border" style={{ margin: '0 10px 0 10px' }}></div>
              </div>

              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

export default MetaTraderModal