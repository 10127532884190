import React, { Component } from 'react'
import { AmplifyConfirmSignUp } from '@aws-amplify/ui-react'
import { authConfirmSignUp } from '../../services/auth'

class ConfirmSignUp extends Component {
  handleSubmit = (e) => {
    e.preventDefault();
    const usee = localStorage.getItem("VoxStorageResu")
    const paa = localStorage.getItem("VoxStorageSsap")
    const code = localStorage.getItem("VoxStorageAcoo")
    const visit = localStorage.setItem("FirstVisit", 'true')
    const buy = localStorage.setItem("FirstBuy", 'false')
    // const create = localStorage.setItem("FirstChannelCreation", 'false')
    const firstmessage = localStorage.setItem("FirstMessage", 'false')
    authConfirmSignUp(usee, paa, code, visit, buy, firstmessage)
  }

  render() {
    return (      
      <div slot="confirm-sign-up" style={{ textAlign: 'center' }}> 
        <AmplifyConfirmSignUp 
          usernameAlias="email"
          headerText="Verification code was sent to your email"
          handleSubmit={this.handleSubmit}
          user={{'username': localStorage.getItem("VoxStorageResu")}}
          formFields={[
            {
              type: "code",
              required: true,
              handleInputChange: (event, cb) => {
                localStorage.setItem("VoxStorageAcoo", event.target.value)
                cb(event);
              }
            }
          ]}
        />
      </div>
    )
  }
}

export default ConfirmSignUp
