import { HUB_OBSERVER_PRODUCT, paidProductRead } from "../../services/api/product"
import { productCreate, myProductRead, productUpdate, productDelete, productObserve }  from '../../services/api/product'
import { Hub } from 'aws-amplify'

import { add, remove } from "react-redux-permissions"

export const PRODUCT_CREATE_REQUEST = 'PRODUCT_CREATE_REQUEST'
export const PRODUCT_CREATE_SUCCESS = 'PRODUCT_CREATE_SUCCESS'
export const PRODUCT_CREATE_FAIL = 'PRODUCT_CREATE_FAIL'

export const PRODUCT_READ_REQUEST = 'PRODUCT_READ_REQUEST'
export const PRODUCT_READ_SUCCESS = 'PRODUCT_READ_SUCCESS'
export const PRODUCT_READ_FAIL = 'PRODUCT_READ_FAIL'

export const PRODUCT_LIST_REQUEST = 'PRODUCT_LIST_REQUEST'
export const PRODUCT_LIST_SUCCESS = 'PRODUCT_LIST_SUCCESS'
export const PRODUCT_LIST_FAIL = 'PRODUCT_LIST_FAIL'

export const PRODUCT_UPDATE_REQUEST = 'PRODUCT_UPDATE_REQUEST'
export const PRODUCT_UPDATE_SUCCESS = 'PRODUCT_UPDATE_SUCCESS'
export const PRODUCT_UPDATE_FAIL = 'PRODUCT_UPDATE_FAIL'

export const PRODUCT_DELETE_REQUEST = 'PRODUCT_DELETE_REQUEST'
export const PRODUCT_DELETE_SUCCESS = 'PRODUCT_DELETE_SUCCESS'
export const PRODUCT_DELETE_FAIL = 'PRODUCT_DELETE_FAIL'

export function handleProductCreate(bundle, name, description, isPublic, item, features, products, price, currency, media) {
  return dispatch => {
    dispatch({
      type: PRODUCT_CREATE_REQUEST,
      payload: { bundle: bundle, name: name, description: description, isPublic: isPublic, item: item, features: features, products: products, price: price, currency: currency, media: media },
    })
    productCreate(bundle, name, description, isPublic, item, features, products, price, currency, media)
    .then(data => {
      dispatch({
        type: PRODUCT_CREATE_SUCCESS,
        payload: data,        
      })
    })
    .catch(err => {
      dispatch({
        type: PRODUCT_CREATE_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function handleProductRead(ids) {
  return dispatch => {
    dispatch({
      type: PRODUCT_READ_REQUEST,
      payload: ids,
    })
    myProductRead(ids)
    .then(data => {
      Hub.dispatch(HUB_OBSERVER_PRODUCT, { event: HUB_OBSERVER_PRODUCT, data: data, message: HUB_OBSERVER_PRODUCT })
      dispatch({
        type: PRODUCT_READ_SUCCESS,
        payload: data,        
      })
    })
    .catch(err => {      
      dispatch({
        type: PRODUCT_READ_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function handleProductListRead() {
  return dispatch => {
    dispatch({
      type: PRODUCT_LIST_REQUEST,
      payload: null,
    })
    paidProductRead()
      .then(data => {
        dispatch({
          type: PRODUCT_LIST_SUCCESS,
          payload: data,
        })
      })
      .catch(err => {
        dispatch({
          type: PRODUCT_LIST_FAIL,
          error: true,
          payload: new Error(err),
        })
      })
  }
}

export function handleProductUpdate(productId, name, description, features, isPublic, price, trial) {
  return dispatch => {
    dispatch({
      type: PRODUCT_UPDATE_REQUEST,
      payload: { productId: productId, name: name, description: description, features: features, isPublic: isPublic, price: price, trial: trial },
    })
    productUpdate(productId, name, description, features, isPublic, price, trial)
    .then(data => {
      dispatch({
        type: PRODUCT_UPDATE_SUCCESS,
        payload: data,        
      })
    })
    .catch(err => {
      dispatch({
        type: PRODUCT_UPDATE_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function handleProductDelete(productId) {
  return dispatch => {
    dispatch({
      type: PRODUCT_DELETE_REQUEST,
      payload: { productId: productId },
    })
    productDelete(productId)
    .then(data => {
      dispatch({
        type: PRODUCT_DELETE_SUCCESS,
        payload: data,
      })
    })
    .catch(err => {
      dispatch({
        type: PRODUCT_DELETE_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function observeProduct(productId) {
  return dispatch => {
    productObserve(productId)
  }
}

export function handleProductObserver(data) {
  return dispatch => {
    switch (data.opType) {
      case 'INSERT':
        dispatch({
          type: PRODUCT_CREATE_SUCCESS,
          payload: data.element,
        })   
        break;
      case 'UPDATE':
        dispatch({
          type: PRODUCT_UPDATE_SUCCESS,
          payload: data.element,
        })   
        break;
      case 'DELETE':
        dispatch({
          type: PRODUCT_DELETE_SUCCESS,
          payload: data.element,
        })   
        break;      
      default:
        break;
    }    
  }
}

export function handleProductObserverAPI(event, data) {
  return dispatch => {
    switch (event) {
      case 'INSERT':
        console.log(event, data)
        dispatch({
          type: PRODUCT_CREATE_SUCCESS,
          payload: data,
        })   
        break;
      case 'UPDATE':
        console.log(event, data)
        dispatch({
          type: PRODUCT_UPDATE_SUCCESS,
          payload: data,
        })   
        break;
      case 'DELETE':
        console.log(event, data)
        dispatch({
          type: PRODUCT_DELETE_SUCCESS,
          payload: data,
        })   
        break;      
      default:
        break;
    }    
  }
}

export function applyProducts(productPermissions) {
  return dispatch => {
    // dispatch(clear())
    for (let productPermission of productPermissions) {
      dispatch(add(productPermission))
    }
  }
}

export function replacePermission(oldPermission, newPermission) {
  return dispatch => {
    if (oldPermission != null) {
      dispatch(remove(oldPermission))
    }
    if (newPermission != null) {
      dispatch(add(newPermission))
    }    
  }
}

export function clearFeature(clearPermission) {
  return dispatch => {
    dispatch(remove(clearPermission))
  }
}
