import { Hub, API, graphqlOperation } from 'aws-amplify';
import { listChannels, getChannel } from "../../graphql/queries"
import { createChannel, deleteChannel, updateChannel } from "../../graphql/mutations";
import { onCreateChannel, onUpdateChannel, onDeleteChannel } from "../../graphql/subscriptions";
import { STATUS_APPROVED } from '../../utils/Constant'
import { isBlankOrNull } from "../../utils/Helpres"

import { loadList } from "./shared"
import { validateChannelUpdate, validatePublicChannelUpdate } from "../validation/channels"
const action = listChannels
const attribute = "listChannels"

export const HUB_OBSERVER_CHANNEL = "channel"

let subscriptions = []

export const channelCreate = async (owner, user, name, description, info, categories, isPublic, status, media, filterRules) => {
  var channels = []
  if (isPublic === true) {
    try {
      channels = await loadList(action, attribute, { name: { eq: name } }, 1)
    } catch (err) { console.log('error fetching channels') }
  } else {
    try {
      const filter = { name: { eq: name }, or: [ { owner: { eq: owner } }, { public: { eq: true } } ] }
      channels = await loadList(action, attribute, filter, 1)
    } catch (err) { console.log('error fetching channels') }
  }
  if (channels.length > 0) { 
    console.log('Channel already exist!')
    return
  }
  if (owner == null || user == null || isBlankOrNull(name) || isPublic == null || status == null) {
    console.log('Channel API: Required fields cannot be empty!')
    return
  }
  try {
    const response = await API.graphql(graphqlOperation(createChannel, {input: {
      owner: owner, 
      name: name?.trim(),
      brief: description?.trim() ?? "",
      description: description?.trim() ?? "",
      info: info?.trim() ?? "",
      categories: categories,
      products: [],
      channelUserId: user.id,
      public: isPublic,
      filterRules: filterRules,
      status: status,
      media: media,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString() }}))
      return response?.data?.createChannel
  } catch (err) {
    console.log('error creating channel:', err)
    return
  }
}

export const channelRead = async (owner, user) => {
  try {
    const filter = {
      or: [
        {
          owner: {
            eq: owner,
          },
        },
        {
          public: {
            eq: true,
          },
          status: {
            eq: STATUS_APPROVED
          },
        },
      ],
    }
    const channels = await loadList(action, attribute, filter, 0)
    const filteredChannels = channels?.filter(ch => ch?.shared == null ? true : ch?.shared?.some(id => id === user?.id) )
    return filteredChannels
  } catch (err) { 
    console.log('error fetching channels') 
    return null
  }  
}

export const channelGet = async (channelId) => {
  const exist = await API.graphql(graphqlOperation(getChannel,
    { id: channelId }
  ))
  const original = exist?.data?.getChannel
  return original
}

export const channelUpdate = async (owner, channelId, name, description, info, isPublic, status, filterRules, categories, shared) => {
  var original = null
  try {
    const exist = await API.graphql(graphqlOperation(getChannel, 
      { id: channelId }
    ))
    original = exist?.data?.getChannel
  } catch (err) { console.log('error fetching channel:', err) }
  if (original?.id === channelId) {
    if (!validateChannelUpdate(original, owner, channelId, name, description, info, isPublic, status, filterRules, categories)) { return }
    try {
      const response = await API.graphql(graphqlOperation(updateChannel, {input: {
        id: channelId, 
        name: name?.trim(),
        brief: original?.brief ?? "",
        description: description?.trim() ?? "",
        info: info?.trim() ?? "",
        public: isPublic,
        filterRules: filterRules,
        status: status,
        categories: categories,
        shared: shared ?? original?.shared,
        updatedAt: new Date().toISOString() }}))
        return response?.data?.updateChannel
    } catch (err) {
      console.log('error updating channel:', err)
      return
    }
  } else {
    return null
  }
}

export const channelDelete = async (owner, channelId) => {
  try {
    await API.graphql(graphqlOperation(deleteChannel, { input: { id: channelId }}));
    return channelId
  } catch (err) { 
    console.log('error delete channel:', err) 
    return channelId
  }     
}

export const channelObserve = async (owner) => {
  if ( subscriptions.length === 3 ) { return }
  const createSubscription = API.graphql(graphqlOperation(onCreateChannel, { public: true } )).subscribe({
    next: response => {
      const channel = response.value.data.onCreateChannel
      Hub.dispatch(HUB_OBSERVER_CHANNEL, {event: 'INSERT', data: channel, message:'create channel observe' });  
    },
  })
  const createEvent = 'INSERT' 
  subscriptions.push({createSubscription, createEvent})
  const updateSubscription = API.graphql(graphqlOperation(onUpdateChannel, { public: true } )).subscribe({
    next: response => {
      const channel = response.value.data.onUpdateChannel
      Hub.dispatch(HUB_OBSERVER_CHANNEL, {event: 'UPDATE', data: channel, message:'create channel observe' });  
    },
  })
  const updateEvent = 'UPDATE'
  subscriptions.push({updateSubscription, updateEvent})
  const deleteSubscription = API.graphql(graphqlOperation(onDeleteChannel, { public: true } )).subscribe({
    next: response => {
      const channel = response.value.data.onDeleteChannel
      Hub.dispatch(HUB_OBSERVER_CHANNEL, {event: 'DELETE', data: channel, message:'create channel observe' });  
    },
  })
  const deleteEvent = 'DELETE'  
  subscriptions.push({deleteSubscription, deleteEvent})
}

export async function deleteChannelObservers() {
  for (let sub of subscriptions) {
    if (sub.subscription != null) {
        sub.subscription.unsubscribe()
    }
  }          
}

// Admin panel

export const publishedChannelRead = async () => {
  try {
    const filter = {
      or: [
        {
          public: {
            eq: true,
          },
        },
      ],
    }
    const channels = await loadList(action, attribute, filter, 0)
    return channels
  } catch (err) { 
    console.log('error fetching published channels', err) 
    return null
  }  
}

export const publishedChannelUpdate = async (channelId, name, brief, description, media, isPublic, status, products, categories) => {
  var original = null
  try {
    const exist = await API.graphql(graphqlOperation(getChannel, 
      { id: channelId }
    ))
    original = exist?.data?.getChannel
  } catch (err) { console.log('error fetching channel:', err) }
  if (original?.id === channelId) {
    if (!validatePublicChannelUpdate(original, channelId, name, brief, description, media, isPublic, status, products)) { return }
    try {
      const response = await API.graphql(graphqlOperation(updateChannel, {input: {
        id: channelId, 
        name: name?.trim(),
        brief: brief?.trim() ?? "",
        description: description?.trim() ?? "",
        media: media,
        public: isPublic,
        status: status,
        products: products,
        categories: categories,
        updatedAt: new Date().toISOString() }}))
        return response?.data?.updateChannel
    } catch (err) {
      console.log('error updating channel:', err)
      return
    }
  } else {
    return null
  }
}