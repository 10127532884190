import { API, graphqlOperation } from 'aws-amplify';
import { getReaction, listReactions, reactionsByItem } from "../../graphql/queries"
import { createReaction, deleteReaction, updateReaction } from "../../graphql/mutations"

import { loadList, loadListWithCustomFilters } from "./shared"
import { AWS_API_LIMIT, REACTION_INAPPROPRIATE, STATUS_SUBMITTED } from "../../utils/Constant"
const action = listReactions
const attribute = "listReactions"

export const HUB_OBSERVER_REACTION = "reaction"

export const reactionCreate = async (owner, user, reaction, message) => {
  try {
    const filter = { owner: { eq: owner }, reaction: { eq: reaction }, reactionMessageId: { eq: message.id } }
    const reactions = await loadList(action, attribute, filter, 1)
    if (reactions?.length > 0) {
      console.log('Reaction already exist!')
      return
    }
  } catch (err) { console.log('error fetching reactions') }
  if (owner == null || user == null || reaction == null || message == null) {
    console.log('Reaction API: Required fields cannot be empty!')
    return
  }
  try {
    const response = await API.graphql(graphqlOperation(createReaction, {input: {
      owner: owner,
      reaction: reaction,
      reactionUserId: user.id,
      reactionMessageId: message.id,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString() }}))
      return response?.data?.createReaction
  } catch (err) {
    console.log('error creating reaction:', err)
    return null
  }
}

export const reactionRead = async (owner) => {
  try {
    const filter = {
      owner: {
        eq: owner
      }
    }
    const reactions = await loadList(action, attribute, filter, 0)
    return reactions
  } catch (err) { 
    console.log('error fetching reactions', err) 
    return null
  }
}

export const reactionInAppropriateRead = async (lastCreatedAt) => {
  try {
    const customFilters = new Map()
    customFilters.set("reaction", REACTION_INAPPROPRIATE)
    customFilters.set("status", STATUS_SUBMITTED)
    customFilters.set("sortDirection", "DESC")
    if (lastCreatedAt) {
      customFilters.set("createdAt", { gt: lastCreatedAt })
    }
    const reactions = await loadListWithCustomFilters(reactionsByItem, "reactionsByItem", customFilters, null, AWS_API_LIMIT)
    return reactions
  } catch (err) {
    console.log('error fetching reactions', err)
    return null
  }
}

export const reactionUpdate = async (reactionId, reaction, status) => {
  var original = null
  try {
    const exist = await API.graphql(graphqlOperation(getReaction,
      { id: reactionId }
    ))
    original = exist?.data?.getReaction
  } catch (err) { console.log('error fetching reaction:', err) }
  if (original?.id === reactionId) {
    if (reactionId == null || reaction == null) {
      console.log('Reaction API: Required fields cannot be empty!')
      return
    }
    try {
      const response = await API.graphql(graphqlOperation(updateReaction, {input: {
          id: reactionId,
          reaction: reaction,
          status: status ?? original.status,
          updatedAt: new Date().toISOString() }}))
      return response?.data?.updateReaction
    } catch (err) {
      console.log('error updating reaction:', err)
      return null
    }
  } else {
    return null
  }
}

export const reactionDelete = async (owner, reactionId) => {
  await API.graphql(graphqlOperation(deleteReaction, { input: { id: reactionId }}));
  return reactionId
}