import React, { Component } from 'react'
import { Modal, FormControl, Row, Col } from "react-bootstrap"
import { PERIOD_DAYS, PERIOD_HOURS, PERIOD_MINUTES, PERIODS_REVERSED } from "../../../../utils/Constant"

import "./RssConnectModal.scss"
import { schedulerCreateRule, schedulerDeleteRule } from "../../../../services/function/scheduler"
import { isBlankOrNull } from "../../../../utils/Helpres"
import { handleRssSource } from "../../../../services/function/eventhub"


import { withTranslation } from 'react-i18next'

class RssConnectModal extends Component {
  constructor(props) {
    super(props)
    this.state={
      source: '',
      sourceValidation: null,
      interval: '5',
      period: PERIOD_MINUTES,
      enabled: false
    }
  }

  handleSource = async (source) => {
    var payload = this.buildParams(source)
    payload["skipCreation"] = true
    var feed = isBlankOrNull(source) ? null : await handleRssSource("plugin", "rss", payload)
    var sourceValidation = null;
    sourceValidation = feed?.title != null ? `${feed?.title} is found. ${feed?.items ?? 0} items.` : isBlankOrNull(source) ? null : `Source could not be loaded.`
    this.setState({sourceValidation: sourceValidation})
    return feed
  }

  handleChangeSource(e) {
    const source = e.target.value
    this.setState({source: source, sourceValidation: null})
    if (!isBlankOrNull(source)) {
      this.handleSource(source)
    }
  }

  handleChangeInterval(e) {
    this.setState({interval: e.target.value})
  }

  handleChangePeriod(e) {
    this.setState({period: e.target.value})
  }

  handleSwitch(e) {
    this.setState({enabled: e.target.checked})
    if (e.target.checked) {
      this.onCreateRss()
    } else {
      this.onDeleteRss()
    }
  }

  onCreateRss() {
    const payload = this.buildParams(this.state.source)
    var cron = "cron(0/60 * * * ? *)";
    if (this.state.period === PERIOD_MINUTES) {
      cron = `cron(0/${this.state.interval} * * * ? *)`;
    }
    if (this.state.period === PERIOD_HOURS) {
      cron = `cron(* 0/${this.state.interval} * * ? *)`;
    }
    if (this.state.period === PERIOD_DAYS) {
      cron = `cron(* * 0/${this.state.interval} * ? *)`;
    }
    schedulerCreateRule(`rss`, cron, "plugin", "rss", payload)
    handleRssSource("plugin", "rss", payload)
  }

  buildParams(source) {
    const payload = {
      "source": source,
      "interval": this.state.interval,
      "period": this.state.period,
      "category": this.props.category?.id,
      "messageChannelId": this.props.channel?.id,
      "messageUserId": this.props.account?.user?.id,
      "owner": this.props.account?.owner
    }
    return payload
  }

  onDeleteRss() {
    if (this.props.rule == null ) { return }
    try {
      const payload = JSON.parse(this.props.rule?.payload)
      const payloadEvent = { "owner": this.props.account?.owner, "eventId": this.props.rule?.id}
      schedulerDeleteRule(payload?.rule?.name, payload?.rule?.target, "plugin", "rss", payloadEvent)
    } catch (e) {
      console.log('Failed rule payload loading')
    }
  }

  render() {
    
    const { t } = this.props

    var payload = null;
    if (this.props.rule) {
      try {
        payload = JSON.parse(this.props.rule?.payload)
      } catch (e) {
        console.log('Failed rule payload loading')
      }
    }

    return (
      <Modal {...this.props} className="rss-connect-modal">
        <div className="rss-connect-modal__wrapper">
          <div className="rss-connect-modal__header">
            <div className="rss-connect-modal__close">
              <div className="css-fn5tqh">
                  <span className="css-j8fq0c">
                    <span className="Icon__IconWrapper-dyhwwi-0 jdkWJB" onClick={this.props.onHide}><svg width="24rem" height="24rem" viewBox="0 0 24 24" focusable="false" role="presentation"><path d="M12 10.586L6.707 5.293a1 1 0 0 0-1.414 1.414L10.586 12l-5.293 5.293a1 1 0 0 0 1.414 1.414L12 13.414l5.293 5.293a1 1 0 0 0 1.414-1.414L13.414 12l5.293-5.293a1 1 0 1 0-1.414-1.414L12 10.586z" fill="currentColor"></path></svg></span>
                  </span>
              </div>
            </div>
            <div className="rss-connect-modal__header__title">
              <div className="rss-connect-modal__header__title--bold">{t("filter_menu_text_30")}</div>&nbsp;
              <div>{t("filter_menu_text_31")}</div>
            </div>
          </div>
          <div className="rss-connect-modal__body d-flex justify-content-between">
            <div className="rss-connect-modal__body">
              <div className="rss-connect-modal__body__subheader">{t("filter_menu_text_32")}</div>
              <div className="rss-connect-modal__body__source">{t("filter_menu_text_33")}</div>
              <div className="rss-connect-modal__body__source__description">{t("filter_menu_text_34")}</div>
              <div className="rss-connect-modal__body__source__control">
                <FormControl
                  name="source"
                  disabled={payload?.plugin?.source || this.state.enabled}
                  value={payload?.plugin?.source ?? this.state.source}
                  onChange={this.handleChangeSource.bind(this)}
                  placeholder="E.g. https://rss.nytimes.com/services/xml/rss/nyt/World.xml" />
                {this.state.sourceValidation && <div className="rss-connect-modal__body__source__description">{this.state.sourceValidation}</div>}
              </div>
              <div className="rss-connect-modal__body__interval">{t("filter_menu_text_35")}</div>
              <div className="rss-connect-modal__body__interval__description">{t("filter_menu_text_36")}</div>
              <div className="rss-connect-modal__body__interval__control">
                <Row className="row">
                  <Col md>
                    <FormControl
                      name="interval"
                      disabled={payload?.plugin?.source || this.state.enabled}
                      value={ payload?.plugin?.interval ? payload?.plugin?.interval : this.state.interval}
                      onChange={this.handleChangeInterval.bind(this)}
                      placeholder="Interval" />
                  </Col>
                  <Col md>
                    <select className="rss-connect-modal__body__interval__control2 custom-select"
                            disabled={payload?.plugin?.period}
                            value={payload?.plugin?.period ? payload?.plugin?.period : this.state.period}
                            onChange={this.handleChangePeriod.bind(this)}
                    >
                      { PERIODS_REVERSED.map(unit => <option key={unit} value={unit}>{unit}</option>)}
                    </select>
                  </Col>
                </Row>
              </div>
              <div className="rss-connect-modal__body__action">{t("filter_menu_text_37")}&nbsp;&nbsp;<label className="switcher switcher-lg">
                <input type="checkbox" className="switcher-input"
                       disabled={payload?.plugin?.source == null && !this.state.sourceValidation?.includes("items")}
                       // disabled={isBlankOrNull(this.state.source) || isBlankOrNull(this.state.interval)}
                       checked={payload?.plugin?.source ?? this.state.enabled}
                       onChange={this.handleSwitch.bind(this)}/>
                <span className="switcher-indicator">
                  <span className="switcher-yes"><span className="ion ion-md-checkmark"></span></span>
                  <span className="switcher-no"><span className="ion ion-md-close"></span></span>
                </span>
              </label>{t("filter_menu_text_38")}</div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}


export default withTranslation()(RssConnectModal)
