import React, { Component } from "react"
import Sidenav from "../../vendor/libs/sidenav"
import layoutHelpers from "./helpers"

import { connect } from "react-redux"
import Permissions from "react-redux-permissions"
import { handleConfigsRead } from "../../store/actions/ConfigsActions"
import { storageRead } from "../../services/storage/storage"
import * as K from "../../utils/Constant"
import { getCompanyNameFromConfig } from "../../utils/ConfigsHelpers"

class AdminSideNav extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loadedCompanyLogo: false,
      companyLogo: null,
    }
  }

  componentDidMount() {
    this.previewImageLogo()
    this.props.handleConfigsRead()
  }

  toggleSidenav(e) {
    e.preventDefault()
    layoutHelpers.toggleCollapsed()
  }

  isMenuActive(url) {
    return this.props.location.pathname.indexOf(url) === 0
  }

  isMenuOpen(url) {
    return (
      this.props.location.pathname.indexOf(url) === 0 &&
      this.props.orientation !== "horizontal"
    )
  }

  previewImageLogo() {
    const key = K.DEFAULT_SET_COMPANY_LOGO_CONFIG_KEY
    storageRead(key)
      .then(data => {
        this.setState({ companyLogo: data })
      })
      .catch(err => console.log("READ FILE ERROR ", err))
  }

  render() {
    const companyName = getCompanyNameFromConfig(this.props.configs.list)

    return (
      <Sidenav
        orientation={this.props.orientation}
        className="sidenav bg-sidenav-theme layout-sidenav"
      >
        {/* Brand demo (see src/demo.css) */}
        {this.props.orientation !== "horizontal" && (
          <React.Fragment>
            <div className="app-brand demo">
              <a href={"/"} className="app-brand-logo demo">
                {this.state.companyLogo === null ? (
                  <div></div>
                ) : (
                  <img
                    src={this.state.companyLogo}
                    alt="company-logo"
                    className="app-brand-logo demo"
                  />
                )}
              </a>
              <a
                className="app-brand-text demo sidenav-text font-weight-normal ml-2"
                href="/"
              >
                {companyName}
              </a>
              <a
                href="#toggle"
                className="layout-sidenav-toggle sidenav-link text-large ml-auto"
                onClick={this.toggleSidenav}
              >
                <i className="ion ion-md-menu align-middle"></i>
              </a>
            </div>
          </React.Fragment>
        )}
        {/* Links */}
        <div
          className={`sidenav-inner ${this.props.orientation !== "horizontal"
            }`}
        >
          {/* <Permissions allowed={[K.PLATFORM_DASHBOARD]}>
            <Sidenav.RouterLink to="/admin/dashboard">
              Dashboard
            </Sidenav.RouterLink>
          </Permissions> */}
          <Permissions allowed={[K.PLATFORM_USERS]}>
            <Sidenav.RouterLink to="/admin/users">
              Users
            </Sidenav.RouterLink>
          </Permissions>
          <Permissions allowed={[K.PLATFORM_ROLES_AND_PERMISSIONS]}>
            <Sidenav.RouterLink to="/admin/roles-permissions">
              Roles & Permissions
            </Sidenav.RouterLink>
          </Permissions>
          <Sidenav.Divider className="mb-1" />
          <Permissions allowed={[K.PLATFORM_CHANNEL_MANAGEMENT]}>
            <Sidenav.RouterLink to="/admin/channel-management">
              Channel Management{" "}
            </Sidenav.RouterLink>
          </Permissions>
          <Permissions allowed={[K.PLATFORM_EXTRAS]}>
            <Sidenav.RouterLink to="/admin/extras">
              Extras
            </Sidenav.RouterLink>
          </Permissions>
          {/* <Permissions allowed={[K.PLATFORM_PRODUCTS]}><Sidenav.RouterLink to="/admin/products">Products</Sidenav.RouterLink></Permissions> */}
          <Sidenav.Menu
            linkText="Products"
            active={this.isMenuActive("/admin/products")}
            open={this.isMenuOpen("/admin/products")}
          >
            <Sidenav.RouterLink to="/admin/products/single-plans">
              Single plans
            </Sidenav.RouterLink>
            <Sidenav.RouterLink to="/admin/products/feature-plans">
              Feature plans
            </Sidenav.RouterLink>
            <Sidenav.RouterLink to="/admin/products/packages">
              Packages
            </Sidenav.RouterLink>
          </Sidenav.Menu>
          <Permissions>
            <Sidenav.RouterLink to="/admin/moderation">
              Content Moderation
            </Sidenav.RouterLink>
          </Permissions>
          <Sidenav.Menu
            linkText="Integrations"
            active={this.isMenuActive("/admin/integrations")}
            open={this.isMenuOpen("/admin/integrations")}
          >
            <Sidenav.RouterLink to="/admin/integrations/payments">
              Stripe
            </Sidenav.RouterLink>
            <Sidenav.RouterLink to="/admin/integrations/copytrading">
              Copytrading
            </Sidenav.RouterLink>
          </Sidenav.Menu>
          {/* <Sidenav.RouterLink to="/admin/ui-settings-page">UI Settings page</Sidenav.RouterLink> */}
          <Sidenav.Menu
            linkText="Customizations"
            active={this.isMenuActive("/admin/customizations")}
            open={this.isMenuOpen("/admin/customizations")}
          >
            {/* <Sidenav.RouterLink to="/admin/customizations/rich-messages">Rich Messages</Sidenav.RouterLink> */}
            <Sidenav.RouterLink to="/admin/customizations/message-categories">
              Message Categories
            </Sidenav.RouterLink>
            <Sidenav.RouterLink to="/admin/customizations/external-links">
              External links
            </Sidenav.RouterLink>
            <Sidenav.RouterLink to="/admin/customizations/template-workspaces">
              Template workspaces
            </Sidenav.RouterLink>
            <Sidenav.RouterLink to="/admin/customizations/registration-authenication">
              Requesting personal information
            </Sidenav.RouterLink>
            <Sidenav.RouterLink to="/admin/customizations/corporate-branding">
              Corporate branding
            </Sidenav.RouterLink>
            <Sidenav.RouterLink to="/admin/customizations/privacy">
              Disclaimer, PP, ToS & EULA
            </Sidenav.RouterLink>
            <Sidenav.RouterLink to="/admin/customizations/privacy-policy">
              Privacy policy
            </Sidenav.RouterLink>
          </Sidenav.Menu>
          <Sidenav.Menu
            linkText="UI Groups"
            active={this.isMenuActive("/admin/groups")}
            open={this.isMenuOpen("/admin/groups")}
          >
            <Permissions allowed={[K.PLATFORM_CHANNEL_MANAGEMENT]}>
              <Sidenav.RouterLink to="/admin/groups/channels">
                Groups of Channels
              </Sidenav.RouterLink>
            </Permissions>
            <Permissions allowed={[K.PLATFORM_EXTRAS]}>
              <Sidenav.RouterLink to="/admin/groups/extras">
                Groups of Extras
              </Sidenav.RouterLink>
            </Permissions>
            {/* <Permissions allowed={[K.PLATFORM_EXTRAS]}> */}
            <Sidenav.RouterLink to="/admin/groups/links">
              Groups of Links
            </Sidenav.RouterLink>
            {/* </Permissions> */}
          </Sidenav.Menu>
        </div>
      </Sidenav>
    )
  }
}

const mapStateToProps = store => {
  return {
    configs: store.configs,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleConfigsRead: () => dispatch(handleConfigsRead()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminSideNav)
