import * as TRADESYNC from "../actions/TradesyncActions"

const initialState = {
  accounts: [],
  brokers: [],
  brokerServers: [],
  copiers: [],
  analysis: [],
  webhooks: [],
  trades: [],
  typeCreatingAccount: '',
  creatingAccountStatus: false,
  isPendingAfterCreatingAccount: false,
  creatingAccountError: false
}

export function tradesyncReducer(state = initialState, action) {
  switch (action.type) {
    case TRADESYNC.COPYTRADING_ACCOUNT_LIST_SUCCESS:
      return {
        ...state, accounts: action.payload
      }
    case TRADESYNC.COPYTRADING_ACCOUNT_GET_SUCCESS:
      if (state.accounts.some(item => item.id === action.payload?.id)) {
        return state
      }
      return { 
        ...state, accounts: [...state.accounts.concat(action.payload)] 
      }
    case TRADESYNC.COPYTRADING_ACCOUNT_CREATE_START:
      return { 
        ...state, creatingAccountStatus: "pending" 
      }
    case TRADESYNC.COPYTRADING_ACCOUNT_CREATE_SUCCESS:
      if (state.accounts.some(item => item.id === action.payload?.id)) {
        return state
      }
      return {
        ...state, 
        accounts: [...state.accounts, action.payload], 
        creatingAccountStatus: "success",
        isPendingAfterCreatingAccount: true
      }
    case TRADESYNC.COPYTRADING_TYPE_CREATE_ACCOUNT:
      return {
        ...state, 
        typeCreatingAccount: action.payload
      }
    case TRADESYNC.COPYTRADING_ACCOUNT_CREATE_FAIL:
      return {
        ...state,
        creatingAccountStatus: "fail",
        creatingAccountError: action.payload?.message ? action.payload?.message : "Internal error",
        isPendingAfterCreatingAccount: false
      }
    case TRADESYNC.COPYTRADING_ACCOUNT_UPDATE_SUCCESS:
      return {
        ...state, accounts: state.accounts.map(item => item.id === action.payload?.id ? {
          ...item,
          name: action.payload?.name
        } : item)
      }
    case TRADESYNC.COPYTRADING_ACCOUNT_DELETE_SUCCESS:
      return {
        ...state, 
        creatingAccountStatus: "success", 
        accounts: state.accounts.filter(item => item.id !== action.payload),
        isPendingAfterCreatingAccount: false
      }
    case TRADESYNC.COPYTRADING_ACCOUNT_DELETE_FAIL:
      return {
        ...state,
        creatingAccountStatus: "fail",
        creatingAccountError: action.payload?.message ? action.payload?.message : "Internal error",
        isPendingAfterCreatingAccount: false
      }
    case TRADESYNC.COPYTRADING_BROKER_LIST_SUCCESS:
      return {
        ...state, brokers: action.payload
      }
    case TRADESYNC.COPYTRADING_BROKER_SERVER_LIST_SUCCESS:
      return {
        ...state, brokerServers: action.payload
      }
    case TRADESYNC.COPYTRADING_COPIER_LIST_SUCCESS:
      return {
        ...state, copiers: action.payload
      }
    case TRADESYNC.COPYTRADING_COPIER_CREATE_SUCCESS:
      if (state.copiers.some(item => item.id === action.payload?.id)) {
        return state
      }
      return {
        ...state, copiers: [...state.copiers, action.payload]
      }
    case TRADESYNC.COPYTRADING_COPIER_DELETE_SUCCESS:
      return {
        ...state, copiers: state.copiers.filter(item => item.id !== action.payload)
      }
    case TRADESYNC.COPYTRADING_ANALYSIS_ACCOUNT_LIST_SUCCESS:
      return {
        ...state, analysis: action.payload
      }
    case TRADESYNC.COPYTRADING_WEBHOOK_LIST_SUCCESS:
      return {
        ...state, webhooks: action.payload
      }
    case TRADESYNC.COPYTRADING_WEBHOOK_CREATE_SUCCESS:
      if (state.webhooks.some(item => item.id === action.payload?.id)) {
        return state
      }
      return {
        ...state, webhooks: [...state.webhooks, action.payload]
      }
    case TRADESYNC.COPYTRADING_WEBHOOK_DELETE_SUCCESS:
      return {
        ...state, webhooks: state.webhooks.filter(item => item.id !== action.payload)
      }
    case TRADESYNC.COPYTRADING_TRADESYNC_LIST_SUCCESS:
      return {
        ...state, trades: action.payload
      }
    case TRADESYNC.COPYTRADING_TRADESYNC_CREATE_SUCCESS:
      if (state.trades.some(item => item.id === action.payload?.id)) {
        return state
      }
      return {
        ...state, trades: [...state.trades, action.payload]
      }
    case TRADESYNC.COPYTRADING_TRADESYNC_DELETE_SUCCESS:
      return {
        ...state, trades: state.trades.filter(item => item.id !== action.payload)
      }
    case TRADESYNC.COPYTRADING_TRADESYNC_DELETE_SUCCESS:
      return {
        ...state, 
        trades: state.trades.filter(item => item.id !== action.payload)
      }
    case TRADESYNC.COPYTRADING_TRADESYNC_RESET_DATA:
      return {
        ...state, 
        creatingAccountStatus: false,
        isPendingAfterCreatingAccount: false,
        creatingAccountError: false
      }
    default:
      return state
  }
}