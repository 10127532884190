import React, { Component } from 'react'
import { connect } from 'react-redux'
import { updateCategory } from '../../../../../../store/actions/RichMessageActions';


class ChooseMessageCategory extends Component {
  handleChangeMessageCategory(event) {
    this.props.updateCategory({id: this.props.id, category: event.target.value})
  }

  render() {    
    const categories = this.props.configs.MESSAGE_CATEGORIES
    return ( 
      <div className="rrich-message__message__card__body__message">
        <div className="rrich-message__message__card__body__message__icon">
          <svg width="25rem" height="24rem" viewBox="0 0 25 24" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="Rich-message-input-window:-Visual-Composition" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><g id="Group-28" transform="translate(-12.000000, -19.000000)"><g id="Group-24" transform="translate(13.000000, 18.000000)"><g id="Group-18-Copy" transform="translate(0.000000, 1.000000)"><rect id="Rectangle" fill="#AAAAAA" x="0" y="0" width="7.2" height="7.2" rx="1"></rect><rect id="Rectangle-Copy-26" fill="#AAAAAA" x="0" y="16.8" width="7.2" height="7.2" rx="1"></rect><rect id="Rectangle-Copy-23" fill="#AAAAAA" x="8.4" y="0" width="15.6" height="7.2" rx="1"></rect><rect id="Rectangle-Copy-25" stroke="#AAAAAA" strokeWidth="2" fill="#FFFFFF" x="9.4" y="9.4" width="13.6" height="5.2" rx="1"></rect><rect id="Rectangle-Copy-27" fill="#AAAAAA" x="8.4" y="16.8" width="15.6" height="7.2" rx="1"></rect><path d="M-4.4408921e-16,11.1396365 C-4.4408921e-16,11.1396365 0.82102787,12.2387732 2.46308361,14.4370466 L6.73375028,9" id="Path-2" stroke="#888888" strokeWidth="2"></path></g></g></g></g></svg>
        </div>
        <div className="rrich-message__message__card__body__message__text">Message category:</div>
        <div className="rrich-message__message__card__body__message__select">
          <select 
            className="custom-select" 
            size="sm" 
            value={this.props.category} 
            onChange={(e) => this.handleChangeMessageCategory(e)}
          >
          {categories.map(category =>
            <option key={category?.id} value={category?.id}>{category?.content}</option>
          )}
          </select>
        </div>
      </div>
    )
  }
}

const mapStateToProps = store => {
  return {
    configs: store.configs
  }
}

const mapDispatchToProps = dispatch => {
  return { updateCategory: (id, category) => dispatch(updateCategory(id, category)) }
}

export default connect(
  mapStateToProps, 
  mapDispatchToProps
)(ChooseMessageCategory)