import React, { Component } from 'react'
import { connect } from 'react-redux'
import Preview from './Preview'


import { withTranslation } from 'react-i18next'

class PreviewContainer extends Component {
  render() {
    
    const { t } = this.props
    
    return (
      <div className="rrich-message__container">
        {this.props.syncPosts.length > 0 ?
          this.props.syncPosts.map(post => <Preview post={post} key={post.id} />) :        
          <div className="rrich-message__message">
            <div className="rrich-message__message__card">
              <div className="rrich-message__message__card__title">{t("filter_menu_text_58")}</div>
            </div>
          </div>
        }
      </div>
    )
  }
}

const mapStateToProps = store => {
  return {
    syncPosts: store.posts.posts
  }
}

export default withTranslation()(connect(mapStateToProps)(PreviewContainer))