import { PLATFORM_USE_NEW_DESIGN_KEY } from '../../../utils/Constant';
import { AVAILABLE_SETTINGS_KEYS, DEFAULT_SETTINGS_OBJ } from '../consts/settings';
import { findObject } from './common';

export { AVAILABLE_SETTINGS_KEYS } from '../consts/settings';

export const stringToBoolean = (stringValue) => {
  switch(stringValue?.toLowerCase()?.trim()){
    case "true": 
    case "yes": 
    case "1":
    case true:
      return true;

    case "false": 
    case "no": 
    case "0": 
    case null: 
    case false:
    case undefined:
      return false;

    default: 
      return JSON.parse(stringValue);
  }
}

export const generateSettingsObj = configsList => {
  return AVAILABLE_SETTINGS_KEYS.reduce((acc, settingsKey) => {
    const found = findObject(configsList, 'detail', settingsKey)

    if (found) {
      return {
        ...acc,
        [settingsKey]: {
          title: found.title,
          value: stringToBoolean(found.content)
        }
      }
    } else {
      if (settingsKey === PLATFORM_USE_NEW_DESIGN_KEY) {
        return {
          ...acc,
          [settingsKey]: {
            title: DEFAULT_SETTINGS_OBJ[settingsKey].title,
            value: DEFAULT_SETTINGS_OBJ[settingsKey].value
          }
        }
      }
    }
  }, {})
}
