import { Hub } from "aws-amplify"
import React, { Component } from "react"
import { Modal } from "react-bootstrap"
import { isBlankOrNull } from "../../../utils/Helpres"
import * as K from "../../../utils/Constant"


import { withTranslation } from 'react-i18next'

class AddNewChannelModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      channelName: "",
      channelDescription: "",
      isShowModal: true,
      isShowError: false,
      isShowVisible: false,
    }
  }

  handleChangeChannelName(event) {
    const { channels } = this.props.data
    const newChannelName = event.target.value?.trim()
    const isShowError =
      isBlankOrNull(newChannelName) ||
      channels?.find(
        ch => ch?.name?.toLowerCase() === newChannelName?.toLowerCase()
      )
    this.setState({
      channelName: event.target.value,
      isShowError: isShowError,
      isShowVisible: !isShowError,
    })
    setTimeout(() => {
      if (this.state.channelName.trim().length) {
        Hub.dispatch(K.HUB_ACTIVECHANGECHANNELNAME)
      } else {
        Hub.dispatch(K.HUB_INACTIVECHANGECHANNELNAME)
      }
    }, 200)
  }

  handleChangeChannelDescription(event) {
    this.setState({ channelDescription: event.target.value })
  }

  onSaveChannel() {
    
    const { t } = this.props

    const { channelName, channelDescription } = this.state
    const { isPublic, channels, onChannelCreate } = this.props.data
    const newChannelName = channelName?.trim()
    const isShowError =
      isBlankOrNull(newChannelName) ||
      channels?.find(
        ch => ch?.name?.toLowerCase() === newChannelName?.toLowerCase()
      )
    var newChannelDesc = channelDescription?.trim()
    if (isBlankOrNull(newChannelDesc)) {
      newChannelDesc = t("modal_add_channel_text_1")
    }
    this.setState({ isShowError: isShowError })
    if (!isShowError) {
      onChannelCreate(
        newChannelName,
        newChannelDesc,
        newChannelDesc,
        isPublic === true
      )
      this.toggleModal()
    }
  }

  toggleModal = () => {
    this.props.onHide()
  }

  componentDidMount() {
    Hub.listen(K.HUB_CLOSEADDNEWCHANNELMODAL, () => {
      this.props.onHide()
    })
  }

  render() {
    
    const { t } = this.props

    const { channelName, channelDescription } = this.state
    return (
      <Modal
        {...this.props}
        onHide={this.toggleModal}
        centered
        aria-labelledby="contained-modal-title-vcenter"
        className="n-modal__modal"
      >
        <div className="n-modal">
          <div className="n-modal__wrapper">
            <div className="n-modal__close" onClick={this.toggleModal}>
              <svg
                width="20rem"
                height="20rem"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M20 2.01429L17.9857 0L10 7.98571L2.01429 0L0 2.01429L7.98571 10L0 17.9857L2.01429 20L10 12.0143L17.9857 20L20 17.9857L12.0143 10L20 2.01429Z" />
              </svg>
            </div>
            <div className="n-modal__header">
              <div className="n-modal__header__icon">
                <svg
                  width="39rem"
                  height="38rem"
                  viewBox="0 0 39 38"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_533_3952)">
                    <path
                      d="M19.5 13.5V24.5"
                      stroke="#203B60"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                    />
                    <path
                      d="M25 19H14"
                      stroke="#203B60"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                    />
                    <path
                      d="M19.5 37C29.4411 37 37.5 28.9411 37.5 19C37.5 9.05887 29.4411 1 19.5 1C9.55887 1 1.5 9.05887 1.5 19C1.5 28.9411 9.55887 37 19.5 37Z"
                      stroke="#203B60"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_533_3952">
                      <rect
                        width="38"
                        height="38"
                        fill="white"
                        transform="translate(0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className="n-modal__header__title">{t("modal_add_channel_text_1")}</div>
              <div className="n-modal__line" />
              <div className="n-modal__content n-modal__content--flex channel-name-guide">
                <div className="n-modal__content__title">
                {t("modal_add_channel_text_3")}&nbsp;<b>{t("modal_add_channel_text_4")}</b>
                </div>
                <div className="n-modal__content__required">{t("modal_add_channel_text_5")}</div>
                <div
                  className={
                    this.state.isShowError ? "n-input error" : "n-input"
                  }
                >
                  <input
                    value={channelName}
                    type="text"
                    // maxLength={20}
                    onChange={this.handleChangeChannelName.bind(this)}
                    placeholder={t("modal_add_channel_text_6")}
                  />
                  <div className="n-input__error-message">
                    {isBlankOrNull(channelName)
                      ? t("modal_add_channel_text_7")
                      : t("modal_add_channel_text_8")}
                  </div>
                </div>
              </div>
              <div
                className={
                  this.state.isShowVisible
                    ? "n-modal__content n-modal__content--flex channel-description-guide"
                    : "n-modal__content n-modal__content--flex is-opacity-30 channel-description-guide"
                }
              >
                <div className="n-modal__content__title">
                {t("modal_add_channel_text_9")}&nbsp;<b>{t("modal_add_channel_text_10")}</b>
                </div>
                <div className="n-modal__content__optional">{t("modal_add_channel_text_11")}</div>
                <div className="n-input">
                  <input
                    value={channelDescription}
                    onChange={this.handleChangeChannelDescription.bind(this)}
                    type="text"
                    placeholder={t("modal_add_channel_text_12")}
                  />
                </div>
              </div>
              <div className="n-modal__btns channel-modal-btn-guide">
                <div
                  className="button-lg"
                  onClick={this.onSaveChannel.bind(this)}
                >
                  {t("modal_add_channel_text_13")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

export default withTranslation()(AddNewChannelModal)
