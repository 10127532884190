import React, { Component } from 'react'
import { Modal } from "react-bootstrap"
import "./CopytradingConnectModal.scss"


import { withTranslation } from 'react-i18next'

class CopytradingConnectModal extends Component {
  constructor(props) {
    super(props)
    this.state={
      channel: null
    }
  }

  componentDidMount() {
    const payload = this.loadPayload()
    if (payload) {
      this.setState({channel: payload?.plugin?.messageChannelId})
    }
  }

  handleChangeChannel(e) {
    const value = e.target.value === "null" ? null : e.target.value
    this.setState({channel: value})
  }

  handleConnectAction(payload) {
    if (!payload) {
      this.onCreateCopytradingConnect()
    } else {
      this.onDeleteCopytradingConnect()
    }
  }

  onCreateCopytradingConnect() {
    const payload = {
      "master": this.props.masterAccount?.id,
      "category": this.props.category?.id,
      "messageChannelId": this.state.channel,
      "messageUserId": this.props.account?.user?.id,
      "owner": this.props.account?.owner
    }
    this.props.handleEventCreate(this.props.account?.owner, "plugin", "copytrading", JSON.stringify({"plugin": payload}))
  }

  onDeleteCopytradingConnect() {
    this.props.handleEventDelete(this.props.account?.owner, this.props.event?.id)
  }

  loadPayload = () => {
    var payload = null;
    if (this.props.event) {
      try {
        payload = JSON.parse(this.props.event?.payload)
      } catch (e) {
        console.log('Failed event payload loading')
      }
    }
    return payload
  }

  render() {
    
    const { t } = this.props

    const payload = this.loadPayload()

    return (
      <Modal {...this.props} className="copytrading-connect-modal">
        <div className="copytrading-connect-modal__wrapper">
          <div className="copytrading-connect-modal__header">
            <div className="copytrading-connect-modal__close">
              <div className="css-fn5tqh">
                  <span className="css-j8fq0c">
                    <span className="Icon__IconWrapper-dyhwwi-0 jdkWJB" onClick={this.props.onHide}><svg width="24rem" height="24rem" viewBox="0 0 24 24" focusable="false" role="presentation"><path d="M12 10.586L6.707 5.293a1 1 0 0 0-1.414 1.414L10.586 12l-5.293 5.293a1 1 0 0 0 1.414 1.414L12 13.414l5.293 5.293a1 1 0 0 0 1.414-1.414L13.414 12l5.293-5.293a1 1 0 1 0-1.414-1.414L12 10.586z" fill="currentColor"></path></svg></span>
                  </span>
              </div>
            </div>
            <div className="copytrading-connect-modal__header__title">
              <div className="copytrading-connect-modal__header__title--bold">{t("filter_menu_text_24")}</div>&nbsp;
              <div>{t("filter_menu_text_25")}</div>
            </div>
          </div>
          <div className="copytrading-connect-modal__body d-flex justify-content-between">
            <div className="copytrading-connect-modal__body">
              <div className="copytrading-connect-modal__body__subheader">{t("filter_menu_text_26")}</div>
              <div className="copytrading-connect-modal__body__source">{t("filter_menu_text_27")}</div>
              <div className="copytrading-connect-modal__body__source__control">
                <select className="copytrading-connect-modal__body__control2 custom-select"
                        disabled={payload}
                        value={payload?.plugin?.messageChannelId ? payload?.plugin?.messageChannelId : this.state.channel}
                        onChange={this.handleChangeChannel.bind(this)}
                >
                  <option key={"null"} value={"null"}>Empty</option>
                  { this.props.channels?.map(ch => <option key={ch?.id} value={ch?.id}>{ch?.name}</option>)}
                </select>&nbsp;&nbsp;
                <button className="button-lg-outline" disabled={!payload && !this.state.channel}
                        onClick={() => this.handleConnectAction(payload)}>{payload ? t("filter_menu_text_28") : t("filter_menu_text_29") }</button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}


export default withTranslation()(CopytradingConnectModal)
