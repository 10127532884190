import { API, graphqlOperation } from 'aws-amplify';
import { listLabels, getLabel } from "../../graphql/queries";
import { createLabel, deleteLabel, updateLabel } from "../../graphql/mutations";

import { loadList } from "./shared"
const action = listLabels
const attribute = "listLabels"

export const HUB_OBSERVER_LABEL = "label"

// let subscriptions = []

export const labelCreate = async (owner, user, name) => {
  try {
    const filter = { owner: { eq: owner }, name: { eq: name } }
    const labels = await loadList(action, attribute, filter, 1)
    if (labels?.length > 0) {
      console.log('Label already exist!')
      return
    }
  } catch (err) { console.log('error fetching labels') }
  if (owner == null || user == null || name == null) { 
    console.log('Label API: Required fields cannot be empty!')
    return
  }
  try {
    const response = await API.graphql(graphqlOperation(createLabel, {input: {
      owner: owner, 
      name: name,
      labelUserId: user.id,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString() }}))
      return response?.data?.createLabel
  } catch (err) {
    console.log('error creating label:', err)
    return
  }
}

export const labelRead = async (owner) => {
  try {
    const filter = {
      owner: {
        eq: owner
      }
    }
    const labels = await loadList(action, attribute, filter, 0)
    return labels
  } catch (err) { 
    console.log('error fetching labels') 
    return null
  }
}

export const labelUpdate = async (owner, labelId, name) => {
  var original = null
  try {
    const exist = await API.graphql(graphqlOperation(getLabel, 
      { id: labelId }
    ))
    original = exist?.data?.getLabel
  } catch (err) { console.log('error fetching label:', err) }
  if (original?.id === labelId) {
    if (owner == null || labelId == null || name == null) { 
      console.log('Label API: Required fields cannot be empty!')
      return
    }
    try {
      const response = await API.graphql(graphqlOperation(updateLabel, {input: {
        id: labelId, 
        name: name,
        updatedAt: new Date().toISOString() }}))
        return response?.data?.updateLabel
    } catch (err) {
      console.log('error updating label:', err)
      return
    }
  } else {
    return null
  }
}

export const labelDelete = async (owner, labelId) => {
  await API.graphql(graphqlOperation(deleteLabel, { input: { id: labelId }}));
  return labelId
}