import { API, graphqlOperation } from 'aws-amplify';
import { listPermissions } from "../../graphql/queries";
import { createPermission, deletePermission } from "../../graphql/mutations";
import * as K from '../../utils/Constant'

import { loadList } from "./shared"
const action = listPermissions
const attribute = "listPermissions"

export const HUB_OBSERVER_PERMISSION = "permission"

// let subscriptions = []

export const permissionCreate = async (module, name, description) => {
  try {
    const filter = { module: { eq: module }, name: { eq: name } }
    const permissions = await loadList(action, attribute, filter, 1)
    if (permissions?.length > 0) {
      console.log('Permission already exist!')
      return
    }
  } catch (err) { console.log('error fetching permissions') }
  if (module == null || name == null) { 
    console.log('Permission API: Required fields cannot be empty!')
    return
  }
  try {
    const response = await API.graphql(graphqlOperation(createPermission, {input: {
      module: module,
      name: name,
      description: description,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString() }}))
      return response?.data?.createPermission
  } catch (err) {
    console.log('error creating permission:', err)
    return
  }
}

export const permissionRead = async () => {
  try {
    const permissions = await loadList(action, attribute, null, 0)
    return permissions
  } catch (err) { 
    console.log('error fetching permissions') 
    return null
  }
}

export const permissionDelete = async (permissionId) => {
  await API.graphql(graphqlOperation(deletePermission, { input: { id: permissionId }}));
  return permissionId
}

export async function fillDefaultPermissions() {
  var perms =[]
  var perm = null
  perm = await permissionCreate(K.PLATFORM_MODULE, K.PLATFORM_ADMIN_PANEL, "Platform admin panel")
  if (perm != null && perm?.name === K.PLATFORM_ADMIN_PANEL) { perms.push(perm) }
  perm = await permissionCreate(K.PLATFORM_MODULE, K.PLATFORM_DASHBOARD, "Platform dashboard")
  if (perm != null && perm?.name === K.PLATFORM_DASHBOARD) { perms.push(perm) }
  perm = await permissionCreate(K.PLATFORM_MODULE, K.PLATFORM_USERS, "Platform users")
  if (perm != null && perm?.name === K.PLATFORM_USERS) { perms.push(perm) }
  perm = await permissionCreate(K.PLATFORM_MODULE, K.PLATFORM_ROLES_AND_PERMISSIONS, "Platform roles and permissions")
  if (perm != null && perm?.name === K.PLATFORM_ROLES_AND_PERMISSIONS) { perms.push(perm) }
  perm = await permissionCreate(K.PLATFORM_MODULE, K.PLATFORM_CHANNEL_MANAGEMENT, "Platform channel management")
  if (perm != null && perm?.name === K.PLATFORM_CHANNEL_MANAGEMENT) { perms.push(perm) }
  perm = await permissionCreate(K.PLATFORM_MODULE, K.PLATFORM_PRODUCTS, "Platform products")
  if (perm != null && perm?.name === K.PLATFORM_PRODUCTS) { perms.push(perm) }
  perm = await permissionCreate(K.PLATFORM_MODULE, K.PLATFORM_EXTRAS, "Platform extras")
  if (perm != null && perm?.name === K.PLATFORM_EXTRAS) { perms.push(perm) }
  perm = await permissionCreate(K.PLATFORM_MODULE, K.PLATFORM_INTEGRATIONS, "Platform integrations")
  if (perm != null && perm?.name === K.PLATFORM_INTEGRATIONS) { perms.push(perm) }
  perm = await permissionCreate(K.PLATFORM_MODULE, K.PLATFORM_CUSTOMIZATIONS, "Platform customizations")
  if (perm != null && perm?.name === K.PLATFORM_CUSTOMIZATIONS) { perms.push(perm) }
  
  perm = await permissionCreate(K.USER_MODULE, K.USER_CAN_HAVE_STRIPE_SUBACCOUNT, "User can have stripe subaccount")
  if (perm != null && perm?.name === K.USER_CAN_HAVE_STRIPE_SUBACCOUNT) { perms.push(perm) }  
  perm = await permissionCreate(K.USER_MODULE, K.USER_CAN_HAVE_EXTERNAL_LINKS, "User can have external links")
  if (perm != null && perm?.name === K.USER_CAN_HAVE_EXTERNAL_LINKS) { perms.push(perm) }   
  perm = await permissionCreate(K.USER_MODULE, K.USER_CAN_MANAGE_TEMPLATE_WORKSPACE, "User can manage template workspace")
  if (perm != null && perm?.name === K.USER_CAN_MANAGE_TEMPLATE_WORKSPACE) { perms.push(perm) }    
  perm = await permissionCreate(K.USER_MODULE, K.USER_CAN_ACCESS_PREMIUM_CONTENT, "User can access premium content")
  if (perm != null && perm?.name === K.USER_CAN_ACCESS_PREMIUM_CONTENT) { perms.push(perm) }

  // Create channel permissions
  perm = await permissionCreate(K.CHANNEL_MODULE, K.CHANNEL_CAN_CREATE, "Channel can create")
  if (perm != null && perm?.name === K.CHANNEL_CAN_CREATE) { perms.push(perm) }
  perm = await permissionCreate(K.CHANNEL_MODULE, K.CHANNEL_CAN_POST, "Channel can post")
  if (perm != null && perm?.name === K.CHANNEL_CAN_POST) { perms.push(perm) }
  perm = await permissionCreate(K.CHANNEL_MODULE, K.CHANNEL_CAN_PUBLISH, "Channel can publish")
  if (perm != null && perm?.name === K.CHANNEL_CAN_PUBLISH) { perms.push(perm) }
  perm = await permissionCreate(K.CHANNEL_MODULE, K.CHANNEL_CAN_EDIT_PUBLIC_CHANNEL_INFO, "Channel can edit channel's item card")
  if (perm != null && perm?.name === K.CHANNEL_CAN_EDIT_PUBLIC_CHANNEL_INFO) { perms.push(perm) }
  perm = await permissionCreate(K.CHANNEL_MODULE, K.CHANNEL_CAN_EDIT_CHANNEL_DESCRIPTION, "Channel can edit channel description")
  if (perm != null && perm?.name === K.CHANNEL_CAN_EDIT_CHANNEL_DESCRIPTION) { perms.push(perm) }

  // Create message permissions
  perm = await permissionCreate(K.MESSAGE_MODULE, K.MESSAGE_CAN_EDIT_MESSAGES, "Message can edit messages")
  if (perm != null && perm?.name === K.MESSAGE_CAN_EDIT_MESSAGES) { perms.push(perm) }
  
  // Create product permissions
  perm = await permissionCreate(K.FEATURE_MODULE, K.FEATURE_USER_INCOGNITO_MODE, "Control over visibility")
  if (perm != null && perm?.name === K.FEATURE_USER_INCOGNITO_MODE) { perms.push(perm) }
  perm = await permissionCreate(K.FEATURE_MODULE, K.FEATURE_CHANNEL_MAXIMUM_NUMBER_OF_USERS_IN_A_PRIVATE_CHANNEL, "Max. # of users in local chats")
  if (perm != null && perm?.name === K.FEATURE_CHANNEL_MAXIMUM_NUMBER_OF_USERS_IN_A_PRIVATE_CHANNEL) { perms.push(perm) }
  perm = await permissionCreate(K.FEATURE_MODULE, K.FEATURE_CHANNEL_MAXIMUM_NUMBER_OF_OWN_PRIVATE_CHANNELS, "Max. # of local Channels")
  if (perm != null && perm?.name === K.FEATURE_CHANNEL_MAXIMUM_NUMBER_OF_OWN_PRIVATE_CHANNELS) { perms.push(perm) }
  perm = await permissionCreate(K.FEATURE_MODULE, K.FEATURE_CHANNEL_MAXIMUM_DEPTH_OF_VISIBLE_MESSAGES_HISTORY, "Max. messages in history")
  if (perm != null && perm?.name === K.FEATURE_CHANNEL_MAXIMUM_DEPTH_OF_VISIBLE_MESSAGES_HISTORY) { perms.push(perm) }
  perm = await permissionCreate(K.FEATURE_MODULE, K.FEATURE_CHANNEL_MAXIMUM_NUMBER_OF_SUBSCRIBED_FREE_PUBLIC_CHANNELS, "Max. # of free public channels")
  if (perm != null && perm?.name === K.FEATURE_CHANNEL_MAXIMUM_NUMBER_OF_SUBSCRIBED_FREE_PUBLIC_CHANNELS) { perms.push(perm) }
  perm = await permissionCreate(K.FEATURE_MODULE, K.FEATURE_LABEL_MAXIMUM_NUMBER_OF_LABELS, "Max. # of local Labels")
  if (perm != null && perm?.name === K.FEATURE_LABEL_MAXIMUM_NUMBER_OF_LABELS) { perms.push(perm) }
  perm = await permissionCreate(K.FEATURE_MODULE, K.FEATURE_MESSAGE_MAXIMUM_LENGTH_OF_A_PLAIN_MESSAGE, "Max. # of characters in message")
  if (perm != null && perm?.name === K.FEATURE_MESSAGE_MAXIMUM_LENGTH_OF_A_PLAIN_MESSAGE) { perms.push(perm) }
  perm = await permissionCreate(K.FEATURE_MODULE, K.FEATURE_MESSAGE_MAXIMUM_IMAGE_SIZE, "Max. size of images")
  if (perm != null && perm?.name === K.FEATURE_MESSAGE_MAXIMUM_IMAGE_SIZE) { perms.push(perm) }
  perm = await permissionCreate(K.FEATURE_MODULE, K.FEATURE_MESSAGE_SENDING_NON_IMAGE_MEDIA_FILES, "Sending PDF and Office")
  if (perm != null && perm?.name === K.FEATURE_MESSAGE_SENDING_NON_IMAGE_MEDIA_FILES) { perms.push(perm) }
  perm = await permissionCreate(K.FEATURE_MODULE, K.FEATURE_MESSAGE_SENDING_RICH_MESSAGES, "Sending Rich Messages")
  if (perm != null && perm?.name === K.FEATURE_MESSAGE_SENDING_RICH_MESSAGES) { perms.push(perm) }
  perm = await permissionCreate(K.FEATURE_MODULE, K.FEATURE_MESSAGE_MAXIMUM_NUMBER_OF_AVAILABLE_USER_TEMPLATES, "Max. # of User Templates")
  if (perm != null && perm?.name === K.FEATURE_MESSAGE_MAXIMUM_NUMBER_OF_AVAILABLE_USER_TEMPLATES) { perms.push(perm) }
  perm = await permissionCreate(K.FEATURE_MODULE, K.FEATURE_MESSAGE_USING_FACTORY_TEMPLATES_IN_RICH_MESSAGES, "Using Factory Templates")
  if (perm != null && perm?.name === K.FEATURE_MESSAGE_USING_FACTORY_TEMPLATES_IN_RICH_MESSAGES) { perms.push(perm) }
  perm = await permissionCreate(K.FEATURE_MODULE, K.FEATURE_EXTRA_MAXIMUM_NUMBER_OF_SUBSCRIBED_FREE_EXTRAS, "Max. # of free Extras")
  if (perm != null && perm?.name === K.FEATURE_EXTRA_MAXIMUM_NUMBER_OF_SUBSCRIBED_FREE_EXTRAS) { perms.push(perm) }
  perm = await permissionCreate(K.FEATURE_MODULE, K.FEATURE_INTEGRATION_FORWARDING_MESSAGES_TO_EMAIL, "Forwarding to email")
  if (perm != null && perm?.name === K.FEATURE_INTEGRATION_FORWARDING_MESSAGES_TO_EMAIL) { perms.push(perm) }
  perm = await permissionCreate(K.FEATURE_MODULE, K.FEATURE_INTEGRATION_FORWARDING_MESSAGES_TO_TELEGRAM, "Forwarding to Telegram")
  if (perm != null && perm?.name === K.FEATURE_INTEGRATION_FORWARDING_MESSAGES_TO_TELEGRAM) { perms.push(perm) }
  perm = await permissionCreate(K.FEATURE_MODULE, K.FEATURE_INTEGRATION_FORWARDING_MESSAGES_TO_BROKER, "Forwarding to Broker")
  if (perm != null && perm?.name === K.FEATURE_INTEGRATION_FORWARDING_MESSAGES_TO_BROKER) { perms.push(perm) }
  perm = await permissionCreate(K.FEATURE_MODULE, K.FEATURE_INTEGRATION_FORWARDING_MESSAGES_TO_API, "Forwarding to API")
  if (perm != null && perm?.name === K.FEATURE_INTEGRATION_FORWARDING_MESSAGES_TO_API) { perms.push(perm) }
  perm = await permissionCreate(K.FEATURE_MODULE, K.FEATURE_INTEGRATION_RETRIEVING_MESSAGES_VIA_RSS, "Integration with Zapier")
  if (perm != null && perm?.name === K.FEATURE_INTEGRATION_RETRIEVING_MESSAGES_VIA_RSS) { perms.push(perm) }

  return perms
}