
export const validateProductUpdate = (original, productId, name, description, features, isPublic, price, trial, item, products) => {
  if (productId == null || name == null || isPublic == null || price == null) {
    console.log('Product API: Required fields cannot be empty!')
    return false
  }
  if (original.price > 0 && isPublic === false) {
    console.log('Product API: Cannot be NOT public because has price!')
    return false
  }
  return true
}