import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  handleStripeListCustomers,
  handleStripeListProduct,
  handleStripeListPrice,
  handleStripeListSubscription,
  handleStripeReadMyAccount,
  handleStripeListAccounts,
  handleStripeListAccountTransfers, handleStripeReadMyCustomer
} from "../../store/actions/BillingActions"
import { USER_CAN_HAVE_STRIPE_SUBACCOUNT } from "../../utils/Constant"
import { getStripeKeyFromConfig } from "../../utils/ConfigsHelpers"

class BillingContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      stripe_key: "",
      isCreatedCustomer: false,
      isProductRead: false,
      isPlanRead: false,
      isSubscriptionRead: false,
      isListAccounts: false,
      isReadMyAccount: false,
      isReadAccountTransfers: false,
    }  
  }

  componentDidMount() {
    // console.log('BILLING - DID MOUNT')
    if (this.state.stripe_key === "") {
      this.handleStripeKey()
    }
  }

  componentDidUpdate() {
    // console.log('BILLING - DID UPDATE')
    if (this.state.stripe_key === "") {
      this.handleStripeKey()
    } else if (this.state.stripe_key !== "" && !this.state.isCreatedCustomer) {
      this.onCustomerRead(this.state.stripe_key, this.props.account?.user?.email)
      this.setState({isCreatedCustomer: true})
    } else if (this.state.isCreatedCustomer && this.props.stripe?.customer != null && !this.state.isProductRead) {
      this.onProductRead(this.state.stripe_key, this.props.stripe?.customer?.id)
      this.setState({isProductRead: true})
    } else if (this.state.isProductRead && !this.state.isPlanRead) {
      this.onPriceRead(this.state.stripe_key, this.props.stripe?.customer?.id)
      this.setState({isPlanRead: true})
    } else if (this.state.isPlanRead && !this.state.isSubscriptionRead) {
      this.onSubscriptionRead(this.state.stripe_key, this.props.stripe?.customer?.id)
      this.setState({isSubscriptionRead: true})
    } else if (this.state.isSubscriptionRead && !this.state.isListAccounts) {
      this.onAccountList(this.state.stripe_key)
      this.setState({isListAccounts: true})
    } else if (this.state.isListAccounts && !this.state.isReadMyAccount && this.props.permissions.some(per => per === USER_CAN_HAVE_STRIPE_SUBACCOUNT)) {
      this.onAccountRead(this.state.stripe_key, this.props.account?.user?.email)
      this.setState({isReadMyAccount: true})
    } else if (this.state.isReadMyAccount && !this.state.isReadAccountTransfers) {
      this.onAccountTransfersRead(this.state.stripe_key, this.props.account?.user?.email)
      this.setState({isReadAccountTransfers: true})
    }
  }

  handleStripeKey = () => {
    if (this.state.stripe_key === "") {
      const stripeKey = getStripeKeyFromConfig(this.props.configs)
      if (stripeKey != null) {
        this.setState({stripe_key: stripeKey})
      }
    }
  }

  onCustomerRead = (stripeKey, email) => {
    // console.log('BILLING - CUSTOMER READ')
    this.props.handleStripeReadMyCustomer(stripeKey, email)
  }

  onProductRead = (stripeKey) => {
    // console.log('BILLING - Product READ')
    this.props.handleStripeListProduct(stripeKey)
  }

  onPriceRead = (stripeKey) => {
    // console.log('BILLING - Plan READ')
    this.props.handleStripeListPrice(stripeKey)
  }

  onSubscriptionRead = (stripeKey, customerId) => {
    // console.log('BILLING - SUBSCRIPTION READ')
    this.props.handleStripeListSubscription(stripeKey, customerId)
  }

  onAccountList = (stripeKey) => {
    // console.log('BILLING - ACCOUNT READ')
    this.props.handleStripeListAccounts(stripeKey)
  }

  onAccountRead = (stripeKey, email) => {
    // console.log('BILLING - ACCOUNT READ')
    this.props.handleStripeReadMyAccount(stripeKey, email)
  }

  onAccountTransfersRead = (stripeKey) => {
    // console.log('BILLING - ACCOUNT TRANSFERS READ')
    this.props.handleStripeListAccountTransfers(stripeKey)
  }

  render() {
    return (
      <div>
        {this.props.children}
      </div>
    )
  }
}

const mapStateToProps = store => {
  return {
    account: store.user,
    configs: store.configs.list,
    stripe: store.stripe,
    permissions: store.permissions, 
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleStripeListCustomers : (stripeKey, email) => dispatch(handleStripeListCustomers(stripeKey, email)),
    handleStripeReadMyCustomer : (stripeKey, email) => dispatch(handleStripeReadMyCustomer(stripeKey, email)),
    handleStripeListProduct : (stripeKey) => dispatch(handleStripeListProduct(stripeKey)),
    handleStripeListPrice : (stripeKey) => dispatch(handleStripeListPrice(stripeKey)),
    handleStripeListSubscription : (stripeKey, customerId) => dispatch(handleStripeListSubscription(stripeKey, customerId)),
    handleStripeListAccounts : (stripeKey) => dispatch(handleStripeListAccounts(stripeKey)),
    handleStripeReadMyAccount : (stripeKey, email) => dispatch(handleStripeReadMyAccount(stripeKey, email)),
    handleStripeListAccountTransfers : (stripeKey) => dispatch(handleStripeListAccountTransfers(stripeKey)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BillingContainer)