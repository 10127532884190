import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'


class FilesTooLarge extends Component {
  constructor(props) {
    super(props)
    this.state = {} 
  }

  toggleModal = () => {
    this.props.onHide()
  }

  render() {
    return (
      <Modal {...this.props}
             onHide={this.toggleModal()}
             centered
             aria-labelledby="contained-modal-title-vcenter"
             className="welcome-screen__modal">
        <div className="welcome-screen text-center">
          <Modal.Body>
            <div className="welcome-screen__container">
            <div className="welcome-screen__container__title">File too large</div>
              <div className="welcome-screen__container__subtitle-smal">The size of the file you're trying to send exceeds a maximum of 10M.</div>
              <div className="d-flex justify-content-end">
                <div className="welcome-screen__container__btn-skip" onClick={this.toggleModal}>Cancel</div>
                <div className="pl-4 welcome-screen__container__btn-next" onClick={this.toggleModal}>Ok</div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    );
  }
}

export default FilesTooLarge
