import { Hub, API, graphqlOperation } from "aws-amplify"
import { listUsers, getUser } from "../../graphql/queries"
import { createUser, deleteUser, updateUser } from "../../graphql/mutations"
import {
  onCreateUser,
  onUpdateUser,
  onDeleteUser,
} from "../../graphql/subscriptions"
import { authSignOut } from "../auth"

import { loadList } from "./shared"
import { isBlankOrNull } from "../../utils/Helpres"
const action = listUsers
const attribute = "listUsers"

export const HUB_OBSERVER_USER = "user"
export const HUB_OBSERVER_SEARCH_USER = "search_user"

export const userCreate = async (
  owner,
  username,
  email,
  name,
  phone,
  isInitialVisit
) => {
  try {
    const filter = { owner: { eq: owner } }
    const users = await loadList(action, attribute, filter, 1)
    if (users?.length > 0) {
      console.log("User already exist!")
      return users[0]
    }
  } catch (err) {
    console.log("error fetching user:", err)
  }
  if (owner != null && username != null && email != null && isInitialVisit) {
    try {
      const response = await API.graphql(
        graphqlOperation(createUser, {
          input: {
            owner: owner,
            email: email.toLowerCase(),
            name: name ?? "Unknown",
            phone: phone ?? "+1",
            username: username.toLowerCase().split('@')[0],
            country: "Unknown",
            publicByName: true,
            publicByUsername: true,
            publicByEmail: true,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
          },
        })
      )
      var createdUser = response?.data?.createUser
      createdUser.isCreated = true
      return createdUser
    } catch (err) {
      console.log("error creating user:", err)
      authSignOut()
      return
    }
  } else {
    console.log("User API: Required fields cannot be empty!")
  }
}

export const searchUserRead = async filter => {
  if (isBlankOrNull(filter)) {
    return
  }
  try {
    const userFilters = {
      or: [
        { name: { eq: filter }, publicByName: { eq: true } },
        {
          username: { eq: filter?.toLowerCase() },
          publicByUsername: { eq: true },
        },
        { email: { eq: filter }, publicByEmail: { eq: true } },
      ],
    }
    const users = await loadList(action, attribute, userFilters, 0)
    return users
  } catch (err) {
    console.log("error fetching users")
    return null
  }
}

export const searchUsersRead = async userIds => {
  try {
    var users = []
    for (const userId of userIds) {
      const resp = await API.graphql(graphqlOperation(getUser, { id: userId }))
      const user = resp?.data?.getUser
      if (user != null) {
        users.push(user)
      }
    }
    return users
  } catch (err) {
    console.log("error fetching users")
    return []
  }
}

export const userRead = async userId => {
  try {
    const resp = await API.graphql(graphqlOperation(getUser, { id: userId }))
    const user = resp?.data?.getUser
    if (user != null) {
      return user
    } else {
      console.log("User not found!")
    }
  } catch (err) {
    console.log("error fetching users")
    return null
  }
}

export const userUpdate = async (
  owner,
  userId,
  username,
  name,
  phone,
  country,
  info,
  publicByName,
  publicByUsername,
  publicByEmail
) => {
  var original = null
  try {
    const exist = await API.graphql(graphqlOperation(getUser, { id: userId }))
    original = exist?.data?.getUser
  } catch (err) {
    console.log("error fetching user:", err)
  }
  if (original?.id === userId) {
    if (
      owner == null ||
      userId == null ||
      username == null ||
      name == null ||
      phone == null ||
      country == null
    ) {
      console.log("User API: Required fields cannot be empty!")
      return
    }
    try {
      // console.log(username)
      const response = await API.graphql(
        graphqlOperation(updateUser, {
          input: {
            id: userId,
            username: username,
            // username: username.toLowerCase(),
            name: name,
            phone: phone,
            country: country,
            info: info,
            publicByName: publicByName ?? original?.publicByName,
            publicByUsername: publicByUsername ?? original?.publicByUsername,
            publicByEmail: publicByEmail ?? original?.publicByEmail,
            updatedAt: new Date().toISOString(),
          },
        })
      )
      return response?.data?.updateUser
    } catch (err) {
      console.log("error update user:", err)
      return
    }
  } else {
    return null
  }
}

export const userDelete = async (owner, userId) => {
  await API.graphql(graphqlOperation(deleteUser, { input: { id: userId } }))
  return userId
}

export const userObserve = async (owner, userId) => {
  // const createSubscription =
  API.graphql(graphqlOperation(onCreateUser, { id: userId })).subscribe({
    next: response => {
      const user = response.value.data.onCreateUser
      Hub.dispatch(HUB_OBSERVER_USER, {
        event: "INSERT",
        data: user,
        message: "create user observe",
      })
    },
  })
  // createSubscription.unsubscribe();
  // const updateSubscription =
  API.graphql(graphqlOperation(onUpdateUser, { id: userId })).subscribe({
    next: response => {
      const user = response.value.data.onUpdateUser
      Hub.dispatch(HUB_OBSERVER_USER, {
        event: "UPDATE",
        data: user,
        message: "create user observe",
      })
    },
  })
  // deleteSubscription.unsubscribe();
  // const deleteSubscription =
  API.graphql(graphqlOperation(onDeleteUser, { id: userId })).subscribe({
    next: response => {
      const user = response.value.data.onDeleteUser
      Hub.dispatch(HUB_OBSERVER_USER, {
        event: "DELETE",
        data: user,
        message: "create user observe",
      })
    },
  })
  // deleteSubscription.unsubscribe();
}

// Admin panel

export const adminUserRead = async filters => {
  try {
    var userFilters = null
    if (filters != null) {
      userFilters = {}
      if (filters?.name != null) {
        userFilters["name"] = { eq: filters?.name }
      }
      if (filters?.phone != null) {
        userFilters["phone"] = { eq: filters?.phone }
      }
      if (filters?.username != null) {
        userFilters["username"] = { eq: filters?.username?.toLowerCase() }
      }
      if (filters?.email != null) {
        userFilters["email"] = { eq: filters?.email?.toLowerCase() }
      }
      if (filters?.createdAt != null) {
        userFilters["createdAt"] = { contains: filters?.createdAt }
      }
      if (filters?.updatedAt != null) {
        userFilters["updatedAt"] = { contains: filters?.updatedAt }
      }
    }
    const users = await loadList(action, attribute, userFilters, 0)
    return users
  } catch (err) {
    console.log("error fetching users")
    return null
  }
}

export const adminUserUpdate = async (
  userId,
  username,
  name,
  phone,
  country,
  info
) => {
  var original = null
  try {
    const exist = await API.graphql(graphqlOperation(getUser, { id: userId }))
    original = exist?.data?.getUser
  } catch (err) {
    console.log("error fetching user:", err)
  }
  if (original?.id === userId) {
    if (userId == null || username == null || name == null || phone == null) {
      console.log("User API: Required fields cannot be empty!")
      return
    }
    try {
      const response = await API.graphql(
        graphqlOperation(updateUser, {
          input: {
            id: userId,
            username: username?.toLowerCase(),
            name: name,
            phone: phone,
            country: country,
            info: info,
            updatedAt: new Date().toISOString(),
          },
        })
      )
      return response?.data?.updateUser
    } catch (err) {
      console.log("error update user:", err)
      return
    }
  } else {
    return null
  }
}
