import { COPYTRADING_API_LIMIT } from "../../utils/Constant"

export const tradeSyncApiUrl = 'https://api.tradesync.io';

export const tradeSyncAuthHeader = () => {
  return {
    'Content-Type': 'application/json',
  }
}

export const tradeSyncHeaders = (token) => {
  return {
    'Authorization': `Basic ${token}`,
    'Content-Type': 'application/json',
  }
}

export const loadTradeSyncList = async (action, headers, customParams) => {
  const nextLoad = async (action, headers, customParams, lastId) => {
    const params = new URLSearchParams({"limit": COPYTRADING_API_LIMIT});
    if (lastId != null) {
      params.append("last_id", lastId)
    }
    try {
      const _ = Object(customParams)?.forEach((value, key) => params.append(key, value))
    } catch (e) { }
    const response = await fetch(action + "?" + params.toString(), {
      method: 'GET',
      headers,
    })
    if (response.ok) {
      const json = await response?.json()
      return json
    } else {
      console.error('tradesync error: ', response.status, response.statusText)
      return null
    }
  }

  var savedItems = []
  var response = await nextLoad(action, headers, customParams, null)
  if (response?.data == null) {
    return []
  }
  var items = response?.data ?? []
  if (items?.length > 0) { savedItems = [...savedItems, ...items] }
  var afterItem = response?.meta?.last_id
  while (afterItem != null) {
    response = await nextLoad(action, headers, customParams, afterItem)
    items = response?.data ?? []
    afterItem = response?.meta?.last_id
    if (items?.length > 0) { savedItems = [...savedItems, ...items] }
  }
  return savedItems
}