import { Hub } from "aws-amplify"
import React, { Component } from "react"
import { Modal } from "react-bootstrap"
import { isBlankOrNull } from "../../../utils/Helpres"
import { HUB_OBSERVER_SEARCH_USER } from "../../../services/api/user"


import { withTranslation } from 'react-i18next'

class EditPrivateChannelModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      channelName: this.props.data.channel?.name,
      channelDescription: this.props.data.channel?.description,
      searchUser: "",
      connectedUsers: [],
      limitConnectedUsers: 0,
      isShowModal: true,
      isShowError: false,
      isShowVisible: !isBlankOrNull(this.props.data.channel?.description),
      isSearchError: false,
    }
  }

  componentDidMount() {
    Hub.listen(HUB_OBSERVER_SEARCH_USER, this.searchObserver)
    const limit = this.props.data?.usersLimitPrivateChannel ?? 1
    this.setState({ limitConnectedUsers: limit })

    const { onSearchUsersRead, channel } = this.props.data
    if (onSearchUsersRead != null) {
      onSearchUsersRead(channel?.shared)
    }
  }

  componentWillUnmount() {
    if (this.searchObserver)
      Hub.remove(HUB_OBSERVER_SEARCH_USER, this.searchObserver)
  }

  searchObserver = async ({ payload: { event, data, message } }) => {
    var newFoundUsers = this.state.connectedUsers
    const foundUsers = data?.filter(u => u?.id != null) ?? []
    newFoundUsers = newFoundUsers.concat(
      foundUsers?.filter(
        u => !this.state.connectedUsers?.some(el => el?.id === u?.id)
      )
    )
    const isSuccess = foundUsers.length > 0
    this.setState({
      searchUser: isSuccess ? "" : this.state.searchUser,
      connectedUsers: newFoundUsers,
      isSearchError: !isSuccess,
    })
  }

  removeConnectedUser = user => {
    var newFoundUsers = this.state.connectedUsers.filter(
      u => u?.id !== user?.id
    )
    this.setState({ connectedUsers: newFoundUsers })
  }

  handleChangeChannelName(event) {
    const { channels } = this.props.data
    const newChannelName = event.target.value?.trim()
    const isShowError =
      isBlankOrNull(newChannelName) ||
      channels?.find(
        ch => ch?.name?.toLowerCase() === newChannelName?.toLowerCase()
      )
    this.setState({
      channelName: event.target.value,
      isShowError: isShowError,
      isShowVisible: !isShowError,
    })
  }

  handleChangeChannelDescription(event) {
    this.setState({ channelDescription: event.target.value })
  }

  handleSearchUser = event => {
    const { onSearchUserRead } = this.props.data
    if (event.key === "Enter" && !isBlankOrNull(event.target.value)) {
      this.setState({ isSearchError: false })
      onSearchUserRead(event.target.value?.trim())
    } else {
      const searchUser = event.target.value
      this.setState({ searchUser: searchUser, isSearchError: false })
    }
  }

  onSaveChannel() {
    const { channelName, channelDescription, connectedUsers } = this.state
    const { channel, channels, onChannelUpdate } = this.props.data

    const newChannelName = channelName?.trim()
    const isShowError =
      isBlankOrNull(newChannelName) ||
      channels?.find(
        ch => ch?.name?.toLowerCase() === newChannelName?.toLowerCase()
      )
    const isSearchError = connectedUsers?.filter(u => u?.id != null)?.length < 1
    var newChannelDesc = channelDescription?.trim()
    if (isBlankOrNull(newChannelDesc)) {
      newChannelDesc = channel?.description
    }
    this.setState({ isShowError: isShowError, isSearchError: isSearchError })
    if (!isShowError && !isSearchError) {
      onChannelUpdate(
        channel,
        newChannelName,
        newChannelDesc,
        channel?.info,
        channel?.public,
        channel?.status,
        channel?.categories,
        connectedUsers
      )
      this.toggleModal()
    }
  }

  toggleModal = () => {
    this.props.onHide()
  }

  render() {
    
    const { t } = this.props

    const { channel } = this.props.data
    const { channelName, channelDescription, searchUser } = this.state

    const allowConnectUser =
      this.state.connectedUsers?.length < this.state.limitConnectedUsers

    return (
      <Modal
        {...this.props}
        onHide={this.toggleModal}
        centered
        aria-labelledby="contained-modal-title-vcenter"
        className="n-modal__modal"
      >
        <div className="n-modal">
          <div className="n-modal__wrapper">
            <div className="n-modal__close" onClick={this.toggleModal}>
              <svg
                width="20rem"
                height="20rem"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M20 2.01429L17.9857 0L10 7.98571L2.01429 0L0 2.01429L7.98571 10L0 17.9857L2.01429 20L10 12.0143L17.9857 20L20 17.9857L12.0143 10L20 2.01429Z" />
              </svg>
            </div>
            <div className="n-modal__header">
              <div className="n-modal__header__icon">
                <svg
                  width="39rem"
                  height="38rem"
                  viewBox="0 0 39 38"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_533_3952)">
                    <path
                      d="M19.5 13.5V24.5"
                      stroke="#203B60"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                    />
                    <path
                      d="M25 19H14"
                      stroke="#203B60"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                    />
                    <path
                      d="M19.5 37C29.4411 37 37.5 28.9411 37.5 19C37.5 9.05887 29.4411 1 19.5 1C9.55887 1 1.5 9.05887 1.5 19C1.5 28.9411 9.55887 37 19.5 37Z"
                      stroke="#203B60"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_533_3952">
                      <rect
                        width="38"
                        height="38"
                        fill="white"
                        transform="translate(0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className="n-modal__header__title">
              {t("modal_channel_text_65")}&nbsp;<b>{channel?.name}</b>
              </div>
              <div className="n-modal__line" />
              <div className="n-modal__content n-modal__content--flex channel-name-guide">
                <div className="n-modal__content__title">
                {t("modal_channel_text_66")}&nbsp;<b>{t("modal_channel_text_67")}</b>
                </div>
                <div className="n-modal__content__required">{t("modal_channel_text_68")}</div>
                <div
                  className={
                    this.state.isShowError ? "n-input error" : "n-input"
                  }
                >
                  <input
                    value={channelName}
                    type="text"
                    // maxLength={20}
                    onChange={this.handleChangeChannelName.bind(this)}
                    placeholder={t("modal_channel_text_71")}
                  />
                  <div className="n-input__error-message">
                    {isBlankOrNull(channelName)
                      ? t("modal_channel_text_69")
                      : t("modal_channel_text_70") }
                  </div>
                </div>
              </div>
              <div
                className={
                  this.state.isShowVisible
                    ? "n-modal__content n-modal__content--flex channel-description-guide"
                    : "n-modal__content n-modal__content--flex is-opacity-30 channel-description-guide"
                }
              >
                <div className="n-modal__content__title">
                {t("modal_channel_text_72")}&nbsp;<b>{t("modal_channel_text_73")}</b>
                </div>
                <div className="n-modal__content__optional">{t("modal_channel_text_74")}</div>
                <div className="n-input">
                  <input
                    value={channelDescription}
                    onChange={this.handleChangeChannelDescription.bind(this)}
                    type="text"
                    placeholder={t("modal_channel_text_75")}
                  />
                </div>
              </div>
              <div className="n-modal__line" />
              {allowConnectUser && (
                <div className="n-modal__content n-modal__content--flex channel-name-guide">
                  <div className="n-modal__content__title">
                  {t("modal_channel_text_76")}&nbsp;<b>{t("modal_channel_text_77")}</b>
                  </div>
                  <div
                    className={
                      this.state.isSearchError ? "n-input error" : "n-input"
                    }
                  >
                    <input
                      value={searchUser}
                      type="text"
                      //   maxLength={40}
                      onChange={this.handleSearchUser.bind(this)}
                      onKeyDown={this.handleSearchUser.bind(this)}
                      placeholder={t("modal_channel_text_78")}
                    />
                    <div className="n-input__error-message">
                    {t("modal_channel_text_79")}
                    </div>
                  </div>
                </div>
              )}
              {this.state.connectedUsers?.length > 0 && (
                <div className="n-modal__content n-modal__content--flex channel-name-guide">
                  <div className="n-modal__content__title">{t("modal_channel_text_80")}</div>
                  {!allowConnectUser && (
                    <div className="n-modal__content__required">
                      {t("modal_channel_text_81")}
                    </div>
                  )}
                  {this.state.connectedUsers?.map(u => (
                    <div key={u?.id}>
                      <b>{u?.name === "Unknown" ? u?.username : u?.name}</b>
                      &nbsp;&nbsp;
                      {this.props.data?.account?.user?.id !== u?.id && (
                        <span onClick={() => this.removeConnectedUser(u)}>
                          x
                        </span>
                      )}
                    </div>
                  ))}
                </div>
              )}
              <div className="n-modal__btns channel-modal-btn-guide">
                <div
                  className="button-lg"
                  onClick={this.onSaveChannel.bind(this)}
                >
                  {t("modal_channel_text_82")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

export default withTranslation()(EditPrivateChannelModal)
